import { Box, Stack, styled, Typography, Radio, Input } from "@mui/material";
import PredictionBg from "../../../assets/images/prediction-bg.svg";
import stadiumBg from "../../../assets/images/stadium_bg.svg";
import { GRAD_COLOR_1, GRADIENT1 } from "../../../utils/constants";

namespace S {
  export const PoolCardWrapper = styled(Stack, {
    shouldForwardProp: (prop) => prop !== "bgType",
  })(({ bgType }: { bgType: string }) => ({ theme }) => ({
    boxSizing: "border-box",
    alignItem: "center",
    borderRadius: "1.25rem",
    overflow: "hidden",
    background: bgType === "live" ? GRAD_COLOR_1 : "#2D2C31",
    width: "35.3125rem",
    height: "48.8125rem",
    padding: "0.3125rem",
    [theme.breakpoints.down("sm")]: {
      width: "100% !important",
      maxWidth: "22.5625rem",
      height: "fit-content",
      maxHeight: "54rem",
    },
  }));

  export const CardHeader = styled(Box)(({ theme }) => ({
    boxSizing: "border-box",
    display: "flex",
    justifyContent: "space-between",
    padding: "0.625rem 1.25rem",
  }));

  export const HeaderText = styled(Typography)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    gap: "0.5rem",
    fontSize: "1.375rem",
    fontWeight: 700,
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
    },
  }));

  export const HeaderLiveText = styled(Typography, {
    shouldForwardProp: (prop) => prop !== "bgType",
  })(({ bgType }: { bgType: string }) => ({ theme }) => ({
    display: "flex",
    alignItems: "center",
    gap: "0.5rem",
    fontSize: "1.375rem",
    fontWeight: 700,
    color: bgType === "live" ? theme.palette.custom.accent.accent_4 : theme.palette.custom.secondary.secondary_1,
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.875rem",
    },
  }));

  export const BallImage = styled("img")(({ theme }) => ({
    [theme.breakpoints.down("sm")]: {
      width: "auto",
      height: "1.25rem",
    },
  }));

  export const LiveImage = styled("img")(({ theme }) => ({
    height: "2rem",
    width: "auto",
    [theme.breakpoints.down("sm")]: {
      height: "1.3125rem",
    },
  }));

  export const CardContent = styled(Box)(({ theme }) => ({
    boxSizing: "border-box",
    backgroundColor: theme.palette.custom.secondary.secondary_4,
    paddingBottom: "1.25rem",
    borderRadius: "0 0 1.25rem 1.25rem",
    display: "flex",
    flexDirection: "column",
    gap: "1.25rem",
    width: "100%",
    height: "44.9375rem",
    [theme.breakpoints.down("sm")]: {
      overflowY: "auto",
      height: "calc(100vh - 260px)",
      minHeight: "calc(100vh - 260px)",
    },
  }));

  export const CardContentLoading = styled(CardContent)(({ theme }) => ({
    alignItems: "center",
    justifyContent: "center",
    flex: 1,
  }));

  export const CurrentMatchContainer = styled(Box)(({ theme }) => ({
    // boxSizing: "border-box",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
    width: "100%",
    height: "10.6875rem",
    minHeight: "10.6875rem",
    backgroundImage: `URL(${stadiumBg})`,
    backgroundRepeat: "no-repeat",
    // backgroundSize: "100% 100%",
    overflow: "hidden",
    [theme.breakpoints.down("sm")]: {},
  }));

  export const MatchFlagContainer = styled(Box)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
    alignItems: "center",
    marginTop: "0.625rem",
  }));

  export const MatchText = styled(Typography)(({ theme }) => ({
    fontSize: "0.75rem",
    fontWeight: 700,
  }));

  export const FlagImage = styled("img")(({ theme }) => ({
    width: "auto",
    height: "4rem",
    [theme.breakpoints.down("sm")]: {
      height: "2.9375rem",
    },
  }));

  export const CupImage = styled("img")(({ theme }) => ({
    height: "auto",
    width: "4.25rem",
    // boxShadow: "-4px 4px 10px 0px #00000080",
    [theme.breakpoints.down("sm")]: {
      width: "3.125rem",
      marginBottom: "0.9375rem",
    },
  }));

  export const MatchStatusContainer = styled(Box)(({ theme }) => ({
    boxSizing: "border-box",
    margin: "0 0.625rem",
    display: "flex",
    width: "calc(100% - 1.25rem)",
    flexDirection: "column",
    gap: "0.5rem",
    padding: "0 0.425rem ",
  }));

  export const StatusHeader = styled(Typography)(({ theme }) => ({
    // padding: "0 0.425rem ",
    boxSizing: "border-box",
    fontSize: "1.125rem",
    fontWeight: 600,
  }));

  export const StatusContent = styled(Box)(({ theme }) => ({
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
    width: "100%",
    background: "#2D2C3166",
  }));

  export const StatusTopContainer = styled(Box)(({ theme }) => ({
    display: "flex",
    gap: "1.5rem",
    width: "calc(100% - 2rem)",
    padding: "1rem",
    borderBottom: "0.0625rem solid #2D2C31",
  }));

  export const IccMensCricketLogoImage = styled("img")(({ theme }) => ({
    width: "auto",
    height: "2.5rem",
  }));

  export const WorldCupStatus = styled(Box)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    gap: "0.25rem",
  }));

  export const WorldCupStatusText = styled(Box)(({ theme }) => ({
    fontSize: "1rem",
  }));

  export const StatusText = styled(Box)(({ theme }) => ({
    fontSize: "0.75rem",
    color: "#FFFFFF80",
    [theme.breakpoints.down("sm")]: {
      fontWeight: 500,
    },
  }));

  export const StatusBottomContainer = styled(Box)(({ theme }) => ({
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
    gap: "0.75rem",
    width: "100%",
    padding: "1rem",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column-reverse",
    },
  }));

  export const LoaderBox = styled(Box)(({ theme }) => ({
    boxSizing: "border-box",
    display: "flex",
    gap: "1.125rem",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  }));

  export const LoaderPart = styled(Box)(({ theme }) => ({
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
    gap: "0.75rem",
    width: "50%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  }));

  export const TextArea = styled(Box)(({ theme }) => ({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  }));

  export const LoaderSubText = styled(Box, {
    shouldForwardProp: (prop) => prop !== "hidable",
  })(({ hidable }: { hidable?: boolean }) => () => ({
    fontSize: "0.875rem",
    visibility: hidable ? "hidden" : "visible",
  }));

  export const Loader = styled(Box, {
    shouldForwardProp: (prop) => prop !== "LoadPercentage" && prop !== "loaderLeft",
  })(({ LoadPercentage, loaderLeft }: { LoadPercentage: number; loaderLeft: boolean }) => ({ theme }) => ({
    display: "flex",
    justifyContent: loaderLeft ? "flex-start" : "flex-end",
    width: "100%",
    height: "0.625rem",
    borderRadius: "1.875rem",
    background: theme.palette.custom.strokes.primary,
    span: {
      width: `${LoadPercentage}%`,
      height: "0.625rem",
      borderRadius: "1.875rem",
      background: GRAD_COLOR_1,
    },
  }));

  export const PredictAmount = styled(Box)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  }));

  export const PredictMainText = styled(Typography)(({ theme }) => ({
    fontWeight: 700,
    fontSize: "1.25rem",
  }));

  export const PredictSubText = styled(Typography)(({ theme }) => ({
    fontSize: "0.875rem",
    fontWeight: 500,
    color: "#FFFFFF80",
  }));

  export const PredictContainer = styled(`div`)(({ theme }) => ({
    boxSizing: "border-box",
    margin: "0 0.625rem",
    display: "flex",
    width: "calc(100% - 1.25rem)",
    flexDirection: "column",
    gap: "0.5rem",
    padding: "0 0.425rem ",
  }));

  export const PredictHeader = styled(Box)(({ theme }) => ({
    boxSizing: "border-box",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
  }));

  export const PredictHeaderMainText = styled(Typography)(({ theme }) => ({
    fontSize: "1.125rem",
    fontWeight: 600,
  }));

  export const YourPredictionContainer = styled(Box)(({ theme }) => ({
    display: "flex",
    gap: "0.5rem",
    alignItems: "center",
  }));

  export const YourPredictionText = styled(Typography)(({ theme }) => ({
    fontSize: "0.75rem",
    lineHeight: 1,
  }));

  export const PredictedCountry = styled(Typography)(({ theme }) => ({
    fontSize: "1.125rem",
    fontWeight: 600,
    lineHeight: 1,
    color: theme.palette.custom.accent.accent_1,
  }));

  export const PredictHeaderLockText = styled(Typography)(({ theme }) => ({
    fontSize: "1.125rem",
    minWidth: "10.3125rem",
    textAlign: "left",
    fontWeight: 600,
    color: theme.palette.custom.accent.accent_2,
  }));

  export const PredictContent = styled(Box)(({ theme }) => ({
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "13.875rem",
    background: "#2D2C3166",
    [theme.breakpoints.down("sm")]: {
      height: "auto",
    },
  }));

  export const PredictBottomContainer = styled(Box)(({ theme }) => ({
    boxSizing: "border-box",
    display: "flex",
    alignItems: "center",
    gap: "1.5rem",
    justifyContent: "center",
    padding: "1.75rem 1rem",
  }));

  export const CricketAnimeLogo = styled("img")(({ theme }) => ({
    height: "6.25rem",
    width: "auto",
    [theme.breakpoints.down("sm")]: {
      height: "3.75rem",
    },
  }));

  export const PredictStatus = styled(Box)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    [theme.breakpoints.down("sm")]: {
      width: "calc(100% - 80px)",
    },
  }));

  export const PredictedStatus = styled(Box)(({ theme }) => ({
    display: "flex",
    alignItems: "flex-end",
    gap: "0.3rem",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "flex-start",
      gap: "0.5rem",
    },
  }));

  export const MobileAlignContainer = styled(Box)(({ theme }) => ({
    display: "flex",
    alignItems: "flex-end",
    gap: "0.3rem",
  }));

  export const PredictedSubText = styled(Typography)(({ theme }) => ({
    fontSize: "0.75rem",
    lineHeight: 1,
    fontWeight: 600,
  }));

  export const PredictedMainText = styled(Typography)(({ theme }) => ({
    fontSize: "1.25rem",
    fontWeight: 600,
    lineHeight: 1,
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.125rem",
    },
  }));

  export const PredictedAmountText = styled(Typography)(({ theme }) => ({
    fontSize: "0.75rem",
    lineHeight: 1,
    fontWeight: 600,
    color: theme.palette.custom.accent.accent_1,
  }));

  export const PredictStatusText = styled(Typography)(({ theme }) => ({
    fontSize: "1.25rem",
    paddingLeft: "0.75rem",
    [theme.breakpoints.down("sm")]: {
      padding: 0,
    },
  }));

  export const PredictSetContainer = styled(Typography)(({ theme }) => ({
    display: "flex",
    gap: "1rem",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      marginTop: "1.25rem",
    },
  }));

  export const RadioContainer = styled(Box)(({ theme }) => ({
    display: "flex",
    gap: "0.5rem",
    alignItems: "center",
  }));

  export const CountryFlagMini = styled("img")(({ theme }) => ({
    height: "0.75rem",
    width: "1.375rem",
    boxShadow: "0rem 0.25rem 0.25rem 0rem #00000040",
  }));

  export const CountryText = styled(Typography)(({ theme }) => ({
    fontSize: "0.875rem",
  }));

  export const OnePointFiveX = styled(Box)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "1.4375rem",
    width: "1.6875rem",
    borderRadius: "0.25rem",
    fontSize: "0.625rem",
    fontWeight: 700,
    color: theme.palette.custom.secondary.secondary_4,
    background: theme.palette.custom.accent.accent_2,
  }));

  export const CommonPoint = styled(OnePointFiveX)(({ theme }) => ({
    background: theme.palette.custom.secondary.secondary_1,
  }));

  export const CustomInput = styled(Input)(({ theme }) => ({
    background: theme.palette.custom.strokes.primary,
    borderRadius: "0.5rem",
    minHeight: "2rem",
    fontSize: "0.75rem",
    width: "16.3125rem",
    paddingLeft: "1rem",
    '& input[type="number"]::-webkit-inner-spin-button, & input[type="number"]::-webkit-outer-spin-button': {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& input:disabled": {
      cursor: "not-allowed",
    },
  }));

  export const InputContainer = styled("span")(({ theme }) => ({
    position: "relative",
  }));
  export const ErrorText = styled(Typography)(({ theme }) => ({
    position: "absolute",
    marginTop: "5px",
    left: "10px",
    fontSize: "10px",
    fontWeight: 600,
    color: theme.palette.error.main,
  }));

  export const LoadingText = styled(Typography)(({ theme }) => ({
    fontSize: "1.5625rem",
    fontWeight: 600,
  }));

  export const PredictionLockedContainer = styled(Box)(({ theme }) => ({
    display: "flex",
    height: "100%",
    width: "100%",
    backgroundImage: `URL(${PredictionBg})`,
    backgroundRepeat: "no-repeat",
    alignItems: "center",
    justifyContent: "center",
    gap: "3rem",
    borderRadius: "4px",
    overflow: "hidden",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      gap: "1rem",
    },
  }));

  export const LockImage = styled("img")(({ theme }) => ({
    [theme.breakpoints.down("sm")]: {
      width: "auto",
      height: "6.25rem",
    },
  }));

  export const PredictTextContainer = styled(Box)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  }));

  export const PredictText = styled(Typography)(({ theme }) => ({
    fontSize: "1.125rem",
    fontWeight: 700,
    fontStyle: "italic",
  }));

  export const PredictLockedSubText = styled(Typography)(({ theme }) => ({
    fontSize: "2.75rem",
    fontWeight: 700,
    fontStyle: "italic",
  }));

  export const PredictionWinTopContainer = styled(Box)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    flexDirection: "column",
    width: "calc(100% - 2rem)",
    height: "8.5313rem",
    padding: "0 1rem",
    gap: "0.5rem",
    borderBottom: "0.0625rem solid #2D2C31",
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "2.8125rem",
      paddingTop: "0.625rem",
    },
  }));

  export const partyWrapper1 = styled("img")(({ theme }) => ({
    position: "absolute",
    height: "2.75rem",
    top: 0,
    left: "18%",
  }));

  export const partyWrapper2 = styled("img")(({ theme }) => ({
    position: "absolute",
    height: "2.75rem",
    top: 0,
    right: "18%",
  }));

  export const partyBell = styled("img")(({ theme }) => ({
    height: "1.5625rem",
  }));

  export const CricketStamp = styled("img")(({ theme }) => ({
    position: "absolute",
    height: "3.5625rem",
    bottom: 0,
    left: "5%",
  }));

  export const Stars = styled("img")(({ theme }) => ({
    position: "absolute",
    height: "3.5625rem",
    bottom: 0,
    right: "5%",
  }));

  export const CongragulationText = styled(Typography)(({ theme }) => ({
    fontWeight: 600,
    color: theme.palette.custom.accent.accent_2,
  }));

  export const CongragulationMainText = styled(Typography)(({ theme }) => ({
    fontSize: "1.625rem",
    lineHeight: "2.375rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "22px",
      textAlign: "center",
    },
  }));

  export const PredictWinBottomContainer = styled(Box)(({ theme }) => ({
    boxSizing: "border-box",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "1.75rem 1rem",
  }));

  export const TextContainer = styled(Box)(({ theme }) => ({
    boxSizing: "border-box",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    minwidth: "18.75rem",
    height: "2rem",
    borderRadius: "0.5rem",
    background: theme.palette.custom.strokes.primary,
  }));

  export const PredictInputText = styled(Typography)(({ theme }) => ({
    fontSize: "0.625rem",
    lineHeight: 1,
    padding: "0 1rem",
  }));
}

export default S;
