import { FC } from "react";
import S from "./gradient-checkbox.styled";

const GradientCheckBox: FC<{ active: boolean; setActive: (val: boolean) => void }> = ({ active, setActive }) => {
  return (
    <div style={{ width: "18px", height: "18px" }}>
      <S.CheckBoxContainer onClick={() => setActive(!active)}>
        <S.InnerNonActiveContainer>
          <S.InnerActiveContainer isActive={active}></S.InnerActiveContainer>
        </S.InnerNonActiveContainer>
      </S.CheckBoxContainer>
    </div>
  );
};

export default GradientCheckBox;
