import { FC, useContext, useEffect, useMemo, useState } from "react";
import { useIdleTimer } from "react-idle-timer";
import { useSelector } from "react-redux";
import { Route, Routes, useLocation, useNavigate, Navigate } from "react-router-dom";
import Tour from "reactour";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { GlobalStyles } from "@mui/material";
import moment from "moment";
import { useAccount } from "wagmi";
import S from "./layout.styled";
import { iercContract } from "../../services/ethereum/contract/iercContract";
import {
  setAnnouncedWinner,
  setInvitedBy,
  setWinnerOverlay,
  updateBalance,
  updateWalletBalance,
  updateRecentWinners,
  reset1KPrediction,
  setShowTour,
  setIsPredictionSucceed,
  setParticipantTxnHash,
  setIsIdle,
  setConfetti,
} from "../../store/slices/user";
import {
  fetchEarnings,
  getAnnouncedWinners,
  getDistributedAmounts,
  getNewUser,
  getReferralEarnings,
  getWhiteListedData,
} from "../../store/slices/user.actions";
import { updateCurrentDraws } from "../../store/slices/wallet.actions";
import { ONE_K_DRAW, PRIMARY, TOUR_CONFIG, YEARN_TOUR_MINUTES, cricketTeams } from "../../utils/constants";
import { ReferrerContext } from "../../utils/contexts/ReferrerContext";
import { getInvitedBy, getOneKCompleted, getWalletBalance } from "../../utils/helpers";
import useEventListener from "../../utils/hooks/eventListener";
import useWalletConnection from "../../utils/hooks/events";
import {
  useAnnouncedWinner,
  useAppStatus,
  useClaimableToken,
  useConfetti,
  useCurrentDraw,
  useCurrentTenKDraw,
  useDraw1kNumber,
  useEnteredDraw,
  useEvents,
  useHasParticipant,
  useIsAppLoading,
  useIsDrawReady,
  useIsPredictionSucceed,
  useIsReady,
  useIsWhitelistLoading,
  useIsWhitelisted,
  useOneKDrawWinners,
  useShowTour,
  useTenKDrawWinners,
  useTicketsSold,
  useUserDetails,
  useUserTickets,
  useWinnerOverlay,
} from "../../utils/hooks/selector";
import { useAppDispatch } from "../../utils/hooks/state";
import { usePolygon } from "../../utils/hooks/switchNetwork";
import { AppStatus, TourGuide } from "../../utils/types";
import { Button } from "../../utils/widgets";
import ConfettiExplode from "../../utils/widgets/confetti-explode-animation";
import MessageCard from "../../utils/widgets/toast/MessageCard";
import GreetingCardModal from "../greetings";
import LuckyModal from "../lucky-modal";
import PredictionSucceedModal from "../modals/prediction-succeed-modal";
import WinningTimerModal from "../modals/winning-timer-modal";
import OneMatch from "../one-match";
import PostConnect from "../post-connect";
import Content from "./content";
import Header from "./header";

const Layout: FC = () => {
  //constructors
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  //local strage
  const whitelistedUser = localStorage.getItem("Whitelist");

  //state values
  const [openWhitelistedModal, setOpenWhitelistedModal] = useState(false);
  const [isTourOpen, setIsTourOpen] = useState(false);
  const [tourConfig, setTourConfig] = useState(TourGuide.none);
  const userDetails = useSelector(useUserDetails);
  const enteredDraw = useSelector(useEnteredDraw);
  const oneKDrawWinners = useSelector(useOneKDrawWinners);
  const tenKDrawWinners = useSelector(useTenKDrawWinners);
  const winnerOverlay = useSelector(useWinnerOverlay);
  const ticketsSold = useSelector(useTicketsSold);
  const currentDraw = useSelector(useCurrentDraw);
  const currentTenKDraw = useSelector(useCurrentTenKDraw);
  const recentWinners = useSelector(useEvents).recentWinners;
  const winnerId = useSelector(useAnnouncedWinner).winnerId;
  const appStatus = useSelector(useAppStatus);
  const showTour = useSelector(useShowTour);
  const userTickets = useSelector(useUserTickets);
  const isPredictionSucceed = useSelector(useIsPredictionSucceed);
  const claimableToken = useSelector(useClaimableToken);
  const isReady = useSelector(useIsReady);
  const isAppLoading = useSelector(useIsAppLoading);
  const draw1kNumber = useSelector(useDraw1kNumber);
  const isDrawReady = useSelector(useIsDrawReady);
  const isWhitelisted = useSelector(useIsWhitelisted);
  const isWhitelistLoading = useSelector(useIsWhitelistLoading);
  const confetti = useSelector(useConfetti);
  const hasParticipant = useSelector(useHasParticipant);

  const context = useContext(ReferrerContext);
  const { isConnected: isWalletConnected, address } = useAccount();

  //constants
  const isOneKReached = Boolean(ticketsSold !== 0 && ticketsSold % draw1kNumber === 0 && +currentDraw * draw1kNumber === ticketsSold);
  const isUserDrawReached = isDrawReady && +userDetails.participatedOneKDrawNumber * draw1kNumber === ticketsSold;

  useWalletConnection();

  usePolygon();

  //Event listeners for app
  useEventListener();

  //functions
  const onIdle = () => {
    dispatch(setIsIdle(true));
  };

  const idleTimer = useIdleTimer({
    onIdle,
    timeout: 1000 * 60 * 5,
    throttle: 500,
  });

  //On new draw started.
  useEffect(() => {
    if (appStatus === AppStatus.drawStarted) {
      dispatch(setWinnerOverlay(false));
      dispatch(updateCurrentDraws(appStatus, ticketsSold));
      dispatch(getDistributedAmounts());
      context.setIsBuyNowDisabled(false);
      dispatch(setAnnouncedWinner());
      dispatch(updateRecentWinners());
      dispatch(reset1KPrediction());
    }
  }, [appStatus]);

  // Redirecting to home when it's not a valid referral
  useEffect(() => {
    if (enteredDraw) return;
    const invitedBy = getInvitedBy(location.pathname);
    if (invitedBy?.referrerId) dispatch(setInvitedBy(invitedBy));
    else if (!invitedBy?.cricket) navigate("/", { replace: true });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enteredDraw]);

  //Winner overlay
  useEffect(() => {
    if (!appStatus || !isDrawReady || appStatus === AppStatus.active || appStatus === AppStatus.drawStarted) return;
    dispatch(getAnnouncedWinners(currentDraw, currentTenKDraw));
  }, [currentDraw, currentTenKDraw, appStatus, isDrawReady]);

  useEffect(() => {
    if (!ticketsSold || !appStatus || !isDrawReady) return;
    if (
      getOneKCompleted(ticketsSold, recentWinners[ONE_K_DRAW].userId, currentDraw, draw1kNumber) ||
      (appStatus !== AppStatus.active && appStatus !== AppStatus.drawStarted)
    )
      dispatch(setWinnerOverlay(true));
  }, [ticketsSold, isWalletConnected, appStatus, isDrawReady]);

  //Update token balance.
  useEffect(() => {
    let tokenBalInterval: ReturnType<typeof setInterval>;
    if (isWalletConnected && userDetails.address) {
      tokenBalInterval = setInterval(async () => {
        let balance = await iercContract.getBalance(userDetails.address);
        let walletBalance = await getWalletBalance(userDetails.address);
        dispatch(updateWalletBalance(Number(walletBalance)));
        dispatch(updateBalance(balance));
      }, 5000);
    }

    return () => clearInterval(tokenBalInterval);
  }, [isWalletConnected, userDetails.address]);

  useEffect(() => {
    if (!isReady) return;
    let fetchInterval: ReturnType<typeof setInterval>;
    dispatch(fetchEarnings(address, isWalletConnected, enteredDraw));
    dispatch(getReferralEarnings(address, isWalletConnected, enteredDraw));
    fetchInterval = setInterval(async () => {
      dispatch(fetchEarnings(address, isWalletConnected, enteredDraw));
    }, 10000);
    if (!enteredDraw || !address || !isWalletConnected) clearInterval(fetchInterval);

    return () => clearInterval(fetchInterval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isReady, enteredDraw, address, oneKDrawWinners.length, tenKDrawWinners.length, ticketsSold, appStatus]);

  //Triggering tour
  useEffect(() => {
    let fetchInterval: ReturnType<typeof setInterval>;
    // const isUserDrawReached = isDrawReady && +userDetails.participatedOneKDrawNumber * draw1kNumber === ticketsSold;
    // const unClaimed = BigInt(claimableToken.unclaimed);
    if (userTickets.ticketNumbers.length && userDetails.address && !winnerOverlay && !isUserDrawReached) {
      fetchInterval = setInterval(async () => {
        const hasUser = await getNewUser(userDetails.address);
        if (hasUser.length) {
          dispatch(setParticipantTxnHash(hasUser[0].transactionHash));
          const enteredTimeStamp = +hasUser[0].blockTimestamp * 1000;
          const currentTimeStamp = +new Date();
          const difference = moment(currentTimeStamp).diff(enteredTimeStamp, "minutes");
          const hasDiff = difference <= 1;
          const isTourAvailable = localStorage.getItem(TourGuide.enteredDraw);
          if (hasDiff && !isTourAvailable && !winnerOverlay) {
            setIsTourOpen(true);
            setTourConfig(TourGuide.enteredDraw);
          }
          // isTourAvailable && !winnerOverlay && dispatch(setShowTour(true));
          clearInterval(fetchInterval);
        }
      }, 3000);
    }
    return () => clearInterval(fetchInterval);
  }, [userTickets.ticketNumbers.length, userDetails.address, isUserDrawReached, isDrawReady, claimableToken.unclaimed]);

  //closing all modals except winner overlay
  useEffect(() => {
    if (winnerOverlay || isAppLoading) {
      dispatch(setIsPredictionSucceed());
      // dispatch(setShowTour(false));
    }
  }, [winnerOverlay, isAppLoading]);

  useEffect(() => {
    if (isReady && isWalletConnected && address) {
      dispatch(getWhiteListedData(address));
    }
  }, [isReady, isWalletConnected, address]);

  useEffect(() => {
    if (!whitelistedUser && !isWhitelistLoading) setOpenWhitelistedModal(true);
    else setOpenWhitelistedModal(false);
  }, [address, isWhitelisted, isWhitelistLoading]);

  // Enabling tour feature
  useEffect(() => {
    if (winnerOverlay || !isReady || isAppLoading) return;
    if (isWalletConnected && address) {
      if (!enteredDraw && !userDetails.id) {
        const isTourAvailable = localStorage.getItem(TourGuide.login);
        if (!isTourAvailable && hasParticipant) {
          setIsTourOpen(true);
          setTourConfig(TourGuide.login);
        }
      } else {
        // setIsTourOpen(false);
        // setTourConfig(TourGuide.none);
        localStorage.setItem(TourGuide.login, "true");
      }
    } else if (!isWalletConnected && !address) {
      const isTourAvailable = localStorage.getItem(TourGuide.logout);
      if (!isTourAvailable) {
        setIsTourOpen(true);
        setTourConfig(TourGuide.logout);
      }
    }
  }, [isWalletConnected, address, winnerOverlay, enteredDraw, isReady, isAppLoading, hasParticipant]);

  // on app load confetti will be shown
  useEffect(() => {
    dispatch(setConfetti(true));
  }, []);

  const onClosingTour = () => {
    setIsTourOpen(false);
    switch (tourConfig) {
      case TourGuide.logout:
        localStorage.setItem(TourGuide.logout, "true");
        break;
      case TourGuide.enteredDraw:
        localStorage.setItem(TourGuide.enteredDraw, "true");
        break;
      case TourGuide.login:
        localStorage.setItem(TourGuide.login, "true");
        break;
      case TourGuide.match:
        localStorage.setItem(TourGuide.match, "true");
        break;
      default:
        break;
    }
  };

  //Fetching live match id
  const fetchLiveMatchId = useMemo(() => {
    let liveMatchId;

    const liveMatch = cricketTeams.find((c) => c.status === "live");
    if (liveMatch) liveMatchId = `${liveMatch.pool}`;

    return liveMatchId;
  }, [location.pathname, cricketTeams.length]);

  //TODO: Remove loader and add suspense to appRouters
  return (
    <S.Layout>
      <GlobalStyles
        styles={(theme) => ({
          "*": {
            "&::-webkit-scrollbar": {
              width: "5px", // for vertical scroll
              height: "5px", // for horizontal scroll
              backgroundColor: "transparent",
              borderRadius: "10px",
            },

            "&::-webkit-scrollbar-thumb": {
              borderRadius: "10px",
              backgroundColor: theme.palette.custom.bg.scroll,
              height: "20px",
            },

            "&::-webkit-scrollbar-track-piece:start": {
              backgroundColor: "transparent",
              marginTop: "20px",
            },

            "&::-webkit-scrollbar-track-piece:end": {
              backgroundColor: "transparent",
              marginBottom: "20px",
            },
            color: theme.palette.custom.secondary.secondary_1,
            // fontSize: "font-size: clamp(2rem, 4vw + 1rem, 3.5rem);",
          },
          ".MuiPopover-root .MuiPopover-paper": {
            borderRadius: theme.spacing(2),
            backgroundColor: theme.palette.custom.secondary.secondary_3,
          },
          ".MuiLinearProgress-root .css-19c4lqn-MuiLinearProgress-root": {
            backgroundColor: `${theme.palette.custom.secondary.secondary_2} !important`,
          },
        })}
      />
      <>
        <Header />
        {(Boolean(winnerId) || isOneKReached) && appStatus && appStatus !== AppStatus.drawHundredKWinner && (
          <MessageCard message="The next draw will start soon!" />
        )}
        <Content>
          <Routes>
            <Route path={"*"} element={<PostConnect />} />
            <Route path={"/cricket/:id"} element={<OneMatch setIsTourOpen={setIsTourOpen} setTourConfig={setTourConfig} />} />
            <Route path="/cricket/*" element={<Navigate to={`${fetchLiveMatchId ? `/cricket/${fetchLiveMatchId}` : "/"}`} />} />
          </Routes>
        </Content>
      </>
      {winnerOverlay && <WinningTimerModal onClose={() => dispatch(setWinnerOverlay(false))} />}
      {showTour && (
        <GreetingCardModal
          open={showTour}
          handleClose={() => {
            dispatch(setShowTour(false));
            dispatch(setParticipantTxnHash());

            const isTourAvailable = localStorage.getItem(TourGuide.enteredDraw);
            if (!isTourAvailable) {
              setIsTourOpen(true);
              setTourConfig(TourGuide.enteredDraw);
            }
          }}
        />
      )}
      {Boolean(isPredictionSucceed.prediction) && (
        <PredictionSucceedModal open={Boolean(isPredictionSucceed.prediction)} handleClose={() => dispatch(setIsPredictionSucceed())} />
      )}
      {openWhitelistedModal && !winnerOverlay && <LuckyModal open={openWhitelistedModal} handleClose={() => setOpenWhitelistedModal(false)} />}
      {confetti && <ConfettiExplode open={confetti} />}
      {Boolean(TOUR_CONFIG[tourConfig].length) && (
        <Tour
          steps={TOUR_CONFIG[tourConfig]}
          isOpen={isTourOpen}
          onRequestClose={onClosingTour}
          rounded={6}
          maskClassName="react-tour-mask"
          className="react-tour-helper"
          prevButton={
            <Button
              buttonType={PRIMARY}
              customStyle={{
                borderRadius: "50%",
                minWidth: "30px",
                maxWidth: "30px",
                height: "30px",
              }}
              containerCustomStyle={{
                display: "flex",
                justifyContent: "center",
                width: "65px",
              }}
              onClick={() => {}}
            >
              <ChevronLeftIcon />
            </Button>
          }
          nextButton={
            <Button
              buttonType={PRIMARY}
              customStyle={{
                borderRadius: "50%",
                minWidth: "30px",
                maxWidth: "30px",
                height: "30px",
              }}
              containerCustomStyle={{
                display: "flex",
                justifyContent: "center",
                width: "65px",
              }}
              onClick={() => {}}
            >
              <ChevronRightIcon />
            </Button>
          }
          lastStepNextButton={
            <Button buttonType={PRIMARY} customStyle={{ height: "35px" }} onClick={() => {}}>
              Done
            </Button>
          }
        />
      )}
    </S.Layout>
  );
};

export default Layout;
