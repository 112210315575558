import { Box, Typography, styled } from "@mui/material";
import { GRAD_COLOR_1 } from "../../utils/constants/index";

namespace S {
  export const BlurContainer = styled(Box)({
    position: "fixed",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 3,
    backdropFilter: "blur(4px)",
  });

  export const OuterContainer = styled(Box)(({ theme }) => ({
    // position: "absolute",
    // top: "50%",
    // left: "50%",
    // transform: "translate(-50%,-50%)",
    width: "480px",
    // height: "670px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "10px",
    overflow: "hidden",

    "::after": {
      content: `""`,
      position: "absolute",
      zIndex: -1,
      background: "linear-gradient(0deg, #EB00FF 37%, #E9B80C 80%)",
      animation: "gradientRotate 2s infinite linear",
      width: "900px",
      height: "900px",
      "@keyframes gradientRotate": {
        to: {
          transform: "rotate(360deg)",
        },
      },
    },
    [theme.breakpoints.down("sm")]: {
      width: "auto",
      // height: "auto",
      boxSizing: "border-box",
      padding: "0px",
    },
  }));

  export const ContentContainer = styled(Box, { shouldForwardProp: (prop) => prop !== "isGap" })<{ isGap: boolean }>(({ theme, isGap }) => ({
    width: "471px",
    maxHeight: "661px",
    height: "88vh",
    zIndex: 5,
    background: "white",
    borderRadius: "10px",
    backgroundColor: theme.palette.custom.secondary.secondary_3,
    boxSizing: "border-box",
    padding: "25px 20px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "start",
    alignItems: "center",
    gap: isGap ? "18px" : "24px",
    overflow: "auto",
    [theme.breakpoints.down("sm")]: {
      // height: "591px",
      // overflow: "auto",
      width: "auto",
      gap: "20px",
    },
  }));

  export const HeadingText = styled(Typography)(({ theme }) => ({
    fontSize: "16px",
    fontWeight: 600,
    lineHeight: "24px",
    color: theme.palette.custom.accent.accent_2,
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
    },
  }));

  export const ImageBox = styled(Box)(({ theme }) => ({}));
  export const CrownImage = styled("img")(({ theme }) => ({}));

  export const GreetText = styled(Typography)(({ theme }) => ({
    textAlign: "center",
    fontSize: "24px",
    fontWeight: 400,
    lineHeight: "40.8px",
    color: "#FFFFFF",
    "& span": {
      fontWeight: 600,
      background: GRAD_COLOR_1,
      webkitBackgroundClip: "text",
      WebkitTextFillColor: "transparent",
      backgroundClip: "text",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "20px",
      lineHeight: "30px",
    },
  }));

  export const SubText = styled(Typography)(({ theme }) => ({
    textAlign: "center",
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "27px",
    color: "#FFFFFF99",

    "& span": {
      color: "#0AE93B",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
    },
  }));

  export const ButtonBox = styled(Box)(({ theme }) => ({
    marginTop: "auto",
  }));

  export const DetailContainer = styled(Box)(({ theme }) => ({
    display: "flex",
    justifyContent: "space-between",
    gap: "5px",
    width: "100%",
  }));

  export const DetailBox = styled(Box)(({ theme }) => ({
    width: "33.3%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    gap: "15px",
  }));

  export const AccentBox = styled(Box, { shouldForwardProp: (prop) => prop !== "isColor" })<{ isColor: string }>(({ theme, isColor }) => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    gap: "5px",
    width: "100%",
    height: "20px",
    background: isColor === "STAKING" ? "#A246D3" : isColor === "PLAYING" ? "#0AE93B" : "#EFB614",
    border: "1px solid #FFFFFF",
  }));

  export const detailImg = styled("img")(({ theme }) => ({
    width: "32px",
  }));

  export const AccentText = styled(Typography)(({ theme }) => ({
    fontSize: "12px",
    fontWeight: 800,
    lineHeight: "20px",
    color: "#16151A",
    textShadow: "1px 1px white",
  }));

  export const DetailText = styled(Typography)(({ theme }) => ({
    fontSize: "12px",
    fontWeight: 500,
    lineHeight: "16px",
    color: "#FFFFFF",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      minHeight: "90px",
    },
  }));
}

export default S;
