import { useState } from "react";
import { useDispatch } from "react-redux";
import StarBorderRoundedIcon from "@mui/icons-material/StarBorderRounded";
import StarRoundedIcon from "@mui/icons-material/StarRounded";
import { Box, Typography } from "@mui/material";
import Rating from "@mui/material/Rating";
import S from "./skill-gaming.styled";
import Play1 from "../../../assets/images/playImg1.png";
import Play2 from "../../../assets/images/playImg2.png";
import Play3 from "../../../assets/images/playImg3.png";
import Skillgame from "../../../assets/images/skillgaming.png";
import { setGameUrl } from "../../../store/slices/user";
import { PRIMARY } from "../../../utils/constants";
import { gameData } from "../../../utils/constants";
import { Button } from "../../../utils/widgets";
import StarRatings from "../../../utils/widgets/star-rating";

const SkillGaming = () => {
  //constants
  const dispatch = useDispatch();

  return (
    <S.GameContainer data-tut="tour-games-list">
      <S.HeadContainer>
        <Box>
          <img src={Skillgame} alt="skill game" />
        </Box>
        <S.GradientText shineText={"#play2yearn"}>#play2yearn</S.GradientText>
      </S.HeadContainer>
      <S.BodyContainer>
        {gameData.map((val, i) => (
          <S.ContentContainer
            onClick={() => {
              dispatch(setGameUrl(val.link));
            }}
            key={i}
          >
            <S.ImageBox>
              <S.GameImage src={val.image} alt="game" />
            </S.ImageBox>
            <S.InnerContainer>
              <S.NameText>{val.name}</S.NameText>
              {/* <S.StyledRating
                className="custom-rating"
                name="simple-controlled"
                value={val.rating}
                onChange={(event, newValue) => {}}
                max={5}
                size="small"
                precision={0.1}
                emptyIcon={
                  <StarRoundedIcon
                    style={{
                      color: "#57565A",
                    }}
                  />
                }
                icon={<StarRoundedIcon sx={{ color: "#FFC107 !important" }} />}
              /> */}
              <StarRatings rating={val.rating} size={20} />
              <S.PlayWinConatiner>
                <S.NameTextGradient>
                  Plays: {"  "}
                  <S.SpanGradient>{val.plays}</S.SpanGradient>
                </S.NameTextGradient>
                <S.NameTextGradient>
                  Winnings: <S.SpanGradient>{val.winnings}</S.SpanGradient>
                </S.NameTextGradient>
                {/* {play === i && (
                  <Button
                    buttonType={PRIMARY}
                    disabled={false}
                    onClick={() => {
                      dispatch(setGameUrl(val.link));
                    }}
                    customStyle={{
                      borderRadius: "4px",
                      width: "35px",
                      minWidth: "40px",
                      height: "20px",
                      padding: "0",
                      fontSize: "10px",
                      border: "2px solid #2D2C31 !important",
                      boxShadow: "0px 4px 4px 0px #5959590D",
                      background: "linear-gradient(0deg, #16151A, #16151A),linear-gradient(0deg, #2D2C31, #2D2C31)",
                    }}
                  >
                    Play
                  </Button>
                )} */}
              </S.PlayWinConatiner>
            </S.InnerContainer>
            <S.ImageContainer>
              <S.PlayImage src={Play1} alt="" />
              <S.PlayImage src={Play2} alt="" />
              <S.PlayImage src={Play3} alt="" />
            </S.ImageContainer>
          </S.ContentContainer>
        ))}
      </S.BodyContainer>
    </S.GameContainer>
  );
};

export default SkillGaming;
