import { FC } from "react";
import S from "./cards.styled";
import ROUND_1_STAMP from "../../../assets/images/round-1-stamp.svg";
import ROUND_2_STAMP from "../../../assets/images/round-2-stamp.svg";
import ROUND_3_STAMP from "../../../assets/images/round-3-stamp.svg";
import YearnCoin from "../../../assets/images/yearn-logo.png";
import { HUNDRED_K, ONE_K, ONE_K_DRAW, TEN_K, TEN_K_DRAW } from "../../constants";
import { getWholeNum, useGetDimensions } from "../../helpers";

type AffiliaterCardProps = {
  ticketId: string;
  userId: string;
  drawNumber: number;
  draw: string;
  isTimerModal?: boolean;
};

const AffiliaterCard: FC<AffiliaterCardProps> = ({ ticketId, userId, drawNumber, draw, isTimerModal = false }) => {
  //constants
  const isSm = useGetDimensions();

  return (
    <>
      <S.AffiliateCardWrapper className="ticket">
        {!Boolean(userId) && (
          <S.AffiliaterBlurContainer>
            <S.TicketTextContainer>
              <S.TicketText>Round {draw === ONE_K_DRAW ? ONE_K : draw === TEN_K_DRAW ? TEN_K : HUNDRED_K} winner will be here!</S.TicketText>
            </S.TicketTextContainer>
          </S.AffiliaterBlurContainer>
        )}
        <S.AffiliaterMainContainer direction={"column"} blurActive={!Boolean(userId)}>
          <S.AffiliaterTopSection direction={"row"}>
            <S.AffliaterTopImageContainer>
              <S.AffliaterTopImage src={YearnCoin} alt="yearn-coin" />
            </S.AffliaterTopImageContainer>
            <S.AffiliaterTopTitleContainer>
              <S.AffiliaterTicketIDTitle align="left">Lucky Pass</S.AffiliaterTicketIDTitle>
              <S.AffiliaterTopTitle variant={isSm ? "h6" : "h5"} align="left">
                YT{getWholeNum(ticketId)}
              </S.AffiliaterTopTitle>
            </S.AffiliaterTopTitleContainer>
          </S.AffiliaterTopSection>
          <S.AffiliaterBottomSection direction={"row"}>
            {!isTimerModal && (
              <S.AffiliaterBottomDrawContainer>
                <S.AffiliaterDrawTitle variant={isSm ? "h5" : "h4"}>{drawNumber}</S.AffiliaterDrawTitle>
                <S.AffiliaterDrawTitle variant="caption">Draw</S.AffiliaterDrawTitle>
              </S.AffiliaterBottomDrawContainer>
            )}
            {/* <VerticalDivider customstyle={{ height: "36px", marginRight: "1rem", borderColor: "rgba(255, 255, 255, 0.3)" }} /> */}
            <S.AffiliaterBottomUserContainer isTimerModal={isTimerModal}>
              <S.AffilaterUserDetailsContainer isTimerModal={isTimerModal}>
                {isTimerModal ? (
                  <S.RecentWinnerGradientText draw={draw}>
                    WINNER OF {draw === ONE_K_DRAW ? ONE_K : draw === TEN_K_DRAW ? TEN_K : HUNDRED_K} DRAW
                  </S.RecentWinnerGradientText>
                ) : (
                  <>
                    <S.AffiliaterDrawSubtitle variant="overline">User ID</S.AffiliaterDrawSubtitle>
                    <S.AffiliaterUserTitle variant="caption">#{userId}</S.AffiliaterUserTitle>
                  </>
                )}
              </S.AffilaterUserDetailsContainer>
              <S.AffiliaterStampContainer>
                <S.AffiliatedStampImage src={draw === ONE_K_DRAW ? ROUND_1_STAMP : draw === TEN_K_DRAW ? ROUND_2_STAMP : ROUND_3_STAMP} alt="stamp" />
              </S.AffiliaterStampContainer>
            </S.AffiliaterBottomUserContainer>
          </S.AffiliaterBottomSection>
        </S.AffiliaterMainContainer>
        <S.HorizontalDivider isTimerModal={isTimerModal} />
      </S.AffiliateCardWrapper>
    </>
  );
};

export default AffiliaterCard;
