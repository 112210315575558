import { FC } from "react";
import S from "./cards.styled";
import { drawCardProps } from "../../../utils/types/cards";
import { HUNDRED_K, TEN_K } from "../../constants";

const DrawCard: FC<drawCardProps> = ({ drawCount, CustomStyle, fontSize }) => {
  return (
    <S.DrawCardWrapper sx={{ ...CustomStyle }}>
      <S.DrawCountWrapper sx={{ ...CustomStyle }}>
        <S.YearnCategory fontSize={fontSize}>Lucky Round {drawCount}</S.YearnCategory>
        <S.DrawCount>{drawCount === HUNDRED_K ? "YEARN FAM" : drawCount === TEN_K ? "YEARN CHAMP" : "YEARN BUDDY"}</S.DrawCount>
      </S.DrawCountWrapper>
    </S.DrawCardWrapper>
  );
};

export default DrawCard;
