import { styled, Input, Box } from "@mui/material";

namespace S {
  export const InputContainer = styled(Box)(({ theme }) => ({
    display: "flex",
    justifyContent: "space-between",
    gap: theme.spacing(2),
    minWidth: "27rem",
    minHeight: "2.75rem",
  }));

  export const CustomInput = styled(Input)(({ readOnly }) => ({ theme }) => ({
    background: theme.palette.custom.secondary.secondary_4,
    borderRadius: "0.5rem",
    maxHeight: "2rem",
    padding: "0 0.5rem",
    border: `0.0625rem solid ${theme.palette.custom.strokes.primary}`,
    input: {
      cursor: readOnly ? "not-allowed" : "",
    },
  }));

  // export const OTPInput = styled(AuthCode)(({ theme }) => ({
  //   input: {
  //     backgroundColor: theme.palette.custom.bg.level,
  //     height: "45px",
  //     width: "20px",
  //     color: theme.palette.custom.secondary.secondary_2,
  //   },
  // }));

  export const AutoCompleteInput = styled("input")(({ readOnly }) => ({ theme }) => ({
    background: theme.palette.custom.secondary.secondary_4,
    borderRadius: "0.5rem",
    maxHeight: "1.9rem",
    padding: "0 0.5rem",
    border: `0.0625rem solid ${theme.palette.custom.strokes.primary}`,
    cursor: readOnly ? "not-allowed" : "",
    width: "95%",
    "&:focus": {
      outline: theme.palette.custom.primary.main,
      border: `3px solid ${theme.palette.custom.primary.main}`,
      boxShadow: "none",
    },
    "&:active": {
      outline: theme.palette.custom.primary.main,
      border: `3px solid ${theme.palette.custom.primary.main}`,
      boxShadow: "none",
    },
    "&::placeholder": {
      // color: theme.palette.custom.secondary.secondary_4,
      color: "#fff6 !important",
      fontWeight: "500",
      fontFamily: "poppins",
    },
  }));
}

export default S;
