import { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Typography } from "@mui/material";
import S from "./header.styled";
import { useIsAppLoading, useMyEarnings, useWinnerOverlay } from "../../../utils/hooks/selector";
import MyEarningsPopover from "../../popovers/MyEarningsPopover";

const MyEarnings: FC = () => {
  //state values
  const myEarnings = useSelector(useMyEarnings);
  const winnerOverlay = useSelector(useWinnerOverlay);
  const isAppLoading = useSelector(useIsAppLoading);

  const [myEarningsPopover, setMyEarningsPopover] = useState<HTMLElement | null>(null);

  //constants
  const totalEarnings = myEarnings.winning + myEarnings.winnerReferrerBonuses;

  //On WinnerTimerModal or isAppLoading Open
  useEffect(() => {
    if (winnerOverlay || isAppLoading) setMyEarningsPopover(null);
  }, [winnerOverlay, isAppLoading]);

  //functions
  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMyEarningsPopover(event.currentTarget);
  };

  const handleClose = () => {
    setMyEarningsPopover(null);
  };

  return (
    <>
      <S.MyEarningsWrapper onClick={handleOpen} data-tut="tour-my-earnings">
        <Typography>My Earnings:</Typography>
        <S.EarningsAmount>$ {totalEarnings}</S.EarningsAmount>
        <KeyboardArrowDownIcon />
      </S.MyEarningsWrapper>
      <S.ReferContainer>
        {myEarningsPopover && (
          <MyEarningsPopover id={Boolean(myEarningsPopover) ? "invite-by" : undefined} isOpen={myEarningsPopover} onClose={handleClose} />
        )}
      </S.ReferContainer>
    </>
  );
};

export default MyEarnings;
