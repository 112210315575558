import { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { IconButton } from "@mui/material";
import S from "./one-match.styled";
import "../../assets/css/carousel.css";
import { PRIMARY, cricketTeams } from "../../utils/constants";
import { useGetDimensions } from "../../utils/helpers";
import { TourGuide } from "../../utils/types";
import { Button } from "../../utils/widgets";
import PoolCard from "./pool-card";

type OneMatchProps = {
  setIsTourOpen: Dispatch<SetStateAction<boolean>>;
  setTourConfig: Dispatch<SetStateAction<TourGuide>>;
};

const OneMatch: FC<OneMatchProps> = ({ setIsTourOpen, setTourConfig }) => {
  //constructors
  const navigate = useNavigate();
  const params = useParams();

  //state values
  const [index, setIndex] = useState(1);

  //constants
  const md = useGetDimensions("md");
  const sm = useGetDimensions("sm");
  const upcomingPool = cricketTeams.find((c) => c.status === "upcoming");
  const livePool = cricketTeams.find((c) => c.status === "live");
  const completedPool = cricketTeams.find((c) => c.status === "completed");
  const expiredPool = cricketTeams.filter((c) => c.status === "expired");
  const upcomingPoolId = upcomingPool?.pool;
  const livePoolId = livePool?.pool;
  const completedPoolId = completedPool?.pool;
  const expiredPoolIds = expiredPool.map((c: any) => c.pool);

  //functions
  const mod = (n: number, m: number) => {
    let result = n % m;
    return result >= 0 ? result : result + m;
  };

  const NavigateDashboard = () => navigate("/");

  const CreatePoolCards = () => {
    // condition to show only expired card, when there is no other status other than expired when page loads
    // if (Number(params.id) === expiredPoolId || (!livePoolId && !upcomingPoolId && !completedPoolId)) {
    if (
      expiredPoolIds.includes(Number(params.id)) ||
      (!(typeof livePoolId === "number") && !(typeof upcomingPoolId === "number") && !(typeof completedPoolId === "number"))
    ) {
      return [
        { id: "2", component: <PoolCard status={"expired"} setIsTourOpen={setIsTourOpen} setTourConfig={setTourConfig} setIndex={setIndex} /> },
      ];
    } else {
      return [
        { id: "1", component: <PoolCard status={"completed"} setIsTourOpen={setIsTourOpen} setTourConfig={setTourConfig} setIndex={setIndex} /> },
        { id: "2", component: <PoolCard status={"live"} setIsTourOpen={setIsTourOpen} setTourConfig={setTourConfig} setIndex={setIndex} /> },
        { id: "3", component: <PoolCard status={"upcoming"} setIsTourOpen={setIsTourOpen} setTourConfig={setTourConfig} setIndex={setIndex} /> },
      ];
    }
  };

  const Cards = CreatePoolCards();

  const NavigateLeft = () => {
    setIndex((index - 1 + 3) % Cards.length);
  };

  const NavigateRight = () => {
    setIndex((index + 1) % Cards.length);
  };

  useEffect(() => {
    if (
      expiredPoolIds.includes(Number(params.id)) ||
      (!(typeof livePoolId === "number") && !(typeof upcomingPoolId === "number") && !(typeof completedPoolId === "number"))
    ) {
      navigate(`/cricket/${Number(params.id)}`);
    } else {
      if (index === 0 && Number(params.id) !== completedPoolId) {
        navigate(`/cricket/${completedPoolId}`);
      }
      if (index === 1 && Number(params.id) !== livePoolId) {
        navigate(`/cricket/${livePoolId}`);
      }
      if (index === 2 && Number(params.id) !== upcomingPoolId) {
        navigate(`/cricket/${upcomingPoolId}`);
      }
    }
  }, [cricketTeams, index, params.id]);

  // when router id changes in browser, it will change the cards
  useEffect(() => {
    const poolWithMatchingId = cricketTeams.find((c) => c.pool === Number(params.id));
    if (poolWithMatchingId) {
      if (poolWithMatchingId.status === "live") {
        setIndex(1);
      } else if (poolWithMatchingId.status === "completed") {
        setIndex(0);
      } else if (poolWithMatchingId.status === "upcoming") {
        setIndex(2);
      }
    }
  }, []);

  return (
    <S.OneMatchWrapper>
      <S.CarouselContainer>
        <S.DashboardReturn onClick={NavigateDashboard}>
          <IconButton>
            <S.CustomLeftArrowIcon />
          </IconButton>
          Dashboard
        </S.DashboardReturn>
        {Cards.map((item, i) => {
          const indexLeft = mod(index - 1, Cards.length);
          const indexRight = mod(index + 1, Cards.length);

          let className = "card";

          if (i === index) {
            className = "card card--active";
          } else if (i === indexRight) {
            className = "card card--right";
          } else if (i === indexLeft) {
            className = "card card--left";
          } else className = "card";

          return (
            <span key={item.id} className={!expiredPoolIds.includes(Number(params.id)) ? className : "card card--active"}>
              {item.component}
            </span>
          );
        })}

        <Button
          buttonType={PRIMARY}
          onClick={NavigateLeft}
          disabled={false}
          customStyle={{
            minWidth: md ? "44px" : "56px",
            maxWidth: md ? "44px" : "56px",
            minHeight: md ? "44px" : "56px",
            maxHeight: md ? "44px" : "56px",
            border: "2px solid #FFFFFF",
            padding: "0",
            borderRadius: "50%",
            position: "absolute",
            top: sm ? "95%" : md ? "50%" : "320px",
            left: sm ? "27%" : md ? "1%" : "15%",
            bottom: md ? "20px" : "unset",
            zIndex: "5",
          }}
        >
          <KeyboardArrowLeftIcon fontSize={"large"} />
        </Button>
        <Button
          buttonType={PRIMARY}
          onClick={NavigateRight}
          disabled={false}
          customStyle={{
            minWidth: md ? "44px" : "56px",
            maxWidth: md ? "44px" : "56px",
            minHeight: md ? "44px" : "56px",
            maxHeight: md ? "44px" : "56px",
            border: "2px solid #FFFFFF",
            padding: "0",
            borderRadius: "50%",
            position: "absolute",
            top: sm ? "95%" : md ? "50%" : "320px",
            right: sm ? "27%" : md ? "1%" : "15%",
            bottom: md ? "20px" : "unset",
            zIndex: "5",
          }}
        >
          <KeyboardArrowRightIcon fontSize={"large"} />
        </Button>
      </S.CarouselContainer>
    </S.OneMatchWrapper>
  );
};

export default OneMatch;
