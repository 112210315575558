import { Box, styled, Typography } from "@mui/material";
import {
  ELLIPSE_GRAD_COLOR_1,
  ELLIPSE_GRAD_COLOR_2,
  ELLIPSE_GRAD_COLOR_3,
  GRADIENT1,
  GRADIENT2,
  GRAD_COLOR_1,
  GRAD_COLOR_2,
  GRAD_COLOR_3,
} from "../../constants";
import { gradient } from "../../types/progress-bar";

namespace S {
  export const EllipseWrapper = styled(Box, {
    shouldForwardProp: (prop) => prop !== "gradient",
  })(({ gradient }: { gradient: gradient }) => ({ theme }) => ({
    height: "2.4375rem",
    width: "2.4375rem",
    borderRadius: "50%",
    alignItems: "center",
    justifyContent: "center",
    background: theme.palette.custom.secondary.secondary_4,
    position: "absolute",
    display: "flex",
    zIndex: 4,
    left: "3rem",
    bottom: "7.25rem",
    border: "3px solid transparent",
    backgroundImage: gradient === GRADIENT1 ? ELLIPSE_GRAD_COLOR_1 : gradient === GRADIENT2 ? ELLIPSE_GRAD_COLOR_2 : ELLIPSE_GRAD_COLOR_3,
    borderImageSlice: 1,
    backgroundOrigin: "border-box",
    backgroundClip: "content-box,border-box",
    [theme.breakpoints.down("md")]: {
      left: "2.25rem",
      bottom: "5.5rem",
      height: "2.1875rem",
      width: "2.1875rem",
    },
    [theme.breakpoints.down("sm")]: {
      left: "1.7188rem",
      bottom: "4.875rem",
      height: "1.8125rem",
      width: "1.8125rem",
    },
  }));

  export const EllipseContainerContent = styled(Typography, {
    shouldForwardProp: (prop) => prop !== "gradient",
  })(({ gradient }: { gradient: gradient }) => ({ theme }) => ({
    background: gradient === GRADIENT1 ? GRAD_COLOR_1 : gradient === GRADIENT2 ? GRAD_COLOR_2 : GRAD_COLOR_3,
    fontSize: "0.875rem",
    fontWeight: 600,
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    [theme.breakpoints.down("md")]: {
      fontSize: "0.75rem",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "0.625rem",
    },
  }));
}

export default S;
