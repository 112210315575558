import { FC, useEffect } from "react";
import { useSelector } from "react-redux";
import { Route, Routes, useNavigate, useLocation } from "react-router-dom";
import IdlePage from "../components/idle-page";
import Layout from "../components/layout";
import SomethingWrong from "../components/something-wrong";
import { CRICKET_PATH_NAME } from "../utils/constants";
import { useEnteredDraw, useIsIdle, useIsSomethingWrong } from "../utils/hooks/selector";
import { ToastContainer } from "../utils/widgets/toast";

const AppRouters: FC = () => {
  //constructors
  const navigate = useNavigate();
  const location = useLocation();

  //state values
  // const { enteredDraw, isSomethingWrong, isIdle } = useUser();
  const enteredDraw = useSelector(useEnteredDraw);
  const isSomethingWrong = useSelector(useIsSomethingWrong);
  const isIdle = useSelector(useIsIdle);

  //Redirecting to home when there is no valid url
  useEffect(() => {
    if (enteredDraw) {
      const pathArray = location.pathname.split("/").filter(Boolean);
      const isCricket = pathArray[0] === CRICKET_PATH_NAME;
      if (pathArray.length && !isCricket && !(pathArray.length === 2)) {
        navigate("/", { replace: true });
      }
    }
  }, [enteredDraw]);

  //TODO: Use Suspence here
  return (
    <Routes>
      <Route
        path={"*"}
        element={
          isSomethingWrong ? (
            <SomethingWrong />
          ) : isIdle ? (
            <IdlePage />
          ) : (
            <>
              <Layout />
              <ToastContainer newestOnTop hideProgressBar={true} pauseOnFocusLoss={false} />
            </>
          )
        }
      />
    </Routes>
  );
};

export default AppRouters;
