import { FC } from "react";
import ListIcon from "@mui/icons-material/List";
import { Typography } from "@mui/material";
import S from "./recent-winner-card.styled";
import { HUNDRED } from "../../../utils/constants";
import { useGetDimensions } from "../../../utils/helpers";
import { WinnerCardTypes } from "../../../utils/types/cards";

export const WinnerCard: FC<WinnerCardTypes.WinnerCard> = ({ children }) => {
  return <S.WinnerCard>{children}</S.WinnerCard>;
};

export const WinnerCardHeader: FC<WinnerCardTypes.WinnerCardHeader> = ({ draw, amount, onClick, disabled, date }) => {
  // constants
  const isSm = useGetDimensions();

  return (
    <S.WinnerCardHeader>
      <S.WinnerHeaderTextContainer>
        <S.WinnerHeaderText variant="subtitle2">{draw !== HUNDRED ? "RECENT" : ""} WINNER</S.WinnerHeaderText>
        <S.WinnerHeaderText variant={isSm ? "subtitle1" : "h6"}>
          {/* OF{` ${draw}`}K DRAW123  */}
          OF LUCKY ROUND {draw === "100" ? 3 : draw === "10" ? 2 : 1}
          <S.WinnerHeaderSpan> {date !== "" ? `on ${date}` : ``} </S.WinnerHeaderSpan>
        </S.WinnerHeaderText>
      </S.WinnerHeaderTextContainer>
      {/* <S.WinningAmountWrapper>
        <Typography variant="h5">${amount}</Typography>
      </S.WinningAmountWrapper> */}
      <S.ListIconButton disabled={disabled} onClick={onClick}>
        <ListIcon />
      </S.ListIconButton>
    </S.WinnerCardHeader>
  );
};

export const WinnerCardBody: FC<WinnerCardTypes.WinnerCardBody> = ({ children }) => {
  return <S.WinnerCardBody>{children}</S.WinnerCardBody>;
};

export const WinnerCardFooter: FC<WinnerCardTypes.WinnerCardFooter> = ({ slot, date, onClick, disabled }) => {
  //TODO: Make this component as custom one, so that we can use this for both 1K and 10K
  return (
    <S.WinnerCardFooter>
      <S.SlotContentWrapper>
        <Typography variant="caption">{date}</Typography>
        <S.SlotContent variant="subtitle2">SLOT : {slot}</S.SlotContent>
      </S.SlotContentWrapper>
      <S.ListIconButton disabled={disabled} onClick={onClick}>
        <ListIcon />
      </S.ListIconButton>
    </S.WinnerCardFooter>
  );
};
