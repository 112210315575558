import { Box, Stack, styled, Typography } from "@mui/material";

namespace S {
  export const CardWrapper = styled(Box)(({ theme }) => ({
    boxSizing: "border-box",
  }));

  export const TicketSold = styled(Stack)(({ theme }) => ({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: theme.spacing(2),
  }));

  export const HeaderAlignment = styled("span")(({ theme }) => ({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: theme.spacing(2),
  }));

  export const HeadingText = styled(Typography)(({ theme }) => ({
    fontSize: "14px",
    fontWeight: 500,
    color: theme.palette.custom.secondary.secondary_1,
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
    },
  }));

  export const FlipCounterWrapper = styled(Box)(({ theme }) => ({
    display: "flex",
    section: {
      width: "100%",
      justifyContent: "space-between !important",
      gap: "0.125rem",

      span: {
        width: "1.4rem !important",
        height: "2.1rem !important",
        textAlign: "center",
        border: "1px solid #2D2C31",
        padding: "1px 5px 0px 1px !important",
        [theme.breakpoints.down("sm")]: {
          width: "0.6rem !important",
          height: "1.1rem !important",
        },
        span: {
          border: "0",
          span: {
            color: `skyblue !important`,
            textAlign: "center",
            fontSize: "1.1rem !important",
            fontWeight: 600,
            background: "linear-gradient(92.48deg, #1967fc 1.43%, #19fcb8 75%) !important",
            webkitBackgroundClip: "text !important",
            WebkitTextFillColor: "transparent !important",
            backgroundClip: "text !important",
            [theme.breakpoints.down("sm")]: {
              fontSize: "0.5rem !important",
            },
          },
        },
      },
    },
  }));
}

export default S;
