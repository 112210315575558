import { FC } from "react";
import { useSelector } from "react-redux";
import S from "./toast.styled";
import { setWinnerOverlay } from "../../../store/slices/user";
import { HUNDRED_K, HUNDRED_K_DRAW, ONE_K, ONE_K_DRAW, TEN_K, TEN_K_DRAW } from "../../constants";
import {
  useAppStatus,
  useCurrentDraw,
  useCurrentTenKDraw,
  useDraw100kNumber,
  useDraw10kNumber,
  useDraw1kNumber,
  useEvents,
  useTicketsSold,
  useWinningTimer,
} from "../../hooks/selector";
import { useAppDispatch } from "../../hooks/state";
import { AppStatus } from "../../types";
import FlipCounter from "../counter/FlipCounter";

type MessageCardProps = {
  message: string;
};

const MessageCardCounter: FC<{ tenKCompleted: boolean; hundredKCompleted: boolean }> = ({ tenKCompleted, hundredKCompleted }) => {
  //state values
  const winningTimer = useSelector(useWinningTimer);
  const appStatus = useSelector(useAppStatus);

  return (
    <S.MessageCardTimerWrapper direction={"row"}>
      <span>
        {tenKCompleted && appStatus === AppStatus.drawOneKWinner
          ? TEN_K
          : hundredKCompleted && appStatus === AppStatus.drawTenKWinner
          ? HUNDRED_K
          : ONE_K}
        winner will be announced in
      </span>
      <S.FlipCounterContainer>
        <FlipCounter width={20} height={35} numbers={winningTimer.hours} isTimer={true} isMessageCounter={true} />
        <S.MessageCardTimerDivider>:</S.MessageCardTimerDivider>
        <FlipCounter width={20} height={35} numbers={winningTimer.minutes} isTimer={true} isMessageCounter={true} />
        <S.MessageCardTimerDivider>:</S.MessageCardTimerDivider>
        <FlipCounter width={20} height={35} numbers={winningTimer.seconds} isTimer={true} isMessageCounter={true} />
      </S.FlipCounterContainer>
    </S.MessageCardTimerWrapper>
  );
};

const MessageCard: FC<MessageCardProps> = ({ message }) => {
  //construtors
  const dispatch = useAppDispatch();

  //state values
  const appStatus = useSelector(useAppStatus);
  const ticketsSold = useSelector(useTicketsSold);
  const currentDraw = useSelector(useCurrentDraw);
  const currentTenKDraw = useSelector(useCurrentTenKDraw);
  const recentWinners = useSelector(useEvents).recentWinners;
  const draw1kNumber = useSelector(useDraw1kNumber);
  const draw10kNumber = useSelector(useDraw10kNumber);
  const draw100kNumber = useSelector(useDraw100kNumber);

  //constants
  const isOneKReached = Boolean(ticketsSold !== 0 && ticketsSold % draw1kNumber === 0 && +currentDraw * draw1kNumber === ticketsSold);
  const tenKCompleted = Boolean(ticketsSold !== 0 && ticketsSold % draw10kNumber === 0 && +currentTenKDraw * draw10kNumber === ticketsSold);
  const hundredKCompleted = Boolean(ticketsSold !== 0 && ticketsSold % draw100kNumber === 0);

  const showCounter =
    (isOneKReached && !recentWinners[ONE_K_DRAW].ticketNumber) ||
    (!recentWinners[TEN_K_DRAW].ticketNumber && tenKCompleted) ||
    (!recentWinners[HUNDRED_K_DRAW].ticketNumber && hundredKCompleted);

  return (
    <S.MessageCard onClick={() => dispatch(setWinnerOverlay(true))}>
      {(appStatus === AppStatus.drawOneKWinner || appStatus === AppStatus.drawTenKWinner || appStatus === AppStatus.onHoldForDraw) && message}
      {showCounter && <MessageCardCounter tenKCompleted={tenKCompleted} hundredKCompleted={hundredKCompleted} />}
    </S.MessageCard>
  );
};

export default MessageCard;
