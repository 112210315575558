import { Stack, styled, Typography, Box } from "@mui/material";
import { GRAD_COLOR_1 } from "../../../../utils/constants";

namespace S {
  export const WinningHeaderWrapper = styled(Stack)(({ theme }) => ({
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("md")]: {
      width: "90%",
      padding: "0 3%",
    },
  }));

  export const WinningHeaderContainer = styled(Stack)(({ theme }) => ({
    padding: `${theme.spacing(6)} ${theme.spacing(6)} 0 ${theme.spacing(6)} `,
    alignItems: "center",
    justifyContent: "space-between",
    ".fadeIn": {
      opacity: 0,
      animation: "fadeAnime 1s ease-in-out forwards",
      "@keyframes fadeAnime": {
        to: {
          opacity: 1,
        },
      },
    },
    [theme.breakpoints.down("md")]: {
      width: "95%",
    },
  }));

  export const WinningTextBoxContainer: any = styled(Stack)(({ theme }) => ({
    minWidth: "25vw",
    maxWidth: "25vw",
    [theme.breakpoints.down("md")]: {
      maxWidth: "unset",
    },
    [theme.breakpoints.down("sm")]: {
      minWidth: "100%",
      minHeight: "92px",
    },
  }));

  export const WinningHeaderText = styled(Typography)(({ theme }) => ({
    fontWeight: 600,
    [theme.breakpoints.down("md")]: {
      fontSize: "12px",
    },
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  }));

  export const WinningHeaderSpan = styled("span")(({ theme }) => ({
    [theme.breakpoints.down("md")]: {
      fontSize: "16px",
      maxWidth: "40vw",
    },
    [theme.breakpoints.down("sm")]: {
      maxWidth: "unset",
    },
  }));

  export const WinningHeaderSpanGradient = styled("span")(({ theme }) => ({
    background: GRAD_COLOR_1,
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    fontWeight: 600,
    [theme.breakpoints.down("md")]: {
      fontSize: "16px",
    },
  }));

  export const WinningImageContainer = styled(Box)(({ theme }) => ({
    width: "100px",
    height: "100px",
    position: "relative",
    [theme.breakpoints.down("md")]: {
      width: "75px",
      height: "75px",
    },
    [theme.breakpoints.down("sm")]: {
      width: "50px",
      height: "50px",
    },
  }));

  export const WinningImage = styled("img")({
    width: "100%",
    height: "100%",
    position: "absolute",
    top: 0,
    left: 0,
    animation: "scale 1s ease-in-out alternate infinite",
    "@keyframes scale": {
      "0%": {
        transform: "scale(0.8)",
      },
      "100%": {
        transform: "scale(1)",
      },
    },
  });

  export const WinningTimerVideoContainer = styled(Box)(() => ({
    width: "95%",
    height: "63px",
    padding: "1.5rem 0",
  }));

  export const WinningTimerVideo = styled("video")(() => ({
    width: "100%",
    height: "100%",
    borderRadius: "12px",
  }));
}

export default S;
