import { Box, Paper, styled, TableCell, TableRow, Typography } from "@mui/material";

namespace S {
  export const TableContainer = styled(Paper)(({ theme }) => ({
    background: theme.palette.custom.secondary.secondary_3,
    padding: `0 ${theme.spacing(6)} ${theme.spacing(2)}`,
    overflowX: "auto",
    maxHeight: "31.25rem",
    minHeight: "12.5rem",
    td: {
      padding: "10px",
    },
  }));

  export const TableHeading = styled(TableCell)(({ theme }) => ({
    fontSize: "0.75rem",
    fontWeight: 600,
    color: "#fff6",
    borderBottom: "none",
    minWidth: "180px",
    backgroundColor: theme.palette.custom.secondary.secondary_3,
    padding: "0.625rem",
    [theme.breakpoints.down("md")]: {
      minWidth: "8rem",
    },
  }));

  export const TableContent = styled(TableCell, {
    shouldForwardProp: (prop) => prop !== "mySelf",
  })(({ mySelf }: { mySelf: boolean }) => ({ theme }) => ({
    fontSize: "0.875rem",
    fontWeight: 500,
    color: mySelf ? theme.palette.custom.accent.accent_2 : theme.palette.custom.secondary.secondary_1,
    borderBottom: "none",
    minWidth: "11.25rem",
    a: {
      textDecoration: "none",
    },
    [theme.breakpoints.down("md")]: {
      minWidth: "8rem",
    },
  }));

  export const TableTicketContent = styled(TableCell, {
    shouldForwardProp: (prop) => prop !== "mySelf" && prop !== "isTenK" && prop !== "isHundredK",
  })(({ mySelf, isTenK, isHundredK }: { mySelf: boolean; isTenK: boolean; isHundredK: boolean }) => ({ theme }) => ({
    fontSize: "0.875rem",
    fontWeight: "bold",
    color: mySelf ? theme.palette.custom.accent.accent_2 : theme.palette.custom.secondary.secondary_1,
    borderBottom: "none",
    minWidth: "11.25rem",
    background: "linear-gradient(92.48deg, #1967FC 1.43%, #19FCB8 75%)",
    webkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    backgroundClip: "text",
    ...(isTenK && {
      background: "linear-gradient(268.66deg, #FFE600 5.41%, #24FF00 93.94%)",
    }),
    ...(isHundredK && {
      background: "linear-gradient(337.35deg, #eb00ff 7.34%, #e9b80c 91.96%)",
    }),
    [theme.breakpoints.down("md")]: {
      minWidth: "8rem",
    },
  }));

  export const HashContainer = styled(TableCell, {
    shouldForwardProp: (prop) => prop !== "mySelf",
  })(({ mySelf }: { mySelf: boolean }) => ({ theme }) => ({
    fontSize: "0.875rem",
    fontWeight: 500,
    color: mySelf ? theme.palette.custom.accent.accent_2 : theme.palette.custom.secondary.secondary_1,
    borderBottom: "none",
    minWidth: "11.25rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    button: {
      svg: {
        fill: mySelf ? theme.palette.custom.accent.accent_2 : theme.palette.custom.secondary.secondary_1,
        height: "0.75em",
        width: "0.75em",
      },
    },
  }));

  export const DetailsWrapper = styled(Box, {
    shouldForwardProp: (prop) => prop !== "mySelf",
  })(({ mySelf }: { mySelf: boolean }) => ({ theme }) => ({
    display: "flex",
    gap: theme.spacing(2),
    alignItems: "center",
    borderBottom: "none",
    p: {
      fontSize: "0.875rem",
      fontWeight: 500,
      color: mySelf ? theme.palette.custom.accent.accent_2 : theme.palette.custom.secondary.secondary_1,
      span: {
        fontSize: theme.spacing(2),
        color: theme.palette.custom.secondary.secondary_2,
      },
    },
  }));

  export const NoWinnerFounderTableRow = styled(TableRow)({});
  export const NoRecordContainer = styled(Box)(() => ({
    minHeight: "9.75rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  }));

  export const NoWinnerFounderContainer = styled(TableCell)({
    // minWidth: "calc(100vw - 16rem)",
    maxWidth: "calc(100vw - 16rem)",
    position: "relative",
    border: "none",
  });

  export const NoWinnerFoundText = styled(Typography)({
    textAlign: "center",
    color: "#fff6",
    border: "none",
  });
}

export default S;
