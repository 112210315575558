import { CSSProperties } from "react";
import ReplyIcon from "@mui/icons-material/Reply";
import { Theme, Box, styled, Typography, IconButton, Popper } from "@mui/material";

namespace S {
  export const Header = styled(Box)(({ theme }: { theme: Theme }) => ({
    height: "5rem",
    padding: "1.5rem 2.5rem",
    backgroundColor: theme.palette.custom.bg.app,
    boxSizing: "border-box",
    borderBottom: `1px solid ${theme.palette.custom.secondary.secondary_3}`,
    boxShadow: "0px 4px 4px rgba(89, 89, 89, 0.05)",
    [theme.breakpoints.down("sm")]: {
      height: "auto",
      padding: "1rem",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
  }));

  export const HeaderMainContainer = styled(Box, {
    shouldForwardProp: (prop) => prop !== "customStyle" && prop !== "validRoute",
  })(({ customStyle, validRoute }: { customStyle?: CSSProperties; validRoute: Boolean }) => ({ theme }) => ({
    // export const HeaderMainContainer = styled(Box)(({ theme }: { theme: Theme }) => ({
    display: "grid",
    gridTemplateColumns: !validRoute ? "1fr 1fr max-content" : "1fr max-content",
    maxWidth: "90rem",
    margin: "0 auto",
    gap: "10px",
    [theme.breakpoints.down("sm")]: {
      minWidth: "100%",
      display: "flex",
      justifyContent: "space-between",
      flexWrap: "wrap",
    },
  }));

  export const HeaderLogo = styled("img")(({ theme }) => ({
    height: "1.5rem",
    alignSelf: "center",
    [theme.breakpoints.down("sm")]: {
      height: "1rem",
    },
    "&:hover": {
      cursor: "pointer",
    },
  }));

  export const LogoutButton = styled(IconButton)(({ theme }) => ({
    borderRadius: "50%",
    background: theme.palette.custom.primary.main,
    height: "2rem",
    width: "2rem",
    "&:hover": {
      background: "#1846dd",
    },
    svg: {
      height: "1.125rem",
      width: "1.125rem",
    },
  }));

  export const HeaderRightContainer = styled(Box)(({ theme }: { theme: Theme }) => ({
    display: "flex",
    gap: "1.25rem",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  }));

  export const ReferContainer = styled(Box)(() => ({
    position: "relative",
  }));

  export const MyEarningsWrapper = styled(Box)(() => ({
    display: "flex",
    gap: "0.3rem",
    cursor: "pointer",
    alignItems: "center",
    width: "max-content",
    svg: {
      color: "#FFFFFF",
    },
    p: {
      color: "#ffffff",
    },
  }));

  export const MyBalanceText = styled(Typography)(({ theme }: { theme: Theme }) => ({
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.875rem",
    },
  }));

  export const EarningsAmount = styled(Typography)(({ theme }: { theme: Theme }) => ({
    color: `${theme.palette.custom.accent.accent_1} !important`,
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.875rem",
    },
  }));

  export const WhiteListedUser = styled(Typography)(({ theme }: { theme: Theme }) => ({
    color: `${theme.palette.custom.accent.accent_2} !important`,
    fontSize: "0.625rem",
    fontWeight: 500,
  }));

  export const UserDetailsWrapper = styled(Box, {
    shouldForwardProp: (prop) => prop !== "customStyle",
  })(({ customStyle }: { customStyle?: CSSProperties }) => ({ theme }) => ({
    display: "flex",
    gap: "0.5rem",
    alignItems: "center",
    position: "relative",

    svg: {
      color: "#FFFFFF",
    },
    p: {
      color: "#ffffff",
    },
    [theme.breakpoints.up("md")]: {
      paddingLeft: "1.25rem",
    },
    ...customStyle,
    [theme.breakpoints.down("sm")]: {
      display: "flex",
    },
  }));

  export const MyProfile = styled(Typography)(({ theme }: { theme: Theme }) => ({
    color: `${theme.palette.custom.secondary.secondary_2} !important`,
  }));

  export const ProfileImage = styled("img")(({ theme }) => ({
    height: "2rem",
  }));

  export const InvitedByText = styled(Typography)(({ theme }: { theme: Theme }) => ({
    color: `${theme.palette.custom.primary.main} !important`,
  }));

  export const HeaderMenu = styled(IconButton)(({ theme }: { theme: Theme }) => ({
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
    svg: {
      color: theme.palette.custom.primary.main,
      fontSize: "2.25rem",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  }));

  export const MenuPopper = styled(Popper)(({ theme }: { theme: Theme }) => ({
    borderRadius: "0.5rem",
    backgroundColor: theme.palette.custom.bg.app,
    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
    zIndex: 101,
    left: "-2rem !important",
  }));

  export const ClaimTokenContainer = styled(Box)({
    position: "relative",
  });

  export const MyEarnedTokensWrapper = styled(Box)({
    display: "flex",
    alignItems: "center",
    gap: "0.5rem",
    svg: {
      cursor: "pointer",
    },
  });

  export const MyEarnedTokensLogo = styled("img")({
    width: "14px",
    height: "14px",
  });

  export const MyEarnedTokenAmount = styled(Typography)(({ theme }) => ({
    color: theme.palette.custom.accent.accent_2,
  }));

  export const HeaderMobileRightContainer = styled(Box)(({ theme }) => ({
    display: "none",
    [theme.breakpoints.down("md")]: {
      display: "flex",
      alignItems: "center",
      // justifyContent: "flex-end",
      gap: "1rem",
      width: "100%",
      justifyContent: "space-between",
    },
  }));

  export const CustomReplyIcon = styled(ReplyIcon)(() => ({
    transform: "scaleX(-1)",
    fontSize: "1rem",
  }));

  export const BuyButton = styled(Box)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    gap: "0.5rem",
    cursor: "pointer",
    borderRadius: "0.5rem",
    padding: "0.5rem 1rem",
    // backgroundColor: theme.palette.custom.primary.main,
    border: `1px solid ${theme.palette.custom.primary.main}`,
    boxShadow: "0px 0px 5px 1px #1967fcc9",
    transitionDuration: "0.3s",
    ".img": {
      height: "1.5rem",
    },
    "&:hover": {
      backgroundColor: "#1846dd",
      transitionDuration: "0.3s",
    },
    // [theme.breakpoints.down("md")]: {
    //   display: "none",
    // },
  }));
}

export default S;
