import { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import S from "./winning-footer-styled";
import { GRADIENT1, GRADIENT2, GRADIENT3, HUNDRED, ONE, PRIMARY, TEN, ROUND_1, ROUND_2, ROUND_3 } from "../../../../utils/constants";
import { useGetDimensions } from "../../../../utils/helpers";
import { useCurrentTenKDraw, useDraw100kNumber, useDraw10kNumber, useEvents, useTicketsSold } from "../../../../utils/hooks/selector";
import { Button } from "../../../../utils/widgets";
import PercentageProgressbar from "../../../../utils/widgets/progress-bar/PercentageProgressbar";

type WinningModalFooterPropsType = {
  amount: string;
  onClose: () => void;
};

const WinningCounterFooter: FC<WinningModalFooterPropsType> = ({ onClose }) => {
  //state values
  const ticketsSold = useSelector(useTicketsSold);
  const events = useSelector(useEvents);
  const currentTenKDraw = useSelector(useCurrentTenKDraw);
  const draw10kNumber = useSelector(useDraw10kNumber);
  const draw100kNumber = useSelector(useDraw100kNumber);
  const [percentage, setPercentage] = useState(0);

  //constants
  const tenKCompleted = Boolean(ticketsSold !== 0 && ticketsSold % draw10kNumber === 0 && +currentTenKDraw * draw10kNumber === ticketsSold);
  const hundredKCompleted = Boolean(ticketsSold !== 0 && ticketsSold % draw100kNumber === 0);
  const isSm = useGetDimensions();

  //functions
  useEffect(() => {
    const interval = setInterval(() => {
      setPercentage((prev) => (prev < 100 ? prev + 1 : 100));
    }, 2500);
    return () => clearInterval(interval);
  }, []);

  const getDrawStatus = (type: string) => {
    let oneKWinner = events && events.recentWinners.ONE_K_DRAW?.userId;
    let tenKWinner = events && events.recentWinners.TEN_K_DRAW?.userId;
    let hundredKWinner = events && events.recentWinners.HUNDRED_K_DRAW?.userId;
    switch (type) {
      case ONE:
        type = Boolean(oneKWinner) ? "OVER" : "NOW";
        break;
      case TEN:
        type = Boolean(oneKWinner) && Boolean(tenKWinner) ? "OVER" : Boolean(oneKWinner) ? "NOW" : "UPNEXT";
        break;
      case HUNDRED:
        type = Boolean(tenKWinner) && Boolean(hundredKWinner) ? "OVER" : Boolean(oneKWinner) && Boolean(tenKWinner) ? "NOW" : "UPNEXT";
        break;
      default:
        type = "UPNEXT";
        break;
    }
    return type;
  };

  const getDrawPercentage = (type: string) => {
    let status = getDrawStatus(type);
    return status === "OVER" ? 100 : status === "UPNEXT" ? 0 : -1;
  };

  return (
    <S.WinningFooterContainer direction={isSm ? "column" : "row"} isMobileView={isSm}>
      {/* <S.WinningAmountBtnContainer>
        <S.WinningAmountBtnElement>
          <S.GradientBG />
          <S.WinningMoneyBagImage src={CashPocket1} alt="money-bag" />
          <S.WinningAmount>${amount}</S.WinningAmount>
        </S.WinningAmountBtnElement>
      </S.WinningAmountBtnContainer> */}
      <S.WinningProgressPercentageContainer direction={"row"}>
        <PercentageProgressbar
          status={getDrawStatus(ONE)}
          value={ROUND_1}
          percentage={getDrawPercentage(ONE) === -1 ? percentage : getDrawPercentage(ONE)}
          gradient={GRADIENT1}
        />
        {tenKCompleted && (
          <PercentageProgressbar
            status={getDrawStatus(TEN)}
            value={ROUND_2}
            percentage={getDrawPercentage(TEN) === -1 ? percentage : getDrawPercentage(TEN)}
            gradient={GRADIENT2}
          />
        )}
        {hundredKCompleted && (
          <PercentageProgressbar
            status={getDrawStatus(HUNDRED)}
            value={ROUND_3}
            percentage={getDrawPercentage(HUNDRED) === -1 ? percentage : getDrawPercentage(HUNDRED)}
            gradient={GRADIENT3}
          />
        )}
      </S.WinningProgressPercentageContainer>
      <Button buttonType={PRIMARY} onClick={onClose} customStyle={{ height: "2rem", width: "25%" }}>
        Close
      </Button>
    </S.WinningFooterContainer>
  );
};

export default WinningCounterFooter;
