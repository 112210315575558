import { FC } from "react";
import { useSelector } from "react-redux";
import { Typography } from "@mui/material";
import S from "./mainboard.styled";
import "../../assets/css/flipCounter.css";
import { hundredKDrawWinningAmount } from "../../utils/constants";
import { useGetDimensions } from "../../utils/helpers";
import { useHundredKWinner } from "../../utils/hooks/selector";
import { CustomCardWrapper } from "../../utils/widgets";
import FlipCounter from "../../utils/widgets/counter/FlipCounter";
import WinningTicketSkeleton from "../../utils/widgets/skeletons/winning-ticket-skeleton";

const WinningNumber: FC = () => {
  //state values
  const hundredKWinner = useSelector(useHundredKWinner);

  //constants
  const isSm = useGetDimensions();
  const hasSkeleton = !hundredKWinner.ticketNumber;

  return (
    <CustomCardWrapper>
      {hasSkeleton ? (
        <WinningTicketSkeleton />
      ) : (
        <S.WinningNumberContainer>
          <S.WinningNumberHeader>
            <S.WinningNumberHeadDetails>
              <Typography variant={isSm ? "caption" : "subtitle2"} lineHeight={1} fontWeight={600}>
                WINNING NUMBER
              </Typography>
              <Typography variant={isSm ? "body1" : "h6"} lineHeight={1} fontWeight={600}>
                OF 100K DRAW
              </Typography>
            </S.WinningNumberHeadDetails>
            <S.WinningAmountWrapper>
              <S.WinningAmountNumber lineHeight={1}>${hundredKDrawWinningAmount}</S.WinningAmountNumber>
            </S.WinningAmountWrapper>
          </S.WinningNumberHeader>
          <S.FlipCounterWrapper className="flip" id="jackpot-flip3">
            <FlipCounter flipNumber={hundredKWinner.ticketNumber} height={114} />
          </S.FlipCounterWrapper>
        </S.WinningNumberContainer>
      )}
    </CustomCardWrapper>
  );
};

export default WinningNumber;
