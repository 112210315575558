import { CSSProperties, FC, ReactNode } from "react";
import { SGradientContainer } from "./index";

type GradientContainerProps = {
  children: ReactNode;
  customStyle: CSSProperties;
  padding?: string;
};

const GradientContainer: FC<GradientContainerProps> = ({ children, customStyle, padding }) => {
  return (
    // add important to padding to make it work
    <SGradientContainer customStyle={customStyle} padding={padding}>
      {children}
    </SGradientContainer>
  );
};

export default GradientContainer;
