import { CSSProperties } from "react";
import { Box, Stack, styled } from "@mui/material";

export namespace S {
  export const Container = styled(Box, {
    shouldForwardProp: (prop) => prop !== "customStyle",
  })(({ customStyle }: { customStyle?: CSSProperties }) => ({
    minHeight: "1.5625rem",
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    ...customStyle,
  }));

  export const DotContainer = styled(Stack)(({ theme }) => ({
    width: "50%",
    maxWidth: "50%",
    gap: theme.spacing(1.1),
    flexDirection: "row",
    alignItems: "center",
    p: {
      fontWeight: "bold",
    },
  }));

  export const Dot = styled(Box, {
    shouldForwardProp: (prop) => prop !== "currentPage",
  })(({ currentPage }: { currentPage: boolean }) => ({ theme }) => ({
    width: currentPage ? "1.875rem" : "0.5rem",
    height: "0.5rem",
    borderRadius: currentPage ? "0.375rem" : "50%",
    transitionDuration: "0.2s",
    backgroundColor: currentPage ? theme.palette.custom.primary.main : theme.palette.custom.secondary.secondary_1,
  }));

  export const NavigatorsContainer = styled(Stack)(({ theme }) => ({
    width: "50%",
    maxWidth: "50%",
    gap: theme.spacing(4),
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
  }));
}
