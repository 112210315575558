import { PredictType } from ".";
import { Round } from "./index";

export namespace UserSlicetypes {
  export interface initialState {
    userDetails: {
      id: string;
      address: string;
      balance: string;
      isFreeUser: boolean;
      participatedOneKDrawNumber: string;
    };
    yearnBalance: string;
    participantTxnHash: string;
    userTickets: UserTickets;
    enteredDraw: boolean;
    hasParticipant: boolean;
    drawStart: boolean;
    showTour: boolean;
    isPredictionSucceed: {
      type: PredictType.free | PredictType.premium | "";
      draw: string;
      prediction: string;
      txnHash: string;
    };
    invitedBy: InvitedBy;
    currentDraw: string;
    currentTenKDraw: string;
    ticketsSold: number;
    selectedRound: Round.roundOne | Round.roundTwo | Round.roundThree;
    oneKDrawWinners: IUsers[] | [];
    tenKDrawWinners: IUsers[] | [];
    hundredKWinner: I100KWinner;
    referrers: Referrers;
    isBuying: { loading: boolean; close: boolean };
    walletBalance: number;
    isAppLoading: boolean;
    topReferrers100K: TopReferrers[] | [];
    topReferrersOneK: {
      [level: string]: TopReferrers[];
    };
    isRequestingHundredKReferrers: boolean;
    isRequestingOneKReferrers: {
      [level: string]: boolean;
    };
    distributedAmounts: DistributedAmounts;
    myEarnings: {
      [type: string]: number;
    };
    isFetchingEarnings: boolean;
    events: {
      recentWinners: RecentWinners;
    };
    isReady: boolean;
    winnerOverlay: boolean;
    connector: string;
    prediction: {
      [user: string]: Prediction;
    };
    isRequestingMyPredictions: boolean;
    myPredictions: {
      ONE_K_DRAW: [] | MyPredictionWinner[];
      HUNDRED_K_DRAW: [] | MyPredictionWinner[];
    };
    predictionAdminApproval: {
      hundredK: string;
      oneK: number[];
    };
    predictionLoader: PredictionLoaderType;
    isFetchingPredicted: boolean;
    isRedeeming: {
      [id: string]: Redeem;
    };
    announcedWinner: { winnerId: string; value: string; slot: string; userId: string };
    isWithdrawingTokens: boolean;
    isWithdrawingPredictTokens: boolean;
    freeUserReferralAmountByLevel: Bonus;
    paidUserReferralAmountByLevel: Bonus;
    appStatus: string;
    predictionWinners: {
      [draw: string]: PredictionWinner[];
    };
    isFetching1KPredictionWinners: {
      [draw: string]: boolean;
    };
    isFetching100KPredictionWinners: boolean;
    prediction100KWinners: PredictionWinner[];
    isFetchingReferrerAddress: boolean;
    isFetchingReferrerId: boolean;
    isInitiatingEventstream: boolean;
    winningTimer: WinningTimer;
    claimableAmount: {
      unclaimed: number;
      claimed: number;
    };
    claimableToken: {
      unclaimed: string;
      claimed: string;
    };
    predictGameToken: {
      claimed: string;
      lastReleased: string;
      unclaimed: string;
    };
    isSomethingWrong: boolean;
    isIdle: boolean;
    //pools
    activePool: string;
    dashboardPool: {
      isRequesting: boolean;
      payload: Pool | null;
    };
    poolParticipants: Participants[] | [];
    nextAvailableAt: string;
    addingYTG: boolean;
    poolInfo: {
      total: string;
      activePool: null | string;
    };
    gameUrl: string;
    isWhitelisted: boolean;
    whitelistLoading: boolean;
    confetti: boolean;
  }

  export type Redeem = { redeeming: boolean; redeemed: boolean };

  export type InvitedBy = null | {
    referrerId: string;
    address: string;
    cricket?: boolean;
  };

  export type IncludeWinners = {
    ticketNumber: string;
    draw: string;
  };

  export type UserTickets = {
    ticketNumbers: string[] | [];
    includeWinners: IncludeWinners[] | [];
  };

  export type EnteringDraw =
    | undefined
    | {
        userDetails: {
          id: string;
          address: string;
          balance: string;
          isFreeUser: boolean;
          participatedOneKDrawNumber: string;
        };
        currentDraw: string;
      };

  export interface IUsers {
    id: string;
    userId: string;
    ticketId: string;
    address: string;
    amount: string;
    blockTimestamp: number;
    drawNumber: number;
    dp: string;
    transactionHash: string;
  }

  export interface I100KWinner {
    id: string;
    userId: string;
    ticketNumber: string;
    address: string;
    blockTimestamp: number;
    drawNumber: number;
    dp: string;
    transactionHash: string;
  }

  export type Referrer = {
    id: string;
    userId: string;
    address: string;
    dp: string;
  };

  export interface IWinner {
    userId: string;
    ticketNumber: string;
    drawNumber: number;
  }

  export interface ITopReferrers extends IUsers {
    referredCount: number;
  }

  export type TopReferrers = {
    _id: string;
    count: number;
    drawNumber: number;
    userId: string;
    dp: string;
  };

  export interface PredictionWinner {
    id: string;
    drawNumber: number;
    winnerToken: string;
    type: string;
    amount: string;
    address: string;
    dp: string;
  }
  export interface MyPredictionWinner extends PredictionWinner {
    transactionHash: string;
  }

  export type Referrers = {
    [level: string]: Referrer[];
  };

  export enum Levels {
    one = "1",
    two = "2",
    three = "3",
    four = "4",
    five = "5",
  }

  export type DistributedAmounts = {
    isRequesting: boolean;
    winningDistributed: string;
    earningDistributed: string;
    referralAmount: string;
    totalUser: string;
    totalPaidUser: string;
    currentDraw: string;
    completedDraws: string;
    completedTenKDraws: string;
  };

  export enum Earnings {
    affiliate = "affiliate",
    winning = "winning",
    winnerReferrerBonuses = "winnerReferrerBonuses",
    winnerReferralPayouts = "winnerReferralPayouts",
    referralPayouts = "referralPayouts",
  }

  export type RecentWinners = {
    [draw: string]: IWinner;
  };

  export type Prediction = {
    [draw: string]: string;
  };

  export type isPredictionSucceedType = PredictType.free | PredictType.premium | "";

  export type Bonus = {
    [draw: string]: number;
  };

  export type WinningTimer = {
    hours: string;
    minutes: string;
    seconds: string;
  };

  //Pools
  export type Pool = {
    id: string;
    poolAddress: string;
    blockTimestamp: string;
    maxAmount: string;
    transactionHash: string;
    deposited: string;
    claimed: string;
    joinedDraw: boolean;
    total: string;
    totalOwners: string;
    withdrawn: string;
    ticketNumber: string;
  };

  export type Owner = {
    id: string;
  };

  export type Participants = {
    deposited: string;
    id: string;
    owner: Owner;
    total: string;
    withdrawn: string;
  };

  export interface PredictionLoaderType {
    freePrediction: PredictionLoaderDrawType;
    paidPrediction: PredictionLoaderDrawType;
  }

  export enum PredictionLoaderKeyType {
    currentDraw = "currentDraw",
    hundredKDraw = "hundredKDraw",
  }
  export interface PredictionLoaderDrawType {
    currentDraw?: boolean;
    hundredKDraw?: boolean;
  }
}

export namespace StakeSliceType {
  export type InitialState = {
    isStaking: boolean;
    stakeTransactionHash: string;
    isWithdrawing: boolean;
    withdrawTransactionHash: string;
    myStakes: { fetching: boolean; payload: string };
    myRewards: { fetching: boolean; payload: string };
    isClaiming: boolean;
    stakeClaimAmount: string;
    lastClaimedTime: { canClaim: boolean; claimAfter: number };
  };
}

// match
export namespace MatchSliceType {
  export type InitialState = {
    minAmount: string;
    maxAmount: string;
    isPredictingPool: boolean;
    isFetchingPoolTotalAmount: boolean;
    poolATotal: string;
    poolBTotal: string;
    isFetchingPoolEndTime: boolean;
    poolEndTime: string;
    isWithDrawingFund: boolean;
    isRedeemRewardLoading: boolean;
  };
}

export enum Pool {
  poolA = "poolA",
  poolB = "poolB",
}
