import { Promise } from "bluebird";
import store from "../../../store";
import {
  setStakeTransactionHash,
  setWithdrawTransactionHash,
  toggleIsClaiming,
  toggleIsStaking,
  toggleIsWithdrawing,
} from "../../../store/slices/stake";
import { setConfetti } from "../../../store/slices/user";
import { getCurrentChainId } from "../../../store/slices/wallet.actions";
import Toast from "../../../utils/widgets/toast";
import StakeContractABI from "../build/yearn_stake.json";
import { YEARN_STAKE_CONTRACT, web3 } from "./web3";

export namespace stakeContract {
  let deployed, web3Contract: any;

  export const setup = async (library?: any) => {
    deployed = false;
    let currentChainId = await getCurrentChainId();

    if (library) {
      web3.setProvider(library);
    }
    const myContractAbi = StakeContractABI.abi as any;

    web3Contract = new web3.eth.Contract(myContractAbi, YEARN_STAKE_CONTRACT[currentChainId]);
    Promise.promisifyAll(web3Contract, { suffix: "Promise" });
    deployed = true;
    return deployed;
  };

  export const getStakedAmount = async (account: string) => await web3Contract.methods.depositedTokens(account).call();

  export const getNumberOfHolders = async () => await web3Contract.methods.getNumberOfHolders().call();

  export const lastClaimedTime = async (account: string) => await web3Contract.methods.lastClaimedTime(account).call();

  export const stakingTime = async (account: string) => await web3Contract.methods.stakingTime(account).call();

  export const totalEarnedTokens = async (account: string) => await web3Contract.methods.totalEarnedTokens(account).call();

  export const cliffTime = async () => await web3Contract.methods.cliffTime().call();

  export const getPendingDivs = async (account: string) => await web3Contract.methods.getPendingDivs(account).call();

  export const totalClaimedRewards = async (account: string) => await web3Contract.methods.totalClaimedRewards(account).call();

  export const getFeeRate = async () => {
    let stakeFee = await web3Contract.methods.stakingFeeRate().call();
    let unstakeFee = await web3Contract.methods.unstakingFeeRate().call();

    return { stakeFee, unstakeFee };
  };

  // Send Methods

  export const deposit = async (amount: string, address: string, cb?: (res: any) => void, handleConnecting?: () => void) => {
    let amountWei = web3.utils.toWei(amount, "ether");
    store.dispatch(toggleIsStaking({ loading: true }));
    let gasPrice = await web3.eth.getGasPrice();

    web3Contract.methods
      .deposit(amountWei)
      .send({ from: address, gaslimit: 1100000, gasPrice: gasPrice })
      .on("transactionHash", (hash: any) => {
        // console.log("hash", hash);
      })
      .on("receipt", (receipt: any) => {
        // console.log(receipt, "receipt");
        if (receipt.status) {
          store.dispatch(setStakeTransactionHash({ hash: receipt.transactionHash }));
          Toast({ message: "Amount staked successfully!", type: "success" });
          store.dispatch(setConfetti(true));
          if (cb) cb(receipt);
        } else Toast({ message: "Stake was not done!", type: "error" });
        store.dispatch(toggleIsStaking({ loading: false }));
      })
      .on("error", (error: any) => {
        console.log("error", error.message);

        if (error.message.includes(":")) {
          let msg = error.message.split(":")[0];
          Toast({ message: msg, type: "error" });
        } else Toast({ message: error.message, type: "error" });
        store.dispatch(toggleIsStaking({ loading: false }));

        if (handleConnecting) handleConnecting();
      });
  };

  export const withdraw = async (amount: string, address: string, cb?: (res: any) => void, handleConnecting?: () => void) => {
    let amountWei = web3.utils.toWei(amount, "ether");
    store.dispatch(toggleIsWithdrawing({ loading: true }));
    let gasPrice = await web3.eth.getGasPrice();

    web3Contract.methods

      .withdraw(amountWei)
      .send({ from: address, gaslimit: 1100000, gasPrice })
      .on("transactionHash", (hash: any) => {
        // console.log("hash", hash);
      })
      .on("receipt", (receipt: any) => {
        // console.log(receipt, "receipt");
        if (receipt.status) {
          store.dispatch(setWithdrawTransactionHash({ hash: receipt.transactionHash }));
          Toast({ message: "Amount withdrawn successfully!", type: "success" });
          if (cb) cb(receipt);
        } else Toast({ message: "Withdraw was not done!", type: "error" });
        store.dispatch(toggleIsWithdrawing({ loading: false }));
      })
      .on("error", (error: any) => {
        console.log("error", error.message);

        if (error.message.includes(":")) {
          let msg = error.message.split(":")[0];
          Toast({ message: msg, type: "error" });
        } else Toast({ message: error.message, type: "error" });
        store.dispatch(toggleIsWithdrawing({ loading: false }));

        if (handleConnecting) handleConnecting();
      });
  };

  export const claimDivs = async (address: string, cb?: (res: any) => void, handleConnecting?: () => void) => {
    try {
      store.dispatch(toggleIsClaiming({ loading: true }));
      let gasPrice = await web3.eth.getGasPrice();

      web3Contract.methods
        .claimDivs()
        .send({ from: address, gaslimit: 1100000, gasPrice })
        .on("transactionHash", (hash: any) => {
          // console.log("hash", hash);
        })
        .on("receipt", (receipt: any) => {
          // console.log(receipt, "receipt");
          if (receipt.status) {
            Toast({ message: "Claimed successfully!", type: "success" });

            if (cb) cb(receipt);
          } else Toast({ message: "Rewards was not claimed!", type: "error" });
          store.dispatch(toggleIsClaiming({ loading: false }));
        })
        .on("error", (error: any) => {
          console.log("error", error.message);

          if (error.message.includes(":")) {
            let msg = error.message.split(":")[0];
            Toast({ message: msg, type: "error" });
          } else Toast({ message: error.message, type: "error" });
          store.dispatch(toggleIsClaiming({ loading: false }));

          if (handleConnecting) handleConnecting();
        });
    } catch (e) {
      store.dispatch(toggleIsClaiming({ loading: false }));
      console.error("claiming stake amount has failed", e);
    }
  };
}
