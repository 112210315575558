import { FC } from "react";
import { SRoundImage } from ".";

type SRoundImagePropsType = {
  src: string;
  alt: string;
  width: number;
  height: number;
};

const RoundImage: FC<SRoundImagePropsType> = ({ src, alt, width, height }) => {
  return <SRoundImage src={src} alt={alt} width={width} height={height} />;
};

export default RoundImage;
