import { FC } from "react";
import { Skeleton } from "@mui/material";
import S from "./stake-yearn-skeleton.styled";

const StakeYearnSkeleton: FC = () => {
  return (
    <S.SkeletonContainer>
      <S.ContentContainer>
        <S.StakeHeading>
          <S.HeadingLeft>
            <Skeleton variant="text" sx={{ fontSize: "2rem", width: "75%" }} />
          </S.HeadingLeft>
          <S.HeadingRight>
            <Skeleton variant="text" sx={{ fontSize: "1.5rem", width: "35%" }} />
            <Skeleton variant="text" sx={{ fontSize: "1.5rem", width: "35%" }} />
          </S.HeadingRight>
        </S.StakeHeading>
        <S.BodyContainer>
          <S.LeftBodyContainer>
            <S.TopBox>
              <Skeleton variant="text" sx={{ fontSize: "1rem", width: "35%" }} />
              <Skeleton variant="rounded" sx={{ height: "40px", width: "93%" }} />
            </S.TopBox>
            <S.BottomBox>
              <Skeleton variant="text" sx={{ fontSize: "1rem", width: "35%" }} />
              <Skeleton variant="rounded" sx={{ height: "40px", width: "93%" }} />
            </S.BottomBox>
          </S.LeftBodyContainer>
          <S.RightBodyContainer>
            <Skeleton variant="rounded" sx={{ height: "50px", width: "50px", marginBottom: "8px" }} />
            <Skeleton variant="text" sx={{ fontSize: "0.8rem", width: "35%" }} />
            <Skeleton variant="text" sx={{ fontSize: "1rem", width: "20%" }} />
            <Skeleton variant="rounded" sx={{ height: "30px", width: "154px" }} />
          </S.RightBodyContainer>
        </S.BodyContainer>
      </S.ContentContainer>
    </S.SkeletonContainer>
  );
};

export default StakeYearnSkeleton;
