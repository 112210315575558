import { useEffect, useRef } from "react";
import S from "./winning-header-styled";
import GiftBox from "../../../../assets/images/Giftbox.svg";
import YearnVideo from "../../../../assets/video/Yearn.mp4";
import { WinningHeaderContent } from "../../../../utils/constants";
import { useGetDimensions } from "../../../../utils/helpers";
import { WinningContentType } from "../../../../utils/types/custom-modal";

const WinningCounterHeader = () => {
  //constants
  let textContainerRef = useRef<HTMLDivElement>(null);
  let text1Ref = useRef<HTMLSpanElement>(null);
  let text2Ref = useRef<HTMLSpanElement>(null);
  let text3Ref = useRef<HTMLSpanElement>(null);
  let FadeAnimeInterval: ReturnType<typeof setInterval> = setInterval(() => {});
  const isSm = useGetDimensions();

  //functions
  const handleFadeAnimation = () => {
    let num = 2;
    const winningText = (text: keyof WinningContentType) => (WinningHeaderContent[num][text] ? WinningHeaderContent[num][text] : "");
    FadeAnimeInterval = setInterval(() => {
      num = num > Object.keys(WinningHeaderContent).length ? 1 : num;
      textContainerRef.current && textContainerRef.current.classList.add("fadeIn");
      let text1 = text1Ref.current && text1Ref.current;
      (text1 as HTMLSpanElement).innerHTML = winningText("text1") as string;
      let text2 = text2Ref.current && text2Ref.current;
      (text2 as HTMLSpanElement).innerHTML = winningText("text2") as string;
      let text3 = text3Ref.current && text3Ref.current;
      (text3 as HTMLSpanElement).innerHTML = winningText("text3") as string;
      num++;
    }, 4000);
  };

  const removeAnime = () => {
    textContainerRef.current && textContainerRef.current.classList.remove("fadeIn");
  };

  useEffect(() => {
    handleFadeAnimation();
    return () => clearInterval(FadeAnimeInterval);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <S.WinningHeaderWrapper>
      <S.WinningHeaderContainer direction={isSm ? "column" : "row"}>
        <S.WinningTextBoxContainer ref={textContainerRef} onAnimationEnd={removeAnime}>
          <S.WinningHeaderText ref={text1Ref} variant="subtitle2">
            FEW MINUTES AWAY...
          </S.WinningHeaderText>
          <S.WinningHeaderText variant="h6">
            <S.WinningHeaderSpan ref={text2Ref}> FROM BEING A </S.WinningHeaderSpan>
            <S.WinningHeaderSpanGradient ref={text3Ref}>WINNER!</S.WinningHeaderSpanGradient>
          </S.WinningHeaderText>
        </S.WinningTextBoxContainer>
        <S.WinningImageContainer>
          <S.WinningImage src={GiftBox} alt="giftbox" />
        </S.WinningImageContainer>
      </S.WinningHeaderContainer>
      <S.WinningTimerVideoContainer>
        <S.WinningTimerVideo src={YearnVideo} muted autoPlay loop />
      </S.WinningTimerVideoContainer>
    </S.WinningHeaderWrapper>
  );
};

export default WinningCounterHeader;
