import { useEffect } from "react";
import confetti from "canvas-confetti";

const ConfettiShoot = () => {
  // constants
  let end = Date.now() + 5 * 2000;

  useEffect(() => {
    // This function will trigger the confetti shoot animation
    handleConfetti();
  }, []);

  //   functions
  const handleConfetti = () => {
    const colors = ["#2f84f8", "#e9a436", "#f069a8"];

    confetti({
      particleCount: 3,
      angle: 60,
      spread: 75,
      origin: { x: 0 },
      colors: colors,
    });
    confetti({
      particleCount: 3,
      angle: 120,
      spread: 75,
      origin: { x: 1 },
      colors: colors,
    });

    if (Date.now() < end) {
      requestAnimationFrame(handleConfetti);
    }
  };

  return <></>;
};

export default ConfettiShoot;
