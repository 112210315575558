import { CSSProperties } from "react";
import { styled, Box, Typography, Stack, IconButton } from "@mui/material";
import { GRAD_COLOR_4 } from "../../../utils/constants";

namespace S {
  export const CustomCardWrapper = styled(Box, { shouldForwardProp: (prop) => prop !== "gradient" })(
    ({ gradient }: { gradient: boolean }) =>
      ({ theme }) => ({
        overflow: "hidden",
        borderRadius: "0.625rem",
        background: gradient ? GRAD_COLOR_4 : theme.palette.custom.secondary.secondary_3,
        padding: theme.spacing(4),
        paddingBottom: theme.spacing(3),
        position: "relative",
        [theme.breakpoints.down("md")]: {
          overflow: "unset !important",
        },
        [theme.breakpoints.down("sm")]: {
          overflow: "hidden !important",
          padding: theme.spacing(2),
        },
      }),
  );

  export const AffiliaterBlurContainer = styled(Stack)({
    position: "absolute",
    top: 0,
    left: 0,
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    width: "100%",
    minHeight: "15rem",
    maxHeight: "15rem",
    zIndex: "1",
    borderRadius: "0.625rem",
    backgroundColor: "rgba(217, 217, 217, 0.01)",
    backdropFilter: "blur(14px)",
  });

  export const TicketTextContainer = styled(Stack)(({ theme }) => ({
    alignItems: "center",
    justifyContent: "center",
    width: "21.125rem",
    height: "3.1875rem",
    borderRadius: "0.5rem",
    border: `0.0625rem solid ${theme.palette.custom.secondary.secondary_1}`,
    background: "rgba(22,21,26,0.7)",
    [theme.breakpoints.down("sm")]: {
      height: "fit-content",
      maxWidth: "90%",
      padding: "0.5rem 0.5rem",
    },
  }));

  export const TicketText = styled(Typography)(({ theme }) => ({
    textAlign: "center",
    fontSize: "1.25rem",
    fontWeight: "600",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1rem",
    },
  }));

  export const AffiliaterMainContainer = styled(Stack)({
    justifyContent: "space-between",
    alignItems: "center",
    height: "100%",
    minHeight: "12.8125rem",
    maxHeight: "12.8125rem",
  });

  export const HorizontalDivider = styled(Box, { shouldForwardProp: (prop) => prop !== "gradient" })(
    ({ gradient }: { gradient: boolean }) =>
      ({ theme }) => ({
        position: "absolute",
        bottom: "5.3125rem",
        left: "0",
        width: "100%",
        borderTop: gradient ? `0.0625rem solid ${theme.palette.custom.strokes.primary}` : "1px solid rgba(255, 255, 255, 0.2)",
        ":before": {
          content: '""',
          width: "30px",
          height: "30px",
          borderRadius: "50%",
          backgroundColor: theme.palette.custom.secondary.secondary_4,
          transform: "translate(-50%,-50%)",
          position: "absolute",
          left: "0",
          [theme.breakpoints.down("sm")]: {
            backgroundColor: theme.palette.custom.secondary.secondary_3,
          },
        },
        ":after": {
          content: '""',
          width: "30px",
          height: "30px",
          borderRadius: "50%",
          backgroundColor: theme.palette.custom.secondary.secondary_4,
          position: "absolute",
          transform: "translate(50%,-50%)",
          right: "0",
          [theme.breakpoints.down("sm")]: {
            backgroundColor: theme.palette.custom.secondary.secondary_3,
          },
        },
      }),
  );

  export const HorizontalBorder = styled(Box)({
    width: "30px",
    height: "30px",
    borderRadius: "50%",
    backgroundColor: "red",
    position: "absolute",
    left: "0",
  });

  export const AffiliaterTopSection = styled(Stack)(({ theme }) => ({
    width: "100%",
    height: "7.5rem",
    justifyContent: "space-evenly",
  }));

  export const TicketIdContainer = styled(Stack)(({ theme }) => ({
    alignItems: "center",
    justifyContent: "flex-start",
    gap: theme.spacing(4),
    width: "100%",
    height: "3.75rem",
  }));

  export const NoteContainer = styled(Stack)(({ theme }) => ({
    height: "2.1875rem",
    alignItems: "center",
    gap: theme.spacing(1.5),
    paddingLeft: theme.spacing(2),
    marginTop: theme.spacing(4),
  }));

  export const NoteText = styled(Typography)(({ theme }) => ({
    fontWeight: "500",
    letterSpacing: "0.02222rem",
  }));

  export const IBtnContainer = styled(IconButton)(({ theme }) => ({
    background: theme.palette.custom.primary.main,
    maxHeight: "1rem",
    maxWidth: "1rem",
    height: "1rem",
    pointerEvents: "auto",
    alignSelf: "center",
    "&:hover": {
      backgroundColor: "#1846dd",
    },
    p: {
      lineHeight: "0",
      fontSize: "0.7375rem",
      color: theme.palette.custom.secondary.secondary_1,
    },
  }));

  export const AffliaterTopImageContainer = styled(Box)({
    width: "fit-content",
  });

  export const AffliaterTopImage = styled("img", {
    shouldForwardProp: (prop) => prop !== "type",
  })(({ type }: { type: boolean }) => ({ theme }) => ({
    width: type ? "3.75rem" : "4.8125rem",
    height: type ? "3.75rem" : "4.8125rem",
    [theme.breakpoints.down("sm")]: {
      width: "3.25rem",
      height: "3.25rem",
    },
  }));

  export const AffiliaterTopTitleContainer = styled(Box)({
    flex: 1.5,
  });

  export const AffiliaterTopTitle = styled(Typography, { shouldForwardProp: (prop) => prop !== "customStyles" })(
    ({ customStyles }: { customStyles?: CSSProperties }) =>
      () => ({
        fontWeight: 600,
        ...customStyles,
        // whiteSpace: "nowrap",
      }),
  );

  export const AffiliaterBottomSection = styled(Stack)(({ theme }) => ({
    boxSizing: "border-box",
    alignItems: "center",
    justifyContent: "flex-start",
    width: "97%",
    height: "3.9375rem",
    gap: theme.spacing(4),
    paddingLeft: theme.spacing(1),
  }));

  export const AffiliaterBottomDrawContainer = styled(Box)({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  });

  export const AffiliaterDrawAmount = styled(Typography)({
    fontSize: "2.375rem",
    fontWeight: 600,
    textAlign: "center",
  });

  export const AffiliaterDrawTitle = styled(Typography)({
    fontWeight: 500,
    textAlign: "center",
  });

  export const AffiliaterDrawSubtitle = styled(Typography)({
    fontWeight: 500,
  });

  export const AffiliaterBottomUserContainer = styled(Box)({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flex: 1,
  });

  export const AffilaterUserDetailsContainer = styled(Box)({
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "space-evenly",
    height: "100%",
  });

  export const AffiliaterUserTitle = styled(Typography)({
    fontSize: "1.125rem",
    fontWeight: 600,
    textAlign: "center",
  });

  export const AffiliaterQRContainer = styled(Box)(({ theme }) => ({
    position: "relative",
    width: "3.125rem",
    height: "3.125rem",
    cursor: "pointer",
    margin: `0 ${theme.spacing(1)}`,
  }));

  export const TapToScan = styled(Box)(({ theme }) => ({
    position: "absolute",
    textAlign: "center",
    width: "70px",
    height: "14px",
    border: `1px solid ${theme.palette.custom.secondary.secondary_1}`,
    color: theme.palette.custom.secondary.secondary_1,
    backgroundColor: theme.palette.custom.secondary.secondary_3,
    fontSize: "0.5938rem",
    lineHeight: "1.6",
    fontWeight: "600",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  }));

  export const WinnerStamp = styled("img")({
    height: "107px",
    marginBottom: "15px",
    marginRight: "-20px",
  });
}

export default S;
