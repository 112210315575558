import { FC } from "react";
import S from "./gradient-container.styled";
import { CustomModalTypes } from "../../types/custom-modal";

const GradientModalContainer: FC<CustomModalTypes.Container> = ({ children, open, customStyle, className, onClose, isLoading }) => {
  const handleClose = (event: {}, reason: "backdropClick" | "escapeKeyDown") => {
    if (reason === "backdropClick" && !isLoading) {
      if (onClose) onClose();
    }
  };
  return (
    <S.ModalContainer open={open} onClose={handleClose}>
      <S.GradientContainer></S.GradientContainer>
      <S.ModalContentContainer className={className} customStyle={customStyle}>
        {children}
      </S.ModalContentContainer>
    </S.ModalContainer>
  );
};

export default GradientModalContainer;
