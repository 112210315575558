import { Box, styled } from "@mui/material";

namespace S {
  export const PostConnectWrapper = styled(Box)(({ theme }) => ({
    display: "grid",
    gridTemplateColumns: "1fr 2fr 1fr",
    gap: "1.875rem",
    maxWidth: "90rem",
    margin: "0 auto",
    [theme.breakpoints.down("md")]: {
      gridTemplateColumns: "1fr 72% 1fr",
    },
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "100%",
      padding: "0 10px",
    },
  }));
}

export default S;
