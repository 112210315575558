import { FC } from "react";
import { Skeleton } from "@mui/material";
import S from "./my-progress-skeleton.styled";

const MyProgressSkeleton: FC = () => {
  return (
    <S.SkeletonContainer>
      <S.SkeletonTopContainer>
        <Skeleton variant="text" sx={{ fontSize: "2rem", width: "35%" }} />
        <Skeleton variant="text" sx={{ fontSize: "1.5rem", width: "30%" }} />
      </S.SkeletonTopContainer>

      <S.SkeletonBottomContainer>
        {Array.from({ length: 3 }).map((item, i) => {
          return (
            <S.SkeletonSubContainer key={i}>
              <S.CustomCircularSkeletonContainer>
                <S.CustomCircleSkeleton variant="circular" />
              </S.CustomCircularSkeletonContainer>
              <S.RoundedBorderSkeleton variant="rounded" />
            </S.SkeletonSubContainer>
          );
        })}
      </S.SkeletonBottomContainer>
    </S.SkeletonContainer>
  );
};

export default MyProgressSkeleton;
