import { BorderBottom } from "@mui/icons-material";
import { Box, Typography, styled } from "@mui/material";
import Rating from "@mui/material/Rating";
import { TextGradient, TransparentTextGradient } from "../../../utils/constants";

namespace S {
  export const GameContainer = styled(Box)(({ theme }) => ({
    padding: "1.25rem 0rem 1.25rem 0rem",
    boxSizing: "border-box",
    width: "380px",
    height: "27.25rem",
    maxHeight: "30.4rem",
    borderRadius: "0.625rem",
    backgroundColor: theme.palette.custom.secondary.secondary_3,
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  }));

  export const GradientText = styled(Typography, { shouldForwardProp: (prop) => prop !== "shineText" })<{ shineText: string }>(
    ({ theme, shineText }) => ({
      background: TextGradient,
      webkitBackgroundClip: "text",
      WebkitTextFillColor: "transparent",
      backgroundClip: "text",
      backgroundSize: "200px",
      // fontWeight: 500,
      // fontSize: "14px",
      // lineHeight: "24.15px",
      fontSize: "1rem !important",
      fontWeight: "bold !important",
      color: TextGradient,
      textShadow: `0 0px 0px ${TextGradient}`,
      position: "relative",
      ":after": {
        content: `"${shineText}"`,
        // minWidth: "200px",
        background: TransparentTextGradient,
        webkitBackgroundClip: "text",
        WebkitTextFillColor: "transparent",
        backgroundClip: "text",
        position: "absolute",
        top: 0,
        left: 0,
        transform: "translate(0,0)",
        zIndex: 3,
        animation: "pool-shine 20s linear infinite",
        "@keyframes pool-shine": {
          "0%": {
            backgroundPosition: "-300px",
          },
          "100%": {
            backgroundPosition: "300px",
          },
        },
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: "14px !important",
      },
    }),
  );

  export const HeadContainer = styled(Box)(({ theme }) => ({
    display: "flex",
    justifyContent: "space-between",
    gap: "2rem",
    padding: "0rem 1.25rem 1rem 1.25rem",
  }));

  export const ImageBox = styled(Box)(({ theme }) => ({
    width: "60px",
    height: "64px",
  }));

  export const GameImage = styled("img")(({ theme }) => ({
    width: "100%",
    height: "100%",
  }));

  export const BodyContainer = styled(Box)(({ theme }) => ({
    height: "100%",
    overflow: "auto",
    cursor: "pointer",
  }));

  export const ContentContainer = styled(Box)(({ theme }) => ({
    boxSizing: "border-box",
    padding: "1rem 1.25rem 1rem 1.25rem",
    // padding: "1rem 0.5rem 1rem 0.8rem",
    display: "flex",
    justifyContent: "space-between",
    gap: "10px",
    borderBottom: "1px solid #FFFFFF33",

    "&:hover": {
      background: "#16151A66",
    },
  }));

  export const InnerContainer = styled(Box)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    gap: "1px",
    paddingLeft: "2px",
    flex: 1,
  }));

  export const ImageContainer = styled(Box)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    gap: "9px",
  }));

  export const PlayImage = styled("img")(({ theme }) => ({
    width: "16px",
    height: "16px",
    cursor: "pointer",
  }));

  export const PlayWinConatiner = styled(Box)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    gap: "20px",
    height: "20px",
  }));

  export const NameText = styled(Typography)(({ theme }) => ({
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "24px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
    },
  }));

  export const NameTextGradient = styled(Typography)(({ theme }) => ({
    background: TextGradient,
    webkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    backgroundClip: "text",
    fontWeight: 500,
    fontSize: "10px",
    lineHeight: "17.25px",
    display: "flex",
    gap: "5px",
  }));

  export const SpanGradient = styled("span")(({ theme }) => ({
    fontWeight: 500,
    fontSize: "10px",
    lineHeight: "17.25px",
    WebkitTextFillColor: "#fff",
  }));

  export const StyledRating = styled(Rating)(({ theme }) => ({
    width: "fit-content",
    "& .MuiRating-iconFilled": {
      color: "#FFC107",
    },
    "& .MuiRating-iconHover ": {
      color: "#FFC107",
    },
    "& .custom-rating": {
      color: "red !important",
    },
    "& .MuiRating-iconEmpty path": {
      color: "#57565A",
    },
    "& path": {
      color: "#FFC107",
    },
  }));
}

export default S;
