import { AppDispatch } from "..";
import { poolContract } from "../../services/ethereum/contract/poolContract";
import { getOwners, getPoolByAddress } from "../../services/ethereum/subgraph/query";
import { BACKEND_URL } from "../../utils/constants";
import { poolClient } from "../../utils/providers";
import { setActivePool, setDashboardPool, setPoolParticipants } from "./user";

const CURRENT_CHAIN_ID: number = Number(process.env.REACT_APP_CURRENT_CHAINID);

export const updateActivePool = () => async (dispatch: AppDispatch) => {
  let activePool = await poolContract.getActivePool();
  dispatch(setActivePool(activePool));
};

export const getActivePoolDetails = (activePoolAddress: string) => async (dispatch: AppDispatch) => {
  const poolDetail: any = await poolClient.request(getPoolByAddress, {
    where: {
      id: activePoolAddress,
    },
  });

  dispatch(setDashboardPool(poolDetail.pools[0]));
};

export const fetchParticipantsByPool = (poolAddress: string) => async (dispatch: AppDispatch) => {
  try {
    const participantsResponse: any = await poolClient.request(getOwners, {
      where: {
        pools_: {
          pool_contains: poolAddress,
        },
      },
      poolsWhere2: {
        pool_contains: poolAddress,
        total_not: "0",
      },
    });

    const owners = participantsResponse.owners.map((o: any) => o.pools).flat();
    dispatch(setPoolParticipants(owners));
  } catch (e) {
    console.log("Fetching participants by pool has failed");
  }
};

export const getPoolStatus = async () => {
  let status = await fetch(`${BACKEND_URL[CURRENT_CHAIN_ID]}/poolStatus`);
  if (status.status === 200) {
    let response = await status.json();
    return response.status;
  }
};
