import { createTheme, Theme } from "@mui/material";

export const darkTheme: Theme = createTheme({
  palette: {
    mode: "dark",
    custom: {
      primary: {
        main: "#1967FC",
      },
      secondary: {
        secondary_1: "#FFFFFF",
        secondary_2: "#595959",
        secondary_3: "#2D2C31",
        secondary_4: "#16151A",
        secondary_5: "#4c4a4a",
        secondary_6: "#202022",
      },
      accent: {
        accent_1: "#0AE93B",
        accent_2: "#EFB614",
        accent_3: "#A246D3",
        accent_4: "#E54900",
      },
      strokes: {
        primary: "#3E3E3E",
        transparent: "#D9D9D9",
      },
      bg: {
        app: "#16151A",
        scroll: "#1967FC",
        backdrop: "#deefe97c",
        level: "#16151a24",
        live: "#EF3636",
      },
      toast: {
        success: "#1A9035",
        error: "#EC3C93",
        info: "#2196f3",
        warning: "#FDCA17",
      },
    },
  },
  spacing: (factor: number) => `${0.3125 * factor}rem`, // (Bootstrap strategy)
});
