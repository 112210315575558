import { FC } from "react";
import S from "./jackpot-winner-header.styled";
import { HUNDREDK_DRAW, HUNDRED_K_DRAW, ONEK_DRAW, ONE_K_DRAW, TENK_DRAW, TEN_K_DRAW } from "../../../../utils/constants";
import { isMyself } from "../../../../utils/helpers";

type JackpotWinnerHeaderPropsType = {
  value: string;
  userId: string;
};

const JackpotWinnerHeader: FC<JackpotWinnerHeaderPropsType> = ({ value, userId }) => {
  return (
    <S.JackpotWinnerHeaderContainer>
      <S.HeaderText1>Hey, since you were away</S.HeaderText1>
      <S.HeaderText2>
        Here’s the result for{" "}
        <S.HighlightText customStyle={value}>
          {value === ONE_K_DRAW ? ONEK_DRAW : value === TEN_K_DRAW ? TENK_DRAW : value === HUNDRED_K_DRAW ? HUNDREDK_DRAW : ""} !
        </S.HighlightText>
      </S.HeaderText2>
      {isMyself(userId) && (
        <S.JackpotWinningTextContainer>
          <S.JackpotWinningText variant="h3">IT'S YOU</S.JackpotWinningText>
        </S.JackpotWinningTextContainer>
      )}
    </S.JackpotWinnerHeaderContainer>
  );
};

export default JackpotWinnerHeader;
