import { FC } from "react";
import { Tooltip } from "@mui/material";
import S from "./buttons.styled";
import { PRIMARY } from "../../constants";
import { customButtonType } from "../../types/button";
import CircularLoader from "../loader/CircularLoader";

export const Button: FC<customButtonType> = ({
  buttonType = PRIMARY,
  onClick,
  children,
  customStyle,
  disabled = false,
  containerCustomStyle,
  loading,
}) => {
  return (
    <S.DefaultButtonContainer sx={containerCustomStyle} disabled={disabled}>
      <S.CustomButton buttonType={buttonType} onClick={onClick} sx={customStyle} disabled={disabled}>
        {children}
        {loading && <CircularLoader size={20} />}
      </S.CustomButton>
    </S.DefaultButtonContainer>
  );
};

export const GradientButton: FC<customButtonType> = ({
  buttonType = PRIMARY,
  onClick,
  children,
  customStyle,
  containerCustomStyle,
  disabled = false,
  gradientStyle,
  loading,
  title,
}) => {
  return (
    <Tooltip title={title ?? ""}>
      <S.ButtonContainer sx={containerCustomStyle} disabled={disabled} gradientStyle={gradientStyle}>
        <S.CustomButton buttonType={buttonType} onClick={onClick} sx={customStyle} disabled={disabled}>
          {children}
          {loading && <CircularLoader size={20} />}
        </S.CustomButton>
      </S.ButtonContainer>
    </Tooltip>
  );
};
