import { Box, styled, Typography } from "@mui/material";

namespace S {
  export const SomethingWrongWrapper = styled(Box)(({ theme }) => ({
    display: "grid",
    placeContent: "center",
    background: theme.palette.custom.bg.app,
    height: "100vh",
  }));

  export const WarningText = styled(Typography)(({ theme }) => ({
    color: theme.palette.custom.secondary.secondary_1,
    fontSize: "3rem",
    textAlign: "center",
    span: {
      color: theme.palette.custom.primary.main,
      cursor: "pointer",
    },
  }));
}

export default S;
