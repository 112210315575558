import { styled, Box } from "@mui/material";

namespace S {
  export const CheckBoxContainer = styled(Box)(({ theme }) => ({
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "18px",
    height: "18px",
    borderRadius: "60%",
    background: "linear-gradient(180deg, #196DF9 0%, #19FCB8 100%)",
    // boxSizing: "border-box",
    // padding: "2px",
    // overflow: "hidden",
  }));

  export const InnerNonActiveContainer = styled(Box)(({ theme }) => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "black",
    width: "12px",
    height: "12px",
    borderRadius: "60%",
  }));

  export const InnerActiveContainer = styled(Box, { shouldForwardProp: (prop) => prop !== "isActive" })<{ isActive: boolean }>(
    ({ theme, isActive }) => ({
      background: isActive ? "linear-gradient(180deg, #196DF9 0%, #19FCB8 100%)" : "black",
      width: "8px",
      height: "8px",
      borderRadius: "60%",
    }),
  );
}

export default S;
