import { FC } from "react";
import { useSelector } from "react-redux";
import { Skeleton } from "@mui/material";
import S from "./header-skeleton.styled";
import { useGetDimensions } from "../../../helpers";
import { useEnteredDraw } from "../../../hooks/selector";

const HeaderSkeleton: FC = () => {
  //state values
  const enteredDraw = useSelector(useEnteredDraw);

  //constants
  const isMd = useGetDimensions("md");
  const isSm = useGetDimensions();

  return (
    <S.SkeletonContainer>
      {/*   my balance */}
      {!isSm && !enteredDraw && <Skeleton variant="rectangular" width={isSm ? 80 : 140} height={20} />}
      {/* buy now
      {!enteredDraw && !isMd && <Skeleton variant="rectangular" width={135} height={42} />} */}
      {/*   token */}
      {enteredDraw && !isMd && <Skeleton variant="rectangular" width={120} height={20} />}
      {/*   profile */}
      {enteredDraw && <Skeleton variant="circular" width={30} height={30} />}
      {/*   refer */}
      {enteredDraw && !isMd && <Skeleton variant="rectangular" width={100} height={25} />}
      {/*   log out */}
      <Skeleton variant="circular" width={30} height={30} />
    </S.SkeletonContainer>
  );
};

export default HeaderSkeleton;
