import { Box, styled, keyframes } from "@mui/material";

namespace S {
  export const CreatoAddedAnimeContainer = styled(Box)(() => ({
    height: "100%",
    width: "100%",
    zIndex: 5,
  }));
  export const ConfettiShowerContainer = styled(Box)(() => ({
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
  }));

  export const confetti = styled("img")(() => ({
    opacity: 0,
    width: "20px",
    height: "30px",
    transform: "skew(20deg, 20deg)",
    position: "relative",
    animation: `${animeConfetti} 3s ease-in-out infinite`,
  }));

  const animeConfetti = keyframes`
  0% {
    opacity: 0.6;
    margin-top: -100vh;
    // margin-left: -200px;
    top: -30%;
  }
  50% {
    opacity: 1;
  }
  100% {
    display: none;
  }
`;
}

export default S;

// import { Box, IconButton, Stack, styled, Typography, keyframes } from "@mui/material";

// namespace S {
//   export const CreatoAddedAnimeContainer = styled(Box)(({ theme }) => ({
//     maxWidth: "100vw",
//     height: "100%",
//     display: "flex",
//     alignItems: "center",
//     justifyContent: "center",
//     top: 0,
//     left: 0,
//     zIndex: 5,
//   }));
//   export const ConfettiShowerContainer = styled(Box)(({ theme }) => ({
//     position: "absolute",
//     top: 0,
//     left: 0,
//     width: "100%",
//     height: "100%",
//     zIndex: "-2",
//   }));

//   export const confetti = styled("img")(({ theme }) => ({
//     opacity: 0,
//     width: "20px",
//     height: "30px",
//     transform: "skew(20deg, 20deg)",
//     position: "relative",
//     animation: `${animeConfetti} 3s ease-in-out infinite`,
//   }));

//   const animeConfetti = keyframes`
//   0% {
//     opacity: 0.6;
//     margin-top: -100vh;
//     margin-left: -200px;
//     top: -30%;
//   }
//   70% {
//     opacity: 1;
//   }
//   100% {
//     display: none;
//   }
// `;
// }

// export default S;
