import { FC } from "react";
import { QRCodeSVG } from "qrcode.react";
import S from "./qr-modal.styled";
import { CustomModal, ModalHeader, ModalBody } from "../../../utils/widgets/custom-modal";

type QrModalType = {
  open: boolean;
  handleClose: () => void;
  url: string;
};

const QrModal: FC<QrModalType> = ({ open, handleClose, url }) => {
  return (
    <CustomModal open={open} onClose={handleClose}>
      <ModalHeader handleClose={() => handleClose()}>
        <S.Heading variant="subtitle2">SCAN ME TO GET MY REFERRAL URL</S.Heading>
      </ModalHeader>
      <ModalBody customStyle={{ borderRadius: "0.625rem" }}>
        <S.QrModalbody>
          <QRCodeSVG value={url} level={"H"} />
        </S.QrModalbody>
      </ModalBody>
    </CustomModal>
  );
};
export default QrModal;
