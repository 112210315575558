import { Stack, styled, Typography } from "@mui/material";

namespace S {
  export const HeaderContainer = styled(Stack)(({ theme }) => ({
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottom: `0.0625rem solid ${theme.palette.custom.strokes.primary}`,
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
    flexWrap: "wrap",
  }));

  export const HeaderContainerLeft = styled(Stack)(({ theme }) => ({
    boxSizing: "border-box",
    gap: theme.spacing(4),
    padding: `${theme.spacing(4)}`,
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  }));

  export const HeaderContainerMiddle = styled(Stack)(({ theme }) => ({
    boxSizing: "border-box",
    gap: theme.spacing(4),
    padding: `${theme.spacing(4)}`,
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  }));

  export const HeaderContainerRight = styled(Stack)(({ theme }) => ({
    alignSelf: "end",
    marginBottom: "1rem",
    flex: 0.49,
    alignItems: "center",
    gap: "1rem",
    div: {
      // width: "100%",
    },
    [theme.breakpoints.down("lg")]: {
      flex: 0.55,
    },
    [theme.breakpoints.down("md")]: {
      flex: 0.6,
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      flex: "unset",
      justifyContent: "center",
    },
  }));

  export const SearchContainer = styled(Stack)({
    flexDirection: "row",
    alignItems: "center",
    gap: "1rem",
  });

  export const HeaderText1 = styled(Typography)(({ theme }) => ({
    fontSize: "1.375rem",
    fontWeight: "500",
    width: "fit-content",
    lineHeight: "0.8",
    [theme.breakpoints.down("md")]: {
      fontSize: "0.875rem",
    },
  }));

  export const HeaderText2 = styled(Typography, {
    shouldForwardProp: (prop) => prop !== "draw",
  })(({ draw }: { draw: number }) => ({ theme }) => ({
    lineHeight: "0.9",
    display: "flex",
    gap: theme.spacing(2.5),
    flexDirection: "column",
    fontSize: "3.5rem",
    fontWeight: "600",
    width: "fit-content",
    [theme.breakpoints.down("md")]: {
      fontSize: "1.5rem",
    },
    background: "linear-gradient(92.48deg, #1967FC 1.43%, #19FCB8 75%)",
    webkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    backgroundClip: "text",
    textAlign: "center",
    ...(draw === 10 && {
      background: "linear-gradient(268.66deg, #FFE600 5.41%, #24FF00 93.94%)",
    }),
    ...(draw === 100 && {
      background: "linear-gradient(337.35deg, #eb00ff 7.34%, #e9b80c 91.96%)",
    }),
  }));

  export const HeaderTextUnderLine = styled("span", {
    shouldForwardProp: (prop) => prop !== "draw",
  })(({ draw }: { draw: number }) => () => ({
    height: "0.375rem",
    borderRadius: "1.25rem",
    background: "linear-gradient(92.48deg, #1967FC 1.43%, #19FCB8 75%)",
    ...(draw === 10 && {
      background: "linear-gradient(268.66deg, #FFE600 5.41%, #24FF00 93.94%)",
    }),
    ...(draw === 100 && {
      background: "linear-gradient(337.35deg, #eb00ff 7.34%, #e9b80c 91.96%)",
    }),
  }));

  export const SearchIcon = styled("img")({
    width: "1.2rem",
    height: "1.2rem",
  });
}

export default S;
