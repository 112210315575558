import { Stack, Typography, styled, Box } from "@mui/material";

namespace S {
  export const YearnGamesContainer = styled(Stack)(({ theme }) => ({
    alignItems: "center",
    justifyContent: "center",
    gap: theme.spacing(4),
  }));

  export const YearnGamesTitleContainer = styled(Box)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: theme.spacing(3),
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.875rem",
    },
  }));

  export const YearnGamePointsWrapper = styled(Box)(({ theme }) => ({
    position: "relative",
    width: "100%",
    height: "9vh",
  }));

  export const YearnGamePointsContainer = styled(Box)(({ theme }) => ({
    background: "linear-gradient(91.55deg, #1967FC 0%, #FC19BC 85%)",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: theme.spacing(2),
    position: "absolute",
    left: "-2rem",
    top: "0",
    padding: "0.5rem 2rem",
    [theme.breakpoints.down("sm")]: {
      left: "-1.5rem",
      padding: "0.5rem 1.5rem",
    },
  }));

  export const YearnGamesTitle = styled(Typography)(() => ({
    color: "#000",
    fontWeight: 600,
  }));

  export const YearnGamesContent = styled(Box)(({ theme }) => ({
    color: theme.palette.custom.secondary.secondary_1,
    fontSize: "1.125rem",
    fontWeight: "600",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1rem !important",
    },
  }));

  export const YearnGamesSubContent = styled(Typography)(({ theme }) => ({
    color: theme.palette.custom.secondary.secondary_1,
    fontSize: "0.95rem",
    fontWeight: "600",
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.875rem !important",
    },
  }));

  export const YearnAvatarTitle = styled(Typography)(({ theme }) => ({
    color: "#000",
    fontSize: "0.875rem !important",
    fontWeight: 600,
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.75rem !important",
      span: {
        lineHeight: "1",
      },
    },
    ".avatar-detail": {
      "&.avatar-icon": {
        display: "flex",
        alignItems: "center",
      },
      color: `${theme.palette.custom.secondary.secondary_2} !important`,
      fontWeight: 600,
      lineHeight: 2,
    },
  }));

  export const YearnGamesIcon = styled("img")(() => ({
    cursor: "pointer",
    "&.details-icon": {
      width: "15px",
      cursor: "default",
    },
  }));

  export const YearnAvatarContainer = styled(Box)(({ theme, color }) => ({
    width: "90%",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: theme.spacing(4),
    background: `${color}`,
    borderRadius: "2rem 0.625rem 0.625rem 2rem",
    padding: "0 1rem 0 6rem",
    boxSizing: "border-box",
    position: "relative",
    minHeight: "5.525rem",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      padding: "0 0 0 5rem",
    },
  }));

  export const YearnAvatar = styled("img")(({ theme }) => ({
    width: "5rem",
    height: "auto",
  }));

  export const YearnAvatarWrapper = styled(Box)(({ theme, color }) => ({
    height: "5.625rem",
    width: "5.625rem",
    background: `${color}`,
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-end",
    overflow: "hidden",
    position: "absolute",
    left: "-1rem",
  }));

  export const RocketImg = styled("img")(() => ({
    width: "1.2rem",
    height: "auto",
  }));
}

export default S;
