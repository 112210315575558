import { FC } from "react";
import { circularProgressClasses, Box } from "@mui/material";
import S from "./progress-bar.styled";
import { GRADIENT1, GRADIENT2, GRADIENT3 } from "../../constants";
import { useGetDimensions } from "../../helpers";
import { gradient, progressBarProps } from "../../types/progress-bar";
import GradientSVG from "../gradients/gradient1";
import GradientSVG2 from "../gradients/gradient2";
import GradientSVG3 from "../gradients/gradient3";

interface percentageBarProps extends progressBarProps {
  status: string;
}

const PercentageProgressbar: FC<percentageBarProps> = ({ gradient, percentage, value = "", status }) => {
  //constants
  const isSm = useGetDimensions("sm");
  const isMd = useGetDimensions("md");

  //functions
  const getGradient = (gradient: gradient) => {
    switch (gradient) {
      case GRADIENT1: {
        return <GradientSVG />;
      }
      case GRADIENT2: {
        return <GradientSVG2 />;
      }
      case GRADIENT3: {
        return <GradientSVG3 />;
      }
      default:
        return <GradientSVG />;
    }
  };

  return (
    <S.CircularPercentageWrapper isPercentageBar={true}>
      <Box sx={{ position: "relative" }}>
        <S.StaticCircularProgressBar variant="determinate" thickness={2} value={100} size={isSm ? 70 : isMd ? 80 : 90} />
        {getGradient(gradient)}
        <S.CustomCircularProgressBar
          variant="determinate"
          value={percentage}
          sx={{
            [`& .${circularProgressClasses.circle}`]: {
              stroke: `url(#${gradient})`,
              strokeLinecap: "round",
            },
          }}
          thickness={2}
          size={isSm ? 70 : isMd ? 80 : 90}
        />
      </Box>
      <S.PercentageLabelContentWrapper>
        <S.PercentageContent sx={{ fontSize: "14px" }}>{value}</S.PercentageContent>
        {/* <S.PercentageContent variant={isMd ? "body1" : "h6"}>{value}K</S.PercentageContent> */}
        <S.PercentageStatusContent status={status} variant="caption">
          {status}
        </S.PercentageStatusContent>
      </S.PercentageLabelContentWrapper>
    </S.CircularPercentageWrapper>
  );
};

export default PercentageProgressbar;
