import { CSSProperties } from "react";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { Box, IconButton, Stack, styled, Typography, Input, InputAdornment } from "@mui/material";
import { TextField } from "@mui/material";

export namespace SIcon {
  export const CustomKeyboardArrowRightIcon = styled(KeyboardArrowRightIcon)(({ theme }) => ({
    color: theme.palette.custom.accent.accent_2,
    fill: theme.palette.custom.accent.accent_2,
  }));

  export const CustomKeyboardArrowDownIcon = styled(KeyboardArrowDownIcon)(({ theme }) => ({
    color: theme.palette.custom.accent.accent_2,
    fill: theme.palette.custom.accent.accent_2,
  }));
}

export namespace SInvitedByPopover {
  export const Wrapper = styled(Stack)(({ theme }) => ({
    boxSizing: "border-box",
    gap: theme.spacing(4),
    borderRadius: theme.spacing(2),
    backgroundColor: theme.palette.custom.secondary.secondary_3,
  }));

  export const Header = styled(Box, {
    shouldForwardProp: (prop) => prop !== "customStyle",
  })(({ customStyle }: { customStyle: CSSProperties }) => ({ theme }) => ({
    display: "flex",
    gap: theme.spacing(7),
    justifyContent: "space-between",
    alignItems: "center",
    padding: theme.spacing(4),
    borderBottom: `1px solid ${theme.palette.custom.strokes.primary}`,
    ...customStyle,
    [theme.breakpoints.down("sm")]: {
      minWidth: "320px !important",
      display: "flex",
      gap: theme.spacing(2),
      justifyContent: "flex-start",
      padding: theme.spacing(2),
    },
  }));

  export const BuyButton = styled(Box)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    gap: "0.5rem",
    cursor: "pointer",
    borderRadius: "0.5rem",
    padding: "0.5rem 1rem",
    border: `1px solid ${theme.palette.custom.primary.main}`,
    boxShadow: "0px 0px 5px 1px #1967fcc9",
    transitionDuration: "0.3s",
    alignSelf: "flex-end",
    ".img": {
      height: "1.5rem",
    },
    "&:hover": {
      backgroundColor: "#1846dd",
      transitionDuration: "0.3s",
    },
  }));

  export const Profile = styled(Box)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(3),

    p: {
      "&:nth-of-type(1)": {
        fontWeight: "bold",
      },
    },
    img: {
      height: "3.75rem",
      width: "3.75rem",
    },
    [theme.breakpoints.down("sm")]: {
      img: {
        height: "2.8125rem",
        width: "2.8125rem",
      },
    },
  }));

  export const Ticket = styled(Box)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: "2.5rem",

    p: {
      "&:nth-of-type(1)": {
        fontWeight: "bold",
        // color: theme.palette.custom.accent.accent_1,
      },
    },
    [theme.breakpoints.down("sm")]: {
      gap: theme.spacing(2),
    },
  }));

  export const TicketAmount = styled(Typography)(({ theme }) => ({
    fontWeight: "bold",
    color: theme.palette.custom.accent.accent_1,
  }));

  export const CopyIcon = styled(IconButton)(({ theme }) => ({
    color: theme.palette.custom.primary.main,
    fill: theme.palette.custom.primary.main,
    svg: {
      fill: theme.palette.custom.primary.main,
    },
  }));

  export const Body = styled(Stack, {
    shouldForwardProp: (prop) => prop !== "customStyle",
  })(({ customStyle }: { customStyle: CSSProperties }) => ({ theme }) => ({
    gap: theme.spacing(4),
    padding: "0 1.25rem 1.25rem 1.25rem",

    "img+p": {
      fontWeight: "bold",
    },
    ...customStyle,
  }));

  export const Highlight = styled(Typography)(({ theme }) => ({
    fontWeight: "bold",
    color: theme.palette.custom.accent.accent_2,
  }));

  export const UserAddress = styled(Typography)(({ theme }) => ({
    // textOverflow: "ellipsis",
    // overflow: "hidden",
    color: theme.palette.custom.secondary.secondary_1,
  }));

  export const AddressAlignmentBox = styled(Box)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
  }));

  export const SubText = styled("span", {
    shouldForwardProp: (prop) => prop !== "customStyle",
  })(({ customStyle }: { customStyle?: CSSProperties }) => ({ theme }) => ({
    fontSize: "0.75rem",
    color: theme.palette.custom.secondary.secondary_2,
    marginLeft: "0.15rem",
    ...customStyle,
  }));

  export const MyProfileContainer = styled(Box)(({ theme }) => ({
    padding: `0 ${theme.spacing(4)}`,
  }));

  export const Footer = styled(Stack)(({ theme }) => ({
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
    gap: theme.spacing(2),
  }));

  export const FooterText = styled(Typography)(({ theme }) => ({
    a: {
      textDecoration: "none",
      color: theme.palette.custom.secondary.secondary_1,
      cursor: "pointer",
    },
  }));
}

export namespace SReferPopover {
  export const Wrapper = styled(SInvitedByPopover.Wrapper)(({ theme }) => ({
    padding: theme.spacing(4),
  }));

  export const InvitedByWrapper = styled(SInvitedByPopover.Wrapper)(({ theme }) => ({
    padding: `${theme.spacing(4)} 0`,
  }));

  export const Highlight = SInvitedByPopover.Highlight;
}

export namespace SClaimAmountPopover {
  export const Wrapper = styled(Stack)(({ theme }) => ({
    gap: theme.spacing(4),
    borderRadius: theme.spacing(2),
    backgroundColor: theme.palette.custom.secondary.secondary_3,
    alignItems: "center",
    padding: "1rem",
  }));

  export const Max = styled(Typography)(() => ({
    cursor: "pointer",
    fontSize: "0.9rem",
  }));

  export const AmountInputField = styled(TextField)(({ theme, type }) => ({
    ".MuiInputBase-root": {
      borderRadius: "0.25rem",
      height: "3.5rem",
      fieldset: {
        border: `0.15rem solid ${theme.palette.custom.secondary.secondary_5} !important`,
      },
      "&:hover fieldset": {
        border: `0.15rem solid ${theme.palette.custom.secondary.secondary_5} !important`,
      },
      "&:focus fieldset": {
        border: `0.15rem solid ${theme.palette.custom.secondary.secondary_5} !important`,
      },
      "&:active fieldset": {
        border: `0.15rem solid ${theme.palette.custom.secondary.secondary_5} !important`,
      },
    },
    "& legend": {
      width: type === "email" || type === "datetime-local" ? 0 : "unset",
    },
    ".MuiInputBase-root.Mui-focused": {
      fieldset: {
        "& legend": {
          width: "unset",
        },
      },
    },
    input: {
      padding: "0.7rem",
      fontWeight: 500,
      color: theme.palette.custom.secondary.secondary_1,
      "&::placeholder": {
        fontWeight: 500,
        color: theme.palette.custom.secondary.secondary_1,
        opacity: "0.65",
        fontSize: "1rem",
      },
    },
    [theme.breakpoints.down("md")]: {
      minWidth: "300px",
    },
  }));
}

export namespace SMenuPopover {
  export const Wrapper = styled(Box)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    borderRadius: "0.625rem",
    cursor: "pointer",
    padding: "2rem 1rem",
    gap: "1.5rem",
    alignItems: "center",
    backgroundColor: theme.palette.custom.secondary.secondary_3,
  }));

  export const ReferContainer = styled(Box)(() => ({
    position: "relative",
    width: "100%",
  }));
}

export namespace SInfoPopover {
  export const PopoverText = styled(Typography)(() => ({
    cursor: "pointer",
  }));

  export const PopoverTextBox = styled(Box)(({ theme }) => ({
    padding: "0.125rem 0.5rem",
    textAlign: "justify",
    maxWidth: "10rem",
    backgroundColor: theme.palette.custom.secondary.secondary_2,
  }));
}

//Earnings popover
export namespace SMyEarningsPopover {
  export const Wrapper = styled(Box)(({ theme }) => ({
    minHeight: "17.6875rem",
    minWidth: "21.1875rem",
    maxWidth: "21.1875rem",
    boxSizing: "border-box",
    lineHeight: "1",
    backgroundColor: theme.palette.custom.secondary.secondary_3,
  }));

  export const Header = styled(Box)(({ theme }) => ({
    padding: theme.spacing(4),
    paddingBottom: theme.spacing(2),
    borderBottom: `0.0625rem solid ${theme.palette.custom.strokes.primary}`,
  }));

  export const AccordionHeader = styled(Box)(({ theme }) => ({
    display: "flex",
    padding: `${theme.spacing(2)} 0`,
    justifyContent: "space-between",
    alignItems: "center",
  }));

  export const Body = styled(Box)(({ theme }) => ({
    borderTop: `0.0625rem solid ${theme.palette.custom.strokes.primary}`,
    padding: theme.spacing(4),
    display: "flex",
    gap: "1.4375rem",
    flexDirection: "column",
  }));

  export const Footer = styled(Box)(({ theme }) => ({
    padding: "1.625rem 1.25rem",
    borderTop: `0.0625rem solid ${theme.palette.custom.strokes.primary}`,
  }));

  export const HeaderText = styled(Typography)(() => ({
    fontSize: "1rem",
    fontWeight: "600",
  }));

  export const AccordionHeaderText = styled(Typography)(({ theme }) => ({
    fontSize: "1rem",
    fontWeight: "600",
    lineHeight: 1,
    height: "1rem",
    color: theme.palette.custom.accent.accent_2,
  }));

  export const Text1 = styled(Typography)(() => ({
    fontWeight: "bold",
  }));

  export const Text2 = styled(Text1)(() => ({
    fontSize: "1rem",
  }));

  export const Text3 = styled(Typography)(({ theme }) => ({
    fontWeight: "bold",
    color: theme.palette.custom.accent.accent_1,
  }));

  export const Text4 = styled(Text3)(() => ({
    fontSize: "1.5rem",
  }));

  export const Row = styled(Box)(() => ({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  }));

  export const AmountInputField = styled(TextField)(({ theme, type }) => ({
    alignSelf: "center",
    minWidth: "80%",
    maxWidth: "300px",
    ".MuiInputBase-root": {
      borderRadius: "0.25rem",
      height: "3.5rem",
      fieldset: {
        border: `0.15rem solid ${theme.palette.custom.secondary.secondary_5} !important`,
      },
      "&:hover fieldset": {
        border: `0.15rem solid ${theme.palette.custom.secondary.secondary_5} !important`,
      },
      "&:focus fieldset": {
        border: `0.15rem solid ${theme.palette.custom.secondary.secondary_5} !important`,
      },
      "&:active fieldset": {
        border: `0.15rem solid ${theme.palette.custom.secondary.secondary_5} !important`,
      },
    },
    "& legend": {
      width: type === "email" || type === "datetime-local" ? 0 : "unset",
    },
    ".MuiInputBase-root.Mui-focused": {
      fieldset: {
        "& legend": {
          width: "unset",
        },
      },
    },
    input: {
      padding: "0.7rem",
      fontWeight: 500,
      color: theme.palette.custom.secondary.secondary_1,
      "&::placeholder": {
        fontWeight: 500,
        color: theme.palette.custom.secondary.secondary_1,
        opacity: "0.65",
        fontSize: "1rem",
      },
    },
    [theme.breakpoints.down("sm")]: {
      input: {
        "&::placeholder": {
          fontSize: "0.7rem",
        },
      },
    },
  }));

  export const MiniText = styled(Typography)(() => ({
    fontSize: "10px",
    fontWeight: "400",
    color: "rgba(255, 255, 255, 0.6)",
    lineHeight: "0.5",
  }));

  export const AmountExceededWarningText = styled(MiniText, { shouldForwardProp: (prop) => prop !== "isVisible" })(
    ({ isVisible }: { isVisible: boolean }) =>
      () => ({
        color: "red",
        display: isVisible ? "block" : "none",
      }),
  );

  export const MaxAmountWarningText = styled(MiniText, { shouldForwardProp: (prop) => prop !== "isVisible" })(
    ({ isVisible }: { isVisible: boolean }) =>
      () => ({
        color: "#3ab23a",
        display: isVisible ? "block" : "none",
      }),
  );

  export const MaxChipWrapper = styled(Box, {
    shouldForwardProp: (prop) => prop !== "disabled",
  })(({ disabled }: { disabled?: boolean }) => ({ theme }) => ({
    border: disabled ? `1px solid ${theme.palette.custom.secondary.secondary_2}` : `1px solid ${theme.palette.custom.secondary.secondary_1}`,
    borderRadius: "0.75rem",
    padding: "0.2rem 1rem",
    cursor: disabled ? "not-allowed" : "pointer",
    pointerEvents: disabled ? "none" : "auto",
  }));

  export const MaxText = styled(Typography, {
    shouldForwardProp: (prop) => prop !== "disabled",
  })(({ disabled }: { disabled?: boolean }) => ({ theme }) => ({
    fontSize: "0.9rem",
    color: disabled ? `${theme.palette.custom.secondary.secondary_2}` : `${theme.palette.custom.secondary.secondary_1}`,
    cursor: disabled ? "not-allowed" : "pointer",
  }));

  export const ClaimAmountWrapper = styled(Box)(() => ({
    display: "flex",
    alignSelf: "center",
  }));
}

//Connect Popover
export namespace SConnectPopover {
  export const Wrapper = styled(Stack)(({ theme }) => ({
    width: "max-content",
    minWidth: "12rem",
    padding: "1rem 0.5rem",
    alignItems: "center",
    gap: theme.spacing(4),
    borderRadius: theme.spacing(2),
    backgroundColor: theme.palette.custom.secondary.secondary_3,
  }));
}

//EarnedTokens Popover
export namespace SEarnedTokensPopover {
  export const Wrapper = styled(Box)(({ theme }) => ({
    minHeight: "19.313rem",
    minWidth: "22.1875rem",
    maxWidth: "22.1875rem",
    boxSizing: "border-box",
    lineHeight: "1",
    backgroundColor: theme.palette.custom.secondary.secondary_3,
  }));

  export const Header = styled(Box)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "space-between",
    padding: theme.spacing(4),
    gap: theme.spacing(4),
    paddingBottom: theme.spacing(2),
    borderBottom: `0.0625rem solid ${theme.palette.custom.strokes.primary}`,
  }));

  export const Body = styled(Box)(({ theme }) => ({
    padding: theme.spacing(4),
    display: "flex",
    gap: "1.4375rem",
    flexDirection: "column",
  }));

  export const Footer = styled(Box)(({ theme }) => ({
    padding: "1.625rem 1.25rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  }));

  export const HeaderTitle = styled(Typography)({
    fontWeight: 600,
  });

  export const HeaderAmountSection = styled(Box)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: theme.spacing(4),
  }));

  export const HeaderLogo = styled("img")({
    width: "34px",
    height: "34px",
  });

  export const HeaderAmount = styled(Typography)(({ theme }) => ({
    color: theme.palette.custom.accent.accent_2,
    fontWeight: 600,
  }));

  export const BodyDetailsContainer = styled(Box)({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  });

  export const BodyAddress = styled(Typography)(({ theme }) => ({
    color: theme.palette.custom.primary.main,
  }));

  export const BodyTitle = styled(Typography)({
    fontWeight: "bold",
  });

  export const BodyDetail = styled(Typography)(({ theme }) => ({
    color: theme.palette.custom.accent.accent_2,
    fontWeight: "bold",
  }));
}

export namespace SShareLinkPopover {
  export const Wrapper = styled(Box)(({ theme }) => ({
    minHeight: "8.313rem",
    minWidth: "22.1875rem",
    maxWidth: "22.1875rem",
    boxSizing: "border-box",
    padding: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "space-around",
  }));

  export const Title = styled(Typography)(({ theme }) => ({
    color: theme.palette.custom.accent.accent_2,
  }));

  export const SocialIconsContainer = styled(Box)(() => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  }));

  export const IconContainer = styled(Box)(({ theme }) => ({
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: theme.spacing(1),
  }));

  export const IconWrapper = styled(Box)(({ theme }) => ({
    width: "35px",
    height: "35px",
    border: `1px solid ${theme.palette.custom.secondary.secondary_1}`,
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    ".MuiSvgIcon-root": {
      width: "1.3rem",
      heigth: "1.3rem",
    },
  }));
}

export namespace SNewEarnedTokensPopover {
  export const Wrapper = styled(Box)(({ theme }) => ({
    minHeight: "19.313rem",
    minWidth: "22.1875rem",
    maxWidth: "22.1875rem",
    boxSizing: "border-box",
    lineHeight: "1",
    backgroundColor: theme.palette.custom.secondary.secondary_3,
  }));

  export const Header = styled(Box)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "space-between",
    padding: theme.spacing(4),
    gap: theme.spacing(4),
    paddingBottom: theme.spacing(2),
    borderBottom: `0.0625rem solid ${theme.palette.custom.strokes.primary}`,
  }));

  export const Body = styled(Box)(({ theme }) => ({
    padding: theme.spacing(4),
    display: "flex",
    gap: "0.5rem",
    flexDirection: "column",
  }));

  export const Footer = styled(Box)(({ theme }) => ({
    padding: "1.625rem 1.25rem",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: theme.spacing(3),
  }));

  export const FooterAmountText = styled(Typography)(({ theme }) => ({
    color: theme.palette.custom.accent.accent_1,
    fontWeight: 700,
  }));

  export const HeaderTitle = styled(Typography)({
    fontWeight: 600,
  });

  export const HeaderContainer = styled(Box)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: theme.spacing(3),
  }));

  export const CopyContainer = styled(Box)({
    width: "52%",
    background: "#5b5b5b",
    borderRadius: "0.5rem",
    paddingLeft: "0.5rem",
    alignSelf: "center",
  });

  export const CustomInputAdornment = styled(InputAdornment)({
    display: "flex",
    gap: "0.3rem",
  });

  export const OpenExplorerImg = styled("img")({
    width: "0.8rem",
    height: "auto",
  });

  export const CustomInput = styled(Input)(() => ({
    fontSize: "0.8rem",
  }));

  export const IconContainer = styled(Box)(({ theme }) => ({
    height: "1.3rem",
    width: "1.3rem",
    borderRadius: "50%",
    display: "grid",
    placeContent: "center",
    "&:hover": {
      backgroundColor: "#16151a",
      opacity: "0.8",
      cursor: "pointer",
    },
  }));

  export const CustomCopyIcon = styled(ContentCopyIcon)({
    width: "0.8rem",
    height: "auto",
  });

  export const MetamaskIcon = styled("img")({
    width: "1rem",
    height: "auto",
  });

  export const HeaderAmountSection = styled(Box)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: theme.spacing(4),
  }));

  export const HeaderAmountTextContainer = styled(Box)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
    gap: theme.spacing(1),
  }));

  export const HeaderLogo = styled("img")({
    width: "55px",
    height: "55px",
  });

  export const HeaderAmount = styled(Typography)(({ theme }) => ({
    color: theme.palette.custom.accent.accent_2,
    fontWeight: 600,
  }));

  export const BodyDetailsContainer = styled(Box)({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  });

  export const BodyHeadTitle = styled(Typography)({
    fontWeight: "bold",
  });

  export const BodyTitle = styled(Typography)(({ theme }) => ({
    color: theme.palette.custom.accent.accent_1,
  }));

  export const BodyDetail = styled(Typography)(({ theme }) => ({
    color: theme.palette.custom.accent.accent_2,
  }));

  export const BtnContainer = styled(Box)(() => ({
    padding: "0 1.25rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    gap: "0.35rem",
  }));

  export const IBtnContainer = styled(IconButton)(({ theme }) => ({
    background: theme.palette.custom.primary.main,
    maxHeight: "1rem",
    maxWidth: "1rem",
    height: "1rem",
    pointerEvents: "auto",
    fontSize: "12px",
    marginLeft: "10px",
    alignSelf: "center",
    "&:hover": {
      backgroundColor: "#1846dd",
    },
    p: {
      lineHeight: "0.5",
      fontSize: "11.8px",
    },
  }));
}

export const YellowText = styled(Typography)(({ theme }) => ({
  fontSize: "0.875rem",
  fontWeight: "600",
  color: theme.palette.custom.accent.accent_2,
  lineHeight: "1.3125rem",
}));
