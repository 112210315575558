import { FC, useEffect } from "react";
import S from "./my-tickets-modal.styled";
import { fetchWinnersByTickets } from "../../../store/slices/user.actions";
import { useAppDispatch } from "../../../utils/hooks/state";
import { Button } from "../../../utils/widgets";
import GradientModalContainer from "../../../utils/widgets/gradient-container";
import MyTicketsModalBody from "./MyTicketsModalBody";

type MyTicketsModalType = {
  open: boolean;
  handleClose: () => void;
};

const MyTicketsModal: FC<MyTicketsModalType> = ({ open, handleClose }) => {
  //constructors
  const dispatch = useAppDispatch();

  //Fetching winners by draws
  useEffect(() => {
    dispatch(fetchWinnersByTickets());
  }, []);

  return (
    <GradientModalContainer open={open} onClose={handleClose} customStyle={{ background: "#2D2C31" }}>
      <S.MyTicketsModalHeader>
        <S.HeadingText>MY LUCKY PASSES</S.HeadingText>
        <Button
          buttonType="primary"
          customStyle={{ width: "24px", height: "24px", borderRadius: "50%", minWidth: "unset", fontSize: "1.5rem" }}
          onClick={handleClose}
        >
          <p>&times;</p>
        </Button>
      </S.MyTicketsModalHeader>
      <MyTicketsModalBody />
    </GradientModalContainer>
  );
};
export default MyTicketsModal;
