import { CSSProperties } from "react";
import { styled, Button, Box } from "@mui/material";
import { PRIMARY } from "../../constants";
import { buttonType } from "../../types/button";

namespace S {
  export const CustomButton = styled(Button, {
    shouldForwardProp: (prop) => prop !== "buttonType",
  })(({ buttonType, disabled }: { buttonType: buttonType; disabled: boolean }) => ({ theme }) => ({
    // maxHeight: "2rem",
    display: "flex",
    gap: "0.5rem",
    alignItems: "center",
    fontSize: "1rem",
    borderRadius: "0.5rem",
    textTransform: "none",
    fontWeight: 500,
    color: disabled ? "#ffffff6b" : "#ffffff",
    backgroundSize: "200%",
    opacity: 1,
    backgroundPosition: "0%",
    background: disabled ? theme.palette.custom.secondary.secondary_2 : buttonType === PRIMARY ? theme.palette.custom.primary.main : "transparent",
    "&:hover": {
      background: buttonType === PRIMARY ? "#1846dd" : "",
    },
    svg: {
      path: {
        color: disabled && "#8B8B8B",
      },
    },
  }));

  export const ButtonContainer = styled(Box, {
    shouldForwardProp: (prop) => prop !== "disabled" && prop !== "gradientStyle",
  })(({ disabled, gradientStyle }: { disabled: boolean; gradientStyle?: CSSProperties }) => () => ({
    // maxHeight: "2rem",
    borderRadius: "0.3rem",
    overflow: "hidden",
    position: "relative",
    cursor: disabled ? "not-allowed !important" : "pointer",
    width: "fit-content",
    "::before": {
      display: disabled ? "none" : "",
      content: `""`,
      position: "absolute",
      zIndex: 0,
      top: "-7rem",
      left: "-2rem",
      width: "25rem",
      height: "25rem",
      background: `linear-gradient(349.35deg,#EB00FF 37%, #E9B80C 80%, rgba(201, 217, 280, 0) 209.26%) border-box`,
      animation: "gradientRotate 2s infinite linear",
      ...gradientStyle,
      "@keyframes gradientRotate": {
        to: {
          transform: "rotate(360deg)",
        },
      },
    },
  }));

  export const DefaultButtonContainer = styled(Box, {
    shouldForwardProp: (prop) => prop !== "disabled",
  })(({ disabled }: { disabled: boolean }) => () => ({
    cursor: disabled ? "not-allowed !important" : "pointer",
    button: {
      width: "fit-content",
    },
  }));
}
export default S;
