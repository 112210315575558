import React, { FC } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import S from "./content.styled";
import { useAnnouncedWinner } from "../../../utils/hooks/selector";

type Props = {
  children: React.ReactNode;
};

const Content: FC<Props> = ({ children }) => {
  //state values
  const winnerId = useSelector(useAnnouncedWinner).winnerId;

  //constants
  const location = useLocation();
  const valid = location.pathname.includes(`/cricket/`);

  return (
    <S.ContentContainer validRoute={valid} customStyle={Boolean(winnerId) ? { paddingTop: "1rem" } : {}}>
      {children}
    </S.ContentContainer>
  );
};

export default Content;
