import { CSSProperties } from "react";
import { Box, Divider, styled } from "@mui/material";
import { Button, GradientButton } from "./buttons";
import { CustomCardWrapper } from "./cards/CustomCardWrapper";

const CustomDivider = styled(Divider, {
  shouldForwardProp: (prop) => prop !== "customstyle",
})(({ customstyle }: { customstyle?: CSSProperties }) => ({ theme }) => ({
  backgroundColor: theme.palette.custom.strokes.primary,
  ...customstyle,
}));

const VerticalDivider = styled(Box, {
  shouldForwardProp: (prop) => prop !== "customstyle" && prop !== "transparentBg",
})(({ customstyle, transparentBg }: { customstyle?: CSSProperties; transparentBg?: boolean }) => ({ theme }) => ({
  borderLeft: `0.0625rem solid ${transparentBg ? theme.palette.custom.strokes.transparent : theme.palette.custom.strokes.primary}`,
  ...customstyle,
}));

//triangle tilde --> use customStyle to adjust the triangle location
const Triangle = styled(Box, {
  shouldForwardProp: (prop) => prop !== "customStyle",
})(({ customStyle }: { customStyle: CSSProperties }) => ({ theme }) => ({
  width: 0,
  height: 0,
  borderLeft: "10px solid transparent",
  borderRight: "10px solid transparent",
  borderBottom: `15px solid ${theme.palette.custom.secondary.secondary_3}`,
  position: "absolute",
  ...customStyle,
}));

//In customStyles you should add width,height and position alignments
const SGradientContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== "customStyle",
})(({ customStyle }: { customStyle: CSSProperties }) => ({ theme }) => () => ({
  // padding: "0.625rem",
  boxSizing: "border-box",
  padding: "4px",
  borderRadius: "0.625rem",
  position: "relative",
  overflow: "hidden",
  // [theme.breakpoints.down("sm")]: {
  //   margin: "0 -5px 0 -2px",
  // },
  "::after": {
    content: `""`,
    position: "absolute",
    zIndex: -1,
    background: "linear-gradient(0deg, #EB00FF 37%, #E9B80C 80%)",
    animation: "gradientRotate 2s infinite linear",
    ...customStyle,
    "@keyframes gradientRotate": {
      to: {
        transform: "rotate(360deg)",
      },
    },
  },
  [theme.breakpoints.down("sm")]: {
    padding: "4px 4px 8px 4px",
  },
}));

const SRoundImage = styled("img")({
  borderRadius: "50%",
});

export { Button, GradientButton, CustomCardWrapper, CustomDivider, VerticalDivider, Triangle, SGradientContainer, SRoundImage };
