import { Stack, styled, Typography } from "@mui/material";

namespace S {
  export const MyTicketsModalHeader = styled(Stack)(({ theme }) => ({
    boxSizing: "border-box",
    flexDirection: "row",
    justifyContent: "space-between",
    lineHeight: "0.8 !important",
    alignItems: "center",
    borderBottom: `0.0625rem solid ${theme.palette.custom.strokes.primary}`,
    height: "4.5625rem",
    width: "29.4375rem",
    margin: `0 ${theme.spacing(4)}`,
    padding: `0 ${theme.spacing(4)}`,
    paddingRight: theme.spacing(2),
    borderRadius: "10px 10px 0 0",
    button: {
      width: "24px !important",
      lineHeight: 1,
    },
    [theme.breakpoints.down("sm")]: {
      paddingRight: theme.spacing(4),
      width: "100%",
    },
  }));

  export const HeadingText = styled(Typography)(() => ({
    fontSize: "1.375rem",
    fontWeight: "600",
  }));
}
export default S;
