import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { Box, styled, Stack } from "@mui/material";

namespace S {
  export const OneMatchWrapper = styled(Box)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    height: "100%",
    width: "100%",
  }));

  export const CarouselContainer = styled(Stack)(({ theme }) => ({
    width: "100%",
    height: "100%",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  }));

  export const DashboardReturn = styled(Box)(({ theme }) => ({
    position: "absolute",
    cursor: "pointer",
    left: "200px",
    top: 0,
    fontSize: "16px",
    fontWeight: 500,
    color: theme.palette.custom.primary.main,
  }));

  export const CustomLeftArrowIcon = styled(KeyboardArrowLeftIcon)(({ theme }) => ({
    path: {
      color: theme.palette.custom.primary.main,
    },
  }));
}

export default S;
