import { CSSProperties } from "react";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import StarIcon from "@mui/icons-material/Star";
import { Box, IconButton, Stack, styled, Typography } from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import { TextGradient, TransparentTextGradient } from "../../../utils/constants";

namespace S {
  export const Prediction = styled(Stack)(({ theme }) => ({
    justifyContent: "space-between",
    minHeight: "24.75rem",
    maxHeight: "24.75rem",
    maxWidth: "580px",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      minHeight: "fit-content",
      maxHeight: "fit-content",
    },
  }));

  export const PredictionHeader = styled(Box)(({ theme }) => ({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    p: {
      "&:nth-of-type(1)": {
        fontSize: "1.25rem",
        fontWeight: "bold",
      },
    },
    [theme.breakpoints.down("sm")]: {
      marginBottom: "2rem",
    },
  }));

  export const GradientText = styled(Typography, { shouldForwardProp: (prop) => prop !== "shineText" })(({ shineText }: { shineText: string }) => ({
    background: TextGradient,
    webkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    backgroundClip: "text",
    backgroundSize: "200px",
    fontSize: "1.0625rem !important",
    fontWeight: "bold !important",
    animation: "predict-shine 20s linear infinite",
    color: TextGradient,
    textShadow: `0 0px 0px ${TextGradient}`,
    position: "relative",
    ":after": {
      content: `"${shineText}"`,
      // minWidth: "200px",
      background: TransparentTextGradient,
      webkitBackgroundClip: "text",
      WebkitTextFillColor: "transparent",
      backgroundClip: "text",
      position: "absolute",
      top: 0,
      left: 0,
      transform: "translate(0,0)",
      zIndex: 3,
      animation: "pool-shine 20s linear infinite",
      "@keyframes pool-shine": {
        "0%": {
          backgroundPosition: "-300px",
        },
        "100%": {
          backgroundPosition: "300px",
        },
      },
    },
  }));

  export const ListIconButton = styled(IconButton, {
    shouldForwardProp: (prop) => prop !== "disabled",
  })(({ disabled }: { disabled: boolean }) => ({ theme }) => ({
    background: disabled ? theme.palette.custom.secondary.secondary_2 : theme.palette.custom.primary.main,
    height: "1.5rem",
    width: "1.5rem",
    pointerEvents: disabled ? "none" : "auto",
    svg: {
      fontSize: "1rem",
    },
    "&:hover": {
      backgroundColor: disabled ? "" : "#1846dd",
    },
    [theme.breakpoints.down("md")]: {
      height: "1.2rem",
      width: "1.2rem",
      svg: {
        fontSize: "0.75rem",
      },
    },
  }));

  export const LinearContainer = styled(Box)(({ theme }) => ({
    [theme.breakpoints.down("sm")]: {
      padding: "0 0.5rem",
    },
  }));

  export const CustomLinear = styled(LinearProgress)(({ value }: { value: number }) => () => ({
    ".MuiLinearProgress-barColorPrimary": {
      background: `linear-gradient(90deg, #24FF00 ${100 - value}%, #FFE600 100%) !important`,
    },
  }));
  export const CurrenDrawLabel = styled(Typography, {
    shouldForwardProp: (prop) => prop !== "labelPosition",
  })(({ labelPosition }: { labelPosition: number }) => ({ theme }) => ({
    position: "absolute",
    left: `${labelPosition}%`,
    fontWeight: "bold",
    fontSize: "0.675rem",
    marginTop: "0.25rem",
    width: "max-content",
    [theme.breakpoints.down("md")]: {
      fontSize: "0.625rem",
      left: `${labelPosition + 2}%`,
    },
    [theme.breakpoints.down("sm")]: {
      left: `${labelPosition}%`,
    },
  }));

  export const FinalDrawLevel = styled(Typography)(() => ({
    position: "absolute",
    right: "0",
    fontWeight: "bold",
    fontSize: "0.625rem",
    marginTop: "0.9375rem",
  }));

  export const Person = styled("img", {
    shouldForwardProp: (prop) => prop !== "labelPosition",
  })(({ labelPosition }: { labelPosition: number }) => () => ({
    position: "absolute",
    top: "-1.3125rem",
    left: `${labelPosition}%`,
  }));

  export const PredictionContentContainer = styled(Stack)(({ theme }) => ({
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "row",
    gap: theme.spacing(2),
    overflowX: "auto",
    overflowY: "hidden",
    ".css-19c4lqn-MuiLinearProgress-root": {
      backgroundColor: `${theme.palette.custom.secondary.secondary_2} !important`,
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: "2rem",
      flexDirection: "column",
      overflowX: "unset",
      overflowY: "unset",
    },
  }));

  export const PredictionPriceCard = styled(Stack)(({ theme }) => ({
    // gap: theme.spacing(3),
    flex: 1,
    minHeight: "262px",
    minWidth: "252px",
    // maxWidth: "252px",
    gap: "0.5rem",
    borderRadius: "0.625rem",
    border: `1px solid ${theme.palette.custom.strokes.primary}`,
    backgroundColor: theme.palette.custom.bg.level,
    padding: `${theme.spacing(2)} ${theme.spacing(2)}`,
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      minWidth: "unset",
    },
  }));

  export const SubText = styled("sub", {
    shouldForwardProp: (prop) => prop !== "customStyle",
  })(({ customStyle }: { customStyle?: CSSProperties }) => () => ({
    fontWeight: "bold",
    fontSize: "0.65rem",
    whiteSpace: "nowrap",
    ...customStyle,
  }));

  export const PriceCardHeading = styled(Typography, {
    shouldForwardProp: (prop) => prop !== "isPremium",
  })(({ isPremium }: { isPremium: boolean }) => ({ theme }) => ({
    fontSize: "17px",
    fontWeight: "bold",
    color: theme.palette.custom.secondary.secondary_1,
    ...(isPremium && {
      background: "linear-gradient( #E9B80C 1.43%, #EB00FF 95%)",
      webkitBackgroundClip: "text",
      WebkitTextFillColor: "transparent",
      backgroundClip: "text",
    }),
    lineHeight: 1.7,
    whiteSpace: "nowrap",
  }));

  export const PriceList = styled(Stack)(() => ({
    gap: "1rem",
    overflowY: "scroll",
    minHeight: "10rem",
    maxHeight: "10rem",
  }));

  export const CustomStarIcon = styled(StarIcon, {
    shouldForwardProp: (prop) => prop !== "isPremium",
  })(({ isPremium }: { isPremium: boolean }) => () => ({
    fontSize: "0.8rem",
    width: "0.875rem !important",
    height: "0.875rem !important",
    marginTop: "1px",
    ...(isPremium && {
      fill: "#EB00FF !important",
    }),
  }));

  export const PredictionPointsContainer = styled(Box)(() => ({
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    gap: "0.875rem",
    minHeight: "1.5rem",
  }));

  export const PredictionPoints = styled(Typography)(() => ({
    lineHeight: 1.3,
  }));

  export const CustomIconWapper = styled("span")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "0.875rem",
    minWidth: "0.875rem",
    height: "0.875rem",
    minHeight: "0.875rem",
    borderRadius: "50%",
    backgroundColor: theme.palette.custom.primary.main,
    cursor: "pointer",
  }));

  export const CustomPriorityHighIcon = styled(PriorityHighIcon)(() => ({
    height: "0.6563rem",
    transform: "rotate(180deg)",
  }));

  //Ticket number
  export const TicketHeader = styled(Typography)(() => ({
    fontWeight: "bold",
    fontSize: "1rem",
  }));

  export const TicketNumber = styled(Box, {
    shouldForwardProp: (prop) => prop !== "isPremium",
  })(({ isPremium }: { isPremium: boolean }) => ({ theme }) => ({
    fontWeight: "bold",
    fontSize: "1.85rem",
    color: theme.palette.custom.secondary.secondary_1,
    ...(isPremium && {
      background: "linear-gradient( #E9B80C 1.43%, #EB00FF 95%)",
      webkitBackgroundClip: "text",
      WebkitTextFillColor: "transparent",
      backgroundClip: "text",
    }),
  }));

  export const RefreshIcon = styled("img")(() => ({
    fontSize: "1.5rem",
  }));

  export const PredictionWinners = styled(Stack)(() => ({
    minHeight: "10.1875rem",
    maxHeight: "10.1875rem",
    gap: "1.7rem",
  }));

  export const PredictionWinnersHeader = styled(Box)(() => ({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  }));

  export const PredictionWinnersHeading = styled(Typography)(({ theme }) => ({
    fontSize: "1.25rem",
    fontWeight: "600",
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.875rem",
    },
  }));

  export const PredictionWinnersContent = styled(Box)(() => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  }));

  export const PredictionWinnersUserContainer = styled(Stack)(({ theme }) => ({
    minHeight: "8.125rem",
    maxHeight: "8.125rem",
    gap: theme.spacing(4),
  }));

  export const UserDp = styled("img")(({ theme }) => ({
    height: "50px",
    width: "50px",
    borderRadius: "50%",
    [theme.breakpoints.down("sm")]: {
      height: "45px",
      width: "45px",
    },
  }));

  export const UserId = styled(Typography)(({ theme }) => ({
    fontWeight: "600",
    fontSize: "2rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.5rem",
    },
  }));

  export const AdditionalCount = styled("sub")(({ theme }) => ({
    fontWeight: "600",
    fontSize: "1rem",
    marginTop: "0.75rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.7rem",
    },
  }));

  export const InfoText = styled(Typography)(({ theme }) => ({
    fontWeight: "500",
    fontSize: "1rem",
    color: theme.palette.custom.accent.accent_2,
    [theme.breakpoints.down("md")]: {
      fontSize: "0.875rem",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.7rem",
    },
  }));
}

export default S;
