import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Box, Stack } from "@mui/material";
import S from "./prediction.styled";
import ProfileImage from "../../../assets/images/ProfileImage.png";
import { get100KPredictionWinners, getReferrerIdByAddress } from "../../../store/slices/user.actions";
import { useGetDimensions } from "../../../utils/helpers";
import {
  useIsAppLoading,
  useIsDrawReady,
  useIsFetching100KPredictionWinners,
  useIsReady,
  usePrediction100KWinners,
  useWinnerOverlay,
} from "../../../utils/hooks/selector";
import { useAppDispatch } from "../../../utils/hooks/state";
import { Button, CustomCardWrapper } from "../../../utils/widgets";
import DotLoader from "../../../utils/widgets/loader/DotLoader";
import PredictWinnersSkeleton from "../../../utils/widgets/skeletons/predict-winners-skeleton";
import PredictDrawWinnerModal from "../../modals/predict-draw-winner-modal";

const PredictionWinners = () => {
  //constructors
  const dispatch = useAppDispatch();

  //state values
  const prediction100KWinners = useSelector(usePrediction100KWinners);
  const isFetching100KPredictionWinners = useSelector(useIsFetching100KPredictionWinners);
  const isReady = useSelector(useIsReady);
  const winnerOverlay = useSelector(useWinnerOverlay);
  const isAppLoading = useSelector(useIsAppLoading);
  const isDrawReady = useSelector(useIsDrawReady);
  const [showPrediction, setShowPrediction] = useState(false);
  const [userId, setUserId] = useState({ isLoading: false, payload: "???" });

  //constants
  const isSm = useGetDimensions();
  const winner = prediction100KWinners.length ? prediction100KWinners[0] : { dp: ProfileImage, address: "" };
  const hasSkeleton = !isReady || !isDrawReady;

  // Fetching 100K prediction winners
  useEffect(() => {
    dispatch(get100KPredictionWinners());
  }, []);

  useEffect(() => {
    if (!winner.address) return;
    setUserId({ ...userId, isLoading: true });
    getReferrerIdByAddress(winner.address).then((userId) => setUserId({ isLoading: false, payload: userId }));
  }, [winner.address]);

  //On WinnerTimerModal  Open or account switch
  useEffect(() => {
    (winnerOverlay || isAppLoading) && setShowPrediction(false);
  }, [winnerOverlay, isAppLoading]);

  return (
    <CustomCardWrapper>
      {hasSkeleton ? (
        <PredictWinnersSkeleton />
      ) : (
        <S.PredictionWinners>
          <S.PredictionWinnersHeader>
            <S.PredictionWinnersHeading>PREDICTIONS</S.PredictionWinnersHeading>
            <S.GradientText shineText="#predict2yearn">#predict2yearn</S.GradientText>
          </S.PredictionWinnersHeader>
          <S.PredictionWinnersContent>
            <Stack gap="1.9rem">
              <Box display="flex" alignItems="center" gap={2} justifyContent={isFetching100KPredictionWinners ? "center" : "unset"}>
                {isFetching100KPredictionWinners || userId.isLoading ? (
                  <DotLoader />
                ) : (
                  <>
                    <S.UserDp src={winner.dp} alt="profile-img" />
                    <S.UserId>#{userId.payload}</S.UserId>
                    {prediction100KWinners.length > 1 ? <S.AdditionalCount>& {prediction100KWinners.length - 1} users</S.AdditionalCount> : null}
                  </>
                )}
              </Box>
              <S.InfoText>{isFetching100KPredictionWinners ? "Predicting the 100K Draw..." : "Predicted the 100K Draw successfully!"}</S.InfoText>
            </Stack>
            <Box display="flex" justifyContent="center">
              <Button
                buttonType="primary"
                customStyle={{
                  fontSize: isSm ? "0.875rem" : "12px",
                  fontWeight: "600",
                  lineHeight: "2",
                  padding: isSm ? "1.3px 16px" : "4px 20px",
                  borderRadius: isSm ? "4px" : "5px",
                }}
                onClick={() => setShowPrediction(true)}
              >
                View All {isSm ? "" : "Prediction Winners"}
              </Button>
              {showPrediction && <PredictDrawWinnerModal open={true} onClose={() => setShowPrediction(false)} />}
            </Box>
          </S.PredictionWinnersContent>
        </S.PredictionWinners>
      )}
    </CustomCardWrapper>
  );
};

export default PredictionWinners;
