import { CSSProperties, ReactNode } from "react";

export namespace CustomModalTypes {
  export type Container = {
    children: ReactNode;
    open: boolean;
    customStyle?: CSSProperties;
    onClose?: () => void;
    isLoading?: boolean;
    className?: string;
  };

  export type Header = {
    children: ReactNode;
    handleClose: () => void;
    isTransparent?: boolean;
    disableCloseIcon?: boolean;
  };

  export type Body = {
    children: ReactNode;
    isTransparent?: boolean;
    customStyle?: CSSProperties;
  };

  export type Footer = {
    children: ReactNode;
    isTransparent?: boolean;
  };
}

//Winning Header
export type WinningContentType = { text1?: string; text2?: string; text3?: string };

export type WinningHeaderContentType = {
  [key: number]: WinningContentType;
};

//Prediction Modal Toggle
export enum PredictionHeaderType {
  allPredictions = "All Predictions",
  myPredictions = "My Predictions",
}
