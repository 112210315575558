import { Stack, styled } from "@mui/material";

namespace S {
  export const JackpotWinnerBodyContainer = styled(Stack)(({ theme }) => ({
    margin: `0 ${theme.spacing(4)} ${theme.spacing(4)}`,
    maxWidth: "1280px",
  }));
}

export default S;
