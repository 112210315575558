import { FC, ReactNode, createContext, useState, Dispatch, SetStateAction, useEffect } from "react";
import { useSelector } from "react-redux";
import { lottoContract } from "../../services/ethereum/contract/web3Contract";
import { ONE_K_DRAW } from "../constants";
import { getOneKCompleted } from "../helpers";
import { useCurrentDraw, useCurrentTenKDraw, useDraw1kNumber, useEvents, useIsReady, useTicketsSold } from "../hooks/selector";

export type refrrerType = {
  referrerId: string;
  referrerAddress: string;
};

type referrerDetailsType = {
  referrerId: string;
  referrerAddress: string;
  isBuyNowDisabled: boolean;
  setReferrer: Dispatch<SetStateAction<refrrerType>>;
  setIsBuyNowDisabled: Dispatch<SetStateAction<boolean>>;
  referrer: refrrerType;
};

type ReferrerContextProps = {
  children: ReactNode;
};

const ReferrerContext = createContext<referrerDetailsType>({
  referrerId: "",
  referrerAddress: "",
  setReferrer: () => {},
  isBuyNowDisabled: true,
  setIsBuyNowDisabled: () => {},
  referrer: { referrerId: "", referrerAddress: "" },
});

const ReferrerContextProvider: FC<ReferrerContextProps> = ({ children }) => {
  //state values
  const ticketsSold = useSelector(useTicketsSold);
  const currentDraw = useSelector(useCurrentDraw);
  const currentTenKDraw = useSelector(useCurrentTenKDraw);
  const isReady = useSelector(useIsReady);
  const recentWinners = useSelector(useEvents).recentWinners;
  const draw1kNumber = useSelector(useDraw1kNumber);
  const [referrer, setReferrer] = useState({ referrerId: "", referrerAddress: "" });
  const [isBuyNowDisabled, setIsBuyNowDisabled] = useState(false);

  //Winner overlay
  useEffect(() => {
    if (!ticketsSold || !isReady || +currentDraw < 1) return;

    lottoContract.getDrawDetails(+currentDraw).then((res) => {
      if (
        (res && !res.is_active && res.winner[0] !== "0") ||
        getOneKCompleted(ticketsSold, recentWinners[ONE_K_DRAW].userId, currentDraw, draw1kNumber)
      )
        setIsBuyNowDisabled(true);
    });
  }, [isReady, currentDraw, ticketsSold]);

  return (
    <ReferrerContext.Provider
      value={{
        referrerId: referrer.referrerId,
        referrerAddress: referrer.referrerAddress,
        setReferrer,
        setIsBuyNowDisabled,
        isBuyNowDisabled,
        referrer,
      }}
    >
      {children}
    </ReferrerContext.Provider>
  );
};

export { ReferrerContext, ReferrerContextProvider };
