import { Stack, styled, Box, Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Input from "@mui/material/Input";
import { GRAD_COLOR_1, GRAD_COLOR_2, GRAD_COLOR_3, HUNDRED_K_DRAW, TEN_K_DRAW } from "../../../utils/constants";

namespace S {
  export const StakeYearnContainer = styled(Stack)(({ theme }) => ({
    minHeight: "10.875rem",
    // maxHeight: "10.875rem",
    gap: theme.spacing(2),
    position: "relative",
    width: "95%",
    [theme.breakpoints.down("sm")]: {
      minHeight: "fit-content",
      maxHeight: "fit-content",
    },
  }));

  export const StakeHeader = styled(Box)(({ theme }) => ({
    boxSizing: "border-box",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: "10px",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  }));

  export const HeaderLeft = styled(Box)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    gap: "0.6rem",
    width: "50%",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "left",

      width: "100%",
    },
  }));

  export const HeaderText = styled(Typography)(({ theme }) => ({
    fontSize: "1.25rem",
    fontWeight: "600",
    lineHeight: 1,
  }));

  export const HeaderSubText = styled(Typography)(({ theme }) => ({
    fontSize: "0.625rem",
    borderRadius: "4px",
    background: "#3E3E3E80",
    padding: "0.25rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "8px",
    },
  }));

  export const HeaderRight = styled(Box)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "0.6rem",
    width: "50%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  }));

  export const AccentRewardText = styled(Box)(({ theme }) => ({
    fontSize: "0.625rem",
    borderRadius: "4px",
    background: "#3E3E3E80",
    color: theme.palette.custom.accent.accent_1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "0.25rem",
    gap: "0.225rem",
    width: "100%",
    "& span": {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      maxWidth: "40px",
      color: theme.palette.custom.accent.accent_1,
    },
  }));

  export const AccentStakeText = styled(AccentRewardText)(({ theme }) => ({
    color: theme.palette.custom.accent.accent_2,

    "& span": {
      color: theme.palette.custom.accent.accent_2,
    },
  }));

  export const Content = styled(Box)(({ theme }) => ({
    boxSizing: "border-box",
    display: "flex",
    height: "10rem",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      height: "fit-content",
    },
  }));

  export const ContentLeft = styled(Box)(({ theme }) => ({
    width: "50%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    gap: "1rem",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  }));

  export const InputContainer = styled(Box)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    gap: "0.5rem",
  }));

  export const StakeLabel = styled(Box)(({ theme }) => ({
    fontSize: "0.75rem",
    color: theme.palette.custom.accent.accent_1,
    lineHeight: 1,
    display: "flex",
  }));

  export const UnStakeLabel = styled(StakeLabel)(({ theme }) => ({
    color: theme.palette.custom.accent.accent_2,
    display: "flex",
  }));

  export const CustomInput = styled(Input)(({ theme }) => ({
    background: theme.palette.custom.strokes.primary,
    borderRadius: "0.5rem",
    minHeight: "2.75rem",
    fontSize: "0.75rem",
    width: "16.9375rem",
    maxWidth: "95%",
    paddingLeft: "1rem",
    '& input[type="number"]::-webkit-inner-spin-button, & input[type="number"]::-webkit-outer-spin-button': {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& input:disabled": {
      cursor: "not-allowed",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  }));

  export const TicketErrorText = styled(Typography, {
    shouldForwardProp: (prop) => prop !== "errorMessage" && prop !== "isVisible",
  })(({ isVisible }: { isVisible: boolean }) => ({ theme }) => ({
    display: "flex",
    lineHeight: 1,
    whiteSpace: "nowrap",
    color: isVisible ? "red" : "white",
    fontSize: "10px",
    ".balance-text": {
      color: theme.palette.custom.accent.accent_1,
    },
    ".insufficient-balance-text": {
      color: "red",
    },
  }));

  export const ContentRight = styled(Box)(({ theme }) => ({
    width: "50%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  }));

  export const GiftBoxImage = styled("img")(({ theme }) => ({
    padding: "5px 0",
    [theme.breakpoints.down("sm")]: {
      width: "65px",
    },
  }));

  export const GiftText = styled(Typography)(({ theme }) => ({
    fontSize: "1rem",
  }));

  export const GradientGiftText = styled(Typography, {
    shouldForwardProp: (prop) => prop !== "gradient",
  })(({ gradient }: { gradient: string }) => ({ theme }) => ({
    fontSize: "1rem",
    fontWeight: "500",
    background: gradient === HUNDRED_K_DRAW ? GRAD_COLOR_3 : gradient === TEN_K_DRAW ? GRAD_COLOR_2 : GRAD_COLOR_1,
    webkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    backgroundClip: "text",
    lineHeight: 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "0.25rem",
    gap: "0.225rem",
  }));

  export const IBtnContainer = styled(IconButton)(({ theme }) => ({
    background: theme.palette.custom.primary.main,
    maxHeight: "10px",
    maxWidth: "10px",
    height: "10px",
    pointerEvents: "auto",
    fontSize: "8px",
    marginLeft: "10px",
    // alignSelf: "center",
    padding: "6px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "&:hover": {
      backgroundColor: "#1846dd",
    },
    p: {
      lineHeight: "0.5",
      fontSize: "8px",
    },
  }));
}

export default S;
