import { FC } from "react";
import { S } from "./loader.styled";

interface ICircleLoaderProps {
  size: number;
}

const CircularLoader: FC<ICircleLoaderProps> = ({ size }) => {
  return <S.CircleLoader size={size} />;
};

export default CircularLoader;
