import { FC, useState } from "react";
import S from "./post-connect.styled";
import LeftSideArrowPanel from "../left-side-arrow-panel";
import LeftSidePanel from "../left-side-panel";
import Mainboard from "../mainboard";
import RightSideArrowPanel from "../right-side-arrow-panel";
import RightSidePanel from "../right-side-panel";

const PostConnect: FC = () => {
  //state values
  const [showLeftPanel, setShowLeftPanel] = useState(false);
  const [showRightPanel, setShowRightPanel] = useState(false);

  return (
    <S.PostConnectWrapper>
      <LeftSideArrowPanel showLeftPanel={showLeftPanel} setShowLeftPanel={setShowLeftPanel} setShowRightPanel={setShowRightPanel} />
      <LeftSidePanel showLeftPanel={showLeftPanel} setShowLeftPanel={setShowLeftPanel} />
      <Mainboard />
      <RightSideArrowPanel showRightPanel={showRightPanel} setShowRightPanel={setShowRightPanel} setShowLeftPanel={setShowLeftPanel} />
      <RightSidePanel showRightPanel={showRightPanel} setShowRightPanel={setShowRightPanel} />
    </S.PostConnectWrapper>
  );
};

export default PostConnect;
