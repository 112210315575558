import { useContext, useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useAccount, useNetwork, useWalletClient } from "wagmi";
import Web3 from "web3";
import { iercContract } from "../../services/ethereum/contract/iercContract";
import { poolContract } from "../../services/ethereum/contract/poolContract";
import { predictionContract } from "../../services/ethereum/contract/predictionContract";
import { PredictionPoolContract } from "../../services/ethereum/contract/predictionPoolContract";
import { stakeContract } from "../../services/ethereum/contract/stakeContract";
import { lottoContract } from "../../services/ethereum/contract/web3Contract";
import { DrawState, setDrawState } from "../../store/slices/draw";
import { fetchParticipantsByPool, getActivePoolDetails, updateActivePool } from "../../store/slices/pool.actions";
import { setContractIsReady } from "../../store/slices/user";
import { getLatestClaimedTokenByAddress } from "../../store/slices/user.actions";
import { connect, fetchPredictGameTokens, getTokenBalance, onAccountChange, updateCurrentDraws } from "../../store/slices/wallet.actions";
import { CURRENT_CHAIN_ID } from "../constants";
import { ReferrerContext } from "../contexts/ReferrerContext";
import Toast from "../widgets/toast";
import {
  useAppStatus,
  useClaimableToken,
  useEnteredDraw,
  useIsDrawReady,
  useIsReady,
  useNextAvailableAt,
  usePoolInfo,
  useTicketsSold,
} from "./selector";
import { useAppDispatch } from "./state";

let walletAddress: string | null = null;

const useWalletConnection = () => {
  //constructor
  const dispatch = useAppDispatch();

  //constants
  const { isConnected: isWalletConnected, address } = useAccount();
  const { data: signer } = useWalletClient();

  const { chain } = useNetwork();
  const location = useLocation();

  //state values
  const isReady = useSelector(useIsReady);
  const appStatus = useSelector(useAppStatus);
  const ticketsSold = useSelector(useTicketsSold);
  const claimableToken = useSelector(useClaimableToken);
  const nextAvailableAt = useSelector(useNextAvailableAt);
  const poolInfo = useSelector(usePoolInfo);
  const enteredDraw = useSelector(useEnteredDraw);
  const isDrawReady = useSelector(useIsDrawReady);

  const { setReferrer } = useContext(ReferrerContext);

  //To setup contracts.
  useEffect(() => {
    const deploy = async () => {
      let lottoDeployed = await lottoContract.setup(signer);
      let iercDeployed = await iercContract.setup(signer);
      let predictDeployed = await predictionContract.setup(signer);
      let poolContractDeployed = await poolContract.setup(signer);
      let stakeContractDeployed = await stakeContract.setup(signer);
      let predictionPoolDeployed = await PredictionPoolContract.setup(signer);
      if (lottoDeployed && iercDeployed && predictDeployed && poolContractDeployed && stakeContractDeployed && predictionPoolDeployed) {
        dispatch(setContractIsReady(true));
        let draw1kNumber = await lottoContract.getDraw1k();
        let draw10kNumber = await lottoContract.getDraw10k();
        let draw100kNumber = await lottoContract.getDraw100k();
        let drawAmount = await lottoContract.getDrawAmount();
        let drawFee = await lottoContract.getDrawFee();
        let maxTickets = await lottoContract.getMaxTickets();
        let maxDraws = await lottoContract.getMaxDraws();

        let drawstate: DrawState = {
          draw1kNumber: Number(draw1kNumber),
          draw10kNumber: Number(draw10kNumber),
          draw100kNumber: Number(draw100kNumber),
          drawAmount: Number(Web3.utils.fromWei(drawAmount.toString(), "ether")),
          drawFee: Number(Web3.utils.fromWei(drawFee.toString(), "ether")),
          maxTickets: Number(maxTickets),
          maxDraws: Number(maxDraws),
          isDrawReady: true,
        };
        dispatch(setDrawState(drawstate));
      }
    };
    if (address === undefined || (address && chain?.id === CURRENT_CHAIN_ID)) {
      deploy();
    }
  }, [signer, chain?.id]);

  //Connect and reconnect
  useEffect(() => {
    if (isWalletConnected) {
      if (isReady) dispatch(connect(`${address}`));
    }
  }, [isWalletConnected, isReady]);

  //On network change
  useEffect(() => {
    if (isWalletConnected) {
      if (CURRENT_CHAIN_ID === 56 && chain?.id !== 56) Toast({ message: "Kindly switch to BNB Smart Chain!" });
      if (CURRENT_CHAIN_ID === 80001 && chain?.id !== 80001) Toast({ message: "Kindly switch to Matic Network!" });
    }
  }, [isWalletConnected, chain]);

  //On account change
  useEffect(() => {
    let interval: any;
    if (address && isWalletConnected && isReady) {
      dispatch(onAccountChange(`${address}`, setReferrer, location.pathname, Boolean(walletAddress)));
      interval = setInterval(() => {
        dispatch(getTokenBalance(signer, address));
      }, 5000);
      walletAddress = address;
    } else if (!address) walletAddress = null;
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signer, address, isReady]);

  // Fetching game tokens
  useEffect(() => {
    if (isReady && address && isWalletConnected && enteredDraw) {
      dispatch(fetchPredictGameTokens());
    }
  }, [address, isReady, ticketsSold, enteredDraw]);

  useEffect(() => {
    if (!+claimableToken.unclaimed || !address) return;
    dispatch(getLatestClaimedTokenByAddress(address));
  }, [claimableToken, address]);

  useEffect(() => {
    if (!address) return;
    let nextAvailableAtInterval = setInterval(() => {
      dispatch(getLatestClaimedTokenByAddress(address));
    }, 10000);

    if (!nextAvailableAt.length || !+claimableToken.unclaimed) clearInterval(nextAvailableAtInterval);

    return () => clearInterval(nextAvailableAtInterval);
  }, [nextAvailableAt, claimableToken]);

  //Subscribe events
  useEffect(() => {
    if (isReady) dispatch(updateCurrentDraws(appStatus, ticketsSold));
  }, [isReady, isDrawReady]);

  // Fetching active pool
  useEffect(() => {
    if (isReady) dispatch(updateActivePool());
  }, [isReady, ticketsSold]);

  useEffect(() => {
    if (poolInfo.activePool?.trim().length) {
      dispatch(getActivePoolDetails(poolInfo.activePool.toLowerCase()));
      dispatch(fetchParticipantsByPool(poolInfo.activePool.toLowerCase()));
    }
  }, [poolInfo.activePool]);

  //Getting claim amounts
  // useEffect(() => {
  //   if (!isReady) return;
  //   let claimAmountInterval: ReturnType<typeof setInterval>;
  //   if (isWalletConnected && address) {
  //     claimAmountInterval = setInterval(async () => {
  //       if (chain?.id === CURRENT_CHAIN_ID) {
  //         let claimable = await lottoContract.getClaimableAmount(address);
  //         dispatch(
  //           setClaimableAmount({
  //             unclaimed: Number(Web3.utils.fromWei(claimable[0], "ether")),
  //             claimed: Number(Web3.utils.fromWei(claimable[1], "ether")),
  //           }),
  //         );
  //       }
  //     }, 5000);
  //   }

  //   return () => clearInterval(claimAmountInterval);
  // }, [address, isWalletConnected, chain?.id, isReady]);
};

export default useWalletConnection;
