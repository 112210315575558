import { gql } from "graphql-request";

//users
export const getUserDetails = gql`
  query getUserDetails($where: NewDeposit_filter) {
    newDeposits(orderBy: drawNumber, orderDirection: asc, where: $where) {
      addr
      amount
      blockNumber
      blockTimestamp
      drawNumber
      id
      transactionHash
      userId
    }
  }
`;

export const getUsersByAddress = gql`
  query getUsersByAddress($where: NewDeposit_filter) {
    newDeposits(orderBy: drawNumber, orderDirection: asc, where: $where) {
      addr
      amount
      blockTimestamp
      drawNumber
      id
      userId
      ticketNumber
      transactionHash
    }
  }
`;

export const getDepositTimeStampByAddress = gql`
  query getDepositTimeStampByAddress($where: NewDeposit_filter) {
    newDeposits(orderBy: blockTimestamp, orderDirection: desc, where: $where) {
      blockTimestamp
      transactionHash
    }
  }
`;

export const getUsersById = gql`
  query getUsersById($where: NewDeposit_filter) {
    newDeposits(orderBy: drawNumber, orderDirection: asc, where: $where) {
      addr
      amount
      blockTimestamp
      drawNumber
      id
      userId
      transactionHash
    }
  }
`;

//Draw winners
export const getDrawWinnersAddress = gql`
  query drawWinners($where: DrawWinner_filter) {
    drawWinners(orderBy: drawNumber, orderDirection: asc, where: $where) {
      id
      addr
      amount
      drawNumber
      userId
      ticketNumber
      transactionHash
      blockTimestamp
    }
  }
`;

export const getDraw10KWinnersAddress = gql`
  query draw10KWinners($where: Draw10KWinner_filter) {
    draw10KWinners(orderBy: draw10KNumber, orderDirection: asc, where: $where) {
      id
      addr
      amount
      draw10KNumber
      userId
      ticketNumber
      transactionHash
      blockTimestamp
    }
  }
`;

export const getDraw100KWinnersAddress = gql`
  query draw100KWinners {
    draw100KWinners(orderBy: blockTimestamp, orderDirection: asc) {
      id
      addr
      userId
      ticketNumber
      amount
      blockNumber
      blockTimestamp
      transactionHash
    }
  }
`;

export const getMultipleDrawWinners = gql`
  query winners($where: DrawWinner_filter) {
    drawWinners(where: $where) {
      ticketNumber
    }
    draw10KWinners(where: $where) {
      ticketNumber
    }
    draw100KWinners(where: $where) {
      ticketNumber
    }
  }
`;

export const get100KWinnersById = gql`
  query draw100KWinners($where: Draw100KWinner_filter) {
    draw100KWinners(where: $where) {
      amount
    }
  }
`;

//Refferrers
export const getRefferrersByAddress = gql`
  query referrers($where: Referrer_filter) {
    referrers(where: $where) {
      id
      addr
      userId
    }
  }
`;

//Distributed
export const getDistributedAmount = gql`
  query platformStatistics {
    platformStatistics {
      completed10kDraw
      completedDraw
      currentDraw
      id
      totalDepsitAmount
      totalPaidUser
      totalReferralAmount
      totalUser
      totalWinningAmount
    }
  }
`;

export const getDistributedAmountTest = gql`
  query platformStatistics {
    platformStatistics {
      completed10kDraw
      completedDraw
      currentDraw
      id
      totalDepsitAmount
      totalPaidUser
      totalReferralAmount
      totalUser
      totalWinningAmount
    }
  }
`;

//Earnings
export const getDrawWinnerReferralEarnings = gql`
  query platformStatistics($where: DrawWinnerReferrerBonus_filter) {
    drawWinnerReferrerBonuses(where: $where) {
      amount
    }
    drawWinnerReferralPayouts(where: $where) {
      amount
    }
  }
`;

export const getReferralPayouts = gql`
  query referralPayouts($where: ReferralPayout_filter) {
    referralPayouts(where: $where) {
      amount
    }
  }
`;

//Pools
export const getPoolByAddress = gql`
  query pools($where: Pool_filter) {
    pools(where: $where) {
      claimedAmount
      deposited
      id
      joinedDraw
      maxAmount
      total
      totalOwners
      withdrawn
    }
  }
`;

export const getOwners = gql`
  query owners($where: Owner_filter, $poolsWhere2: OwnerPool_filter) {
    owners(where: $where) {
      pools(where: $poolsWhere2) {
        deposited
        id
        owner {
          id
        }
        total
        withdrawn
      }
    }
  }
`;

export const getClaimedToken = gql`
  query claimedTokens($where: ClaimedToken_filter) {
    claimedTokens(where: $where, orderDirection: desc, orderBy: blockTimestamp, first: 1) {
      blockTimestamp
    }
  }
`;

// Redeem
export const get1KFreePredictionRedeemed = gql`
  query redeemed1KDrawFreePredictionRewards($where: Redeemed1kDrawFreePredictionReward_filter) {
    redeemed1KDrawFreePredictionRewards(where: $where, orderDirection: desc, orderBy: blockTimestamp, first: 1) {
      transactionHash
      drawNumber
    }
  }
`;
export const get1KPremiumPredictionRedeemed = gql`
  query redeemed1KDrawPremiumPredictionRewards($where: Redeemed1kDrawPremiumPredictionReward_filter) {
    redeemed1KDrawPremiumPredictionRewards(where: $where, orderDirection: desc, orderBy: blockTimestamp, first: 1) {
      transactionHash
      drawNumber
    }
  }
`;
export const get100KFreePredictionRedeemed = gql`
  query redeemed100KDrawFreePredictionRewards($where: Redeemed100kDrawFreePredictionReward_filter) {
    redeemed100KDrawFreePredictionRewards(where: $where, orderDirection: desc, orderBy: blockTimestamp, first: 1) {
      transactionHash
    }
  }
`;
export const get100KPremiumPredictionRedeemed = gql`
  query redeemed100KDrawPremiumPredictionRewards($where: Redeemed100kDrawPremiumPredictionReward_filter) {
    redeemed100KDrawPremiumPredictionRewards(where: $where, orderDirection: desc, orderBy: blockTimestamp, first: 1) {
      transactionHash
    }
  }
`;

//Admin approval
export const getPredictionApproval = gql`
  query getPredictionApproval {
    prediction100KPoolRewardsAddeds {
      id
    }
    prediction1KPoolRewardsAddeds {
      drawNumber
    }
  }
`;

export const getContractWinnerQuery = gql`
  query getContractWinner($where: PoolAWinner_filter, $where2: PoolBWinner_filter) {
    poolBWinners(where: $where) {
      blockTimestamp
      id
      predictionAddress
    }
    poolAWinners(where: $where2) {
      blockTimestamp
      id
      predictionAddress
    }
    noResults(where: $where) {
      blockTimestamp
      id
      predictionAddress
    }
  }
`;

export const getPredictionCount = gql`
  query getPredictionCount($where: PredictedPoolA_filter) {
    predictedPoolAs(where: $where) {
      id
      sender
    }
    predictedPoolBs(where: $where) {
      id
      sender
    }
  }
`;

export const getIsRedeemedReward = gql`
  query getIsRedeemedReward($where: RedeemedReward_filter) {
    redeemedRewards(where: $where) {
      addr
      blockNumber
      blockTimestamp
      id
      predictionAddress
      rewards
      transactionHash
    }
  }
`;
