import { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Box, Tooltip, Typography } from "@mui/material";
import { useAccount, useWalletClient } from "wagmi";
import { SNewEarnedTokensPopover, YellowText } from "./popovers.styled";
import MetamaskIcon from "../../assets/images/metamask-icon.svg";
import OpenInExplorerIcon from "../../assets/images/open-in-explorer.svg";
import YEARNLOGO from "../../assets/images/yearn-coin.svg";
import { lottoContract } from "../../services/ethereum/contract/web3Contract";
import { addYTGToWallet } from "../../services/helpers";
import { CURRENT_CHAIN_ID, GRADIENT, YEARN_TOKEN } from "../../utils/constants";
import { useGetDimensions, convertWeiToEth, getRoundAmount, exploreAccount, getEllipsisString } from "../../utils/helpers";
import {
  useAddingYTG,
  useClaimableToken,
  useIsReady,
  useIsWithdrawingPredictTokens,
  useIsWithdrawingTokens,
  useNextAvailableAt,
  usePredictGameToken,
  useUserDetails,
} from "../../utils/hooks/selector";
import { yearnTokenAddress } from "../../utils/providers/platforms";
import { CustomPopoverProps } from "../../utils/types";
import { Button, GradientButton, Triangle } from "../../utils/widgets";
import CustomPopover from "../../utils/widgets/popover";

interface EarnedTokenProps extends CustomPopoverProps {
  handleClaim: () => void;
  handlePredictClaim: () => void;
}

const NewEarnedTokensPopover: FC<EarnedTokenProps> = (props) => {
  //props
  const { id, isOpen, onClose, handleClaim, handlePredictClaim } = props;

  //state values

  const isWithdrawingTokens = useSelector(useIsWithdrawingTokens);
  const claimableToken = useSelector(useClaimableToken);
  const predictGameToken = useSelector(usePredictGameToken);
  const isWithdrawingPredictTokens = useSelector(useIsWithdrawingPredictTokens);
  const isReady = useSelector(useIsReady);
  const userDetails = useSelector(useUserDetails);
  const nextAvailableAt = useSelector(useNextAvailableAt);
  const addingYTG = useSelector(useAddingYTG);
  const [claim, setClaim] = useState(false);
  const [toolTipTime, setToolTipTime] = useState(false);

  const { data: signer } = useWalletClient();

  const explorerUrl = exploreAccount(CURRENT_CHAIN_ID, yearnTokenAddress[CURRENT_CHAIN_ID]);
  const currentTokenAddress = yearnTokenAddress[CURRENT_CHAIN_ID];
  //constants
  const isSm = useGetDimensions();
  const totalTokens = getRoundAmount(
    `${BigInt(claimableToken.claimed) + BigInt(claimableToken.unclaimed) + BigInt(predictGameToken.claimed) + BigInt(predictGameToken.unclaimed)}`,
  );

  //To enable claim
  useEffect(() => {
    if (isReady && userDetails.address) {
      lottoContract.getIsWithdrawalEnabled().then((res: boolean) => setClaim(res));
    }
  }, [isReady, userDetails.address]);

  //functions
  const handleAddressCopy = () => {
    setToolTipTime(true);
    navigator.clipboard.writeText(currentTokenAddress);
    setTimeout(() => {
      setToolTipTime(false);
    }, 3000);
  };

  const handleOpenExplorerLink = () => {
    window.open(explorerUrl);
  };

  return (
    <>
      {Boolean(isOpen) && <Triangle customStyle={{ top: 34, right: 45 }} />}
      <CustomPopover id={id} isOpen={isOpen} onClose={onClose} customStyle={{ top: 20, left: isSm ? 50 : -37 }}>
        <SNewEarnedTokensPopover.Wrapper>
          <SNewEarnedTokensPopover.Header>
            <SNewEarnedTokensPopover.HeaderContainer>
              <SNewEarnedTokensPopover.HeaderTitle variant="subtitle1">Earned Tokens</SNewEarnedTokensPopover.HeaderTitle>
              <SNewEarnedTokensPopover.CopyContainer>
                <SNewEarnedTokensPopover.CustomInput
                  id="input-with-icon-adornment"
                  sx={{
                    "& .MuiInputBase-input.Mui-disabled": {
                      WebkitTextFillColor: "#ffffff",
                    },
                  }}
                  endAdornment={
                    <SNewEarnedTokensPopover.CustomInputAdornment position="start">
                      <Tooltip title={toolTipTime ? "copied to clipboard" : "Click to copy"} arrow>
                        <SNewEarnedTokensPopover.IconContainer onClick={() => handleAddressCopy()}>
                          <SNewEarnedTokensPopover.CustomCopyIcon />
                        </SNewEarnedTokensPopover.IconContainer>
                      </Tooltip>
                      <Tooltip title="Add to MetaMask" arrow>
                        <SNewEarnedTokensPopover.IconContainer onClick={() => addYTGToWallet(signer)}>
                          <SNewEarnedTokensPopover.MetamaskIcon src={MetamaskIcon} alt="" />
                        </SNewEarnedTokensPopover.IconContainer>
                      </Tooltip>
                      <Tooltip title="Open in explorer" arrow>
                        <SNewEarnedTokensPopover.IconContainer onClick={() => handleOpenExplorerLink()}>
                          <SNewEarnedTokensPopover.OpenExplorerImg src={OpenInExplorerIcon} alt="" />
                        </SNewEarnedTokensPopover.IconContainer>
                      </Tooltip>
                    </SNewEarnedTokensPopover.CustomInputAdornment>
                  }
                  disableUnderline
                  disabled
                  value={getEllipsisString(currentTokenAddress, 5, 4)}
                />
              </SNewEarnedTokensPopover.CopyContainer>
            </SNewEarnedTokensPopover.HeaderContainer>
            <SNewEarnedTokensPopover.HeaderAmountSection>
              <SNewEarnedTokensPopover.HeaderLogo src={YEARNLOGO} alt="yearn-logo" />
              <SNewEarnedTokensPopover.HeaderAmountTextContainer>
                <Typography fontWeight={500} variant="caption">
                  Total Tokens
                </Typography>
                <SNewEarnedTokensPopover.HeaderAmount variant="h5">
                  {totalTokens} {YEARN_TOKEN}
                </SNewEarnedTokensPopover.HeaderAmount>
                <Typography fontWeight={500} variant="caption">
                  1 {YEARN_TOKEN} = 1 wYEARN
                </Typography>
              </SNewEarnedTokensPopover.HeaderAmountTextContainer>
            </SNewEarnedTokensPopover.HeaderAmountSection>
          </SNewEarnedTokensPopover.Header>
          <SNewEarnedTokensPopover.Body>
            <Box display={"flex"} alignItems={"center"}>
              <SNewEarnedTokensPopover.BodyHeadTitle variant="h6">Yearn Participation</SNewEarnedTokensPopover.BodyHeadTitle>
              <SNewEarnedTokensPopover.IBtnContainer>i</SNewEarnedTokensPopover.IBtnContainer>
            </Box>
            <SNewEarnedTokensPopover.BodyDetailsContainer>
              <SNewEarnedTokensPopover.BodyTitle variant="caption" fontWeight={"500"}>
                Claimed Tokens
              </SNewEarnedTokensPopover.BodyTitle>
              <SNewEarnedTokensPopover.BodyTitle variant="subtitle1" fontWeight={"bold"}>
                {convertWeiToEth(claimableToken.claimed)}
              </SNewEarnedTokensPopover.BodyTitle>
            </SNewEarnedTokensPopover.BodyDetailsContainer>
            <SNewEarnedTokensPopover.BodyDetailsContainer>
              <SNewEarnedTokensPopover.BodyDetail variant="caption" fontWeight={"500"}>
                Unclaimed Tokens
              </SNewEarnedTokensPopover.BodyDetail>
              <SNewEarnedTokensPopover.BodyDetail variant="subtitle1" fontWeight={"bold"}>
                {convertWeiToEth(claimableToken.unclaimed)}
              </SNewEarnedTokensPopover.BodyDetail>
            </SNewEarnedTokensPopover.BodyDetailsContainer>
          </SNewEarnedTokensPopover.Body>
          <SNewEarnedTokensPopover.BtnContainer>
            <YellowText>{nextAvailableAt}</YellowText>
            <GradientButton
              buttonType={GRADIENT}
              onClick={handleClaim}
              containerCustomStyle={{ width: "100% !important" }}
              customStyle={{ width: "100% !important", height: "2rem", padding: "0.3125rem 1rem" }}
              disabled={Number(claimableToken.unclaimed) === 0 || isWithdrawingTokens || Boolean(nextAvailableAt.length) || userDetails.isFreeUser}
              loading={isWithdrawingTokens}
            >
              {isWithdrawingTokens ? "Claiming..." : "Claim Tokens"}
            </GradientButton>
          </SNewEarnedTokensPopover.BtnContainer>

          {/* <SNewEarnedTokensPopover.Body>
            <Box display={"flex"} alignItems={"center"}>
              <SNewEarnedTokensPopover.BodyHeadTitle variant="h6">Yearn Prediction</SNewEarnedTokensPopover.BodyHeadTitle>
              <SNewEarnedTokensPopover.IBtnContainer>i</SNewEarnedTokensPopover.IBtnContainer>
            </Box>
            <SNewEarnedTokensPopover.BodyDetailsContainer>
              <SNewEarnedTokensPopover.BodyTitle variant="caption" fontWeight={"500"}>
                Claimed Tokens
              </SNewEarnedTokensPopover.BodyTitle>
              <SNewEarnedTokensPopover.BodyTitle variant="subtitle1" fontWeight={"bold"}>
                {convertWeiToEth(predictGameToken.claimed)}
              </SNewEarnedTokensPopover.BodyTitle>
            </SNewEarnedTokensPopover.BodyDetailsContainer>
            <SNewEarnedTokensPopover.BodyDetailsContainer>
              <SNewEarnedTokensPopover.BodyDetail variant="caption" fontWeight={"500"}>
                Unclaimed Tokens
              </SNewEarnedTokensPopover.BodyDetail>
              <SNewEarnedTokensPopover.BodyDetail variant="subtitle1" fontWeight={"bold"}>
                {convertWeiToEth(predictGameToken.unclaimed)}
              </SNewEarnedTokensPopover.BodyDetail>
            </SNewEarnedTokensPopover.BodyDetailsContainer>
          </SNewEarnedTokensPopover.Body> */}

          {/* <SNewEarnedTokensPopover.BtnContainer>
            <GradientButton
              buttonType={GRADIENT}
              onClick={handlePredictClaim}
              containerCustomStyle={{ width: "100%" }}
              customStyle={{ width: "100%", height: "2rem", padding: "0.3125rem 1rem" }}
              disabled={Number(predictGameToken.unclaimed) === 0 || !claim || isWithdrawingPredictTokens || userDetails.isFreeUser}
            >
              {isWithdrawingPredictTokens ? "Claiming..." : "Claim Tokens"}
            </GradientButton>
          </SNewEarnedTokensPopover.BtnContainer> */}

          <SNewEarnedTokensPopover.Footer>
            <Button
              buttonType="primary"
              containerCustomStyle={{ width: "100%" }}
              customStyle={{ width: "100% !important", height: "2rem", padding: "0.3125rem 1rem" }}
              onClick={() => addYTGToWallet(signer)}
              disabled={addingYTG}
            >
              {addingYTG ? `Adding wYEARN to Metamask...` : `+ Add wYEARN to Metamask`}
            </Button>
            {/* <Typography fontWeight={500} variant="caption">
              Total Tokens Claimed
            </Typography>
            <SNewEarnedTokensPopover.FooterAmountText variant="h4">2500.0000</SNewEarnedTokensPopover.FooterAmountText> */}
          </SNewEarnedTokensPopover.Footer>
        </SNewEarnedTokensPopover.Wrapper>
      </CustomPopover>
    </>
  );
};

export default NewEarnedTokensPopover;
