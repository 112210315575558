import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Typography } from "@mui/material";
import { QRCodeSVG } from "qrcode.react";
import { useAccount } from "wagmi";
import S from "./affiliater.styled";
import YearnCoin from "../../../assets/images/yearn-coin.svg";
import { REFERRAL_PATH_NAME } from "../../../utils/constants";
import { getWholeNum, useGetDimensions } from "../../../utils/helpers";
import {
  useEnteredDraw,
  useHasParticipant,
  useIsAppLoading,
  useIsReady,
  useUserDetails,
  useUserTickets,
  useWinnerOverlay,
} from "../../../utils/hooks/selector";
import { Button, VerticalDivider } from "../../../utils/widgets";
import AffiliaterSkeleton from "../../../utils/widgets/skeletons/affiliater-skeleton";
import MyTicketsModal from "../../modals/my-tickets-modal";
import QrModal from "../../modals/qr-modal";

const Affiliater = () => {
  //state values
  const { isConnected: isWalletConnected } = useAccount();
  const [showModal, setShowModal] = useState(false);
  const [viewAllTickets, setViewAllTickets] = useState(false);
  const userDetails = useSelector(useUserDetails);
  const enteredDraw = useSelector(useEnteredDraw);
  const winnerOverlay = useSelector(useWinnerOverlay);
  const isReady = useSelector(useIsReady);
  const isAppLoading = useSelector(useIsAppLoading);
  const hasParticipant = useSelector(useHasParticipant);
  const ticketNumbers = useSelector(useUserTickets).ticketNumbers;

  //constants
  const isSm = useGetDimensions();
  const hasSkeleton = isWalletConnected && (isAppLoading || !isReady || (!hasParticipant && !userDetails.id));

  //On WinnerTimerModal or isAppLoading Open
  useEffect(() => {
    if (winnerOverlay || isAppLoading) {
      setShowModal(false);
      setViewAllTickets(false);
    }
  }, [winnerOverlay, isAppLoading]);

  return (
    <>
      {hasSkeleton ? (
        <AffiliaterSkeleton />
      ) : (
        <S.CustomCardWrapper gradient={!userDetails.isFreeUser}>
          {!enteredDraw && (
            <S.AffiliaterBlurContainer data-tut="tour-ticket">
              <S.TicketTextContainer>
                <S.TicketText>Your Lucky Pass will be here!</S.TicketText>
              </S.TicketTextContainer>
            </S.AffiliaterBlurContainer>
          )}
          <S.AffiliaterMainContainer direction={"column"} data-tut="tour-your-ticket">
            <S.AffiliaterTopSection>
              <S.TicketIdContainer direction={"row"}>
                <S.AffliaterTopImageContainer>
                  <S.AffliaterTopImage type={ticketNumbers.length > 1} src={YearnCoin} alt="yearn-coin" />
                </S.AffliaterTopImageContainer>
                <S.AffiliaterTopTitleContainer>
                  <S.AffiliaterTopTitle sx={{ fontSize: ticketNumbers.length > 1 ? "0.75rem" : "1.125rem" }}>
                    {userDetails.isFreeUser ? "AFFILIATE" : "LUCKY PASS"}
                  </S.AffiliaterTopTitle>
                  <S.AffiliaterTopTitle
                    customStyles={{
                      fontSize:
                        ticketNumbers.length > 1 && !isSm
                          ? "1.33rem"
                          : ticketNumbers.length > 1 && isSm
                          ? "1.25rem"
                          : ticketNumbers.length === 1
                          ? "2.375rem"
                          : "1.875rem",
                    }}
                  >
                    {userDetails.isFreeUser
                      ? "FREE USER"
                      : ticketNumbers.length > 1
                      ? `YT ${getWholeNum(ticketNumbers[0])} - YT ${getWholeNum(ticketNumbers[ticketNumbers.length - 1])}`
                      : `YT ${getWholeNum(ticketNumbers[0])}`}
                  </S.AffiliaterTopTitle>
                </S.AffiliaterTopTitleContainer>
              </S.TicketIdContainer>
              {ticketNumbers.length > 1 && !userDetails.isFreeUser && (
                <S.NoteContainer direction={"row"}>
                  <S.IBtnContainer>
                    <Typography>i</Typography>
                  </S.IBtnContainer>
                  <S.NoteText variant={"caption"}>
                    You own {ticketNumbers.length} Lucky Passes. To check status of individual Lucky Passes click on view all button.
                  </S.NoteText>
                </S.NoteContainer>
              )}
            </S.AffiliaterTopSection>
            <S.AffiliaterBottomSection direction={"row"}>
              <S.AffiliaterBottomDrawContainer>
                <S.AffiliaterDrawAmount variant={isSm ? "h4" : "h3"}>{userDetails.participatedOneKDrawNumber}</S.AffiliaterDrawAmount>
                <S.AffiliaterDrawTitle variant="caption">Draw</S.AffiliaterDrawTitle>
              </S.AffiliaterBottomDrawContainer>
              <VerticalDivider customstyle={{ height: "36px", marginRight: "1rem", borderColor: "rgba(255, 255, 255, 0.1)" }} />
              <S.AffiliaterBottomUserContainer>
                <S.AffilaterUserDetailsContainer>
                  <S.AffiliaterDrawSubtitle variant="caption">User ID</S.AffiliaterDrawSubtitle>
                  <S.AffiliaterUserTitle variant="h6">#{userDetails.id}</S.AffiliaterUserTitle>
                </S.AffilaterUserDetailsContainer>
                <S.AffiliaterQRContainer
                  onClick={() => {
                    setShowModal(true);
                  }}
                >
                  <QRCodeSVG
                    value={`${window.location.origin}/${REFERRAL_PATH_NAME}/${userDetails.id}/${userDetails.address}`}
                    level={"H"}
                    size={50}
                  />
                  <S.TapToScan>TAP TO SCAN</S.TapToScan>
                </S.AffiliaterQRContainer>
                {/* {!userDetails.isFreeUser && <S.WinnerStamp src={oneKStamp} alt="draw-winner-stamp" />} */}
                {ticketNumbers.length > 1 && !userDetails.isFreeUser && (
                  <Button
                    buttonType="primary"
                    customStyle={{
                      minWidth: "76px",
                      maxWidth: "276px",
                      width: "76px",
                      height: "24px",
                      borderRadius: "6px",
                      fontSize: "12px",
                      fontWeight: "600",
                      lineHeight: "2",
                    }}
                    onClick={() => {
                      setViewAllTickets(!viewAllTickets);
                    }}
                  >
                    View All
                  </Button>
                )}
              </S.AffiliaterBottomUserContainer>
            </S.AffiliaterBottomSection>
            <S.HorizontalDivider gradient={userDetails.isFreeUser} />
          </S.AffiliaterMainContainer>
        </S.CustomCardWrapper>
      )}
      {showModal && (
        <QrModal
          open={showModal}
          url={`${window.location.origin}/${REFERRAL_PATH_NAME}/${userDetails.id}/${userDetails.address}`}
          handleClose={() => setShowModal(false)}
        />
      )}
      {viewAllTickets && <MyTicketsModal open={viewAllTickets} handleClose={() => setViewAllTickets(false)} />}
    </>
  );
};

export default Affiliater;
