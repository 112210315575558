import { styled, Box, Skeleton } from "@mui/material";

namespace S {
  export const SkeletonContainer = styled(Box)(({ theme }) => ({
    minHeight: "12.5rem",
    maxHeight: "12.5rem",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      maxHeight: "unset",
    },
  }));

  export const SkeletonTopContainer = styled(Box)(() => ({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  }));

  export const SkeletonBottomContainer = styled(Box)(() => ({
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
  }));

  export const SkeletonSubContainer = styled(Box)(({ theme }) => ({
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "1.875rem",
    },
  }));

  export const CustomCircularSkeletonContainer = styled("span")(({ theme }) => ({
    position: "absolute",
    width: "8.125rem",
    height: "5.625rem",
    bottom: "2.5rem",
    overflow: "hidden",
    [theme.breakpoints.down("sm")]: {
      width: "5rem",
      height: "4rem",
      bottom: "2.1875rem",
    },
  }));

  export const CustomCircleSkeleton = styled(Skeleton)(({ theme }) => ({
    width: "8.125rem",
    height: "8.125rem",
    borderRadius: "50%",
    [theme.breakpoints.down("sm")]: {
      width: "5rem",
      height: "5rem",
    },
  }));

  export const RoundedBorderSkeleton = styled(Skeleton)(({ theme }) => ({
    position: "absolute",
    bottom: "0",
    width: "9.375rem",
    height: "2.5rem",
    [theme.breakpoints.down("sm")]: {
      width: "5.625rem",
      height: "2.1875rem",
    },
  }));
}

export default S;
