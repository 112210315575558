import { FC, useEffect } from "react";
import { useSelector } from "react-redux";
import { Box } from "@mui/material";
import S from "./mainboard.styled";
import { setHundredKWinner } from "../../store/slices/user";
import { getHundredKDrawWinnerDetails } from "../../store/slices/user.actions";
import { useGetDimensions } from "../../utils/helpers";
import { useAppStatus, useDraw100kNumber, useEnteredDraw, useHundredKWinner, useTicketsSold } from "../../utils/hooks/selector";
import { useAppDispatch } from "../../utils/hooks/state";
import { AppStatus } from "../../utils/types";
import { CustomCardWrapper } from "../../utils/widgets";
import Affiliater from "../left-side-panel/affiliater";
import GradientPoolTicket from "../left-side-panel/gradient-pool-ticket";
import MyTeamAffiliateEarnings from "../left-side-panel/my-team-affiliate-earnings";
import SkillGaming from "../left-side-panel/skill-gaming";
import RecentWinnerCarousel from "../right-side-panel/RecentWinnerCarousel";
import Challenge from "../right-side-panel/challenge";
import Distributor from "../right-side-panel/earning-card/Distributor";
import GameCard from "../right-side-panel/game-card";
import TopReferralsCard from "../right-side-panel/top-referrals-card";
import MyProgress from "./MyProgress";
import TicketSold from "./TicketSold";
import WinningNumber from "./WinningNumber";
import BuyNowWithInviter from "./buynow-with-inviter";
import PlayGame from "./play-game";
import Prediction from "./prediction";
import PredictionWinners from "./prediction/PredictionWinners";
import PrivateSale from "./private-sale";
import StakeYearn from "./stake-yearn";
import ThankyouForParticipating from "./thankyou-for-participation";

const Mainboard: FC = () => {
  //constructors
  const dispatch = useAppDispatch();

  //state values
  const enteredDraw = useSelector(useEnteredDraw);
  const ticketsSold = useSelector(useTicketsSold);
  const hundredKWinner = useSelector(useHundredKWinner);
  const appStatus = useSelector(useAppStatus);
  const draw100kNumber = useSelector(useDraw100kNumber);

  //constants
  const isSm = useGetDimensions();

  useEffect(() => {
    if (ticketsSold !== 0 && ticketsSold % draw100kNumber === 0 && appStatus === AppStatus.drawHundredKWinner) {
      getHundredKDrawWinnerDetails().then((winner) => {
        if (winner.ticketNumber) dispatch(setHundredKWinner(winner));
      });
    }
  }, [enteredDraw, ticketsSold, appStatus, draw100kNumber]);

  return (
    /* MainboardWrapper is created using mui Stack compopnent, So you can apply Stack components deafult props directly */
    <S.MainboardWrapper>
      {hundredKWinner?.ticketNumber || appStatus === AppStatus.drawHundredKWinner ? (
        <>
          <ThankyouForParticipating />
          <PredictionWinners />
          {isSm && <Affiliater />}
          <WinningNumber />
        </>
      ) : (
        <>
          {/* {enteredDraw ? <MyProgress /> : <BuyNowWithInviter />}
          <TicketSold /> */}

          {enteredDraw ? <MyProgress /> : <PrivateSale />}
          {enteredDraw && <StakeYearn />}
          {isSm && <GameCard />}

          {isSm && <Affiliater />}
          {/* <Prediction /> */}
          {!isSm && <PlayGame />}
        </>
      )}
      {isSm && (
        <>
          {/* <RecentWinnerCarousel /> */}
          {/* <Distributor /> */}
          {/* <TopReferralsCard /> */}
          {/* <Affiliater /> */}
          {/* <GradientPoolTicket /> */}
          {/* <MyTeamAffiliateEarnings /> */}
          <RecentWinnerCarousel />
          <SkillGaming />
          <PlayGame />
          <Challenge />
        </>
      )}
    </S.MainboardWrapper>
  );
};

export default Mainboard;
