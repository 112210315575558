import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Stack } from "@mui/material";
import { useAccount, useWalletClient } from "wagmi";
import S from "./thankyou-for-participating.styled";
import BonusBox from "../../../assets/images/BonusBox.svg";
import { lottoContract } from "../../../services/ethereum/contract/web3Contract";
import { addYTGToWallet } from "../../../services/helpers";
import { YEARN_TOKEN } from "../../../utils/constants";
import { getDurationInWords, getRoundAmount } from "../../../utils/helpers";
import { useAddingYTG, useClaimableToken, useIsReady, usePredictGameToken, useUserDetails } from "../../../utils/hooks/selector";
import { Button } from "../../../utils/widgets";
import GradientContainer from "../../../utils/widgets/GradientContainer";

const ThankyouForParticipating = () => {
  //state values
  const isReady = useSelector(useIsReady);
  const addingYTG = useSelector(useAddingYTG);
  const userDetails = useSelector(useUserDetails);
  const claimableToken = useSelector(useClaimableToken);
  const predictGameToken = useSelector(usePredictGameToken);

  const [releaseInfo, setReleaseInfo] = useState({ percentege: 0, duration: "" });
  const [isApproved, setIsApproved] = useState(false);
  const { data: signer } = useWalletClient();
  //constants
  const totalTokens = getRoundAmount(
    `${BigInt(claimableToken.claimed) + BigInt(claimableToken.unclaimed) + BigInt(predictGameToken.claimed) + BigInt(predictGameToken.unclaimed)}`,
  );

  //functions
  const getReleaseInfo = async () => {
    const releasePercentage = await lottoContract.getReleasePercentage();
    const releaseDuration = await lottoContract.getReleaseDuration();
    const durationInWords = getDurationInWords(releaseDuration * 1000);

    setReleaseInfo({ percentege: releasePercentage, duration: durationInWords });
  };

  useEffect(() => {
    if (!isReady) return;
    getReleaseInfo();
  }, [isReady]);

  //To enable claim
  useEffect(() => {
    if (isReady && userDetails.address) {
      lottoContract.getIsWithdrawalEnabled().then((res: boolean) => setIsApproved(res));
    }
  }, [isReady, userDetails.address]);

  return (
    <>
      <GradientContainer customStyle={{ width: "1500px", height: "1500px", top: "-438px", left: "-442px" }}>
        <S.ThankyouForParticipatingContainer>
          <S.HeaderContainer>
            <S.HeaderText>THE PRE-SALE HAS ENDED.</S.HeaderText>
            <S.ThankYouContainer>
              <S.GradientHeaderText>
                THANK YOU FOR <br />
                YOUR PARTICIPATION
              </S.GradientHeaderText>
              <S.BonusBox>
                <S.BonusImg src={BonusBox} alt="BonusBox" />
              </S.BonusBox>
            </S.ThankYouContainer>
          </S.HeaderContainer>
          <S.BodyContainer>
            <Stack alignItems={"center"} gap={"0.4rem"}>
              <S.BodyTitle>
                You have won {totalTokens} {YEARN_TOKEN} tokens.
              </S.BodyTitle>
              <S.ApproveTitle isApproved={isApproved}>{isApproved ? "Admin has approved!" : "Waiting for admin approval!"}</S.ApproveTitle>
            </Stack>
            {isApproved && (
              <S.ClaimDetailsContainer>
                <S.BodyTitle>
                  Claim Limit: <span>{releaseInfo.percentege}%</span>
                </S.BodyTitle>
                <S.BodyTitle>
                  Cliff Duration: <span>{releaseInfo.duration}</span>
                </S.BodyTitle>
              </S.ClaimDetailsContainer>
            )}
            <Button buttonType="primary" onClick={() => addYTGToWallet(signer)} disabled={addingYTG} loading={addingYTG}>
              {addingYTG ? `Adding ${YEARN_TOKEN} to wallet...` : `+ Add ${YEARN_TOKEN} to wallet`}
            </Button>
          </S.BodyContainer>
        </S.ThankyouForParticipatingContainer>
      </GradientContainer>
    </>
  );
};

export default ThankyouForParticipating;
