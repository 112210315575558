import { CSSProperties, FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { InputAdornment, Tooltip } from "@mui/material";
import S from "./input-with-copy-and-share.styled";
import { PRIMARY } from "../../utils/constants";
import { useIsAppLoading, useWinnerOverlay } from "../../utils/hooks/selector";
import { Button } from "../../utils/widgets";
import ShareLinkPopover from "../popovers/ShareLinkPopover";

type InputWithCopyAndShareProps = {
  Url: string;
  disabled?: boolean;
  InputContainerStyle?: CSSProperties;
  InputStyle?: CSSProperties;
  IconStyle?: CSSProperties;
};

const InputWithCopyAndShare: FC<InputWithCopyAndShareProps> = ({ Url, disabled = false, InputContainerStyle, InputStyle, IconStyle }) => {
  //state values
  const winnerOverlay = useSelector(useWinnerOverlay);
  const isAppLoading = useSelector(useIsAppLoading);

  const [toolTipTime, setToolTipTime] = useState(false);
  const [shareLinkPopover, setShareLinkPopover] = useState<HTMLButtonElement | null>(null);

  //functions
  const handleCopy = () => {
    if (Url.length) {
      setToolTipTime(true);
      navigator.clipboard.writeText(Url);
      setTimeout(() => {
        setToolTipTime(false);
      }, 3000);
    }
  };

  const handleShareLinkPopover = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setShareLinkPopover(event.currentTarget);
  };

  //On WinnerTimerModal or isAppLoading Open
  useEffect(() => {
    if (winnerOverlay || isAppLoading) setShareLinkPopover(null);
  }, [winnerOverlay, isAppLoading]);

  return (
    <S.InputContainer
      sx={{
        cursor: disabled ? "not-allowed" : "pointer",
        ...InputContainerStyle,
      }}
    >
      <Tooltip
        sx={{
          cursor: disabled ? "not-allowed !important" : "pointer",
        }}
        disableHoverListener={disabled}
        title={toolTipTime ? "copied to clipboard" : "copy to clipboard"}
        // arrow
      >
        <S.InputWithIcon
          readOnly
          value={disabled ? "Not Available" : Url}
          disabled={disabled}
          onClick={handleCopy}
          disableUnderline
          sx={{
            cursor: disabled ? "not-allowed !important" : "pointer",
            ".css-ume8vi-MuiInputBase-input-MuiInput-input": {
              cursor: disabled ? "not-allowed !important" : "pointer",
            },
            ...InputStyle,
          }}
          endAdornment={
            <InputAdornment position="end">
              <Button
                buttonType={PRIMARY}
                disabled={disabled}
                onClick={handleCopy}
                customStyle={{
                  height: "2.75rem",
                  minWidth: "2.9375rem",
                  borderRadius: "0 0.5rem 0.5rem 0",
                  ...IconStyle,
                }}
              >
                <S.CustomContentCopyTwoToneIcon />
              </Button>
            </InputAdornment>
          }
        />
      </Tooltip>
      <Button
        disabled={disabled}
        buttonType={PRIMARY}
        onClick={handleShareLinkPopover}
        customStyle={{ height: "2.75rem", minWidth: "2.9375rem", ...IconStyle }}
      >
        <S.CustomReplyIcon />
      </Button>
      {shareLinkPopover && (
        <ShareLinkPopover
          shareUrl={Url}
          isOpen={shareLinkPopover}
          id={Boolean(shareLinkPopover) ? "share-link" : undefined}
          onClose={() => setShareLinkPopover(null)}
          customStyle={{ top: 10 }}
        />
      )}
    </S.InputContainer>
  );
};

export default InputWithCopyAndShare;
