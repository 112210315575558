import { FC, useState } from "react";
import { CustomModal, ModalHeader, ModalBody, ModalFooter } from "../../../utils/widgets/custom-modal";
import MantraFooter from "./mantra-footer";
import MantraHeader from "./mantra-header";
import HundredKDraw from "./mantra-pages/hundredK-draw copy";
import Levels from "./mantra-pages/levels";
import OneKDraw from "./mantra-pages/onek-draw";
import ReferAndEarn from "./mantra-pages/refer-and-earn";
import TenKDraw from "./mantra-pages/tenk-draw";
import YearnProtocol from "./mantra-pages/yearn-protocol";

type MantraModalType = {
  open: boolean;
  handleClose: () => void;
};

const MantraModal: FC<MantraModalType> = ({ open, handleClose }) => {
  //state values
  const [mantraPage, setMantraPage] = useState(1);

  return (
    <CustomModal open={open} onClose={() => handleClose()}>
      <ModalHeader handleClose={() => handleClose()}>
        <MantraHeader />
      </ModalHeader>
      <ModalBody>
        {mantraPage === 1 && <ReferAndEarn />}
        {mantraPage === 2 && <Levels />}
        {mantraPage === 3 && <OneKDraw />}
        {mantraPage === 4 && <TenKDraw />}
        {mantraPage === 5 && <HundredKDraw />}
        {mantraPage === 6 && <YearnProtocol />}
      </ModalBody>
      <ModalFooter>
        <MantraFooter mantraPage={mantraPage} setMantraPage={setMantraPage} />
      </ModalFooter>
    </CustomModal>
  );
};
export default MantraModal;
