import { Box, Stack, styled, Typography } from "@mui/material";

namespace S {
  export const Container = styled(Stack)(({ theme }) => ({
    boxSizing: "border-box",
    height: "25.6rem",
    maxWidth: "38.75rem",
    overflowY: "scroll",
    gap: theme.spacing(4),
    padding: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
      minWidth: "calc(100vw - 1.875rem)",
    },
  }));

  export const ContentHeaderText = styled(Typography)(() => ({
    fontSize: "1rem",
    fontWeight: "600",
  }));

  export const ImageText1 = styled(Typography)(({ theme }) => ({
    fontSize: "0.875rem",
    fontWeight: "600",
    [theme.breakpoints.down("md")]: {
      fontSize: "0.75rem",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.5rem",
    },
  }));

  export const ImageText2 = styled(Typography)(({ theme }) => ({
    fontSize: "0.75rem",
    fontWeight: "600",
    [theme.breakpoints.down("md")]: {
      fontSize: "0.625rem",
    },
  }));

  export const LevelsContainer = styled(Stack)(({ theme }) => ({
    boxSizing: "border-box",
    flexDirection: "row",
    borderRadius: "0.625rem",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "100%",
    minHeight: "5.75rem",
    [theme.breakpoints.down("md")]: {
      overflowX: "auto",
      overflowY: "hidden",
      minHeight: "6.5rem",
      backgroundColor: theme.palette.custom.bg.level,
      padding: "0.5rem 0.5rem 0.5rem 0.5rem",
      border: `0.0625rem solid ${theme.palette.custom.strokes.primary}`,
    },
    [theme.breakpoints.down("sm")]: {
      justifyContent: "space-between",
    },
  }));

  export const SeperateLevel = styled(Box)(({ theme }) => ({
    minHeight: "100%",
    minWidth: "3.75rem",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0.125rem 0",
    [theme.breakpoints.down("sm")]: {
      minWidth: "auto",
      img: {
        width: "1rem",
      },
    },
  }));

  export const ArrowImage = styled("img")(({ theme }) => ({
    minWidth: "2.5rem",
    height: "0.5625rem",
    [theme.breakpoints.down("sm")]: {
      width: "12px",
      height: "auto",
      minWidth: "auto",
    },
  }));
}

export default S;
