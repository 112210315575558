import { FC } from "react";

const GradientSVG: FC = () => {
  //constants
  const gradientTransform = `rotate(90)`;

  return (
    <svg style={{ height: 0, width: 0 }}>
      <defs>
        <linearGradient id="gradient1" gradientTransform={gradientTransform}>
          <stop offset="0%" stopColor="#19FCB8" />
          <stop offset="50%" stopColor="#5C94FF" />
          <stop offset="100%" stopColor="#1967FC" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default GradientSVG;
