import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { MatchSliceType } from "../../utils/types/state";

const initialState: MatchSliceType.InitialState = {
  minAmount: "0",
  maxAmount: "0",
  isPredictingPool: false,
  isFetchingPoolTotalAmount: false,
  poolATotal: "0",
  poolBTotal: "0",
  isFetchingPoolEndTime: false,
  poolEndTime: "",
  isWithDrawingFund: false,
  isRedeemRewardLoading: false,
};

const matchSlice = createSlice({
  name: "match",
  initialState,
  reducers: {
    setMinMaxAmount: (state, action: PayloadAction<{ minAmount: string; maxAmount: string }>) => {
      const { minAmount, maxAmount } = action.payload;
      state.minAmount = minAmount;
      state.maxAmount = maxAmount;
    },
    setIsPredictingPool: (state, action: PayloadAction<boolean | undefined>) => {
      state.isPredictingPool = action.payload ?? initialState.isPredictingPool;
    },
    setIsFetchingPoolTotalAmount: (state, action: PayloadAction<boolean | undefined>) => {
      state.isFetchingPoolTotalAmount = action.payload ?? initialState.isFetchingPoolTotalAmount;
    },
    setPoolTotalAmount: (state, action: PayloadAction<{ poolATotal: string; poolBTotal: string }>) => {
      const { poolATotal, poolBTotal } = action.payload;
      state.poolATotal = poolATotal;
      state.poolBTotal = poolBTotal;
    },
    setIsFetchingPoolEndTime: (state, action: PayloadAction<boolean | undefined>) => {
      state.isFetchingPoolEndTime = action.payload ?? initialState.isFetchingPoolEndTime;
    },
    setPoolEndTime: (state, action: PayloadAction<string>) => {
      state.poolEndTime = action.payload ?? initialState.poolEndTime;
      state.isFetchingPoolEndTime = false;
    },
    setIsWithdrawingFund: (state, action: PayloadAction<boolean | undefined>) => {
      state.isWithDrawingFund = action.payload ?? initialState.isWithDrawingFund;
    },
    setIsRedeemRewardLoading: (state, action: PayloadAction<boolean | undefined>) => {
      state.isRedeemRewardLoading = action.payload ?? initialState.isRedeemRewardLoading;
    },
  },
});

export const {
  setMinMaxAmount,
  setIsPredictingPool,
  setIsFetchingPoolTotalAmount,
  setPoolTotalAmount,
  setIsFetchingPoolEndTime,
  setPoolEndTime,
  setIsWithdrawingFund,
  setIsRedeemRewardLoading,
} = matchSlice.actions;

export const matchReducer = matchSlice.reducer;
