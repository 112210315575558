import { styled, Box } from "@mui/material";

namespace S {
  export const SkeletonContainer = styled(Box)(({ theme }) => ({
    display: "flex",
    gap: "2rem",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      maxHeight: "unset",
    },
    [theme.breakpoints.down("sm")]: {
      gap: "1rem",
    },
  }));

  export const SkeletonSubContainer = styled(Box)(() => ({
    display: "flex",
    alignItems: "center",
    gap: "0.5rem",
  }));
}

export default S;
