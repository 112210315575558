import { Skeleton } from "@mui/material";
import S from "./private-sale-skeleton.styled";

const PrivateSaleSkeleton = () => {
  return (
    <>
      <S.SkeletonContainer>
        <S.Header>
          <S.HeaderLeft>
            <Skeleton variant="text" sx={{ fontSize: "3rem", width: "70%" }} />
            <Skeleton variant="text" sx={{ fontSize: "3rem", width: "30%" }} />
          </S.HeaderLeft>
          <Skeleton variant="text" sx={{ fontSize: "1.4rem", width: "30%" }} />
        </S.Header>
        <S.MessageContainer>
          <Skeleton variant="rectangular" sx={{ height: "100%", width: "100%" }} />
        </S.MessageContainer>
        <S.RoundInfoBox>
          <S.RoundInfo>
            <Skeleton variant="rounded" sx={{ width: "60%" }} />
            <Skeleton variant="rounded" sx={{ width: "30%", height: "30px" }} />
          </S.RoundInfo>
          <S.RoundInfo>
            <Skeleton variant="rounded" sx={{ width: "60%" }} />
            <Skeleton variant="rounded" sx={{ width: "30%", height: "30px" }} />
          </S.RoundInfo>
          <S.RoundInfo>
            <Skeleton variant="rounded" sx={{ width: "60%" }} />
            <Skeleton variant="rounded" sx={{ width: "30%", height: "30px" }} />
          </S.RoundInfo>
        </S.RoundInfoBox>
        <S.SaleOuterContainer>
          <S.SaleContainer>
            <S.SaleLeftContainer>
              <Skeleton variant="rounded" sx={{ width: "110px", height: "18px" }} />
              <Skeleton variant="rounded" sx={{ width: "125px", height: "15px" }} />
              <Skeleton variant="rounded" sx={{ width: "90%", height: "25px" }} />
              <S.TextBox>
                <Skeleton variant="rounded" sx={{ width: "50%", height: "15px" }} />
                <Skeleton variant="rounded" sx={{ width: "30%", height: "15px" }} />
              </S.TextBox>
              <S.TextBox>
                <Skeleton variant="rounded" sx={{ width: "40%", height: "18px" }} />
                <Skeleton variant="rounded" sx={{ width: "50%", height: "18px" }} />
              </S.TextBox>
              <S.TextBox>
                <Skeleton variant="rounded" sx={{ width: "20%", height: "10px" }} />
                <Skeleton variant="rounded" sx={{ width: "30%", height: "25px" }} />
              </S.TextBox>
              <Skeleton variant="rounded" sx={{ width: "80px", height: "12px" }} />
              <Skeleton variant="rounded" sx={{ width: "100%", height: "18px", borderRadius: "23px" }} />
            </S.SaleLeftContainer>
            <S.SaleRightContainer>
              <Skeleton variant="rounded" sx={{ width: "110px", height: "18px" }} />
              <Skeleton variant="rounded" sx={{ width: "125px", height: "15px" }} />
              <Skeleton variant="rounded" sx={{ width: "90%", height: "30px" }} />
              <S.TextBox>
                <Skeleton variant="rounded" sx={{ width: "125px", height: "15px" }} />
                <Skeleton variant="rounded" sx={{ width: "50px", height: "15px" }} />
              </S.TextBox>
              <S.TextBox>
                <Skeleton variant="rounded" sx={{ width: "150px", height: "15px" }} />
                <Skeleton variant="rounded" sx={{ width: "30px", height: "15px" }} />
              </S.TextBox>
              <S.TextBox>
                <Skeleton variant="rounded" sx={{ width: "90px", height: "15px" }} />
                <Skeleton variant="rounded" sx={{ width: "80px", height: "15px" }} />
              </S.TextBox>
              <S.TextBox>
                <Skeleton variant="rounded" sx={{ width: "150px", height: "15px" }} />
                <Skeleton variant="rounded" sx={{ width: "60px", height: "15px" }} />
              </S.TextBox>
              <S.TextBox>
                <Skeleton variant="rounded" sx={{ width: "125px", height: "15px" }} />
                <Skeleton variant="rounded" sx={{ width: "40px", height: "15px" }} />
              </S.TextBox>
              <Skeleton variant="rounded" sx={{ width: "100%", height: "30px" }} />
            </S.SaleRightContainer>
          </S.SaleContainer>
        </S.SaleOuterContainer>
      </S.SkeletonContainer>
    </>
  );
};

export default PrivateSaleSkeleton;
