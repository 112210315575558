import { styled, Box } from "@mui/material";

namespace S {
  export const SkeletonContainer = styled(Box)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    minHeight: "10rem",
    maxHeight: "10rem",
    padding: ` ${theme.spacing(8)} `,
    backgroundColor: theme.palette.custom.secondary.secondary_3,
    borderRadius: "0.625rem",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      maxHeight: "unset",
    },
  }));

  export const SkeletonTopContainer = styled(Box)(({ theme }) => ({
    display: "grid",
    gridTemplateColumns: "1fr 3fr",
    gap: "1rem",
  }));

  export const SkeletonTopSubContainer = styled(Box)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    width: "100%",
  }));

  export const SkeletonBottomContainer = styled(Box)(({ theme }) => ({
    display: "grid",
    gridTemplateColumns: "1fr 3fr",
    gap: "1rem",
    paddingTop: "2rem",
  }));

  export const SkeletonSubContainer = styled(Box)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
  }));

  export const SkeletonContentContainer = styled(Box)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: "100%",
  }));

  export const SkeletonSubContainerRight = styled(Box)(({ theme }) => ({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  }));
}

export default S;
