import { AppDispatch } from "..";
import { lottoContract } from "../../services/ethereum/contract/web3Contract";
import {
  getDrawWinnersAddress,
  getUsersByAddress,
  getDraw10KWinnersAddress,
  getRefferrersByAddress,
  getUsersById,
  getDistributedAmount,
  getDrawWinnerReferralEarnings,
  getReferralPayouts,
  get100KWinnersById,
  getDraw100KWinnersAddress,
  getMultipleDrawWinners,
  getClaimedToken,
  get100KFreePredictionRedeemed,
  get1KPremiumPredictionRedeemed,
  get1KFreePredictionRedeemed,
  getDistributedAmountTest,
  getDepositTimeStampByAddress,
  getPredictionApproval,
} from "../../services/ethereum/subgraph/query";
import { getChecksumAddress, getClaimButtonAvailability, getOneKSlot, getRoundAmount, getTenKSlot } from "../../utils/helpers";
import { graphQlClient, predictionClient } from "../../utils/providers";
import { ApiTypes } from "../../utils/types/query";
import { UserSlicetypes } from "../../utils/types/state";
import Toast from "../../utils/widgets/toast";
import { get100KPremiumPredictionRedeemed } from "./../../services/ethereum/subgraph/query";
import { AvatarList, HUNDRED_K_DRAW, NONE, NULL_ADDRESS, ONE_K_DRAW, TEN_K_DRAW, BACKEND_URL } from "./../../utils/constants/index";
import { Predict100KType, PredictType } from "./../../utils/types/index";
import { RootState } from "./../index";
import {
  set100KPredictionWinners,
  setAnnouncedWinner,
  setDistributed,
  setDistrubutedRequesting,
  setDrawWinners,
  setEarnings,
  setInvitedBy,
  setIsFetching100KPredictionWinners,
  setIsFetching1KPredictionWinners,
  setIsRequestingMyPredictions,
  setMyPredictions,
  setNextAvailableAt,
  set1KPredictionWinners,
  setReferrersByLevel,
  setRequestingHundredKReferrers,
  setRequestingOneKReferrers,
  setTopReferrersIn100K,
  setTopReferrersInOneK,
  setUserTickets,
  updateRecentWinners,
  setIsRedeeming,
  setIsFetchingEarnings,
  setClaimableAmount,
  setClaimableToken,
  setPredictionAdminApproval,
  setIsWhitelisted,
  setWhitelistLoading,
} from "./user";

const CURRENT_CHAIN_ID: number = Number(process.env.REACT_APP_CURRENT_CHAINID);

export const getOneKDrawWinners = () => (dispatch: AppDispatch) => {
  try {
    graphQlClient
      .request(getDrawWinnersAddress)
      .then((res: any) => {
        let drawWinnersAddress = res.drawWinners;
        const drawWinners = drawWinnersAddress.map((r: ApiTypes.DrawWinner) => {
          return {
            id: r.id,
            userId: r.userId,
            ticketId: r.ticketNumber,
            address: r.addr,
            amount: +getRoundAmount(r.amount),
            blockTimestamp: parseInt(r.blockTimestamp) * 1000,
            drawNumber: r.drawNumber,
            dp: AvatarList[Math.floor(Math.random() * Math.floor(AvatarList.length))],
            transactionHash: r.transactionHash,
          };
        });
        dispatch(setDrawWinners({ draw: ONE_K_DRAW, drawWinners }));
      })
      .catch(() => {
        console.log("Oops!, No oneK draw winners found.");
      });
  } catch (e) {
    console.log("Fetching OneKDrawWinners failed! try after sometime.", e);
  }
};

export const getOneKDrawWinner = async (drawNumber: string) => {
  try {
    const winner: any = await graphQlClient.request(getDrawWinnersAddress, {
      where: {
        drawNumber,
      },
    });
    return winner.drawWinners;
  } catch (e) {
    console.log("Fetching OneKDrawWinners by drawNumber failed! try after sometime.", e);
    return [];
  }
};

export const getTenKDrawWinners = () => (dispatch: AppDispatch) => {
  try {
    graphQlClient
      .request(getDraw10KWinnersAddress)
      .then((res: any) => {
        let drawWinnersAddress = res.draw10KWinners;
        const drawWinners = drawWinnersAddress.map((r: ApiTypes.DrawTenKWinner) => {
          return {
            id: r.id,
            userId: r.userId,
            ticketId: r.ticketNumber,
            address: r.addr,
            amount: +getRoundAmount(r.amount),
            blockTimestamp: parseInt(r.blockTimestamp) * 1000,
            drawNumber: r.draw10KNumber,
            dp: AvatarList[Math.floor(Math.random() * Math.floor(AvatarList.length))],
            transactionHash: r.transactionHash,
          };
        });
        dispatch(setDrawWinners({ draw: TEN_K_DRAW, drawWinners }));
      })
      .catch(() => {
        console.log("Oops!, No tenK draw winners found.");
      });
  } catch (e) {
    console.log("Fetching TenKDrawWinners failed! try after sometime.");
  }
};

export const getTenKDrawWinner = async (drawNumber: string) => {
  try {
    const winner: any = await graphQlClient.request(getDraw10KWinnersAddress, {
      where: { draw10KNumber: drawNumber },
    });
    return winner.draw10KWinners;
  } catch (e) {
    console.log("Fetching OneKDrawWinners by drawNumber failed! try after sometime.", e);
    return [];
  }
};

export const getHundredKDrawWinner = async () => {
  try {
    const winner: any = await graphQlClient.request(getDraw100KWinnersAddress);
    return winner.draw100KWinners;
  } catch (e) {
    console.log("Fetching HundredKDrawWinners by drawNumber failed! try after sometime.", e);
    return [];
  }
};

export const fetchWinnersByTickets = () => async (dispatch: AppDispatch, getState: () => RootState) => {
  try {
    const ticketNumbers = getState().user.userTickets.ticketNumbers;
    // console.log("ticketNumbers", ticketNumbers);

    const winnersByDraws: {
      drawWinners: ApiTypes.AllWinners[] | [];
      draw10KWinners: ApiTypes.AllWinners[] | [];
      draw100KWinners: ApiTypes.AllWinners[] | [];
    } = await graphQlClient.request(getMultipleDrawWinners, {
      where: {
        ticketNumber_in: ticketNumbers,
      },
    });
    // console.log("winnersByDraws", winnersByDraws);

    const updatedUserTickets = ticketNumbers.map((t) => {
      const winners = [];
      let hasWinner = false;
      if (winnersByDraws.drawWinners.some((w) => w.ticketNumber === t)) {
        winners.push({ ticketNumber: t, draw: ONE_K_DRAW });
        hasWinner = true;
      }
      if (winnersByDraws.draw10KWinners.some((w) => w.ticketNumber === t)) {
        winners.push({ ticketNumber: t, draw: TEN_K_DRAW });
        hasWinner = true;
      }
      if (winnersByDraws.draw100KWinners.some((w) => w.ticketNumber === t)) {
        winners.push({ ticketNumber: t, draw: HUNDRED_K_DRAW });
        hasWinner = true;
      }
      if (!hasWinner) winners.push({ ticketNumber: t, draw: NONE });
      return winners;
    });
    // console.log("updatedUserTickets.flat()", updatedUserTickets.flat());

    dispatch(setUserTickets({ ticketNumbers, includeWinners: updatedUserTickets.flat() }));
  } catch (e) {
    console.log("Fetching winners by ticketnumbers failed! try after sometime.", e);
  }
};

export const getOneKDrawWinnerDetails = async (drawNumber: string) => {
  let drawWinner = await getOneKDrawWinner(drawNumber);
  let winner = {
    userId: "",
    drawNumber: 0,
    ticketNumber: "",
  };
  if (drawWinner.length) {
    winner = {
      userId: drawWinner[0].userId,
      drawNumber: drawWinner[0].drawNumber,
      ticketNumber: drawWinner[0].ticketNumber,
    };
  }
  return winner;
};

export const getTenKDrawWinnerDetails = async (drawNumber: string) => {
  let drawWinner = await getTenKDrawWinner(drawNumber);
  let winner = {
    userId: "",
    drawNumber: 0,
    ticketNumber: "",
  };

  if (drawWinner.length) {
    winner = {
      userId: drawWinner[0].userId,
      drawNumber: drawWinner[0].draw10KNumber,
      ticketNumber: drawWinner[0].ticketNumber,
    };
  }
  return winner;
};

export const getHundredKDrawWinnerDetails = async () => {
  let drawWinner = await getHundredKDrawWinner();
  const dp = AvatarList[Math.floor(Math.random() * Math.floor(AvatarList.length))];
  let winner = {
    id: "",
    userId: "",
    drawNumber: 0,
    ticketNumber: "",
    blockTimestamp: 0,
    transactionHash: "",
    dp,
    address: "",
  };
  if (drawWinner.length) {
    winner = {
      dp,
      id: drawWinner[0].id,
      address: drawWinner[0].addr,
      userId: drawWinner[0].userId,
      drawNumber: 1,
      ticketNumber: drawWinner[0].ticketNumber,
      blockTimestamp: drawWinner[0].blockTimestamp * 1000,
      transactionHash: drawWinner[0].transactionHash,
    };
  }
  return winner;
};

export const getAnnouncedWinners = (oneKDraw: string, tenKDraw: string) => async (dispatch: AppDispatch, getState: () => RootState) => {
  try {
    let announcedWinner = { winnerId: "", value: "", slot: "", userId: "" };
    const draw1kNumber = getState().draw.draw1kNumber;
    const draw10kCount = getState().draw.draw10kNumber;
    const draw100kCount = getState().draw.draw100kNumber;
    const winnersByDraw = await Promise.all([getOneKDrawWinner(oneKDraw), getTenKDrawWinner(tenKDraw), getHundredKDrawWinner()]);
    if (winnersByDraw.length) {
      if (winnersByDraw[0].length) {
        const { drawNumber, userId, ticketNumber } = winnersByDraw[0][0];
        announcedWinner = { winnerId: ticketNumber, value: ONE_K_DRAW, slot: getOneKSlot(drawNumber, draw1kNumber), userId };
        dispatch(
          updateRecentWinners({
            draw: ONE_K_DRAW,
            winner: {
              userId,
              drawNumber,
              ticketNumber,
            },
          }),
        );
      }
      if (winnersByDraw[1].length) {
        const { draw10KNumber, userId, ticketNumber } = winnersByDraw[1][0];
        announcedWinner = { winnerId: ticketNumber, value: ONE_K_DRAW, slot: getTenKSlot(draw10KNumber, draw10kCount), userId };
        dispatch(
          updateRecentWinners({
            draw: TEN_K_DRAW,
            winner: {
              userId,
              drawNumber: draw10KNumber,
              ticketNumber,
            },
          }),
        );
      }
      if (winnersByDraw[2].length) {
        const { userId, ticketNumber } = winnersByDraw[2][0];
        announcedWinner = { winnerId: ticketNumber, value: HUNDRED_K_DRAW, slot: `1 - ${draw100kCount}`, userId };
        dispatch(
          updateRecentWinners({
            draw: HUNDRED_K_DRAW,
            winner: {
              userId,
              drawNumber: 1,
              ticketNumber,
            },
          }),
        );
      }
      dispatch(setAnnouncedWinner(announcedWinner));
    } else dispatch(setAnnouncedWinner());
  } catch (e) {
    console.log("Fetching announced draw winners failed! try after sometime.", e);
  }
};

export const getReferrers = async (address: string, cb?: ApiTypes.CB): Promise<UserSlicetypes.Referrer[]> => {
  try {
    const referrersByAddress: any = await graphQlClient.request(getRefferrersByAddress, {
      where: {
        referrer: address,
      },
    });

    return referrersByAddress.referrers.map((r: ApiTypes.Referrer) => {
      return {
        id: r.id,
        userId: r.userId,
        address: r.addr,
        dp: AvatarList[Math.floor(Math.random() * Math.floor(AvatarList.length))],
      };
    });
  } catch (e) {
    console.log("Fetching referrers failed! try after sometime.");
    return [];
  }
};

export const getLevelReferrers = (level: string, addressList: string[]) => async (dispatch: AppDispatch) => {
  try {
    const accumulatesReferrers: UserSlicetypes.Referrer[] = [];
    for (const address of addressList) {
      const referrers = await getReferrers(address);
      if (referrers.length) accumulatesReferrers.push(...referrers);
    }
    dispatch(setReferrersByLevel({ level, referrers: accumulatesReferrers }));
  } catch (e) {
    console.log(`Fetching level-${level} referrers failed! try after sometime.`);
  }
};

export const getInvitedBy = (referrerId: string | undefined) => async (dispatch: AppDispatch, getState: any) => {
  const referrer = getState().user.invitedBy;
  try {
    let invitedBy = null;

    if (referrerId) {
      let participant = await lottoContract.participantById(referrerId);
      if (participant.addr !== NULL_ADDRESS && participant.id !== "0") {
        invitedBy = {
          referrerId: participant.id,
          address: participant.addr,
        };
      }
    }
    if (referrer?.referrerId) {
      let participant = await lottoContract.participantById(referrer.referrerId);
      if (participant.addr !== NULL_ADDRESS && participant.id !== "0") {
        invitedBy = {
          referrerId: participant.id,
          address: participant.addr,
        };
      }
    }
    dispatch(setInvitedBy(invitedBy));
  } catch (e) {
    console.log(`Fetching invitedby details for the user has failed! try after sometime.`);
  }
};

export const getReferrerIdByAddress = async (address: string) => {
  const referrer: any = await graphQlClient.request(getUsersByAddress, {
    where: {
      addr: address,
    },
  });
  const { userId } = referrer.newDeposits[0];
  return userId;
};

export const getNewUser = async (address: string) => {
  const user: any = await graphQlClient.request(getDepositTimeStampByAddress, {
    where: {
      addr: address,
    },
  });
  return user.newDeposits;
};

export const verifyReferrer = async (referrerId: string, address: string) => {
  try {
    let verified = false;
    const referrer: any = await graphQlClient.request(getUsersByAddress, {
      where: {
        addr: address,
      },
    });
    if (referrer.newDeposits.length) {
      const { addr, userId } = referrer.newDeposits[0];
      if (addr === address && userId === referrerId) verified = true;
    }
    return verified;
  } catch (e) {
    console.log(`Verifying the refferrer has failed! try after sometime.`);

    return false;
  }
};

export const getTopReferrersIn100K = () => async (dispatch: AppDispatch) => {
  dispatch(setRequestingHundredKReferrers(true));
  try {
    const depositsResponse = await fetch(`${BACKEND_URL[CURRENT_CHAIN_ID]}/deposits/all`);
    if (depositsResponse.status >= 200 && depositsResponse.status < 400) {
      const deposits = await depositsResponse.json();

      const sortedTopReferrers = deposits
        .sort((u1: UserSlicetypes.TopReferrers, u2: UserSlicetypes.TopReferrers) => u2.count - u1.count)
        .slice(0, 10)
        .map((referrer: UserSlicetypes.TopReferrers) => ({
          ...referrer,
          dp: AvatarList[Math.floor(Math.random() * Math.floor(AvatarList.length))],
        }));
      dispatch(setTopReferrersIn100K(sortedTopReferrers));
    } else {
      dispatch(setRequestingHundredKReferrers(false));
      // console.log(`${depositsResponse.status}: ${depositsResponse.statusText}`);
    }
  } catch (e) {
    dispatch(setRequestingHundredKReferrers(false));
    console.log(`Fetching top referrers in 100K failed! try after sometime.`);
  }
};

export const getTopReferrersInOneK = (drawNumber: number) => async (dispatch: AppDispatch) => {
  dispatch(setRequestingOneKReferrers({ drawNumber: drawNumber.toString(), fetching: true }));
  try {
    const depositsResponse = await fetch(`${BACKEND_URL[CURRENT_CHAIN_ID]}/deposits/${drawNumber}`);
    if (depositsResponse.status >= 200 && depositsResponse.status < 400) {
      const deposits = await depositsResponse.json();

      const sortedTopReferrers = deposits
        .sort((u1: UserSlicetypes.TopReferrers, u2: UserSlicetypes.TopReferrers) => u2.count - u1.count)
        .slice(0, 10)
        .map((referrer: UserSlicetypes.TopReferrers) => ({
          ...referrer,
          dp: AvatarList[Math.floor(Math.random() * Math.floor(AvatarList.length))],
        }));
      dispatch(setTopReferrersInOneK({ drawNumber: drawNumber.toString(), referrers: sortedTopReferrers }));
    } else {
      dispatch(setRequestingOneKReferrers({ drawNumber: drawNumber.toString(), fetching: false }));
      // console.log(`${depositsResponse.status}: ${depositsResponse.statusText}`);
    }
  } catch (e) {
    dispatch(setRequestingOneKReferrers({ drawNumber: drawNumber.toString(), fetching: false }));
    console.log(`Fetching top referrers in 100K failed! try after sometime.`);
  }
};

export const verifyReferrerById = async (referrerId: string) => {
  try {
    let verified = false;
    const referrer: any = await graphQlClient.request(getUsersById, {
      where: {
        userId: referrerId,
      },
    });
    if (referrer.newDeposits.length) {
      const { userId } = referrer.newDeposits[0];
      if (userId === referrerId) verified = true;
    }
    return verified;
  } catch (e) {
    console.log(`Verifying the refferrer has failed! try after sometime.`);
    return false;
  }
};

export const verifyReferrerByAddress = async (address: string) => {
  try {
    let verified = false;
    const referrer: any = await graphQlClient.request(getUsersByAddress, {
      where: {
        addr: address,
      },
    });

    if (referrer.newDeposits.length) {
      const { addr } = referrer.newDeposits[0];
      if (getChecksumAddress(addr) === getChecksumAddress(address)) verified = true;
    }
    return verified;
  } catch (e) {
    console.log(`Verifying the refferrer has failed! try after sometime.`);
    return false;
  }
};

//distributed amounts
//TODO:Need to abort the request on continusion.
export const getDistributedAmounts = () => async (dispatch: AppDispatch, getState: () => RootState) => {
  const currentDraw = getState().user.currentDraw;
  const earningDistributed = getState().user.distributedAmounts.earningDistributed;
  if (currentDraw === "0" || earningDistributed === "0") dispatch(setDistrubutedRequesting(true));
  try {
    setTimeout(async () => {
      const query: any = {
        56: getDistributedAmount,
        80001: getDistributedAmountTest,
      };

      const distributedAmounts: any = await graphQlClient.request(query[CURRENT_CHAIN_ID]);
      if (distributedAmounts?.platformStatistics?.length) {
        let platformStatistics = distributedAmounts.platformStatistics[0];
        const modifiedDistributedAmounts = {
          winningDistributed: getRoundAmount(platformStatistics.totalWinningAmount),
          earningDistributed: getRoundAmount(platformStatistics.totalDepsitAmount),
          referralAmount: getRoundAmount(platformStatistics.totalReferralAmount),
          totalUser: platformStatistics.totalUser,
          totalPaidUser: platformStatistics.totalPaidUser,
          currentDraw: platformStatistics.currentDraw,
          completedDraws: platformStatistics.completedDraw,
          completedTenKDraws: platformStatistics.completed10kDraw,
          isRequesting: false,
        };
        dispatch(setDistributed(modifiedDistributedAmounts));
      }
    }, 5000);
  } catch (e) {
    console.log(`Fetching distributed amounts has failed! try after sometime.`);

    dispatch(setDistrubutedRequesting(false));
  }
};

export const getReferralEarnings =
  (address: string | undefined, isWalletConnected: boolean, enteredDraw: boolean) => async (dispatch: AppDispatch) => {
    try {
      if (!enteredDraw || !address || !isWalletConnected) return;
      // const drawWinnerReferralEarningsQuery = () =>
      //   graphQlClient.request(getDrawWinnerReferralEarnings, {
      //     where: {
      //       addr: address,
      //     },
      //   });
      const referralEarningsQuery = () =>
        graphQlClient.request(getReferralPayouts, {
          where: {
            addr: address,
          },
        });
      const earnings: any = await Promise.all([referralEarningsQuery()]);
      if (earnings.length) {
        // let drawWinnerReferralEarnings = 0;
        // const drawWinnerReferralPayouts = earnings[0].drawWinnerReferralPayouts[0];
        // const drawWinnerReferrerBonuses = earnings[0].drawWinnerReferrerBonuses[0];
        // if (drawWinnerReferralPayouts) {
        //   dispatch(setEarnings({ type: UserSlicetypes.Earnings.winnerReferralPayouts, amount: +getRoundAmount(drawWinnerReferralPayouts.amount) }));
        //   drawWinnerReferralEarnings += parseInt(drawWinnerReferralPayouts.amount);
        // }
        // if (drawWinnerReferrerBonuses) {
        //   dispatch(setEarnings({ type: UserSlicetypes.Earnings.winnerReferrerBonuses, amount: +getRoundAmount(drawWinnerReferrerBonuses.amount) }));
        //   drawWinnerReferralEarnings += parseInt(drawWinnerReferrerBonuses.amount);
        // }

        const referralPayouts = earnings[0].referralPayouts;
        if (referralPayouts.length) {
          let referralPayout = 0;
          for (const r of referralPayouts) {
            referralPayout += parseInt(r.amount);
            // drawWinnerReferralEarnings += parseInt(r.amount);
          }
          dispatch(setEarnings({ type: UserSlicetypes.Earnings.referralPayouts, amount: +getRoundAmount(referralPayout.toString()) }));
        }
        // dispatch(setEarnings({ type: UserSlicetypes.Earnings.affiliate, amount: +getRoundAmount(drawWinnerReferralEarnings.toString()) }));
      }
    } catch (e) {
      console.log(`Fetching referral earnings has failed! try after sometime.`);
    }
  };

export const getWinningEarnings = (address: string) => async (dispatch: AppDispatch, getState: any) => {
  try {
    let totalWinningAmount = 0;
    const oneKWinningList = getState().user.oneKDrawWinners.filter(
      (winners: UserSlicetypes.IUsers) => getChecksumAddress(winners.address) === address,
    );
    const tenWinningList = getState().user.tenKDrawWinners.filter(
      (winners: UserSlicetypes.IUsers) => getChecksumAddress(winners.address) === address,
    );

    if (oneKWinningList.length) {
      for (const w of oneKWinningList) {
        totalWinningAmount += parseInt(w.amount);
      }
    }
    if (tenWinningList.length) {
      for (const w of tenWinningList) {
        totalWinningAmount += parseInt(w.amount);
      }
    }
    const hundredKWinnerList: any = await graphQlClient.request(get100KWinnersById, {
      where: {
        addr: address,
      },
    });

    const hundredKWinning = hundredKWinnerList.draw100KWinners[0];
    if (hundredKWinning) {
      totalWinningAmount += parseInt(getRoundAmount(hundredKWinning.amount));
    }

    dispatch(setEarnings({ type: UserSlicetypes.Earnings.winning, amount: totalWinningAmount }));
  } catch (e) {
    console.log(`Fetching referral earnings has failed! try after sometime.`);
  }
};

export const fetchEarnings =
  (address: string | undefined, isWalletConnected: boolean, enteredDraw: boolean) => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      if (!enteredDraw || !address || !isWalletConnected) return;
      const { myEarnings, claimableAmount, claimableToken } = getState().user;
      const hasValue = Boolean(
        myEarnings[UserSlicetypes.Earnings.winning] ||
          myEarnings[UserSlicetypes.Earnings.winnerReferrerBonuses] ||
          claimableAmount["unclaimed"] ||
          claimableAmount["claimed"] ||
          +claimableToken["unclaimed"] ||
          +claimableToken["claimed"],
      );

      dispatch(setIsFetchingEarnings(!hasValue));
      const participant = await lottoContract.participantByAddress(address);
      const totalWinningAmount = +getRoundAmount(participant.total_winning_amount);
      const totalReferralBonus = +getRoundAmount(participant.total_referral_bonus);
      const unclaimedAmount = +getRoundAmount(participant.unclaimed_amount);
      const claimedAmount = +getRoundAmount(participant.claimed_amount);
      const unclaimedToken = participant.unclaimed_token;
      const claimedToken = participant.claimed_token;
      dispatch(setEarnings({ type: UserSlicetypes.Earnings.winning, amount: totalWinningAmount }));
      dispatch(setEarnings({ type: UserSlicetypes.Earnings.winnerReferrerBonuses, amount: totalReferralBonus }));
      dispatch(setClaimableToken({ unclaimed: unclaimedToken, claimed: claimedToken }));
      dispatch(
        setClaimableAmount({
          unclaimed: unclaimedAmount,
          claimed: claimedAmount,
        }),
      );
      dispatch(setIsFetchingEarnings(false));
    } catch (e) {
      console.log("fetching earnings has failed!", e);
      dispatch(setIsFetchingEarnings(false));
    }
  };

export const verifyPredictedTicket =
  (ticketNumber: string, drawName: string, cb: (verified: boolean) => void) => async (dispatch: AppDispatch, getState: any) => {
    const user = getState().user;
    const draw1kNumber = getState().draw.draw1kNumber;
    const currentDraw = user.currentDraw;
    let verified = false;

    let ticketInOneKDraw =
      +ticketNumber > (Boolean(+currentDraw) ? +currentDraw : 1) * draw1kNumber - draw1kNumber &&
      +ticketNumber < (Boolean(+currentDraw) ? +currentDraw : 1) * draw1kNumber + 1;

    if (drawName === ONE_K_DRAW && !ticketInOneKDraw) {
      Toast({ message: "Lucky pass number does not exist in the current draw!", type: "error" });
    } else verified = true;
    cb(verified);
  };

//Predict winners
export const get1KPredictionWinners = (drawNumber: string) => async (dispatch: AppDispatch) => {
  dispatch(setIsFetching1KPredictionWinners({ drawNumber, fetching: true }));
  try {
    const predictWinnersResponse = await fetch(`${BACKEND_URL[CURRENT_CHAIN_ID]}/predictWinners/${+drawNumber}`);
    if (predictWinnersResponse.status >= 200 && predictWinnersResponse.status < 400) {
      const predictionWinners = await predictWinnersResponse.json();

      if (predictionWinners.status && predictionWinners.winners.length) {
        const getWinners = (winnerList: string[], winnerObj: ApiTypes.PredictionWinner, isNear: boolean) => {
          const winners = winnerList.map((winner) => {
            return {
              id: winnerObj._id,
              drawNumber: winnerObj.drawNumber,
              winnerToken: winnerObj.winnerToken,
              type: winnerObj.type,
              amount: winnerObj.type === PredictType.free ? "0" : isNear ? "0" : "50",
              address: winner,
              dp: AvatarList[Math.floor(Math.random() * Math.floor(AvatarList.length))],
            };
          });
          return winners;
        };

        let combinedPredictWinners = [];
        for (const pw of predictionWinners.winners) {
          if (pw.winnerAddress.length) combinedPredictWinners.push(...getWinners(pw.winnerAddress, pw, false));
          if (pw.nearWinnerAddress.length) combinedPredictWinners.push(...getWinners(pw.nearWinnerAddress, pw, true));
        }
        dispatch(set1KPredictionWinners({ drawNumber, predictionWinners: combinedPredictWinners }));
      } else dispatch(setIsFetching1KPredictionWinners({ drawNumber, fetching: false }));
    } else dispatch(setIsFetching1KPredictionWinners({ drawNumber, fetching: false }));
  } catch (e) {
    dispatch(setIsFetching1KPredictionWinners({ drawNumber, fetching: false }));
    console.log(`Fetching predict winners failed for draw number :${drawNumber}`);
  }
};

export const get100KPredictionWinners = () => async (dispatch: AppDispatch) => {
  dispatch(setIsFetching100KPredictionWinners(true));
  try {
    const predictWinnersResponse = await fetch(`${BACKEND_URL[CURRENT_CHAIN_ID]}/predict100kWinners`);
    if (predictWinnersResponse.status >= 200 && predictWinnersResponse.status < 400) {
      const predictionWinners = await predictWinnersResponse.json();

      if (predictionWinners.status && predictionWinners.winners.length) {
        const getWinners = (winnerList: string[], winnerObj: ApiTypes.PredictionWinner, isNear: boolean) => {
          const winners = winnerList.map((winner) => {
            return {
              id: winnerObj._id,
              drawNumber: 1,
              winnerToken: winnerObj.winnerToken,
              type: winnerObj.type,
              amount: winnerObj.type === Predict100KType.free ? "0" : isNear ? "0" : "50",
              address: winner,
              dp: AvatarList[Math.floor(Math.random() * Math.floor(AvatarList.length))],
            };
          });
          return winners;
        };

        let combinedPredictWinners = [];
        for (const pw of predictionWinners.winners) {
          if (pw.winnerAddress.length) combinedPredictWinners.push(...getWinners(pw.winnerAddress, pw, false));
          if (pw.nearWinnerAddress.length) combinedPredictWinners.push(...getWinners(pw.nearWinnerAddress, pw, true));
        }
        dispatch(set100KPredictionWinners(combinedPredictWinners));
      } else dispatch(setIsFetching100KPredictionWinners(false));
    } else dispatch(setIsFetching100KPredictionWinners(false));
  } catch (e) {
    dispatch(setIsFetching100KPredictionWinners(false));
    console.log(`Fetching 100k predict winners failed.`);
  }
};

const getPremium1K = async (address: string, drawNumber: number) => {
  const premium1KRedeemed: { redeemed1KDrawPremiumPredictionRewards: [{ transactionHash: string }] } = await predictionClient.request(
    get1KPremiumPredictionRedeemed,
    {
      where: {
        addr: address,
        drawNumber,
      },
    },
  );
  const transactionHash = premium1KRedeemed.redeemed1KDrawPremiumPredictionRewards.length
    ? premium1KRedeemed.redeemed1KDrawPremiumPredictionRewards[0].transactionHash
    : "";
  return transactionHash;
};

const getFree1K = async (address: string, drawNumber: number) => {
  const free1KRedeemed: { redeemed1KDrawFreePredictionRewards: [{ transactionHash: string }] } = await predictionClient.request(
    get1KFreePredictionRedeemed,
    {
      where: {
        addr: address,
        drawNumber,
      },
    },
  );
  const transactionHash = free1KRedeemed.redeemed1KDrawFreePredictionRewards.length
    ? free1KRedeemed.redeemed1KDrawFreePredictionRewards[0].transactionHash
    : "";
  return transactionHash;
};

const getPremium100K = async (address: string) => {
  const premium100KRedeemed: { redeemed100KDrawPremiumPredictionRewards: [{ transactionHash: string }] } = await predictionClient.request(
    get100KPremiumPredictionRedeemed,
    {
      where: {
        addr: address,
      },
    },
  );
  const transactionHash = premium100KRedeemed.redeemed100KDrawPremiumPredictionRewards.length
    ? premium100KRedeemed.redeemed100KDrawPremiumPredictionRewards[0].transactionHash
    : "";
  return transactionHash;
};

const getFree100K = async (address: string) => {
  const free100KRedeemed: { redeemed100KDrawFreePredictionRewards: [{ transactionHash: string }] } = await predictionClient.request(
    get100KFreePredictionRedeemed,
    {
      where: {
        addr: address,
      },
    },
  );
  const transactionHash = free100KRedeemed.redeemed100KDrawFreePredictionRewards.length
    ? free100KRedeemed.redeemed100KDrawFreePredictionRewards[0].transactionHash
    : "";
  return transactionHash;
};

export const fetchRedeemedPredictionTxnHash =
  (type: string, address: string, id: string, drawNumber: number) => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      const { ONE_K_DRAW, HUNDRED_K_DRAW } = getState().user.myPredictions;
      let transactionHash = "";
      if (type === PredictType.free) transactionHash = await getFree1K(address, drawNumber);
      else if (type === PredictType.premium) transactionHash = await getPremium1K(address, drawNumber);
      else if (type === Predict100KType.free) transactionHash = await getFree100K(address);
      else transactionHash = await getPremium100K(address);
      if (type === PredictType.free || type === PredictType.premium) {
        const oneKDraw = [...ONE_K_DRAW];
        const tergetIndex = oneKDraw.findIndex((winner) => winner.address === address && winner.type === type && winner.drawNumber === drawNumber);
        oneKDraw[tergetIndex].transactionHash = transactionHash;
        dispatch(setMyPredictions({ ONE_K_DRAW: oneKDraw, HUNDRED_K_DRAW, id }));
      } else {
        const hundredKDraw = [...HUNDRED_K_DRAW];
        const tergetIndex = hundredKDraw.findIndex((winner) => winner.address === address && winner.type === type);
        hundredKDraw[tergetIndex].transactionHash = transactionHash;
        dispatch(setMyPredictions({ ONE_K_DRAW, HUNDRED_K_DRAW: hundredKDraw, id }));
        return transactionHash;
        // Toast({ message: "Redeem prediction was successful!", type: "success" });
      }
    } catch (e) {
      dispatch(setIsRedeeming({ id, fetching: { redeeming: false, redeemed: false } }));
      console.log("Updating redeemed prediction failed.", e);
    }
  };

const fetchPremium1K = async (address: string) => {
  const premium1KRedeemed: { redeemed1KDrawPremiumPredictionRewards: [{ transactionHash: string; drawNumber: number }] } =
    await predictionClient.request(get1KPremiumPredictionRedeemed, {
      where: {
        addr: address,
      },
    });
  return premium1KRedeemed.redeemed1KDrawPremiumPredictionRewards;
};

const fetchFree1K = async (address: string) => {
  const free1KRedeemed: { redeemed1KDrawFreePredictionRewards: [{ transactionHash: string; drawNumber: number }] } = await predictionClient.request(
    get1KFreePredictionRedeemed,
    {
      where: {
        addr: address,
      },
    },
  );
  return free1KRedeemed.redeemed1KDrawFreePredictionRewards;
};

export const fetchRedeemedPredictionAtOnce = async (address: string) => {
  try {
    const redeemedAtOnceResponse = await Promise.all([fetchPremium1K(address), fetchFree1K(address), getPremium100K(address), getFree100K(address)]);
    let premium1K: [{ transactionHash: string; drawNumber: number }] | [] = [];
    let free1k: [{ transactionHash: string; drawNumber: number }] | [] = [];
    let premium100K: string = "";
    let free100K: string = "";

    if (redeemedAtOnceResponse.length) {
      premium1K = redeemedAtOnceResponse[0];
      free1k = redeemedAtOnceResponse[1];
      premium100K = redeemedAtOnceResponse[2];
      free100K = redeemedAtOnceResponse[3];
    }
    return { premium1K, free1k, premium100K, free100K };
  } catch (e) {
    console.log("Fetching redeemed predictions at once has failed!", e);
    return { premium1K: [], free1k: [], premium100K: "", free100K: "" };
  }
};

export const getIsAdminApproved = () => async (dispatch: AppDispatch) => {
  try {
    const adminApprovalResponse: { prediction100KPoolRewardsAddeds: [{ id: any }]; prediction1KPoolRewardsAddeds: [{ drawNumber: any }] } =
      await predictionClient.request(getPredictionApproval);
    let hundredK = "";
    let oneK: number[] = [];

    if (adminApprovalResponse.prediction100KPoolRewardsAddeds.length) hundredK = adminApprovalResponse.prediction100KPoolRewardsAddeds[0].id;
    if (adminApprovalResponse.prediction1KPoolRewardsAddeds.length)
      oneK = adminApprovalResponse.prediction1KPoolRewardsAddeds.map((approval) => approval.drawNumber);

    dispatch(setPredictionAdminApproval({ hundredK, oneK }));
  } catch (e) {
    console.log("Fetching admin approval data has failed!", e);
  }
};

export const getMyPredictions = (address: string) => async (dispatch: AppDispatch, getState: () => RootState) => {
  dispatch(setIsRequestingMyPredictions(true));
  try {
    const myPredictionsResponse = await fetch(`${BACKEND_URL[CURRENT_CHAIN_ID]}/myPredictions/${address}`);
    if (myPredictionsResponse.status >= 200 && myPredictionsResponse.status < 400) {
      const myPredictions = await myPredictionsResponse.json();
      if (myPredictions.status && myPredictions.predictions.length) {
        const allPredictions = await fetchRedeemedPredictionAtOnce(address);
        let oneKpredictions = [];
        let hundredKpredictions = [];
        const getWinners = (winnerObj: ApiTypes.PredictionWinner, isNear: boolean, transactionHash: string) => {
          const winners = {
            id: winnerObj._id,
            drawNumber: winnerObj.drawNumber,
            winnerToken: winnerObj.winnerToken,
            type: winnerObj.type,
            amount: winnerObj.type === (PredictType.free || Predict100KType.free) ? "0" : isNear ? "0" : "50",
            address: address,
            dp: AvatarList[Math.floor(Math.random() * Math.floor(AvatarList.length))],
            transactionHash,
          };
          return winners;
        };

        for (const pw of myPredictions.predictions) {
          if ([Predict100KType.premium, Predict100KType.free].includes(pw.type)) {
            // const get100KTxnHash = pw.type === Predict100KType.premium ? getPremium100K : getFree100K;
            const transactionHash = pw.type === Predict100KType.premium ? allPredictions.premium100K : allPredictions.free100K;
            if (pw.winnerAddress.length) {
              // const transactionHash = await get100KTxnHash(address);
              // const transactionHash = get100KTxnHash.length ? get100KTxnHash[0].transactionHash : "";
              hundredKpredictions.push(getWinners(pw, false, transactionHash));
            }
            if (pw.nearWinnerAddress.length) {
              // const transactionHash = await get100KTxnHash(address);
              // const transactionHash = get100KTxnHash.length ? get100KTxnHash[0].transactionHash : "";
              hundredKpredictions.push(getWinners(pw, true, transactionHash));
            }
          } else {
            // const get1KTxnHash = pw.type === PredictType.premium ? getPremium1K : getFree1K;
            const get1KTxnHash = pw.type === PredictType.premium ? allPredictions.premium1K : allPredictions.free1k;
            if (pw.winnerAddress.length) {
              // const transactionHash = await get1KTxnHash(address, pw.drawNumber);
              const targetHash = get1KTxnHash.length ? get1KTxnHash.filter((t) => t.drawNumber === pw.drawNumber) : [];
              const transactionHash = targetHash.length ? targetHash[0].transactionHash : "";
              oneKpredictions.push(getWinners(pw, false, transactionHash));
            }
            if (pw.nearWinnerAddress.length) {
              // const transactionHash = await get1KTxnHash(address, pw.drawNumber);
              const targetHash = get1KTxnHash.length ? get1KTxnHash.filter((t) => t.drawNumber === pw.drawNumber) : [];
              const transactionHash = targetHash.length ? targetHash[0].transactionHash : "";
              oneKpredictions.push(getWinners(pw, true, transactionHash));
            }
          }
        }
        dispatch(setMyPredictions({ ONE_K_DRAW: oneKpredictions, HUNDRED_K_DRAW: hundredKpredictions }));
      } else dispatch(setIsRequestingMyPredictions(false));
    } else dispatch(setIsRequestingMyPredictions(false));
  } catch (e) {
    dispatch(setIsRequestingMyPredictions(false));
    console.log("Fetching my predictions failed!", e);
  }
};

export const getLatestClaimedTokenByAddress = (address: string) => async (dispatch: AppDispatch, getState: () => RootState) => {
  try {
    const { unclaimed } = getState().user.claimableToken;
    const latestClaimedTokenResponse: { claimedTokens: [{ blockTimestamp: string }] } = await graphQlClient.request(getClaimedToken, {
      where: {
        addr: address,
      },
    });

    if (latestClaimedTokenResponse.claimedTokens.length && +unclaimed > 0) {
      const releaseDuration = +(await lottoContract.getReleaseDuration());
      const latestBlockTimestamp = +latestClaimedTokenResponse.claimedTokens[0].blockTimestamp;
      const nextAvaliableAt = (releaseDuration + latestBlockTimestamp) * 1000;
      const nextAvailableInWords = getClaimButtonAvailability(nextAvaliableAt);
      dispatch(setNextAvailableAt(nextAvailableInWords.length ? `You can claim ${nextAvailableInWords}` : ""));
    } else dispatch(setNextAvailableAt());
  } catch (e) {
    console.log("fetching latest claimed token failed.", e);
    dispatch(setNextAvailableAt());
  }
};

export const getWhiteListedData = (address: any) => async (dispatch: AppDispatch) => {
  try {
    const WhitelistApiUrl = `${BACKEND_URL[CURRENT_CHAIN_ID]}/whitelist/${address}`;
    dispatch(setWhitelistLoading(true));
    let isWhitelisted = await lottoContract.getIsWhitelisted(address);

    dispatch(setIsWhitelisted(isWhitelisted));
    dispatch(setWhitelistLoading(false));
  } catch (error) {
    console.error("Fetching whiteListed data has failed!", error);
    dispatch(setWhitelistLoading(false));
  }
};
