import { CSSProperties } from "react";
import { styled, Box, Typography, Stack } from "@mui/material";
import OneKWinnerBg from "../../../assets/images/OneKWinnerBg.png";
import TenKWinnerBg from "../../../assets/images/TenKWinnerBg.png";
import { GRAD_COLOR_1, GRAD_COLOR_2, GRAD_COLOR_3, ONE_K_DRAW, PRIMARY, TEN_K_DRAW } from "../../../utils/constants";
import { counterType } from "../../../utils/types/counter";

namespace S {
  export const CustomCardWrapper = styled(Box)(({ theme }) => ({
    borderRadius: "0.625rem",
    backgroundColor: theme.palette.custom.secondary.secondary_3,
    padding: theme.spacing(4),
    "&.distribution-card": {
      position: "relative",
    },
    "&.stake-card": {
      padding: `${theme.spacing(3)} 0`,
      height: 210,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
      "&.stake-card": {
        height: "auto",
      },
    },
  }));

  export const WinnerWelcomeCard = styled(Box, {
    shouldForwardProp: (prop) => prop !== "counterType",
  })(({ counterType }: { counterType: counterType }) => ({
    height: "5.25rem",
    backgroundImage: `url(${counterType === PRIMARY ? TenKWinnerBg : OneKWinnerBg})`,
    width: "100%",
    margin: "0 auto",
    display: "grid",
    placeContent: "center",
  }));

  export const WinnerWelcomeCardContent = styled(Box, {
    shouldForwardProp: (prop) => prop !== "counterType",
  })(({ counterType }: { counterType: counterType }) => ({ theme }) => ({
    border: `1px solid ${counterType === PRIMARY ? theme.palette.custom.secondary.secondary_4 : theme.palette.custom.secondary.secondary_1}`,
    padding: "0.5rem",
    fontWeight: 600,
    backgroundColor: `${counterType === PRIMARY ? theme.palette.custom.accent.accent_2 : theme.palette.custom.accent.accent_3}`,
    color: `${counterType === PRIMARY ? theme.palette.custom.secondary.secondary_4 : theme.palette.custom.secondary.secondary_1}`,
  }));
  //TODO:Need to verify the styles -->Replaced from old location
  export const DrawCardWrapper = styled(Box)(({ theme }) => ({
    display: "flex",
    background: "transparent",
    height: "4.375rem",
    width: "10rem",
    alignItems: "center",
    position: "absolute",
    right: 0,
    left: "-0.625rem",
    bottom: "-1.2rem",
    [theme.breakpoints.down("md")]: {
      width: "6.1875rem",
      right: 0,
      left: "0.3125rem",
      bottom: "-1.5625rem",
    },
    [theme.breakpoints.down("sm")]: {
      width: "5.6875rem",
      height: "3.4375rem",
      left: "0",
      bottom: "-1.375rem",
    },
  }));

  export const DrawCountWrapper = styled(Box)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    height: "3.125rem",
    width: "inherit",
    border: `1px solid ${theme.palette.custom.secondary.secondary_3}`,
    boxShadow: "0px 4px 4px rgba(89, 89, 89, 0.05)",
    borderRadius: "10px",
    background: "black",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    [theme.breakpoints.down("md")]: {
      height: "2.4375rem",
    },
  }));

  export const MoreText = styled(Typography)(() => ({
    fontSize: "0.75rem",
    color: "#ffffff66",
  }));

  export const DrawCount = styled(Typography)(({ theme }) => ({
    color: "rgba(255, 255, 255, 0.4)",
    fontWeight: 600,
    fontSize: "0.75rem",

    [theme.breakpoints.down("md")]: {
      fontSize: "0.75rem",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.625rem",
    },
  }));

  export const YearnCategory = styled(Typography)(({ theme }) => ({
    fontWeight: 600,
    color: "rgba(255, 255, 255)",
    [theme.breakpoints.down("md")]: {
      fontSize: "0.625rem",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.5625rem",
    },
  }));

  export const AffiliateCardWrapper = styled(Box)(({ theme }) => ({
    overflow: "hidden",
    borderRadius: "0.625rem",
    background: theme.palette.custom.secondary.secondary_2,
    width: "100%",
    padding: `${theme.spacing(2)} ${theme.spacing(4)}`,
    position: "relative",
    [theme.breakpoints.down("md")]: {
      maxWidth: "23.75rem",
    },
  }));

  export const AffiliaterMainContainer = styled(Stack, {
    shouldForwardProp: (prop) => prop !== "blurActive",
  })(({ blurActive }: { blurActive: Boolean }) => ({ theme }) => ({
    justifyContent: "space-between",
    alignItems: "center",
    height: "105%",
    borderRadius: "1rem",
    gap: theme.spacing(4),
    filter: blurActive && "blur(14px)",
  }));

  export const HorizontalDivider = styled(Box, { shouldForwardProp: (prop) => prop !== "isTimerModal" })(
    ({ isTimerModal }: { isTimerModal: boolean }) =>
      ({ theme }) => ({
        position: "absolute",
        top: "57%",
        left: "0",
        width: "100%",
        borderTop: `0.0625rem solid rgba(255, 255, 255, 0.1);
`,
        ":before": {
          content: '""',
          width: "30px",
          height: "30px",
          borderRadius: "50%",
          backgroundColor: isTimerModal ? "#0c0905 !important" : theme.palette.custom.secondary.secondary_3,
          transform: `translate(${isTimerModal ? "-35%" : "-50%"},-50%) rotate(180deg)`,
          position: "absolute",
          left: "-3px",
          clipPath: isTimerModal ? "ellipse(67% 100% at 8% 50%)" : "unset",
          [theme.breakpoints.down("sm")]: {
            left: "0px",
            width: "25px",
            height: "25px",
          },
        },
        ":after": {
          content: '""',
          width: "30px",
          height: "30px",
          borderRadius: "50%",
          backgroundColor: isTimerModal ? "#0c0905 !important" : theme.palette.custom.secondary.secondary_3,
          position: "absolute",
          transform: `translate(${isTimerModal ? "35%" : "50%"},-50%)`,
          right: "-3px",
          clipPath: isTimerModal ? "ellipse(67% 100% at 8% 50%)" : "unset",
          [theme.breakpoints.down("sm")]: {
            right: "0px",
            width: "25px",
            height: "25px",
          },
        },
      }),
  );

  export const HorizontalBorder = styled(Box)({
    width: "30px",
    height: "30px",
    borderRadius: "50%",
    backgroundColor: "red",
    position: "absolute",
    left: "0",
  });

  export const AffiliaterTopSection = styled(Stack)(({ theme }) => ({
    alignItems: "center",
    justifyContent: "space-between",
    gap: theme.spacing(4),
    width: "100%",
  }));

  export const AffliaterTopImageContainer = styled(Box)({
    width: "fit-content",
  });

  export const AffliaterTopImage = styled("img")(({ theme }) => ({
    width: "3.4375rem",
    height: "3.4375rem",
    [theme.breakpoints.down("sm")]: {
      width: "3rem",
      height: "3rem",
    },
  }));

  export const AffiliaterTopTitleContainer = styled(Box)({
    flex: 1.5,
  });

  export const AffiliaterTicketIDTitle = styled(Typography)({
    fontSize: "12px",
    fontWeight: 600,
  });

  export const AffiliaterTopTitle = styled(Typography)({
    fontWeight: 600,
    // whiteSpace: "nowrap",
  });

  export const AffiliaterBottomSection = styled(Stack)({
    alignSelf: "flex-end",
    alignItems: "flex-end",
    justifyContent: "space-between",
    width: "100%",
  });

  export const AffiliaterBottomDrawContainer = styled(Box)({
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  });

  export const AffiliaterDrawTitle = styled(Typography)({
    fontWeight: 600,
    textAlign: "center",
  });

  export const AffiliaterDrawSubtitle = styled(Typography)({
    fontWeight: 500,
  });

  export const AffiliaterBottomUserContainer = styled(Box, { shouldForwardProp: (prop) => prop !== "isTimerModal" })(
    ({ isTimerModal }: { isTimerModal: boolean }) =>
      ({ theme }) => ({
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: isTimerModal ? `0 ${theme.spacing(3)} 0 0` : `0 ${theme.spacing(3)}`,
        flex: 4,
      }),
  );

  export const AffilaterUserDetailsContainer = styled(Box, { shouldForwardProp: (prop) => prop !== "isTimerModal" })(
    ({ isTimerModal }: { isTimerModal: boolean }) => ({
      display: "flex",
      flexDirection: "column",
      justifyContent: isTimerModal ? "center" : "flex-start",
      alignItems: "flex-start",
      height: isTimerModal ? "5.5rem" : "auto",
    }),
  );

  export const AffiliaterUserTitle = styled(Typography)({
    fontWeight: 600,
    textAlign: "center",
  });

  export const AffiliaterStampContainer = styled(Box)({
    position: "relative",
  });

  export const AffiliatedStampImage = styled("img", {
    shouldForwardProp: (prop) => prop !== "customStyle",
  })(({ customStyle }: { customStyle?: CSSProperties }) => ({ theme }) => ({
    position: "absolute",
    width: "130px !important",
    height: "130px !important",
    top: -21,
    left: -12,
    transform: "translate(-60%, -48%) rotate(-16deg)",
    [theme.breakpoints.down("sm")]: {
      width: "90px !important",
      height: "90px !important",
      top: -17,
      left: -3,
    },
    ...customStyle,
  }));

  export const AffiliaterBlurContainer = styled(Stack)(() => ({
    position: "absolute",
    top: 0,
    left: 0,
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    width: "100%",
    zIndex: "2",
    borderRadius: "0.625rem",
    backgroundColor: "rgba(217, 217, 217, 0.01)",
  }));

  export const TicketTextContainer = styled(Stack)(({ theme }) => ({
    alignItems: "center",
    justifyContent: "center",
    width: "19.125rem",
    height: "3.1875rem",
    borderRadius: "0.5rem",
    border: `0.0625rem solid ${theme.palette.custom.secondary.secondary_1}`,
    background: "rgba(22,21,26,0.7)",
    [theme.breakpoints.down("sm")]: {
      width: "95%",
      maxWidth: "19rem",
    },
  }));

  export const TicketText = styled(Typography)(({ theme }) => ({
    textAlign: "center",
    fontSize: "1.25rem",
    fontWeight: "600",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
    },
  }));

  export const RecentWinnerGradientText = styled(Typography, { shouldForwardProp: (prop) => prop !== "draw" })(
    ({ draw }: { draw: string }) =>
      ({ theme }) => ({
        background: draw === ONE_K_DRAW ? GRAD_COLOR_1 : draw === TEN_K_DRAW ? GRAD_COLOR_2 : GRAD_COLOR_3,
        WebkitBackgroundClip: "text",
        WebkitTextFillColor: "transparent",
        fontWeight: 600,
        fontSize: "1rem",
        [theme.breakpoints.down("sm")]: {
          fontSize: "0.875rem",
        },
      }),
  );
}

export default S;
