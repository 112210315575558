import { FC } from "react";
import { useSelector } from "react-redux";
import { SReferPopover } from "./popovers.styled";
import { REFERRAL_PATH_NAME } from "../../utils/constants";
import { useGetDimensions } from "../../utils/helpers";
import { useUserDetails } from "../../utils/hooks/selector";
import { CustomPopoverProps } from "../../utils/types";
import { Triangle } from "../../utils/widgets";
import CustomPopover from "../../utils/widgets/popover";
import InputWithCopyAndShare from "../input-with-copy-and-share";

const ReferPopover: FC<CustomPopoverProps> = (props) => {
  //props
  const { id, isOpen, onClose } = props;

  // constants
  const isSm = useGetDimensions();

  //state values
  const userDetails = useSelector(useUserDetails);

  return (
    <>
      {Boolean(isOpen) && <Triangle customStyle={{ top: isSm ? 28 : 36, right: isSm ? 4 : 45 }} />}
      <CustomPopover id={id} isOpen={isOpen} onClose={onClose} customStyle={{ top: 18, left: -5 }}>
        <SReferPopover.Wrapper>
          <SReferPopover.Highlight variant="body1">Share this unique URL with your friends:</SReferPopover.Highlight>
          <InputWithCopyAndShare Url={`${window.location.origin}/${REFERRAL_PATH_NAME}/${userDetails.id}/${userDetails.address}`} />
        </SReferPopover.Wrapper>
      </CustomPopover>
    </>
  );
};

export default ReferPopover;
