import { FC } from "react";
import { useSelector } from "react-redux";
import S from "./tickets-distributed.styled";
import "../../../../assets/css/flipCounter.css";
import { useIsInitiatingEventstream, useTicketsSold } from "../../../../utils/hooks/selector";
import FlipCounter from "../../../../utils/widgets/counter/FlipCounter";

const TicketsDistributed: FC = () => {
  //local-storage
  const cachedTicketNumber = localStorage.getItem("ticketNumber");

  //state values
  const ticketsSold = useSelector(useTicketsSold);
  const isInitiatingEventstream = useSelector(useIsInitiatingEventstream);

  return (
    <S.CardWrapper>
      <S.TicketSold>
        <S.HeaderAlignment>
          <S.HeadingText>Tickets Distributed: </S.HeadingText>
        </S.HeaderAlignment>
        <S.FlipCounterWrapper className="flip" data-tut="tour-total-tickets">
          <FlipCounter flipNumber={isInitiatingEventstream && cachedTicketNumber ? cachedTicketNumber : ticketsSold.toString()} />
        </S.FlipCounterWrapper>
      </S.TicketSold>
    </S.CardWrapper>
  );
};

export default TicketsDistributed;
