import { FC } from "react";
import { useSelector } from "react-redux";
import { Typography } from "@mui/material";
import S from "./my-tickets-modal-body.styled";
import GoldenTicket from "../../../../assets/images/golden-ticket.png";
import { HUNDRED_K, NONE, ONE_K, ONE_K_DRAW, TEN_K, TEN_K_DRAW } from "../../../../utils/constants";
import { getWholeNum } from "../../../../utils/helpers";
import { useUserDetails, useUserTickets } from "../../../../utils/hooks/selector";

const MyTicketsModalBody: FC = () => {
  //state values
  const ticketNumbers = useSelector(useUserTickets).ticketNumbers;
  const includeWinners = useSelector(useUserTickets).includeWinners;
  const participatedOneKDrawNumber = useSelector(useUserDetails).participatedOneKDrawNumber;

  //constants
  const onlyWinners = includeWinners.filter((w) => w.draw !== NONE);

  return (
    <S.MyTicketsModalBody>
      {Boolean(onlyWinners.length) && (
        <S.WinningTicketContainer>
          <S.HeadingRow>
            <S.HeadingText>WINNING LUCKY PASSES</S.HeadingText>
            <S.DrawCount>DRAW:&nbsp;&nbsp;&nbsp;#{participatedOneKDrawNumber}</S.DrawCount>
          </S.HeadingRow>
          <S.WinningTicketsScrollContainer>
            {onlyWinners.map((winner, i) => {
              return (
                <S.Row key={`${winner.ticketNumber}_${i}`}>
                  <S.TicketHolder>
                    <S.CustomGoldenTicket src={GoldenTicket} alt="golden ticket" />
                    <S.TicketText draw={winner.draw}>YT-{getWholeNum(winner.ticketNumber)}</S.TicketText>
                  </S.TicketHolder>
                  <S.TicketDrawHolder>
                    <S.IBtnContainer>
                      <Typography>i</Typography>
                    </S.IBtnContainer>
                    <S.WinningText draw={winner.draw}>
                      This Lucky Pass won at Lucky Round {winner.draw === ONE_K_DRAW ? ONE_K : winner.draw === TEN_K_DRAW ? TEN_K : HUNDRED_K}
                    </S.WinningText>
                  </S.TicketDrawHolder>
                </S.Row>
              );
            })}
          </S.WinningTicketsScrollContainer>
        </S.WinningTicketContainer>
      )}
      <S.MyTicketsContainer>
        <S.MyTicketsHeadingRow>
          <S.DrawCount>LUCKY PASSES:&nbsp;&nbsp;&nbsp;{ticketNumbers.length} </S.DrawCount>
        </S.MyTicketsHeadingRow>
        <S.MyTicketsScrollContainer>
          {includeWinners.map((t, i) => (
            <S.Row key={`${t.ticketNumber}_${i}`}>
              <S.TicketHolder>
                <S.CustomGoldenTicket src={GoldenTicket} alt="golden ticket" />
                <S.TicketText draw={t.draw}>YT-{getWholeNum(t.ticketNumber)}</S.TicketText>
              </S.TicketHolder>
              {t.draw !== NONE && (
                <S.TicketDrawHolder>
                  <S.IBtnContainer>
                    <Typography>i</Typography>
                  </S.IBtnContainer>
                  <S.WinningText draw={t.draw}>
                    This Lucky Pass won at Lucky round {t.draw === ONE_K_DRAW ? ONE_K : t.draw === TEN_K_DRAW ? TEN_K : HUNDRED_K}
                  </S.WinningText>
                </S.TicketDrawHolder>
              )}
            </S.Row>
          ))}
        </S.MyTicketsScrollContainer>
      </S.MyTicketsContainer>
    </S.MyTicketsModalBody>
  );
};
export default MyTicketsModalBody;
