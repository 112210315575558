import { Box, styled, Typography } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { GRADIENT1, GRADIENT2, GRAD_COLOR_1, GRAD_COLOR_2, GRAD_COLOR_3 } from "../../constants";
import { gradient } from "../../types/progress-bar";

namespace S {
  export const CircularProgressWrapper = styled(Box)(({ theme }) => ({
    height: "8.4375rem",
    width: "8.4375rem",
    position: "relative",
    background: "black",
    borderRadius: "50%",
    [theme.breakpoints.down("md")]: {
      height: "6.875rem",
      width: "6.875rem",
    },
    [theme.breakpoints.down("sm")]: {
      height: "5.625rem",
      width: "5.625rem",
    },
  }));

  export const CircularPercentageWrapper = styled(Box, { shouldForwardProp: (prop) => prop !== "isPercentageBar" })(
    ({ isPercentageBar = false }: { isPercentageBar?: boolean }) =>
      ({ theme }) => ({
        height: isPercentageBar ? "90px" : "50px",
        width: isPercentageBar ? "90px" : "50px",
        position: "relative",
        background: "black",
        borderRadius: "50%",
        [theme.breakpoints.down("md")]: {
          height: isPercentageBar ? "80px" : "50px",
          width: isPercentageBar ? "80px" : "50px",
        },
        [theme.breakpoints.down("sm")]: {
          height: isPercentageBar ? "70px" : "50px",
          width: isPercentageBar ? "70px" : "50px",
        },
      }),
  );

  export const StaticCircularProgressBar = styled(CircularProgress)(({ theme }) => ({
    ".MuiCircularProgress-circle": {
      color: theme.palette.custom.secondary.secondary_2,
    },
  }));

  export const CustomCircularProgressBar = styled(CircularProgress)(() => ({
    transform: "rotate(90deg) !important",
    position: "absolute",
    left: 0,
    top: 0,
  }));

  export const LabelContentWrapper = styled(Box)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: "0.25rem",
    top: 0,
    left: 6,
    bottom: 10,
    right: 0,
    position: "absolute",
    img: {
      width: "auto",
      height: "2.1rem",
    },
    [theme.breakpoints.down("md")]: {
      left: 0,
      gap: "0.125rem",
      img: {
        width: "auto",
        height: "1.6875rem",
      },
    },
  }));

  export const PercentageLabelContentWrapper = styled(Box)(() => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    position: "absolute",
  }));

  export const LabelContent = styled(Typography, {
    shouldForwardProp: (prop) => prop !== "gradient",
  })(({ gradient }: { gradient: gradient }) => ({ theme }) => ({
    background: gradient === GRADIENT1 ? GRAD_COLOR_1 : gradient === GRADIENT2 ? GRAD_COLOR_2 : GRAD_COLOR_3,
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    fontSize: theme.spacing(4),
    fontWeight: 600,
    [theme.breakpoints.down("md")]: {
      fontSize: "1rem",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.85rem",
    },
  }));

  export const PercentageContent = styled(Typography)({
    color: "white",
    fontWeight: 600,
  });

  export const PercentageStatusContent = styled(Typography, { shouldForwardProp: (prop) => prop !== "status" })(({ status }: { status: string }) => ({
    color: status === "OVER" ? "#E54900" : status === "NOW" ? "#0AE93B" : "#EFB614",
    fontWeight: status === "NOW" ? 600 : 400,
    animation: status === "NOW" ? "scale 0.8s linear infinite" : "unset",
    "@keyframes scale": {
      "0%": {
        transform: "scale(1)",
      },
      "100%": {
        transform: "scale(1.2)",
      },
    },
  }));
}

export default S;
