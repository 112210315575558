import { Promise } from "bluebird";
import Web3 from "web3";
import store from "../../../store";
import { toggleIsBuying } from "../../../store/slices/user";
import Toast from "../../../utils/widgets/toast";
import erc20Abi from "../build/IERC20.json";
import { web3 } from "./web3";

export namespace TokenContract {
  export const setupToken = async (library?: any, tokenAddress?: string) => {
    if (library) web3.setProvider(library);
    const IERCAbi = erc20Abi.abi as any;
    let iercContract = new web3.eth.Contract(IERCAbi, tokenAddress);
    Promise.promisifyAll(iercContract, { suffix: "Promise" });
    return iercContract;
  };

  export const getBalance = async (library: any, tokenAddress: any, address: string) => {
    let iercContract = await setupToken(library, tokenAddress);
    let balance = await iercContract.methods.balanceOf(address).call();
    let readable = Web3.utils.fromWei(balance, "ether");
    return readable;
  };

  export const getDecimals = async (library: any, tokenAddress: any) => {
    let iercContract = await setupToken(library, tokenAddress);
    let decimals = await iercContract.methods.decimals().call();
    return decimals;
  };

  export const getAllowance = async (library: any, tokenAddress: any, address: string, contractAddress: string | undefined) => {
    let iercContract = await setupToken(library, tokenAddress);
    let allowance = await iercContract.methods.allowance(address, contractAddress).call();
    let readable = Web3.utils.fromWei(allowance, "ether");
    return readable;
  };

  export const approve = async (
    library: any,
    tokenAddress: any,
    address: string,
    maxAllowance: string,
    contractAddress: string | undefined,
    handleConnecting: () => void,
    cb: () => void,
  ) => {
    let iercContract = await setupToken(library, tokenAddress);
    let gasPrice = await web3.eth.getGasPrice();

    iercContract.methods
      .approve(contractAddress, maxAllowance)
      .send({ from: address, gaslimit: 1100000, gasPrice })
      .on("transactionHash", (hash: any) => {
        // console.log("hash", hash);
        store.dispatch(toggleIsBuying({ loading: true, close: true }));
      })
      .on("receipt", (receipt: any) => {
        // console.log("receipt", receipt);
        if (receipt.status) {
          Toast({ message: "Allowance approved!", type: "success" });
          if (cb) cb();
        } else {
          handleConnecting();
          Toast({ message: "Approval was not successful!", type: "error" });
        }
      })
      .on("error", (error: any, receipt: any) => {
        console.log("error", error, receipt);
        Toast({ message: error.message, type: "error" });
        handleConnecting();
      });
  };
}
