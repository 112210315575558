import { FC, useState, useEffect, useMemo, useCallback, Dispatch, SetStateAction, useRef } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { InputAdornment } from "@mui/material";
import moment from "moment";
import { useAccount, useWalletClient } from "wagmi";
import S from "./pool-card.styled";
import ballIcon from "../../../assets/images/ball-icon.svg";
import cricketStump from "../../../assets/images/cricket-stump.svg";
import CricketerLogo from "../../../assets/images/cricketer-logo.svg";
import liveIconWhite from "../../../assets/images/live-icon-white.png";
import liveIconRed from "../../../assets/images/live-icon.png";
import LockImage from "../../../assets/images/lock-image.svg";
import partyBell from "../../../assets/images/party-bell.svg";
import partyWrapper from "../../../assets/images/party-wrapper.svg";
import starsFlying from "../../../assets/images/start-flying.svg";
import IccCricketLogo from "../../../assets/images/tournament_logo.svg";
import cupImage from "../../../assets/images/trophy.svg";
import { PredictionToEarn, redeemRewards } from "../../../services/ethereum/contract/predictionPTE";
import { PredictionPoolContract } from "../../../services/ethereum/contract/predictionPoolContract";
import {
  fetchIsRedeemedReward,
  fetchPredictionCount,
  getContractWinner,
  getExpectedRewardExisting,
  getExpectedRewardX,
  predictPool,
  withdrawFund,
} from "../../../store/slices/match.actions";
import { GRADIENT, PRIMARY, cricketTeams, getFlag } from "../../../utils/constants";
import { useGetDimensions } from "../../../utils/helpers";
import {
  useIsAppLoading,
  useIsPredicting,
  useIsReady,
  useIsRedeemRewardLoading,
  useIsWithDrawingFund,
  useUserDetails,
  useWinnerOverlay,
} from "../../../utils/hooks/selector";
import { useAppDispatch } from "../../../utils/hooks/state";
import { TourGuide } from "../../../utils/types";
import { Pool } from "../../../utils/types/state";
import { Button, GradientButton } from "../../../utils/widgets";
import GradientCheckBox from "../../../utils/widgets/gradient-checkbox";
import CircularLoader from "../../../utils/widgets/loader/CircularLoader";
import DotLoader from "../../../utils/widgets/loader/DotLoader";
import CricketPredicitonWinnerSkeleton from "../../../utils/widgets/skeletons/cricket-prediction-won-skeleton";
import Toast from "../../../utils/widgets/toast";

type PoolCardProps = {
  status: string;
  setIsTourOpen: Dispatch<SetStateAction<boolean>>;
  setTourConfig: Dispatch<SetStateAction<TourGuide>>;
  setIndex: Dispatch<SetStateAction<number>>;
};

const PoolCard: FC<PoolCardProps> = ({ status, setIsTourOpen, setTourConfig, setIndex }) => {
  // constructors
  const dispatch = useAppDispatch();
  const { address, isConnected: isWalletConnected } = useAccount();
  const { data: signer } = useWalletClient();
  const params = useParams();

  //state values
  const [predictCountry, setPredictCountry] = useState("");
  const [timeRemaining, setTimeRemaining] = useState({ hours: 0, minutes: 0, seconds: 0 });
  const [countdownEnded, setCountdownEnded] = useState(false);
  const [depositError, setDepositError] = useState("");
  const [withDrawError, setWithDrawError] = useState("");
  const [withdrawAmount, setWithdrawAmount] = useState<number | null>(null); // [poolA, poolB
  const [pool, setPool] = useState("");
  const [amount, setAmount] = useState("");
  const isPredictingPool = useSelector(useIsPredicting);
  const isWithdrawingFund = useSelector(useIsWithDrawingFund);
  const balance = useSelector(useUserDetails).balance;
  const isRedeemRewardLoading = useSelector(useIsRedeemRewardLoading);
  const winnerOverlay = useSelector(useWinnerOverlay);
  const isAppLoading = useSelector(useIsAppLoading);

  const isReady = useSelector(useIsReady);
  const [poolContract, setPoolContract] = useState<any>(null);
  const [isValidPool, setIsValidPool] = useState(false);
  const [isPoolLoading, setIsPoolLoading] = useState(true);
  const [winnerStatus, setWinnerStatus] = useState<string | null>(null); // [poolA, poolB, no result]
  const [myPrediction, setMyPrediction] = useState<string | null>(null); // [poolA, poolB]
  const [isRewardRedeemed, setIsRewardRedeemed] = useState<boolean>(false);
  const [expectedReward, setExpectedReward] = useState<number>(0);
  const [claimedAmount, setClaimedAmount] = useState<number>(0);
  const [predictedAmount, setPredictedAmount] = useState<number>(0);
  const [expectedRewardX, setExpectedRewardX] = useState<number>(0);
  const [expectedRewardPerPool, setExpectedRewardPerPool] = useState<{
    poolA: number;
    poolB: number;
  }>({
    poolA: 1,
    poolB: 1,
  });
  const isFetchingUserCount = useRef(true);
  const isFetchingWinner = useRef(true);

  const [userCount, setUserCount] = useState<{
    poolA: number;
    poolB: number;
  }>({
    poolA: 0,
    poolB: 0,
  });
  const [teamTotal, setTeamTotal] = useState<{
    teamA: number;
    teamB: number;
  }>({
    teamA: 0,
    teamB: 0,
  });
  const [depositRange, setDepositRange] = useState<{
    min: number;
    max: number;
  }>({
    min: 1,
    max: 1000,
  }); // [min, max]
  const [predictionDetail, setPredictionDetail] = useState<{
    isPredictionEnded: boolean;
    isWinnerPoolDecided: boolean;
    endTime: number | null;
    winnerAnnouncedOn: number | null;
  }>({
    isPredictionEnded: false,
    isWinnerPoolDecided: false,
    endTime: null,
    winnerAnnouncedOn: null,
  });

  // constants
  const sm = useGetDimensions("sm");
  const expiredPool = cricketTeams.filter((c) => c.status === "expired");
  const expiredPoolIds = expiredPool.map((c: any) => c.pool);
  const totalPoolCount = userCount.poolA + userCount.poolB;
  const poolAPercent = (userCount.poolA / totalPoolCount) * 100;
  const poolBPercent = (userCount.poolB / totalPoolCount) * 100;
  const livePoolId = cricketTeams.find((c) => c.status === "live");

  let cricketData: any;
  if (expiredPoolIds.includes(Number(params.id))) {
    cricketData = cricketTeams.filter((c) => c.pool === Number(params.id))[0];
  } else {
    cricketData = cricketTeams.filter((c) => c.status === status)[0];
  }

  useEffect(() => {
    if (!isReady && !(typeof cricketData?.pool === "number")) return;
    const getPoolContract = async () => {
      try {
        const poolContract = await PredictionPoolContract.getPoolAddress(cricketData?.pool);

        setPoolContract(poolContract);
        setIsValidPool(true);
        setIsPoolLoading(false);
      } catch (error) {
        setPoolContract(null);
        setIsValidPool(false);
        setIsPoolLoading(false);
      }
    };
    getPoolContract();
  }, [isReady, cricketData]);

  // match tour
  useEffect(() => {
    if (winnerOverlay || !isReady || isAppLoading || isPoolLoading || !cricketData || cricketData.status !== "live") return;

    const isTourAvailable = localStorage.getItem(TourGuide.match);
    if (!isTourAvailable) {
      setIsTourOpen(true);
      setTourConfig(TourGuide.match);
    }
  }, [isPoolLoading, cricketData, winnerOverlay, isReady, isAppLoading]);

  const getIsRedeemed = useCallback(async () => {
    let userPoolDetails = await PredictionToEarn.userPoolDetails(signer, poolContract, address);
    setIsRewardRedeemed(userPoolDetails?.isRedeemed || false);
    setMyPrediction(userPoolDetails?.myPrediction || null);
    setPool(userPoolDetails?.myPrediction || null);
    setClaimedAmount(Number(userPoolDetails?.claimed_amount) || 0);
    setPredictedAmount(Number(userPoolDetails?.amount) || 0);
  }, [isReady, address, poolContract]);

  const getPoolData = async () => {
    try {
      let count = await fetchPredictionCount(poolContract);
      setUserCount({
        poolA: count?.poolA || 0,
        poolB: count?.poolB || 0,
      });
      isFetchingUserCount.current = false;

      let matchData: any = await PredictionToEarn.poolBasicBatch(signer, poolContract);

      setTeamTotal({
        teamA: Number(matchData?.poolATotal) || 0,
        teamB: Number(matchData?.poolBTotal) || 0,
      });
      setPredictionDetail({
        isPredictionEnded: matchData?.isPredictionEnded || false,
        isWinnerPoolDecided: matchData?.isWinnerPoolDecided || false,
        endTime: matchData?.endTime || null,
        winnerAnnouncedOn: matchData?.winnerAnnouncedOn || null,
      });

      setDepositRange({
        min: matchData?.minAmount || 1,
        max: matchData?.maxAmount || 1000,
      });

      if (!matchData?.isWinnerPoolDecided) {
        isFetchingWinner.current = false;
      }

      setIsPoolLoading(false);
    } catch (error) {
      console.log(error);
      setIsPoolLoading(false);
    }
  };

  const getRewardExpected = useCallback(async () => {
    // let rewardX = getExpectedRewardX({
    //   poolATotal: teamTotal.teamA,
    //   poolBTotal: teamTotal.teamB,
    //   poolACount: userCount.poolA,
    //   poolBCount: userCount.poolB,
    // });
    let rewardPerPool: any = getExpectedRewardExisting({
      poolATotal: teamTotal.teamA,
      poolBTotal: teamTotal.teamB,
      poolACount: userCount.poolA,
      poolBCount: userCount.poolB,
    });

    setExpectedRewardPerPool({
      poolA: rewardPerPool?.poolA ? rewardPerPool?.poolA : 1,
      poolB: rewardPerPool?.poolB ? rewardPerPool.poolB : 1,
    });
  }, [teamTotal, userCount]);

  useEffect(() => {
    if (!isReady || !poolContract) return;
    getRewardExpected();
  }, [isReady, poolContract, teamTotal, userCount]);

  useEffect(() => {
    if (isReady && address && poolContract) getIsRedeemed();
  }, [isReady, address, poolContract]);
  useEffect(() => {
    const getPrediction = async () => {
      let expectedReward = await PredictionToEarn.expectedRewards(signer, poolContract, address);
      setExpectedReward(Number(expectedReward));
    };
    if (isReady && address && poolContract) {
      getPrediction();
    }
  }, [isReady, address, poolContract]);

  useEffect(() => {
    let interval: any;
    if (!poolContract) {
      setIsPoolLoading(true);
      return;
    } else {
      interval = setInterval(() => {
        getPoolData();
      }, 10000);
    }
    return () => clearInterval(interval);
  }, [poolContract]);

  useEffect(() => {
    if (predictionDetail.endTime !== null) {
      const intervalId = setInterval(() => {
        const now = new Date().getTime();
        const difference = predictionDetail.endTime! * 1000 - now;
        if (difference <= 0) {
          // The countdown has ended
          clearInterval(intervalId);
          setTimeRemaining({ hours: 0, minutes: 0, seconds: 0 });
          setCountdownEnded(true); // Set the countdownEnded state to true to show Locks in soon.
        } else {
          const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
          const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
          const seconds = Math.floor((difference % (1000 * 60)) / 1000);

          setTimeRemaining({ hours, minutes, seconds });
        }
      }, 1000); // Update every second

      // Clean up the interval when the component unmounts
      return () => clearInterval(intervalId);
    }
  }, [predictionDetail.endTime, poolContract]);

  useEffect(() => {
    const getWinner = async () => {
      let data = await getContractWinner(poolContract);
      isFetchingWinner.current = false;
      setWinnerStatus(data);
    };

    if (predictionDetail.isWinnerPoolDecided && !!poolContract) getWinner();
  }, [predictionDetail.isWinnerPoolDecided, poolContract]);

  const formatTime = (value: number) => (value < 10 ? `0${value}` : value);

  return (
    <S.PoolCardWrapper bgType={cricketData?.status}>
      {/* /Header */}
      {isValidPool && !isPoolLoading ? (
        <>
          <S.CardHeader>
            <S.HeaderText>
              {cricketData?.matchTimeStamp && <S.BallImage src={ballIcon} alt="ball-image" />}
              {moment(cricketData?.matchTimeStamp).format("Do MMM YYYY")}
            </S.HeaderText>

            <S.HeaderLiveText bgType={cricketData?.status}>
              <S.LiveImage src={cricketData?.status === "live" ? liveIconRed : liveIconWhite} alt="liveicon" />
              {status === "live" ? "LIVE" : status === "upcoming" ? "UPCOMING" : status === "completed" ? "COMPLETED" : status.toUpperCase()}
            </S.HeaderLiveText>
          </S.CardHeader>
        </>
      ) : !isValidPool ? (
        <>
          <S.CardHeader>
            <S.HeaderText></S.HeaderText>
            <S.HeaderLiveText bgType={"expired"}>{"INVALID POOL"}</S.HeaderLiveText>
          </S.CardHeader>
        </>
      ) : (
        <>
          <S.CardHeader>
            <S.HeaderText></S.HeaderText>
            <S.HeaderLiveText bgType={"expired"}>{"LOADING..."}</S.HeaderLiveText>
          </S.CardHeader>
        </>
      )}

      {/* Content */}
      {cricketData && !isPoolLoading && isValidPool ? (
        <S.CardContent>
          {/* Country Flag shown here */}
          <S.CurrentMatchContainer>
            <S.MatchFlagContainer>
              <S.FlagImage src={getFlag(cricketData?.teamA.flag)} alt="country-flag" />
              <S.MatchText>{cricketData?.teamA.name}</S.MatchText>
            </S.MatchFlagContainer>
            <S.CupImage src={cupImage} alt="cup-image" />
            <S.MatchFlagContainer>
              <S.FlagImage src={getFlag(cricketData?.teamB.flag)} alt="country-flag" />
              <S.MatchText>{cricketData?.teamB.name}</S.MatchText>
            </S.MatchFlagContainer>
          </S.CurrentMatchContainer>
          {/* Match Status */}
          <S.MatchStatusContainer>
            <S.StatusHeader>Match Status</S.StatusHeader>
            <S.StatusContent>
              <S.StatusTopContainer data-tut={status === "live" ? "tour-match-info" : ""}>
                <S.IccMensCricketLogoImage src={IccCricketLogo} alt="IccCricketLogo" />
                <S.WorldCupStatus>
                  <S.WorldCupStatusText>{cricketData?.tournament.name}</S.WorldCupStatusText>
                  <S.StatusText>
                    {cricketData?.tournament.matchType} {cricketData?.matchNumber} OF {cricketData?.tournament.totalMatches} ·{" "}
                    {moment(cricketData?.matchTimeStamp).format("Do MMM YYYY")} · {cricketData?.matchVenue}
                  </S.StatusText>
                </S.WorldCupStatus>
              </S.StatusTopContainer>
              <S.StatusBottomContainer>
                <S.LoaderBox data-tut={status === "live" ? "tour-match-progress" : ""}>
                  <S.LoaderPart>
                    <S.TextArea>
                      <S.LoaderSubText>{cricketData?.teamA.name}</S.LoaderSubText>
                      <S.LoaderSubText>
                        {isFetchingUserCount.current ? <DotLoader isNormal={true} /> : `${isNaN(poolAPercent) ? 0 : poolAPercent}%`}
                      </S.LoaderSubText>
                    </S.TextArea>
                    <S.Loader LoadPercentage={(userCount.poolA / totalPoolCount) * 100} loaderLeft={false}>
                      <span />
                    </S.Loader>
                  </S.LoaderPart>
                  <S.LoaderPart>
                    <S.TextArea sx={{ flexDirection: sm ? "row-reverse" : "" }}>
                      <S.LoaderSubText>
                        {isFetchingUserCount.current ? <DotLoader isNormal={true} /> : `${isNaN(poolBPercent) ? 0 : poolBPercent}%`}
                      </S.LoaderSubText>
                      <S.LoaderSubText>{cricketData?.teamB.name}</S.LoaderSubText>
                    </S.TextArea>
                    <S.Loader LoadPercentage={(userCount.poolB / totalPoolCount) * 100} loaderLeft={sm ? false : true}>
                      <span />
                    </S.Loader>
                  </S.LoaderPart>
                </S.LoaderBox>
                <S.PredictAmount>
                  {/* <S.PredictMainText>${teamTotal.teamA}</S.PredictMainText> */}
                  <S.PredictSubText>Wagger</S.PredictSubText>
                  {/* <S.PredictMainText>${teamTotal.teamB}</S.PredictMainText> */}
                </S.PredictAmount>
              </S.StatusBottomContainer>
            </S.StatusContent>
          </S.MatchStatusContainer>
          {/* Predict content */}
          <S.PredictContainer>
            <S.PredictHeader>
              <S.PredictHeaderMainText>Predict</S.PredictHeaderMainText>

              {!predictionDetail.isWinnerPoolDecided &&
                (predictionDetail.isPredictionEnded ? (
                  !!myPrediction ? (
                    <S.YourPredictionContainer>
                      <S.YourPredictionText>Your Prediction</S.YourPredictionText>
                      <S.CountryFlagMini
                        src={getFlag(myPrediction === Pool.poolA ? cricketData?.teamA.flag : cricketData?.teamB.flag)}
                        alt="country-flag"
                      />
                      <S.PredictedCountry>{myPrediction === Pool.poolA ? cricketData?.teamA.name : cricketData?.teamB.name}</S.PredictedCountry>
                    </S.YourPredictionContainer>
                  ) : (
                    <S.YourPredictionText>No Prediction</S.YourPredictionText>
                  )
                ) : (
                  <S.PredictHeaderLockText data-tut={status === "live" ? "tour-match-locks" : ""}>
                    {countdownEnded
                      ? "Locks In Soon"
                      : `Locks In: ${formatTime(timeRemaining.hours)}:${formatTime(timeRemaining.minutes)}:${formatTime(timeRemaining.seconds)}`}
                  </S.PredictHeaderLockText>
                ))}
            </S.PredictHeader>

            <S.PredictContent>
              {isFetchingWinner.current ? (
                <CricketPredicitonWinnerSkeleton />
              ) : !predictionDetail.isPredictionEnded ? (
                <>
                  <S.StatusTopContainer>
                    <S.CricketAnimeLogo src={CricketerLogo} alt="cricketer-logo" />
                    <S.PredictStatus>
                      {!myPrediction ? (
                        <S.PredictStatusText>Predict the winning team!</S.PredictStatusText>
                      ) : (
                        <S.PredictedStatus>
                          <S.PredictedSubText>You have Predicted for</S.PredictedSubText>
                          <S.MobileAlignContainer>
                            <S.PredictedMainText>{myPrediction === Pool.poolA ? cricketData.teamA.name : cricketData.teamA.name}</S.PredictedMainText>
                            <S.PredictedSubText>with</S.PredictedSubText>
                            <S.PredictedAmountText>{predictedAmount} USDT</S.PredictedAmountText>
                          </S.MobileAlignContainer>
                        </S.PredictedStatus>
                      )}
                      {!myPrediction && (
                        <S.PredictSetContainer data-tut={status === "live" ? "tour-match-predict-option" : ""}>
                          <S.RadioContainer>
                            <GradientCheckBox
                              active={predictCountry === cricketData?.teamA.name}
                              setActive={() => {
                                if (predictCountry === cricketData?.teamA.name) {
                                  setPredictCountry("");
                                  setPool("");
                                } else {
                                  setExpectedRewardX(0);
                                  setPredictCountry(cricketData?.teamA.name);
                                  setPool(Pool.poolA);
                                }
                              }}
                            />

                            <S.CountryFlagMini src={getFlag(cricketData?.teamA.flag)} alt="country-flag" />
                            <S.CountryText>{cricketData?.teamA.name}</S.CountryText>
                            {expectedRewardX > 0 && pool === Pool.poolA && !Number.isNaN(expectedReward) ? (
                              <S.OnePointFiveX>{expectedRewardX}x</S.OnePointFiveX>
                            ) : (
                              <>
                                <S.CommonPoint>{expectedRewardPerPool.poolA}x</S.CommonPoint>
                              </>
                            )}
                            {/* {} */}
                          </S.RadioContainer>
                          <S.RadioContainer>
                            <GradientCheckBox
                              active={predictCountry === cricketData?.teamB.name}
                              setActive={() => {
                                if (predictCountry === cricketData?.teamB.name) {
                                  setPredictCountry("");
                                  setPool("");
                                } else {
                                  setExpectedRewardX(0);
                                  setPredictCountry(cricketData?.teamB.name);
                                  setPool(Pool.poolB);
                                }
                              }}
                            />

                            <S.CountryFlagMini src={getFlag(cricketData?.teamB.flag)} alt="country-flag" />
                            <S.CountryText>{cricketData?.teamB.name}</S.CountryText>
                            {expectedRewardX > 0 && pool === Pool.poolB && !Number.isNaN(expectedReward) ? (
                              <S.OnePointFiveX>{expectedRewardX}x</S.OnePointFiveX>
                            ) : (
                              <>
                                <S.CommonPoint>{expectedRewardPerPool.poolB}x</S.CommonPoint>
                              </>
                            )}
                          </S.RadioContainer>
                        </S.PredictSetContainer>
                      )}
                    </S.PredictStatus>
                  </S.StatusTopContainer>
                  <S.PredictBottomContainer>
                    {/* {!!myPrediction && (
                      <S.InputContainer>
                        <S.CustomInput
                          placeholder="Amount"
                          type="number"
                          sx={{ width: "190px" }}
                          disableUnderline
                          // value={}
                          onChange={(e) => {
                            const withdrawInputValue = parseInt(e.target.value, 10);
                            if (isNaN(withdrawInputValue)) {
                              setWithDrawError("Please enter a valid number.");
                            } else if (withdrawInputValue < 1 || withdrawInputValue > predictedAmount) {
                              setWithDrawError(`Please enter a value between 1 and ${predictedAmount}.`);
                            } else {
                              setWithdrawAmount(withdrawInputValue);
                              setWithDrawError("");
                            }
                          }}
                          disabled={isPredictingPool}
                          onClick={() => {}}
                          endAdornment={
                            <InputAdornment position="end">
                              <Button
                                buttonType={PRIMARY}
                                onClick={() => {
                                  if (!isReady) Toast({ message: "Contract isn't ready yet, try after sometime!", type: "warning" });
                                  else dispatch(withdrawFund(signer, poolContract, withdrawAmount?.toString()));
                                }}
                                disabled={
                                  isWithdrawingFund ||
                                  withdrawAmount === null ||
                                  Number.isNaN(withdrawAmount) ||
                                  withdrawAmount <= 0 ||
                                  !!withDrawError
                                }
                                customStyle={{
                                  minWidth: "6.9375rem",
                                  height: "2rem",
                                  borderRadius: "0.625rem",
                                  fontSize: "0.875rem",
                                }}
                              >
                                {isWithdrawingFund ? <CircularLoader size={20} /> : "Withdraw"}
                              </Button>
                            </InputAdornment>
                          }
                        />
                        <S.ErrorText>{withDrawError}</S.ErrorText>
                      </S.InputContainer>
                    )} */}

                    <S.InputContainer>
                      <S.CustomInput
                        data-tut={status === "live" ? "tour-match-predict-button" : ""}
                        placeholder="Enter USDT(Bep20)"
                        type="number"
                        disableUnderline
                        value={amount}
                        onChange={(e) => {
                          const inputValue = e.target.value;
                          if (isNaN(parseFloat(inputValue))) {
                            setDepositError("Please enter a valid number.");
                          } else if (parseFloat(inputValue) < depositRange.min || parseFloat(inputValue) + predictedAmount > depositRange.max) {
                            setDepositError(`Please enter a value between ${depositRange.min} and ${depositRange.max}.`);
                          } else if (Number(inputValue) > Number(balance)) {
                            setDepositError(`Insufficient Balance`);
                          } else {
                            setDepositError("");
                          }
                          // console.log(
                          //   predictionDetail.isPredictionEnded,
                          //   predictionDetail.isWinnerPoolDecided,
                          //   isPredictingPool,
                          //   !amount.trim().length,
                          //   !pool,
                          //   !!depositError,
                          // );
                          if (isReady && poolContract && pool) {
                            let rewardX = getExpectedRewardX({
                              poolATotal: teamTotal.teamA,
                              poolBTotal: teamTotal.teamB,
                              poolACount: userCount.poolA,
                              poolBCount: userCount.poolB,
                              depositedAmount: parseFloat(inputValue),
                              selectedTeam: pool,
                            });
                            setExpectedRewardX(rewardX);
                          }
                          setAmount(String(inputValue));
                        }}
                        disabled={isPredictingPool}
                        onClick={() => {}}
                        endAdornment={
                          <InputAdornment position="end">
                            <GradientButton
                              buttonType={GRADIENT}
                              disabled={
                                predictionDetail.isPredictionEnded ||
                                predictionDetail.isWinnerPoolDecided ||
                                isPredictingPool ||
                                !amount.trim().length ||
                                !pool ||
                                !!depositError ||
                                !isWalletConnected ||
                                !address
                              }
                              onClick={() => {
                                if (!isReady) Toast({ message: "Contract isn't ready yet, try after sometime!", type: "warning" });
                                else if (!poolContract) Toast({ message: "Invalid pool!", type: "warning" });
                                else
                                  dispatch(
                                    predictPool(signer, poolContract, pool, amount, () => {
                                      setAmount("");
                                      setPredictCountry("");
                                      setPool("");
                                    }),
                                  );
                              }}
                              customStyle={{
                                height: "32px",
                                minWidth: "118px",
                                borderRadius: "10px",
                              }}
                              loading={isPredictingPool}
                            >
                              {/* {isPredictingPool ? <CircularLoader size={20} /> : "Predict"} */}
                              Predict
                            </GradientButton>
                          </InputAdornment>
                        }
                      />
                      <S.ErrorText>{depositError}</S.ErrorText>
                    </S.InputContainer>
                  </S.PredictBottomContainer>
                </>
              ) : !predictionDetail.isWinnerPoolDecided ? (
                <S.PredictionLockedContainer>
                  <S.LockImage src={LockImage} alt="lock-image" />
                  <S.PredictTextContainer>
                    <S.PredictText>PREDICTIONS ARE</S.PredictText>
                    <S.PredictLockedSubText>LOCKED IN</S.PredictLockedSubText>
                  </S.PredictTextContainer>
                </S.PredictionLockedContainer>
              ) : (
                <>
                  <S.PredictionWinTopContainer>
                    <S.partyWrapper1 src={partyWrapper} alt="partyWrapper" />
                    <S.partyWrapper2 src={partyWrapper} alt="partyWrapper" />
                    <S.partyBell src={partyBell} alt="partyBell" />
                    <S.CricketStamp src={cricketStump} alt="cricketStump" />
                    <S.Stars src={starsFlying} alt="starsFlying" />
                    {winnerStatus === myPrediction && <S.CongragulationText>CONGRATULATIONS</S.CongragulationText>}
                    <S.CongragulationMainText>
                      {winnerStatus === Pool.poolA ? cricketData.teamA.name : cricketData.teamB.name} Won the Match!
                    </S.CongragulationMainText>
                  </S.PredictionWinTopContainer>
                  <S.PredictWinBottomContainer>
                    {winnerStatus !== myPrediction ? (
                      <S.TextContainer>
                        {!!myPrediction && <S.PredictInputText>Sorry Better Luck Next Time!</S.PredictInputText>}
                        <GradientButton
                          buttonType={GRADIENT}
                          onClick={() => {
                            !!livePoolId && setIndex(1);
                          }}
                          customStyle={{
                            height: "32px",
                            minWidth: "118px",
                            borderRadius: "8px",
                            fontSize: sm ? "12px !important" : "16px !important",
                          }}
                        >
                          Next Prediction
                        </GradientButton>
                      </S.TextContainer>
                    ) : (
                      <S.TextContainer>
                        <S.PredictInputText>
                          {isRewardRedeemed ? `You claimed ${claimedAmount} USDT` : `You won ${expectedReward} USDT`}
                        </S.PredictInputText>
                        <GradientButton
                          buttonType={GRADIENT}
                          onClick={() => {
                            if (!isReady) Toast({ message: "Contract isn't ready yet, try after sometime!", type: "warning" });
                            else if (!poolContract) Toast({ message: "Invalid pool!", type: "warning" });
                            else if (!address) Toast({ message: "Please connect your wallet!", type: "warning" });
                            else
                              redeemRewards(signer, poolContract, address, () => {
                                getIsRedeemed();
                              });
                          }}
                          customStyle={{
                            height: "32px",
                            minWidth: "118px",
                            borderRadius: "8px",
                          }}
                          disabled={isRedeemRewardLoading || isRewardRedeemed}
                          loading={isRedeemRewardLoading}
                        >
                          {/* {isRedeemRewardLoading ? "..." : isRewardRedeemed ? "Claimed" : "Claim"} */}
                          {isRewardRedeemed ? "Claimed" : "Claim"}
                        </GradientButton>
                      </S.TextContainer>
                    )}
                  </S.PredictWinBottomContainer>
                </>
              )}
            </S.PredictContent>
          </S.PredictContainer>
        </S.CardContent>
      ) : (
        <S.CardContentLoading>
          <S.LoadingText>{!isPoolLoading ? "No pools to show" : "Loading"}</S.LoadingText>
        </S.CardContentLoading>
      )}
    </S.PoolCardWrapper>
  );
};

export default PoolCard;
