import { Stack, styled } from "@mui/material";

namespace S {
  export const RightSideArrowPanelWrapper = styled(Stack)(({ theme }) => ({
    display: "none",
    textAlign: "center",
    justifySelf: "flex-end",
    [theme.breakpoints.down("md")]: {
      display: "block",
      width: "3.125rem",
      backgroundColor: theme.palette.custom.strokes.primary,
      marginTop: "-2.5rem",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  }));
}

export default S;
