import { FC } from "react";
import { Typography } from "@mui/material";
import S from "./yearn-protocol.styled";
import piechart from "../../../../../assets/images/PieChart.png";
import { useGetDimensions } from "../../../../../utils/helpers";

const YearnProtocol: FC = () => {
  //constants
  const isSm = useGetDimensions();

  return (
    <S.Container>
      <S.ProtocolContainer>
        <S.ContentHeaderText>Yearn Protocol</S.ContentHeaderText>
        <Typography variant={isSm ? "caption" : "body2"}>
          We believe in fair play. Hence we are transparent with our shares. We strive to grow as a community.
        </Typography>
        <S.CustomPieChartImageDownSm src={piechart} alt="mantra pie chart" />
        <S.UnorderList>
          <S.NormalText>Charity</S.NormalText>
          <S.RainbowText1>Referrals And Winnings</S.RainbowText1>
          <S.RainbowText2>Promotions and Ecosystem</S.RainbowText2>
          <S.RainbowText3>Liquidity and Exchange fee</S.RainbowText3>
        </S.UnorderList>
      </S.ProtocolContainer>
      <S.PieChartContainer>
        <S.CustomPieChartImage src={piechart} alt="mantra pie chart" />
      </S.PieChartContainer>
    </S.Container>
  );
};
export default YearnProtocol;
