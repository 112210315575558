import { FC } from "react";
import { useSelector } from "react-redux";
import S from "./yearn-games.styled";
import BUDDY_MONEY_BAG from "../../../assets/images/CashPocket1.png";
import CHAMP_MONEY_BAG from "../../../assets/images/CashPocket2.png";
import FAM_MONEY_BAG from "../../../assets/images/CashPocket3.png";
import CURRENCY_NOTE from "../../../assets/images/golden-ticket.png";
import OpenArrow from "../../../assets/images/greetings-assets/openWindow.svg";
import { YEARN_BUDDY, YEARN_CHAMP, YEARN_FAM } from "../../../utils/constants";
import { useGetDimensions } from "../../../utils/helpers";
import { useCurrentTenKDraw, useDraw100kNumber, useDraw10kNumber, useDraw1kNumber, useUserDetails } from "../../../utils/hooks/selector";

const YearnGamesCard: FC = () => {
  //state values
  const draw1kNumber = useSelector(useDraw1kNumber);
  const draw10kNumber = useSelector(useDraw10kNumber);
  const draw100kNumber = useSelector(useDraw100kNumber);
  const userDetails = useSelector(useUserDetails);
  const currentTenKDraw = useSelector(useCurrentTenKDraw);

  //constants
  const isSm = useGetDimensions();

  //functions
  const handleClick = () => {
    window.open(`${process.env.REACT_APP_DOCS_URL}/yearntogether/gaming-rules`);
  };

  return (
    <S.YearnGamesContainer>
      <S.YearnGamesTitleContainer>
        <S.YearnGamesTitle variant={isSm ? "body1" : "h5"}>Yearn Games</S.YearnGamesTitle>
        <S.YearnGamesIcon src={OpenArrow} alt="redirect-icon" onClick={handleClick} />
      </S.YearnGamesTitleContainer>
      <S.YearnGamePointsWrapper>
        <S.YearnGamePointsContainer>
          {/* <S.RocketImg src={rocketIcon} alt="rocket-icon" /> */}
          <S.YearnGamesContent textAlign={"center"}>
            Unleash Lucky Pass Power <S.YearnGamesSubContent>Massive Rewards Await!</S.YearnGamesSubContent>
          </S.YearnGamesContent>
          {/* <S.RocketImg src={rocketIcon} alt="rocket-icon" /> */}
        </S.YearnGamePointsContainer>
      </S.YearnGamePointsWrapper>
      <S.YearnAvatarContainer color="#e4fffa">
        <S.YearnAvatarWrapper color="#5dffe2">
          <S.YearnAvatar src={YEARN_BUDDY} alt="yearn-avatar" />
        </S.YearnAvatarWrapper>
        <S.YearnAvatarTitle className="yearn-title">
          Yearn Buddy, &nbsp;
          <span className="avatar-detail">at Lucky Pass {+userDetails.participatedOneKDrawNumber * draw1kNumber}:</span>
          <br />
          <span className="avatar-detail avatar-icon">
            <S.YearnGamesIcon className="details-icon" src={BUDDY_MONEY_BAG} alt="money-bag" />
            &nbsp;$5,000 + 10K YEARN Tokens
          </span>
          <span className="avatar-detail avatar-icon">
            <S.YearnGamesIcon className="details-icon" src={CURRENCY_NOTE} alt="currency-note" />
            &nbsp;Lifetime NFT Access
          </span>
        </S.YearnAvatarTitle>
      </S.YearnAvatarContainer>
      <S.YearnAvatarContainer color="#efe5ff">
        <S.YearnAvatarWrapper color="#ac78ff">
          <S.YearnAvatar src={YEARN_CHAMP} alt="yearn-avatar" />
        </S.YearnAvatarWrapper>
        <S.YearnAvatarTitle className="yearn-title">
          Yearn Champ, &nbsp;
          <span className="avatar-detail">at Lucky Pass {(+currentTenKDraw + 1) * draw10kNumber}:</span>
          <br />
          <span className="avatar-detail avatar-icon">
            <S.YearnGamesIcon className="details-icon" src={CHAMP_MONEY_BAG} alt="money-bag" />
            &nbsp;$10,000 + 25K YEARN Tokens
          </span>
          <span className="avatar-detail avatar-icon">
            <S.YearnGamesIcon className="details-icon" src={CURRENCY_NOTE} alt="currency-note" />
            &nbsp;Lifetime NFT Access
          </span>
        </S.YearnAvatarTitle>
      </S.YearnAvatarContainer>
      <S.YearnAvatarContainer color="#ffe8e8">
        <S.YearnAvatarWrapper color="#ff8383">
          <S.YearnAvatar src={YEARN_FAM} alt="yearn-avatar" />
        </S.YearnAvatarWrapper>
        <S.YearnAvatarTitle className="yearn-title">
          Yearn Fam, &nbsp;
          <span className="avatar-detail">at Lucky Pass {draw100kNumber}:</span>
          <br />
          <span className="avatar-detail avatar-icon">
            <S.YearnGamesIcon className="details-icon" src={FAM_MONEY_BAG} alt="money-bag" />
            &nbsp;$200,000 + 100K YEARN Tokens
          </span>
          <span className="avatar-detail avatar-icon">
            <S.YearnGamesIcon className="details-icon" src={CURRENCY_NOTE} alt="currency-note" />
            &nbsp;Lifetime NFT Access
          </span>
        </S.YearnAvatarTitle>
      </S.YearnAvatarContainer>
    </S.YearnGamesContainer>
  );
};

export default YearnGamesCard;
