import { FC } from "react";
import { useSelector } from "react-redux";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import S from "./mainboard.styled";
import "../../assets/css/flipCounter.css";
import jackpotImg1 from "../../assets/images/jackpot_gradient1.png";
import jackpotImg2 from "../../assets/images/jackpot_gradient2.png";
import {
  GRADIENT1,
  GRADIENT2,
  GRADIENT3,
  hundredKDrawWinningAmount,
  HUNDREDK_DRAW,
  HUNDRED_K,
  HUNDRED_K_DRAW,
  oneKDrawWinningAmount,
  ONEK_DRAW,
  ONE_K,
  ONE_K_DRAW,
  tenKDrawWinningAmount,
  TENK_DRAW,
  TEN_K,
  TEN_K_DRAW,
  YEARN_BUDDY,
  YEARN_CHAMP,
  YEARN_FAM,
} from "../../utils/constants";
import { getProgressPercentage, isMyself } from "../../utils/helpers";
import { useDraw100kNumber, useDraw10kNumber, useDraw1kNumber, useEvents, useTicketsSold } from "../../utils/hooks/selector";
import DrawCard from "../../utils/widgets/cards/DrawCard";
import CoinAnimations from "../../utils/widgets/coin-animation";
import ConfettiShoot from "../../utils/widgets/confetti-animation";
import FlipCounter from "../../utils/widgets/counter/FlipCounter";
import ConfettiAnimations from "../../utils/widgets/grafetti-animation";
import CircularProgressWithLabelForJackpot from "../../utils/widgets/jackpot-progress-bar/CircularProgressWithLabelForJackpot";
import ProgressWithCount from "./ProgressWithCount";

type JackpotProps = {
  winnerId: string;
  userId: string;
  value: string;
  slot: string;
  showBody?: boolean;
  showFooter?: boolean;
  className?: string;
};

const Jackpot: FC<JackpotProps> = ({ value, winnerId, userId, slot, showFooter = true, className = "flip" }) => {
  //state values
  const ticketsSold = useSelector(useTicketsSold);
  const recentWinners = useSelector(useEvents).recentWinners;
  const draw1kNumber = useSelector(useDraw1kNumber);
  const draw10kNumber = useSelector(useDraw10kNumber);
  const draw100kNumber = useSelector(useDraw100kNumber);

  //constants
  const jackpotImg = value === HUNDRED_K_DRAW ? jackpotImg2 : jackpotImg1;
  const winningAmount = value === ONE_K_DRAW ? oneKDrawWinningAmount : value === TEN_K_DRAW ? tenKDrawWinningAmount : hundredKDrawWinningAmount;
  const buddyText = value === ONE_K_DRAW ? "Buddy" : value === TEN_K_DRAW ? "Champ" : "Fam";
  const buddyImg = value === ONE_K_DRAW ? YEARN_BUDDY : value === TEN_K_DRAW ? YEARN_CHAMP : YEARN_FAM;

  return (
    <S.Jackpot customStyle={value}>
      <S.JackpotHeader>
        <S.JackpotAmountContainer>
          <S.JackpotHeaderImg>
            <img src={jackpotImg} alt="jackpot img 1" />
          </S.JackpotHeaderImg>
          <S.JackpotText variant="h3">${winningAmount}</S.JackpotText>
        </S.JackpotAmountContainer>
        <S.JackpotAnimationWrapper>
          <CoinAnimations />
          <ConfettiAnimations />
          <ConfettiShoot />
          {/* <S.Wrapper1Img src={wrapper1} alt="wrapper1" />
          <S.Wrapper2Img src={wrapper2} alt="wrapper2" />
          <S.Wrapper3Img src={wrapper3} alt="wrapper3" />
          <S.Wrapper4Img src={wrapper4} alt="wrapper4" />
          <S.Wrapper5Img src={wrapper5} alt="wrapper5" /> */}
        </S.JackpotAnimationWrapper>
        <S.JackpotAvatarContainer>
          <S.JackpotBuddyContainer>
            <S.JackpotText variant="body1">Yearn {buddyText}</S.JackpotText>
            <S.JackpotText variant="h3"># {recentWinners[value].drawNumber}</S.JackpotText>
          </S.JackpotBuddyContainer>
          <S.JackpotBuddyImageContainer>
            <img src={buddyImg} alt="avatar" />
          </S.JackpotBuddyImageContainer>
        </S.JackpotAvatarContainer>
      </S.JackpotHeader>
      <S.FlipCounterMainContainer>
        <S.FlipCounterContainer
          className={isMyself(userId) ? `${className} its-you-text` : className}
          id={value === ONE_K_DRAW ? "jackpot-flip1" : value === TEN_K_DRAW ? "jackpot-flip2" : value === HUNDRED_K_DRAW ? "jackpot-flip3" : ""}
        >
          <FlipCounter flipNumber={winnerId} />
        </S.FlipCounterContainer>
      </S.FlipCounterMainContainer>
      {showFooter && (
        <S.JackpotFooter>
          <S.FooterSection1>
            <S.FooterText1>ROLLING FOR</S.FooterText1>
            <S.FooterTextGradient customStyle={value}>
              {value === ONE_K_DRAW ? ONEK_DRAW : value === TEN_K_DRAW ? TENK_DRAW : value === HUNDRED_K_DRAW ? HUNDREDK_DRAW : ""} : {slot}
            </S.FooterTextGradient>
          </S.FooterSection1>
          <S.FooterSection2>
            <ProgressWithCount>
              <CircularProgressWithLabelForJackpot
                value={value === ONE_K_DRAW ? GRADIENT1 : ""}
                percentage={getProgressPercentage(ticketsSold, draw1kNumber)}
                gradient={GRADIENT1}
                amount={oneKDrawWinningAmount}
              />
              <DrawCard CustomStyle={{ height: "20px", width: "72px", borderRadius: "4px", bottom: "-6px" }} fontSize="10px" drawCount={ONE_K} />
            </ProgressWithCount>
            <ProgressWithCount>
              <CircularProgressWithLabelForJackpot
                value={value === TEN_K_DRAW ? GRADIENT2 : ""}
                percentage={getProgressPercentage(ticketsSold, draw10kNumber)}
                gradient={GRADIENT2}
                amount={tenKDrawWinningAmount}
              />
              <DrawCard CustomStyle={{ height: "20px", width: "72px", borderRadius: "4px", bottom: "-6px" }} fontSize="10px" drawCount={TEN_K} />
            </ProgressWithCount>
            <ProgressWithCount>
              <CircularProgressWithLabelForJackpot
                value={value === HUNDRED_K_DRAW ? GRADIENT3 : ""}
                percentage={getProgressPercentage(ticketsSold, draw100kNumber)}
                gradient={GRADIENT3}
                amount={hundredKDrawWinningAmount}
              />
              <DrawCard CustomStyle={{ height: "20px", width: "72px", borderRadius: "4px", bottom: "-6px" }} fontSize="10px" drawCount={HUNDRED_K} />
            </ProgressWithCount>
          </S.FooterSection2>
          <S.FooterSection3>
            <S.ThreeDotsButton>
              <MoreVertIcon />
            </S.ThreeDotsButton>
          </S.FooterSection3>
        </S.JackpotFooter>
      )}
    </S.Jackpot>
  );
};

export default Jackpot;
