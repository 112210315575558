import { Dialog, styled, Box } from "@mui/material";
import { GRAD_COLOR_2, GRAD_COLOR_3 } from "../../constants";

namespace S {
  export const GradientContainer = styled(Box)(({ theme }) => ({
    width: "100%",
    height: "100%",
    top: 0,
    left: 0,
    position: "absolute",
    backgroundColor: "#00000080 !important",
    backdropFilter: "blur(1.5rem) !important",
    zIndex: "10",
  }));

  export const GradientModalContainer = styled(Dialog, { shouldForwardProp: (prop) => prop !== "isTimerModal" })(
    ({ isTimerModal }: { isTimerModal: boolean }) =>
      ({ theme }) => ({
        width: "100%",
        height: "100%",
        boxSizing: "border-box",
        "& .MuiPaper-root": {
          borderRadius: theme.spacing(4),
          overflowY: "unset !important",
          maxWidth: "calc(100vw - 2.5rem)",
          maxHeight: "calc(100vh - 2.5rem)",
          zIndex: 3,
        },

        ".css-1qxadfk-MuiPaper-root-MuiDialog-paper": {
          maxWidth: "calc(100vw - 2.5rem)",
          maxHeight: "calc(100vh - 2.5rem)",
          overflowY: "unset",
          zIndex: 3,
          backgroundColor: isTimerModal ? "transparent !important" : "rgba(0,0,0,0.6)",
          border: isTimerModal ? "none !important" : `3px solid ${theme.palette.custom.secondary.secondary_5}`,
          background: isTimerModal ? "transparent" : "",
          boxShadow: "none !important",
          [theme.breakpoints.down("md")]: {
            maxWidth: "calc(100vw - 1.875rem)",
          },
          [theme.breakpoints.down("sm")]: {
            maxWidth: "calc(100vw - 0.625rem)",
          },
        },

        ".css-qs36h5-MuiTypography-root-MuiDialogTitle-root": {
          padding: 0,
        },

        ".MuiBackdrop-root": {
          backgroundColor: "transparent !important",
        },

        ".css-sd33hq": {
          backgroundColor: isTimerModal ? "transparent !important" : "rgba(0,0,0,0.6)",
          border: isTimerModal ? "none !important" : `3px solid ${theme.palette.custom.secondary.secondary_5}`,
          background: isTimerModal ? "transparent" : "",
          boxShadow: "none !important",
          maxWidth: isTimerModal ? "unset !important" : "auto",
        },
        [theme.breakpoints.down("md")]: {
          ".MuiDialog-container": {
            overflowY: "auto",
            overflowX: "hidden",
          },
        },
      }),
  );

  export const GradientBlurContainer = styled(Box)(() => ({
    width: "110vw",
    height: "110vw",
    position: "absolute",
    top: 0,
    left: 0,
    transform: "translate(0%,-20%) rotate(0deg)",
    filter: "blur(1.5rem)",
    animation: "blurComponent 20s ease-in-out infinite",
    zIndex: 5,
    "@keyframes blurComponent": {
      to: {
        transform: "translate(0%,-20%) rotate(-360deg) ",
      },
    },
  }));

  export const Gradient1 = styled(Box)(() => ({
    background: GRAD_COLOR_2,
    width: "150px",
    height: "150px",
    borderRadius: "50%",
    position: "absolute",
    top: "10%",
    left: "10%",
    opacity: "0.5",
    animation: "blurGradient1 4s linear infinite",
    "@keyframes blurGradient1": {
      to: {
        width: "250px",
        height: "250px",
        top: "80%",
        left: "50%",
      },
    },
  }));

  export const Gradient2 = styled(Box)(() => ({
    background: GRAD_COLOR_3,
    width: "300px",
    height: "300px",
    borderRadius: "50%",
    position: "absolute",
    bottom: "10%",
    right: "10%",
    animation: "blurGradient2 4s linear infinite",
    "@keyframes blurGradient2": {
      to: {
        width: "250px",
        height: "250px",
        bottom: "80%",
        right: "70%",
      },
    },
  }));

  export const Gradient3 = styled(Box)(() => ({
    background: GRAD_COLOR_3,
    width: "200px",
    height: "200px",
    borderRadius: "50%",
    position: "absolute",
    top: "20%",
    right: "10%",
    opacity: "0.5",
    animation: "blurGradient3 4s linear infinite",
    "@keyframes blurGradient3": {
      to: {
        width: "350px",
        height: "350px",
        right: "80%",
        top: "70%",
      },
    },
  }));

  export const Gradient4 = styled(Box)(() => ({
    background: GRAD_COLOR_2,
    width: "250px",
    height: "250px",
    borderRadius: "50%",
    position: "absolute",
    bottom: "10%",
    left: "10%",
    opacity: "0.5",
    animation: "blurGradient4 4s linear infinite",
    "@keyframes blurGradient4": {
      to: {
        width: "350px",
        height: "350px",
        left: "80%",
        bottom: "70%",
      },
    },
  }));
}

export default S;
