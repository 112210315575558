import { CSSProperties } from "react";
import { Box, Dialog, styled } from "@mui/material";

namespace S {
  export const ModalContainer = styled(Dialog)(({ theme }) => ({
    ".MuiPaper-root": {
      padding: "0.5rem",
      borderRadius: "0.625rem",
      overflow: "hidden",
    },
    ".buy-modal-container": {
      [theme.breakpoints.down("sm")]: {
        overflowY: "auto",
        justifyContent: "flex-start",
      },
    },
  }));

  export const GradientContainer = styled(Box)(({ theme }) => () => ({
    width: "100%",
    height: "100%",
    position: "absolute",
    overflow: "hidden",
    left: 0,
    top: 0,
    [theme.breakpoints.down("sm")]: {
      margin: "0 -5px 0 -2px",
    },
    "::after": {
      content: `""`,
      position: "absolute",
      zIndex: 1,
      width: "1000px",
      height: "1000px",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      background: "linear-gradient(0deg, #EB00FF 37%, #E9B80C 80%)",
      animation: "gradientOuterRotate 2s infinite linear",
      "@keyframes gradientOuterRotate": {
        "0%": {
          transform: "translate(-50%, -50%) rotate(0deg)",
        },
        "100%": {
          transform: "translate(-50%, -50%) rotate(360deg)",
        },
      },
    },
  }));

  export const ModalContentContainer = styled(Box, {
    shouldForwardProp: (prop) => prop !== "customStyle",
  })(({ customStyle }: { customStyle?: CSSProperties }) => ({ theme }) => ({
    width: "auto",
    height: "100%",
    position: "relative",
    zIndex: 2,
    borderRadius: "0.5rem",
    background: theme.palette.custom.secondary.secondary_3,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    ...customStyle,
  }));
}

export default S;
