import { FC } from "react";
import { S } from "./loader.styled";

const DotLoader: FC<{ isNormal?: boolean }> = ({ isNormal }) => {
  return (
    <S.DotLoader isNormal={isNormal}>
      <span></span>
      <span></span>
      <span></span>
    </S.DotLoader>
  );
};

export default DotLoader;
