import { styled, Box } from "@mui/material";

namespace S {
  export const SkeletonContainer = styled(Box)(({ theme }) => ({
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
    borderRadius: "0.625rem",
    minHeight: "10.25rem",
    maxHeight: "10.25rem",
    [theme.breakpoints.down("sm")]: {
      minHeight: "fit-content",
      maxHeight: "fit-content",
    },
  }));

  export const SkeletonHeader = styled(Box)(() => ({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  }));

  export const SkeletonBody = styled(Box)(() => ({
    display: "flex",
    justifyContent: "space-between",
  }));

  export const SkeletonBodyLeft = styled(Box)(() => ({
    display: "flex",
    flexDirection: "column",
    width: "50%",
    gap: "0.5rem",
  }));

  export const SkeletonBodyRight = styled(Box)(() => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    width: "50%",
  }));
}

export default S;
