import { Box, styled } from "@mui/material";

namespace S {
  export const SkeletonContainer = styled(Box)(({ theme }) => ({
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "13.875rem",
    background: "#2D2C3166",
    [theme.breakpoints.down("sm")]: {
      height: "auto",
    },
  }));

  export const TopContainer = styled(Box)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    flexDirection: "column",
    width: "calc(100% - 2rem)",
    height: "8.5313rem",
    padding: "0 1rem",
    gap: "0.5rem",
    borderBottom: "0.0625rem solid #2D2C31",
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "2.8125rem",
      paddingTop: "0.625rem",
    },
  }));

  export const BottomContainer = styled(Box)(({ theme }) => ({
    boxSizing: "border-box",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "1.75rem 1rem",
  }));
}

export default S;
