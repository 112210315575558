import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DisabledByDefaultRoundedIcon from "@mui/icons-material/DisabledByDefaultRounded";
import SettingsOverscanIcon from "@mui/icons-material/SettingsOverscan";
import { Box } from "@mui/material";
import S from "./play-game.styled";
import Skillgame from "../../../assets/images/skillgaming.png";
import { setGameUrl } from "../../../store/slices/user";
import { useGameUrl } from "../../../utils/hooks/selector";
import PlayGameSkeleton from "../../../utils/widgets/skeletons/play-game-skeleton";

const PlayGame = () => {
  //constants
  const [Enlarge, setEnlarge] = useState(false);
  const GameUrl = useSelector(useGameUrl);
  const dispatch = useDispatch();
  return (
    <S.GameBlurContainer enlarge={Enlarge} data-tut="tour-game-play">
      <S.GameContainer enlarge={Enlarge}>
        <S.HeadContainer>
          <Box>
            <img src={Skillgame} alt="skill game" />
          </Box>
          <S.ExpandIconBox>
            <S.GradientText shineText={"#play2yearn"}>#play2yearn</S.GradientText>
            {GameUrl && <SettingsOverscanIcon sx={{ cursor: "pointer" }} onClick={() => GameUrl && setEnlarge((p) => !p)} />}
            {/* {GameUrl && (
              <DisabledByDefaultRoundedIcon
                sx={{
                  cursor: "pointer",
                  "& path": {
                    color: "#EF3636",
                  },
                }}
                onClick={() => {
                  dispatch(setGameUrl(""));
                  setEnlarge(false);
                }}
              />
            )} */}
          </S.ExpandIconBox>
        </S.HeadContainer>
        <S.bodyContainer>
          <S.GameIframe
            src={GameUrl}
            style={{ height: "100%", width: "100%" }}
            name="skillgaming"
            scrolling="no"
            frameBorder={0}
            allowFullScreen
          ></S.GameIframe>
        </S.bodyContainer>
      </S.GameContainer>
    </S.GameBlurContainer>
  );
};

export default PlayGame;
