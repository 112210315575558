import { Stack, styled, Typography } from "@mui/material";


namespace S {
  export const Container = styled(Stack)(({ theme }) => ({
    boxSizing: "border-box",
    height: "25.6rem",
    maxWidth: "38.75rem",
    overflowY: "auto",
    gap: theme.spacing(4),
    padding: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
      minWidth: "calc(100vw - 1.875rem)",
    },
  }));

  export const ContentHeaderText = styled(Typography)(() => ({
    fontSize: "1rem",
    fontWeight: "600",
  }));

  export const ExampleText = styled(Typography)(() => ({
    background: "radial-gradient(72.13% 132.54% at 27.87% 38.89%, #24FF00 0%, #FEE600 100%)",
    webkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    backgroundClip: "text",
  }));
}

export default S;