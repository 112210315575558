import { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { HUNDRED_K_DRAW, ONE_K_DRAW, TEN_K_DRAW } from "../../../utils/constants";
import { getChecksumAddress } from "../../../utils/helpers";
import { useHundredKWinner, useOneKDrawWinners, useTenKDrawWinners } from "../../../utils/hooks/selector";
import { UserSlicetypes } from "../../../utils/types/state";
import { CustomModal, ModalBody, ModalFooter, ModalHeader } from "../../../utils/widgets/custom-modal";
// import Navigators from "../../utils/widgets/navigators";
import DrawWinnersTable from "./draw-winners-body/DrawWinnersTable";
import DrawWinnersFooter from "./draw-winners-footer";
import DrawWinnersHeader from "./draw-winners-header";

type DrawWinnerModalType = {
  open: boolean;
  handleClose: () => void;
};

export const OneKDrawWinnersModal: FC<DrawWinnerModalType> = ({ open, handleClose }) => {
  //state values
  // const [drawPage, setDrawPage] = useState(1);
  const oneKDrawWinners = useSelector(useOneKDrawWinners);
  const [searchValue, setSearchValue] = useState("");
  const [filteredDrawWinners, setFilteredDrawWinners] = useState<UserSlicetypes.IUsers[]>([]);

  //functions
  const handleSearchFilter = (e: string) => {
    setSearchValue(e);
  };

  useEffect(() => {
    let filterDrawWinners = oneKDrawWinners.filter((item) => getChecksumAddress(item.address).includes(searchValue));
    setFilteredDrawWinners(filterDrawWinners);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  return (
    <CustomModal open={open} onClose={handleClose}>
      <ModalHeader handleClose={handleClose}>
        <DrawWinnersHeader draw={1} searchValue={searchValue} handleSearchFilter={handleSearchFilter} />
      </ModalHeader>
      <ModalBody>
        <DrawWinnersTable winnersList={filteredDrawWinners} searchValue={searchValue} draw={ONE_K_DRAW} />
      </ModalBody>
      <ModalFooter>
        <DrawWinnersFooter>{/* <Navigators totalPages={5} currentPage={drawPage} setCurrentPage={setDrawPage} /> */}</DrawWinnersFooter>
      </ModalFooter>
    </CustomModal>
  );
};

export const TenKDrawWinnersModal: FC<DrawWinnerModalType> = ({ open, handleClose }) => {
  // state values
  // const [drawPage, setDrawPage] = useState(1);
  const tenKDrawWinners = useSelector(useTenKDrawWinners);
  const [searchValue, setSearchValue] = useState("");
  const [filteredDrawWinners, setFilteredDrawWinners] = useState<UserSlicetypes.IUsers[]>([]);

  //functions
  const handleSearchFilter = (e: string) => {
    setSearchValue(e);
  };

  useEffect(() => {
    let filterDrawWinners = tenKDrawWinners.filter((item) => getChecksumAddress(item.address).includes(searchValue));
    setFilteredDrawWinners(filterDrawWinners);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  return (
    <CustomModal open={open} onClose={handleClose}>
      <ModalHeader handleClose={handleClose}>
        <DrawWinnersHeader draw={10} searchValue={searchValue} handleSearchFilter={handleSearchFilter} />
      </ModalHeader>
      <ModalBody>
        <DrawWinnersTable winnersList={filteredDrawWinners} searchValue={searchValue} draw={TEN_K_DRAW} />
      </ModalBody>
      <ModalFooter>
        <DrawWinnersFooter>{/* <Navigators totalPages={5} currentPage={drawPage} setCurrentPage={setDrawPage} /> */}</DrawWinnersFooter>
      </ModalFooter>
    </CustomModal>
  );
};

export const HundredKDrawWinnersModal: FC<DrawWinnerModalType> = ({ open, handleClose }) => {
  // state values
  const hundredKWinner = useSelector(useHundredKWinner);
  const [winnerdetails, setWinnerDetails] = useState<UserSlicetypes.IUsers[]>([]);

  useEffect(() => {
    if (hundredKWinner.userId) {
      const data = {
        dp: hundredKWinner.dp,
        drawNumber: hundredKWinner.drawNumber,
        id: hundredKWinner.id,
        userId: hundredKWinner.userId,
        ticketId: hundredKWinner.ticketNumber,
        amount: "",
        address: hundredKWinner.address,
        blockTimestamp: hundredKWinner.blockTimestamp,
        transactionHash: hundredKWinner.transactionHash,
      };
      setWinnerDetails([data]);
    }
  }, [hundredKWinner]);

  return (
    <CustomModal open={open} onClose={handleClose}>
      <ModalHeader handleClose={handleClose}>
        <DrawWinnersHeader draw={100} searchValue={""} handleSearchFilter={() => {}} />
      </ModalHeader>
      <ModalBody>
        <DrawWinnersTable winnersList={winnerdetails} searchValue={""} draw={HUNDRED_K_DRAW} />
      </ModalBody>
      <ModalFooter>
        <DrawWinnersFooter>{/* <Navigators totalPages={5} currentPage={drawPage} setCurrentPage={setDrawPage} /> */}</DrawWinnersFooter>
      </ModalFooter>
    </CustomModal>
  );
};
