import { Stack, styled, Typography } from "@mui/material";

namespace S {
  export const HeaderContainer = styled(Stack)(({ theme }) => ({
    boxSizing: "border-box",
    maxWidth: "36.25rem",
    gap: theme.spacing(4),
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
    borderBottom: `0.0625rem solid ${theme.palette.custom.strokes.primary}`,
    [theme.breakpoints.down("sm")]: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
  }));

  export const HeaderText1 = styled(Typography)(({ theme }) => ({
    fontSize: "1.375rem",
    fontWeight: "500",
    width: "fit-content",
    lineHeight: "0.8",
    [theme.breakpoints.down("md")]: {
      fontSize: "1.125rem",
    },
  }));

  export const HeaderText2 = styled(Typography)(({ theme }) => ({
    lineHeight: "0.8",
    display: "flex",
    gap: theme.spacing(2.5),
    flexDirection: "column",
    fontSize: "3.5rem",
    fontWeight: "600",
    width: "fit-content",
    [theme.breakpoints.down("md")]: {
      fontSize: "2rem",
    },
  }));

  export const HeaderTextUnderLine = styled("span")(({ theme }) => ({
    height: "0.375rem",
    borderRadius: "1.25rem",
    backgroundColor: theme.palette.custom.secondary.secondary_1,
  }));
}

export default S;
