import { Stack, styled } from "@mui/material";

namespace S {
  export const RightSidePanelWrapper = styled(Stack, {
    shouldForwardProp: (prop) => prop !== "panelView",
  })(({ panelView }: { panelView: boolean }) => ({ theme }) => ({
    gap: theme.spacing(6),
    maxWidth: "23.75rem",
    minWidth: "23.75rem",
    boxSizing: "border-box",
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(8),
      paddingTop: "0",
      overflowY: "auto",
      backgroundColor: theme.palette.custom.bg.app,
      position: "absolute",
      width: "100%",
      maxWidth: "29.0625rem",
      top: "5rem",
      right: "0",
      borderTopLeftRadius: "0.5rem",
      borderBottomLeftRadius: "0.5rem",
      height: "calc(100% - 5rem)",
      alignContent: "start",
      zIndex: "10",
      transition: "transform 0.5s ease-out",
      transform: panelView ? "translateX(0)" : "translateX(calc(29.0625rem))",
      "& > div: nth-of-type(2)": {
        overflow: "unset !important",
      },
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  }));
}

export default S;
