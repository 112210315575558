import { RootState } from "../../store";

//user
export const useActivePool = (s: RootState) => s.user.activePool;
export const useAddingYTG = (s: RootState) => s.user.addingYTG;
export const useAnnouncedWinner = (s: RootState) => s.user.announcedWinner;
export const useAppStatus = (s: RootState) => s.user.appStatus;
export const useClaimableAmount = (s: RootState) => s.user.claimableAmount;
export const useClaimableToken = (s: RootState) => s.user.claimableToken;
export const useConnector = (s: RootState) => s.user.connector;
export const useCurrentDraw = (s: RootState) => s.user.currentDraw;
export const useCurrentTenKDraw = (s: RootState) => s.user.currentTenKDraw;
export const useDashboardPool = (s: RootState) => s.user.dashboardPool;
export const useDistributedAmounts = (s: RootState) => s.user.distributedAmounts;
export const useDrawStart = (s: RootState) => s.user.drawStart;
export const useEnteredDraw = (s: RootState) => s.user.enteredDraw;
export const useEvents = (s: RootState) => s.user.events;
export const useFreeUserReferralAmountByLevel = (s: RootState) => s.user.freeUserReferralAmountByLevel;
export const useHasParticipant = (s: RootState) => s.user.hasParticipant;
export const useHundredKWinner = (s: RootState) => s.user.hundredKWinner;
export const useInvitedBy = (s: RootState) => s.user.invitedBy;
export const useIsAppLoading = (s: RootState) => s.user.isAppLoading;
export const useIsBuying = (s: RootState) => s.user.isBuying;
export const useIsFetching100KPredictionWinners = (s: RootState) => s.user.isFetching100KPredictionWinners;
export const useIsFetching1KPredictionWinners = (s: RootState) => s.user.isFetching1KPredictionWinners;
export const useIsFetchingEarnings = (s: RootState) => s.user.isFetchingEarnings;
export const useIsFetchingPredicted = (s: RootState) => s.user.isFetchingPredicted;
export const useIsFetchingReferrerAddress = (s: RootState) => s.user.isFetchingReferrerAddress;
export const useIsFetchingReferrerId = (s: RootState) => s.user.isFetchingReferrerId;
export const useIsIdle = (s: RootState) => s.user.isIdle;
export const useIsInitiatingEventstream = (s: RootState) => s.user.isInitiatingEventstream;
export const useIsPredictionSucceed = (s: RootState) => s.user.isPredictionSucceed;
export const useIsReady = (s: RootState) => s.user.isReady;
export const useIsRedeeming = (s: RootState) => s.user.isRedeeming;
export const useIsRequestingHundredKReferrers = (s: RootState) => s.user.isRequestingHundredKReferrers;
export const useIsRequestingMyPredictions = (s: RootState) => s.user.isRequestingMyPredictions;
export const useIsRequestingOneKReferrers = (s: RootState) => s.user.isRequestingOneKReferrers;
export const useIsSomethingWrong = (s: RootState) => s.user.isSomethingWrong;
export const useIsWhitelisted = (s: RootState) => s.user.isWhitelisted;
export const useIsWhitelistLoading = (s: RootState) => s.user.whitelistLoading;
export const useIsWithdrawingPredictTokens = (s: RootState) => s.user.isWithdrawingPredictTokens;
export const useIsWithdrawingTokens = (s: RootState) => s.user.isWithdrawingTokens;
export const useMyEarnings = (s: RootState) => s.user.myEarnings;
export const useMyPredictions = (s: RootState) => s.user.myPredictions;
export const useNextAvailableAt = (s: RootState) => s.user.nextAvailableAt;
export const useOneKDrawWinners = (s: RootState) => s.user.oneKDrawWinners;
export const usePaidUserReferralAmountByLevel = (s: RootState) => s.user.paidUserReferralAmountByLevel;
export const useParticipantTxnHash = (s: RootState) => s.user.participantTxnHash;
export const usePoolInfo = (s: RootState) => s.user.poolInfo;
export const usePoolParticipants = (s: RootState) => s.user.poolParticipants;
export const usePredictGameToken = (s: RootState) => s.user.predictGameToken;
export const usePrediction = (s: RootState) => s.user.prediction;
export const usePrediction100KWinners = (s: RootState) => s.user.prediction100KWinners;
export const usePredictionAdminApproval = (s: RootState) => s.user.predictionAdminApproval;
export const usePredictionLoader = (s: RootState) => s.user.predictionLoader;
export const usePredictionWinners = (s: RootState) => s.user.predictionWinners;
export const useReferrers = (s: RootState) => s.user.referrers;
export const useShowTour = (s: RootState) => s.user.showTour;
export const useTenKDrawWinners = (s: RootState) => s.user.tenKDrawWinners;
export const useTicketsSold = (s: RootState) => s.user.ticketsSold;
export const useTopReferrers100K = (s: RootState) => s.user.topReferrers100K;
export const useTopReferrersOneK = (s: RootState) => s.user.topReferrersOneK;
export const useUserDetails = (s: RootState) => s.user.userDetails;
export const useUserTickets = (s: RootState) => s.user.userTickets;
export const useWalletBalance = (s: RootState) => s.user.walletBalance;
export const useWinnerOverlay = (s: RootState) => s.user.winnerOverlay;
export const useWinningTimer = (s: RootState) => s.user.winningTimer;
export const useGameUrl = (s: RootState) => s.user.gameUrl;
export const useConfetti = (s: RootState) => s.user.confetti;
export const useSelectedRound = (s: RootState) => s.user.selectedRound;

//draw
export const useDraw100kNumber = (s: RootState) => s.draw.draw100kNumber;
export const useDraw10kNumber = (s: RootState) => s.draw.draw10kNumber;
export const useDraw1kNumber = (s: RootState) => s.draw.draw1kNumber;
export const useDrawAmount = (s: RootState) => s.draw.drawAmount;
export const useDrawFee = (s: RootState) => s.draw.drawFee;
export const useIsDrawReady = (s: RootState) => s.draw.isDrawReady;
export const useMaxDraws = (s: RootState) => s.draw.maxDraws;
export const useMaxTickets = (s: RootState) => s.draw.maxTickets;

// stake
export const useMyStakes = (s: RootState) => s.stake.myStakes;
export const useMyRewards = (s: RootState) => s.stake.myRewards;
export const useIsStaking = (s: RootState) => s.stake.isStaking;
export const useIsWithdrawing = (s: RootState) => s.stake.isWithdrawing;
export const useStakeTransactionHash = (s: RootState) => s.stake.stakeTransactionHash;
export const useWithdrawTransactionHash = (s: RootState) => s.stake.withdrawTransactionHash;
export const useStakeClaimableAmount = (s: RootState) => s.stake.stakeClaimAmount;
export const useIsClaiming = (s: RootState) => s.stake.isClaiming;
export const useLastClaimedTime = (s: RootState) => s.stake.lastClaimedTime;

// match
export const useIsPredicting = (s: RootState) => s.match.isPredictingPool;
export const useIsWithDrawingFund = (s: RootState) => s.match.isWithDrawingFund;
export const useIsFetchingPoolEndTime = (s: RootState) => s.match.isFetchingPoolEndTime;
export const usePoolEndTime = (s: RootState) => s.match.poolEndTime;
export const useIsFetchingPoolTotalAmount = (s: RootState) => s.match.isFetchingPoolTotalAmount;
export const usePoolATotal = (s: RootState) => s.match.poolATotal;
export const usePoolBTotall = (s: RootState) => s.match.poolBTotal;
export const useMinAmount = (s: RootState) => s.match.minAmount;
export const useMaxAmount = (s: RootState) => s.match.maxAmount;
export const useIsRedeemRewardLoading = (s: RootState) => s.match.isRedeemRewardLoading;
