import ContentCopyTwoToneIcon from "@mui/icons-material/ContentCopyTwoTone";
import ReplyIcon from "@mui/icons-material/Reply";
import { styled, Input, Box } from "@mui/material";

namespace S {
  export const InputContainer = styled(Box)(({ theme }) => ({
    display: "flex",
    justifyContent: "space-between",
    gap: theme.spacing(2),
    minWidth: "27rem",
    minHeight: "2.75rem",
    [theme.breakpoints.down("sm")]: {
      minWidth: "15rem",
    },
  }));

  export const InputWithIcon = styled(Input)(({ theme }) => ({
    background: theme.palette.custom.strokes.primary,
    borderRadius: "0.5rem",
    minHeight: "2.75rem",
    width: "23.4375rem",
    paddingLeft: "1rem",
    input: {
      cursor: "pointer",
    },
  }));

  export const CustomContentCopyTwoToneIcon = styled(ContentCopyTwoToneIcon)(() => ({
    fontSize: "1.25rem",
  }));

  export const CustomReplyIcon = styled(ReplyIcon)(() => ({
    transform: "scaleX(-1)",
    fontSize: "1.5rem",
  }));
}

export default S;
