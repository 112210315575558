import { CSSProperties, FC, useEffect, useState } from "react";
import ConfettiExplosion from "react-confetti-explosion";
import { setConfetti } from "../../../store/slices/user";
import { useAppDispatch } from "../../hooks/state";

const containerStyle: CSSProperties = {
  zIndex: "10",
  position: "fixed",
  right: 0,
  left: 0,
  bottom: 0,
  top: 0,
  pointerEvents: "none",
};

const ExplodeProps = {
  particleSize: 7,
  force: 0.8,
  particleCount: 100,
  floorHeight: 1600,
  floorWidth: 1600,
  zIndex: 10,
};

const ConfettiExplode: FC<{ open: boolean }> = ({ open }) => {
  const dispatch = useAppDispatch();
  const [positions, setPositions] = useState<any>([]);

  useEffect(() => {
    if (open) {
      setTimeout(() => {
        dispatch(setConfetti(false));
      }, 3000);
    }
  }, [open]);

  useEffect(() => {
    // Generate three random positions for confetti explosions
    const newPositions = [];
    for (let i = 0; i < 4; i++) {
      const top = Math.random() * 100;
      const left = Math.random() * 100;
      newPositions.push({ top, left });
    }
    setPositions(newPositions);
  }, [open]);

  return (
    <>
      {open && (
        <div style={containerStyle}>
          {positions.map((position: any, index: any) => (
            <div
              key={index}
              style={{
                position: "absolute",
                top: `${position.top}%`,
                left: `${position.left}%`,
                transform: `translate(${position.top}%,${position.left}%)`,
              }}
            >
              <ConfettiExplosion onComplete={() => dispatch(setConfetti(false))} duration={4000} {...ExplodeProps} />
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default ConfettiExplode;
