import { FC } from "react";
import S from "./winner-header.styled";
import SEARCH_ICON from "../../../../assets/images/search.svg";
import { oneKDrawWinningAmount, tenKDrawWinningAmount, hundredKDrawWinningAmount, ONE, TEN, HUNDRED } from "../../../../utils/constants";
import SearchBar from "../../../../utils/widgets/search-bar";

type DrawWinnersHeaderType = {
  draw?: number;
  handleSearchFilter: (e: string) => void;
  searchValue: string;
};

const DrawWinnersHeader: FC<DrawWinnersHeaderType> = ({ draw, handleSearchFilter, searchValue }) => {
  return (
    <S.HeaderContainer>
      <S.HeaderContainerLeft>
        <S.HeaderText1>WINNERS OF</S.HeaderText1>
        <S.HeaderText2 draw={Number(draw)}>
          {/* {draw}K DRAW */}
          Lucky Round {draw === 1 ? 1 : draw === 10 ? 2 : 3}
          <S.HeaderTextUnderLine draw={Number(draw)} />
        </S.HeaderText2>
      </S.HeaderContainerLeft>
      <S.HeaderContainerMiddle>
        <S.HeaderText1>WINNERS GET</S.HeaderText1>
        <S.HeaderText2 draw={Number(draw)}>
          {draw === Number(ONE) ? `$${oneKDrawWinningAmount}` : draw === Number(TEN) ? `$${tenKDrawWinningAmount}` : `$${hundredKDrawWinningAmount}`}
          <S.HeaderTextUnderLine draw={Number(draw)} />
        </S.HeaderText2>
      </S.HeaderContainerMiddle>
      <S.HeaderContainerRight direction={"row"}>
        {draw !== Number(HUNDRED) && (
          <S.SearchContainer>
            <S.SearchIcon src={SEARCH_ICON} alt="search-icon" />
            <SearchBar searchHandler={handleSearchFilter} value={searchValue} />
          </S.SearchContainer>
        )}
      </S.HeaderContainerRight>
    </S.HeaderContainer>
  );
};

export default DrawWinnersHeader;
