import { styled, Stack } from "@mui/material";

namespace S {
  export const FooterContainer = styled(Stack)(({ theme }) => ({
    boxSizing: "border-box",
    height: "4rem",
    flexDirection: "row",
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
    padding: theme.spacing(4),
    borderTop: `0.0625rem solid ${theme.palette.custom.strokes.primary}`,
  }));
}

export default S;
