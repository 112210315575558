import { Box } from "@mui/material";
import { styled, Stack, Typography } from "@mui/material";

namespace S {
  export const UserGuideCardContainer = styled(Stack)(({ theme }) => ({
    alignItems: "center",
    justifyContent: "center",
    gap: theme.spacing(4),
    width: "100%",
    height: "100%",
    ".MuiInputBase-root": {
      background: theme.palette.custom.secondary.secondary_1,
      color: theme.palette.custom.secondary.secondary_2,
      border: `1px solid ${theme.palette.custom.secondary.secondary_2}`,
    },
  }));

  export const UserGuideTitle = styled(Typography)(({ theme }) => ({
    color: "#000",
    fontWeight: 600,
    fontSize: "24px",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      fontSize: "20px",
    },
    margin: "0 3.5rem",
  }));

  export const UserGuideContent = styled(Typography)(({ theme }) => ({
    color: theme.palette.custom.secondary.secondary_2,
    ".special-text": {
      color: theme.palette.custom.secondary.secondary_2,
      fontWeight: 600,
    },

    [theme.breakpoints.down("sm")]: {
      fontSize: "0.875rem",
    },
  }));

  export const UserGuideUrl = styled("a")(() => ({
    textDecoration: "none",
    alignSelf: "center",
    height: "18.8px",
  }));

  export const UserGuidePointsWrapper = styled(Stack)(({ theme }) => ({
    width: "100%",
    alignItems: "center",
    gap: theme.spacing(3),
    marginTop: "2rem",
    height: "100%",
    maxHeight: "30vh",
    overflow: "auto",
    [theme.breakpoints.down("sm")]: {
      marginTop: "0",
    },
  }));

  export const UserGuidePointsContainer = styled(Box)(({ theme }) => ({
    width: "95%",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    gap: theme.spacing(2),
  }));

  export const PlayIconImage = styled("img")(() => ({
    width: "1.2rem",
    height: "auto",
  }));

  export const YearnGamePointsWrapper = styled(Box)(({ theme }) => ({
    position: "relative",
    width: "100%",
    height: "9vh",
  }));

  export const YearnGamePointsContainer = styled(Box)(({ theme }) => ({
    background: "linear-gradient(91.55deg, #1967FC 0%, #FC19BC 85%)",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: theme.spacing(2),
    position: "absolute",
    left: "-2rem",
    top: "0",
    padding: "0.5rem 2rem",
    [theme.breakpoints.down("sm")]: {
      left: "-1.5rem",
      padding: "0.5rem 1.5rem",
    },
  }));

  export const YearnGamesContent = styled(Typography)(({ theme }) => ({
    color: theme.palette.custom.secondary.secondary_1,
    fontSize: "1.125rem",
    fontWeight: "600",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1rem !important",
    },
  }));
}

export default S;
