import { Box, Typography, styled } from "@mui/material";
import { TextGradient, TransparentTextGradient } from "../../../utils/constants";

namespace S {
  export const GameBlurContainer = styled(Box, { shouldForwardProp: (prop) => prop !== "enlarge" })<{ enlarge: boolean }>(({ theme, enlarge }) => ({
    position: enlarge ? "fixed" : "unset",
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    zIndex: enlarge ? 4 : 0,
    backdropFilter: enlarge ? "blur(4px)" : "",
  }));

  export const GameContainer = styled(Box, { shouldForwardProp: (prop) => prop !== "enlarge" })<{ enlarge: boolean }>(({ theme, enlarge }) => ({
    padding: "1.25rem 0rem 1.25rem 0rem",
    boxSizing: "border-box",
    // width: "100%",
    height: enlarge ? "90%" : "27.25rem",
    maxHeight: enlarge ? "" : "30.4rem",
    borderRadius: "0.625rem",
    backgroundColor: theme.palette.custom.secondary.secondary_3,
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      height: enlarge ? "100%" : "27.25rem",
      top: enlarge ? 0 : "",
      left: enlarge ? "50%" : "",
      transform: enlarge ? "translate(-50%,0)" : "",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: enlarge ? "100%" : "27.25rem",
      top: enlarge ? 0 : "",
      left: enlarge ? "50%" : "",
      transform: enlarge ? "translate(-50%,0)" : "",
    },

    position: enlarge ? "absolute" : "unset",
    top: "62%",
    left: "50%",
    transform: enlarge ? "translate(-50%,-60%)" : "",
    zIndex: enlarge ? 5 : 0,
    width: enlarge ? "70%" : "",
    transition: "height 0.3s ease",
  }));

  export const HeadContainer = styled(Box)(({ theme }) => ({
    display: "flex",
    justifyContent: "space-between",
    gap: "2rem",
    padding: "0rem 1.25rem 1rem 1.25rem",
  }));

  export const ExpandIconBox = styled(Box)(({ theme }) => ({
    display: "flex",
    gap: "10px",
  }));

  export const bodyContainer = styled(Box)(({ theme }) => ({
    display: "flex",
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
    paddingRight: "8px",
    boxSizing: "border-box",
  }));

  export const GameIframe = styled("iframe")(({ theme }) => ({
    width: "100%",
    height: "100%",
    "& body": {
      margin: 0,
    },
  }));

  export const GradientText = styled(Typography, { shouldForwardProp: (prop) => prop !== "shineText" })<{ shineText: string }>(
    ({ theme, shineText }) => ({
      background: TextGradient,
      webkitBackgroundClip: "text",
      WebkitTextFillColor: "transparent",
      backgroundClip: "text",
      backgroundSize: "200px",
      // fontWeight: 500,
      // fontSize: "14px",
      // lineHeight: "24.15px",
      fontSize: "1rem !important",
      fontWeight: "bold !important",
      color: TextGradient,
      textShadow: `0 0px 0px ${TextGradient}`,
      position: "relative",
      ":after": {
        content: `"${shineText}"`,
        // minWidth: "200px",
        background: TransparentTextGradient,
        webkitBackgroundClip: "text",
        WebkitTextFillColor: "transparent",
        backgroundClip: "text",
        position: "absolute",
        top: 0,
        left: 0,
        transform: "translate(0,0)",
        zIndex: 3,
        animation: "pool-shine 20s linear infinite",
        "@keyframes pool-shine": {
          "0%": {
            backgroundPosition: "-300px",
          },
          "100%": {
            backgroundPosition: "300px",
          },
        },
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: "14px !important",
      },
    }),
  );
}

export default S;
