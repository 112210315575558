import { Stack, styled, Box } from "@mui/material";

namespace S {
  export const FooterContainer = styled(Stack)(({ theme }) => ({
    boxSizing: "border-box",
    maxWidth: "36.25rem",
    height: "4rem",
    flexDirection: "row",
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    borderTop: `1px solid ${theme.palette.custom.strokes.primary}`,
    [theme.breakpoints.down("sm")]: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
  }));

  export const FooterContainerLeft = styled(Stack)(({ theme }) => ({
    maxWidth: "50%",
    width: "50%",
    gap: theme.spacing(1.1),

    flexDirection: "row",
    alignItems: "center",
  }));

  export const FooterContainerRight = styled(Stack)(({ theme }) => ({
    maxWidth: "50%",
    width: "50%",
    gap: theme.spacing(4),
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
  }));

  export const FooterDot = styled(Box, {
    shouldForwardProp: (prop) => prop !== "currentPage",
  })(({ currentPage }: { currentPage: boolean }) => ({ theme }) => ({
    width: currentPage ? "1.875rem" : "0.5rem",
    height: "0.5rem",
    borderRadius: currentPage ? "0.375rem" : "50%",
    transitionDuration: "0.2s",
    backgroundColor: currentPage ? theme.palette.custom.primary.main : theme.palette.custom.secondary.secondary_1,
  }));
}

export default S;
