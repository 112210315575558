import { FC } from "react";
import { circularProgressClasses, Box } from "@mui/material";
import S from "./progress-bar.styled";
import { GRADIENT1, GRADIENT2, GRADIENT3, YEARN_BUDDY, YEARN_CHAMP, YEARN_FAM } from "../../constants";
import { useGetDimensions } from "../../helpers";
import { gradient, progressBarProps } from "../../types/progress-bar";
import GradientSVG from "../gradients/gradient1";
import GradientSVG2 from "../gradients/gradient2";
import GradientSVG3 from "../gradients/gradient3";

const CircularProgressWithLabel: FC<progressBarProps> = ({ gradient, percentage, amount }) => {
  //constants
  const isMd = useGetDimensions("md");
  const isSm = useGetDimensions();

  //functions
  const getGradient = (gradient: gradient) => {
    switch (gradient) {
      case GRADIENT1: {
        return <GradientSVG />;
      }
      case GRADIENT2: {
        return <GradientSVG2 />;
      }
      case GRADIENT3: {
        return <GradientSVG3 />;
      }
      default:
        return <GradientSVG />;
    }
  };

  const getLabelImage = (gradient: gradient) => {
    switch (gradient) {
      case GRADIENT1: {
        return YEARN_BUDDY;
      }
      case GRADIENT2: {
        return YEARN_CHAMP;
      }
      case GRADIENT3: {
        return YEARN_FAM;
      }
      default:
        return YEARN_BUDDY;
    }
  };

  return (
    <S.CircularProgressWrapper>
      <Box sx={{ position: "relative" }}>
        <S.StaticCircularProgressBar variant="determinate" thickness={4} value={100} size={isSm ? 90 : isMd ? 110 : 135} />
        {getGradient(gradient)}
        <S.CustomCircularProgressBar
          variant="determinate"
          value={percentage}
          sx={{
            [`& .${circularProgressClasses.circle}`]: {
              stroke: `url(#${gradient})`,
              strokeLinecap: "round",
            },
          }}
          thickness={4}
          size={isSm ? 90 : isMd ? 110 : 135}
        />
      </Box>
      <S.LabelContentWrapper>
        <img src={getLabelImage(gradient)} alt="cash pocket" />
        <S.LabelContent gradient={gradient}>${amount}</S.LabelContent>
      </S.LabelContentWrapper>
    </S.CircularProgressWrapper>
  );
};

export default CircularProgressWithLabel;
