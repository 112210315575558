import { styled, Box, Skeleton } from "@mui/material";

namespace S {
  export const SkeletonContainer = styled(Box)(({ theme }) => ({
    minHeight: "12.5rem",
    maxHeight: "12.5rem",
    padding: "1.25rem",
    borderRadius: "0.625rem",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    background: "#2d2c31",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      maxHeight: "unset",
    },
  }));

  export const ContentContainer = styled(Box)(({ theme }) => ({
    minHeight: "12.5rem",
    maxHeight: "12.5rem",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  }));

  export const StakeHeading = styled(Box)(({ theme }) => ({
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  }));

  export const HeadingLeft = styled(Box)(({ theme }) => ({
    width: "50%",
  }));

  export const HeadingRight = styled(Box)(({ theme }) => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "20px",
    width: "50%",
  }));

  export const BodyContainer = styled(Box)(({ theme }) => ({
    height: "100%",
    display: "flex",
  }));

  export const LeftBodyContainer = styled(Box)(({ theme }) => ({
    width: "50%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    gap: "20px",
  }));

  export const TopBox = styled(Box)(({ theme }) => ({
    width: "100%",
  }));

  export const BottomBox = styled(Box)(({ theme }) => ({
    width: "100%",
  }));

  export const RightBodyContainer = styled(Box)(({ theme }) => ({
    width: "50%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "3px",
  }));
}

export default S;
