import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FREE_USER, METAMASK, TICKETS_PER_ROUND } from "../../utils/constants";
import { getInvitedBy } from "../../utils/helpers";
import { PredictType, Round } from "../../utils/types";
import { UserSlicetypes } from "../../utils/types/state";
import { ONE_K_DRAW } from "./../../utils/constants/index";

const initialState: UserSlicetypes.initialState = {
  userDetails: {
    id: "",
    address: "",
    balance: "0",
    isFreeUser: false,
    participatedOneKDrawNumber: "0",
  },
  yearnBalance: "0",
  participantTxnHash: "",
  userTickets: {
    ticketNumbers: [],
    includeWinners: [],
  },
  enteredDraw: false,
  hasParticipant: false,
  drawStart: false,
  showTour: false,
  isPredictionSucceed: {
    type: "",
    draw: "",
    prediction: "",
    txnHash: "",
  },
  invitedBy: null,
  currentDraw: "0",
  currentTenKDraw: "0",
  ticketsSold: 0,
  selectedRound: Round.roundOne,
  oneKDrawWinners: [],
  tenKDrawWinners: [],
  hundredKWinner: { id: "", userId: "", drawNumber: 0, ticketNumber: "", blockTimestamp: 0, transactionHash: "", dp: "", address: "" },
  isBuying: { loading: false, close: true },
  isInitiatingEventstream: true,
  winningTimer: { hours: "00", minutes: "06", seconds: "00" },
  referrers: {
    "1": [],
    "2": [],
    "3": [],
    "4": [],
    "5": [],
  },
  walletBalance: 0,
  isAppLoading: false,
  topReferrers100K: [],
  topReferrersOneK: { "1": [] },
  isRequestingHundredKReferrers: false,
  isRequestingOneKReferrers: { "1": false },
  distributedAmounts: {
    isRequesting: false,
    winningDistributed: "0",
    earningDistributed: "0",
    referralAmount: "0",
    totalUser: "0",
    totalPaidUser: "0",
    currentDraw: "0",
    completedDraws: "0",
    completedTenKDraws: "0",
  },
  myEarnings: {
    affiliate: 0,
    winning: 0,
    winnerReferrerBonuses: 0,
    winnerReferralPayouts: 0,
    referralPayouts: 0,
  },
  isFetchingEarnings: false,
  events: {
    recentWinners: {
      ONE_K_DRAW: { userId: "", drawNumber: 0, ticketNumber: "" },
      TEN_K_DRAW: { userId: "", drawNumber: 0, ticketNumber: "" },
      HUNDRED_K_DRAW: { userId: "", drawNumber: 0, ticketNumber: "" },
    },
  },
  isReady: false,
  winnerOverlay: false,
  connector: METAMASK,
  prediction: {
    freeUser: {
      ONE_K_DRAW: "",
      HUNDRED_K_DRAW: "",
    },
    paidUser: {
      ONE_K_DRAW: "",
      HUNDRED_K_DRAW: "",
    },
  },
  isRequestingMyPredictions: false,
  myPredictions: {
    ONE_K_DRAW: [],
    HUNDRED_K_DRAW: [],
  },
  predictionAdminApproval: {
    hundredK: "",
    oneK: [],
  },
  predictionLoader: {
    freePrediction: {
      currentDraw: false,
      hundredKDraw: false,
    },
    paidPrediction: {
      currentDraw: false,
      hundredKDraw: false,
    },
  },
  isFetchingPredicted: false,
  isRedeeming: { "1": { redeeming: false, redeemed: false } },
  announcedWinner: { winnerId: "", value: "", slot: "", userId: "" },
  isWithdrawingTokens: false,
  isWithdrawingPredictTokens: false,
  freeUserReferralAmountByLevel: {
    "1": 0,
    "2": 0,
    "3": 0,
    "4": 0,
    "5": 0,
  },
  paidUserReferralAmountByLevel: {
    "1": 0,
    "2": 0,
    "3": 0,
    "4": 0,
    "5": 0,
  },
  appStatus: "",
  predictionWinners: { "1": [] },
  isFetching1KPredictionWinners: { "1": false },
  isFetching100KPredictionWinners: false,
  prediction100KWinners: [],
  isFetchingReferrerAddress: false,
  isFetchingReferrerId: false,
  claimableAmount: {
    unclaimed: 0,
    claimed: 0,
  },
  claimableToken: {
    unclaimed: "0",
    claimed: "0",
  },
  predictGameToken: {
    claimed: "0",
    lastReleased: "",
    unclaimed: "0",
  },
  isSomethingWrong: false,
  isIdle: false,
  activePool: "",
  dashboardPool: {
    isRequesting: false,
    payload: null,
  },
  poolParticipants: [],
  nextAvailableAt: "",
  addingYTG: false,
  poolInfo: {
    total: "0",
    activePool: null,
  },
  gameUrl: "https://skillgaming.com/game/export/chaos",
  isWhitelisted: false,
  whitelistLoading: false,
  confetti: false,
};

// Use the PayloadAction type to declare the contents of `action.payload`
const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    onEnteringDraw: (state, action: PayloadAction<UserSlicetypes.EnteringDraw>) => {
      const payload = action.payload;
      if (payload) {
        state.enteredDraw = true;
        state.drawStart = true;
        state.userDetails = payload.userDetails;
        state.currentDraw = payload.currentDraw;
        state.hasParticipant = true;
      } else {
        state.enteredDraw = false;
        state.drawStart = false;
        state.userDetails.balance = initialState.userDetails.balance;
        state.userDetails.id = initialState.userDetails.id;
        state.hasParticipant = true;
        state.showTour = false;
      }
    },
    setYearnToken: (state, action: PayloadAction<string>) => {
      state.yearnBalance = action.payload;
    },
    setUserTickets: (state, action: PayloadAction<UserSlicetypes.UserTickets>) => {
      state.userTickets = action.payload;
    },
    setInvitedBy: (state, action: PayloadAction<UserSlicetypes.InvitedBy>) => {
      state.invitedBy = action.payload;
    },
    updateBalance: (state, action: PayloadAction<string>) => {
      state.userDetails.balance = action.payload;
    },
    setParticipantTxnHash: (state, action: PayloadAction<string | undefined>) => {
      state.participantTxnHash = action.payload ?? initialState.participantTxnHash;
    },
    updateTicketsSold: (state, action: PayloadAction<number>) => {
      state.ticketsSold = action.payload;
      if (action.payload > TICKETS_PER_ROUND[Round.roundOne] && action.payload <= TICKETS_PER_ROUND[Round.roundTwo])
        state.selectedRound = Round.roundTwo;
      else if (action.payload > TICKETS_PER_ROUND[Round.roundTwo] && action.payload <= TICKETS_PER_ROUND[Round.roundThree])
        state.selectedRound = Round.roundThree;
      else state.selectedRound = Round.roundOne;
    },
    setDrawWinners: (state, action: PayloadAction<{ draw: string; drawWinners: UserSlicetypes.IUsers[] }>) => {
      const { draw, drawWinners } = action.payload;
      if (draw === ONE_K_DRAW) state.oneKDrawWinners = drawWinners;
      else state.tenKDrawWinners = drawWinners;
    },
    toggleIsBuying: (state, action: PayloadAction<{ loading: boolean; close: boolean }>) => {
      const payload = action.payload ?? initialState.isBuying;
      state.isBuying = payload;
    },
    setReferrersByLevel: (state, action: PayloadAction<{ level: string; referrers: UserSlicetypes.Referrer[] }>) => {
      const { level, referrers } = action.payload;
      state.referrers[level] = referrers;
    },
    updateWalletBalance: (state, action: PayloadAction<number>) => {
      state.walletBalance = action.payload;
    },
    updateUserAddress: (state, action: PayloadAction<string>) => {
      state.userDetails.address = action.payload;
    },
    setIsAppLoading: (state, action: PayloadAction<boolean>) => {
      state.isAppLoading = action.payload;
    },
    setShowTour: (state, action: PayloadAction<boolean>) => {
      state.showTour = action.payload;
    },
    setIsPredictionSucceed: (
      state,
      action: PayloadAction<{ type: PredictType.free | PredictType.premium | ""; draw: string; prediction: string; txnHash: string } | undefined>,
    ) => {
      state.isPredictionSucceed = action.payload ?? initialState.isPredictionSucceed;
    },
    setIsEnteredDraw: (state, action: PayloadAction<boolean>) => {
      state.enteredDraw = action.payload;
    },
    setRequestingHundredKReferrers: (state, action: PayloadAction<boolean>) => {
      state.isRequestingHundredKReferrers = state.topReferrers100K.length ? false : action.payload;
    },
    setTopReferrersIn100K: (state, action: PayloadAction<UserSlicetypes.TopReferrers[]>) => {
      state.topReferrers100K = action.payload;
      state.isRequestingHundredKReferrers = false;
    },
    setRequestingOneKReferrers: (state, action: PayloadAction<{ drawNumber: string; fetching: boolean }>) => {
      const { drawNumber, fetching } = action.payload;
      state.isRequestingOneKReferrers[drawNumber] = state.topReferrersOneK[drawNumber]?.length ? false : fetching;
    },
    setTopReferrersInOneK: (state, action: PayloadAction<{ drawNumber: string; referrers: UserSlicetypes.TopReferrers[] }>) => {
      const { drawNumber, referrers } = action.payload;
      state.topReferrersOneK[drawNumber] = referrers;
      state.isRequestingOneKReferrers[drawNumber] = false;
    },
    updateCurrentDraw: (state, action: PayloadAction<string>) => {
      state.currentDraw = action.payload;
    },
    setDistrubutedRequesting: (state, action: PayloadAction<boolean>) => {
      state.distributedAmounts.isRequesting = action.payload;
    },
    setDistributed: (state, action: PayloadAction<UserSlicetypes.DistributedAmounts>) => {
      state.distributedAmounts = action.payload;
      if (!state.appStatus) state.currentDraw = action.payload.currentDraw;
    },
    onSwitchAccount: (state, action: PayloadAction<string>) => {
      state.referrers = initialState.referrers;
      state.prediction = initialState.prediction;
      state.isRequestingMyPredictions = initialState.isRequestingMyPredictions;
      state.myPredictions = initialState.myPredictions;
      state.isRedeeming = initialState.isRedeeming;
      state.myEarnings = initialState.myEarnings;
      state.claimableAmount = initialState.claimableAmount;
      state.claimableToken = initialState.claimableToken;
      state.predictGameToken = initialState.predictGameToken;
      state.nextAvailableAt = initialState.nextAvailableAt;
      state.participantTxnHash = initialState.participantTxnHash;
      state.isBuying = initialState.isBuying;
      // state.isWhitelisted = initialState.isWhitelisted;

      const hasInviteFromUrl = getInvitedBy(action.payload);
      if (!hasInviteFromUrl || hasInviteFromUrl.cricket) state.invitedBy = initialState.invitedBy;
    },
    setEarnings: (state, action: PayloadAction<{ type: string; amount: number }>) => {
      const { type, amount } = action.payload;
      state.myEarnings[type] = amount;
    },
    setIsFetchingEarnings: (state, action: PayloadAction<boolean>) => {
      state.isFetchingEarnings = action.payload;
    },
    updateRecentWinners: (state, action: PayloadAction<{ draw: string; winner: UserSlicetypes.IWinner } | undefined>) => {
      if (action.payload) {
        const { draw, winner } = action.payload;
        state.events.recentWinners[draw] = winner;
      } else state.events.recentWinners = initialState.events.recentWinners;
    },
    setContractIsReady: (state, action: PayloadAction<boolean>) => {
      state.isReady = action.payload;
    },
    setWinnerOverlay: (state, action: PayloadAction<boolean>) => {
      state.winnerOverlay = action.payload;
    },
    reset1KPrediction: (state) => {
      state.prediction.freeUser.ONE_K_DRAW = "";
      state.prediction.paidUser.ONE_K_DRAW = "";
    },
    setConnector: (state, action: PayloadAction<string | null>) => {
      const payload = action.payload ? action.payload : initialState.connector;
      state.connector = payload;
    },
    setPrediction: (state, action: PayloadAction<{ userType: string; prediction: UserSlicetypes.Prediction }>) => {
      const { userType, prediction } = action.payload;
      state.prediction[userType] = { ...state.prediction[userType], ...prediction };
      state.isFetchingPredicted = false;
    },
    setPredictionLoader: (state, action: PayloadAction<{ predictionType: string; drawValue: UserSlicetypes.PredictionLoaderDrawType }>) => {
      const { predictionType, drawValue } = action.payload;
      const updatedLoader = {
        ...state.predictionLoader[predictionType as keyof UserSlicetypes.PredictionLoaderType],
        ...drawValue,
      };
      state.predictionLoader = { ...state.predictionLoader, [predictionType]: updatedLoader };
    },
    setIsFetchingPredicted: (state, action: PayloadAction<boolean>) => {
      state.isFetchingPredicted = action.payload;
    },
    setIsRedeeming: (state, action: PayloadAction<{ id: string; fetching: UserSlicetypes.Redeem }>) => {
      const { id, fetching } = action.payload;
      state.isRedeeming[id] = fetching;
    },
    setIsRequestingMyPredictions: (state, action: PayloadAction<boolean>) => {
      state.isRequestingMyPredictions = action.payload;
    },
    setMyPredictions: (
      state,
      action: PayloadAction<{
        ONE_K_DRAW: UserSlicetypes.MyPredictionWinner[];
        HUNDRED_K_DRAW: UserSlicetypes.MyPredictionWinner[];
        id?: string;
      }>,
    ) => {
      const { ONE_K_DRAW, HUNDRED_K_DRAW, id } = action.payload;
      state.myPredictions = { ONE_K_DRAW, HUNDRED_K_DRAW };
      state.isRequestingMyPredictions = false;
      // if (id) state.isRedeeming[id] = {redeeming:false,redeemed:false};
    },
    setPredictionAdminApproval: (state, action: PayloadAction<{ hundredK: string; oneK: number[] }>) => {
      state.predictionAdminApproval = action.payload;
    },
    setAnnouncedWinner: (state, action: PayloadAction<{ winnerId: string; value: string; slot: string; userId: string } | undefined>) => {
      const payload = action.payload ?? initialState.announcedWinner;
      state.announcedWinner = payload;
    },
    setIsWithdrawingTokens: (state, action: PayloadAction<boolean>) => {
      state.isWithdrawingTokens = action.payload;
    },
    setIsWithdrawingPredictTokens: (state, action: PayloadAction<boolean>) => {
      state.isWithdrawingPredictTokens = action.payload;
    },
    setReferralBonus: (state, action: PayloadAction<{ userType: string; bonus: UserSlicetypes.Bonus }>) => {
      const { userType, bonus } = action.payload;
      if (userType === FREE_USER) state.freeUserReferralAmountByLevel = bonus;
      else state.paidUserReferralAmountByLevel = bonus;
    },
    updateCurrent10KDraw: (state, action: PayloadAction<string>) => {
      state.currentTenKDraw = action.payload;
    },
    setAppStatus: (state, action: PayloadAction<string>) => {
      state.appStatus = action.payload;
    },
    setIsFetching1KPredictionWinners: (state, action: PayloadAction<{ drawNumber: string; fetching: boolean }>) => {
      const { drawNumber, fetching } = action.payload;
      state.isFetching1KPredictionWinners[drawNumber] = state.predictionWinners[drawNumber]?.length ? false : fetching;
    },
    set1KPredictionWinners: (state, action: PayloadAction<{ drawNumber: string; predictionWinners: UserSlicetypes.PredictionWinner[] }>) => {
      const { drawNumber, predictionWinners } = action.payload;
      state.predictionWinners[drawNumber] = predictionWinners;
      state.isFetching1KPredictionWinners[drawNumber] = false;
    },
    setIsFetching100KPredictionWinners: (state, action: PayloadAction<boolean>) => {
      state.isFetching100KPredictionWinners = state.prediction100KWinners.length ? false : action.payload;
    },
    set100KPredictionWinners: (state, action: PayloadAction<UserSlicetypes.PredictionWinner[]>) => {
      state.prediction100KWinners = action.payload;
      state.isFetching100KPredictionWinners = false;
    },
    setHundredKWinner: (state, action: PayloadAction<UserSlicetypes.I100KWinner>) => {
      const winner = action.payload ?? initialState.hundredKWinner;
      state.hundredKWinner = winner;
    },
    setIsFetchingReferrerAddress: (state, action) => {
      state.isFetchingReferrerAddress = action.payload;
    },
    setIsFetchingReferrerId: (state, action) => {
      state.isFetchingReferrerId = action.payload;
    },
    setIsListeningEventstream: (state, action) => {
      state.isInitiatingEventstream = action.payload;
    },
    setWinningTimer: (state, action) => {
      state.winningTimer = action.payload;
    },
    setClaimableAmount: (state, action) => {
      state.claimableAmount = { unclaimed: action.payload.unclaimed, claimed: action.payload.claimed };
    },
    setClaimableToken: (state, action) => {
      state.claimableToken = { unclaimed: action.payload.unclaimed, claimed: action.payload.claimed };
    },
    setPredictGameToken: (state, action) => {
      state.predictGameToken = { unclaimed: action.payload.unclaimed, lastReleased: action.payload.lastReleased, claimed: action.payload.claimed };
    },
    //pools
    setActivePool: (state, action) => {
      state.activePool = action.payload;
    },
    setIsSomethingWrong: (state, action: PayloadAction<boolean>) => {
      state.isSomethingWrong = action.payload;
    },
    setIsIdle: (state, action: PayloadAction<boolean>) => {
      state.isIdle = action.payload;
    },
    setNextAvailableAt: (state, action: PayloadAction<string | undefined>) => {
      state.nextAvailableAt = action.payload ?? initialState.nextAvailableAt;
    },
    setAddingYTG: (state, action: PayloadAction<boolean>) => {
      state.addingYTG = action.payload;
    },
    //pools
    setPoolInfo: (state, action: PayloadAction<{ total: string; activePool: null | string }>) => {
      state.poolInfo = action.payload;
    },
    setIsRequestingDashboardPool: (state, action: PayloadAction<boolean>) => {
      state.dashboardPool.isRequesting = action.payload;
    },
    setDashboardPool: (state, action: PayloadAction<UserSlicetypes.Pool>) => {
      state.dashboardPool.isRequesting = false;
      state.dashboardPool.payload = action.payload;
    },
    setPoolParticipants: (state, action: PayloadAction<UserSlicetypes.Participants[] | []>) => {
      state.poolParticipants = action.payload;
    },
    onDisconnect: (state) => {
      state.userDetails = initialState.userDetails;
      state.invitedBy = initialState.invitedBy;
      state.enteredDraw = initialState.enteredDraw;
      state.drawStart = initialState.drawStart;
      state.myEarnings = initialState.myEarnings;
      state.referrers = initialState.referrers;
      state.walletBalance = initialState.walletBalance;
      state.prediction = initialState.prediction;
      state.participantTxnHash = initialState.participantTxnHash;
      state.isWhitelisted = initialState.isWhitelisted;
    },
    setGameUrl: (state, action: PayloadAction<string>) => {
      state.gameUrl = action.payload;
    },
    setIsWhitelisted: (state, action: PayloadAction<boolean>) => {
      state.isWhitelisted = action.payload;
    },
    setWhitelistLoading: (state, action: PayloadAction<boolean>) => {
      state.whitelistLoading = action.payload;
    },
    setConfetti: (state, action: PayloadAction<boolean>) => {
      state.confetti = action.payload;
    },
  },
});

export const {
  onEnteringDraw,
  setYearnToken,
  setInvitedBy,
  updateBalance,
  updateTicketsSold,
  setParticipantTxnHash,
  setDrawWinners,
  toggleIsBuying,
  setReferrersByLevel,
  updateWalletBalance,
  updateUserAddress,
  setIsAppLoading,
  setShowTour,
  setIsPredictionSucceed,
  setIsEnteredDraw,
  setRequestingHundredKReferrers,
  setRequestingOneKReferrers,
  setTopReferrersIn100K,
  setTopReferrersInOneK,
  onDisconnect,
  updateCurrentDraw,
  setDistrubutedRequesting,
  setDistributed,
  onSwitchAccount,
  setEarnings,
  setIsFetchingEarnings,
  updateRecentWinners,
  setContractIsReady,
  setWinnerOverlay,
  reset1KPrediction,
  setConnector,
  setPrediction,
  setPredictionLoader,
  setIsFetchingPredicted,
  setIsRedeeming,
  setAnnouncedWinner,
  setIsWithdrawingTokens,
  setReferralBonus,
  updateCurrent10KDraw,
  setAppStatus,
  setIsFetching1KPredictionWinners,
  set1KPredictionWinners,
  setHundredKWinner,
  setIsFetchingReferrerAddress,
  setIsFetchingReferrerId,
  setIsListeningEventstream,
  setWinningTimer,
  setClaimableAmount,
  setClaimableToken,
  setPredictGameToken,
  setIsWithdrawingPredictTokens,
  setIsSomethingWrong,
  setIsIdle,
  setUserTickets,
  setNextAvailableAt,
  setAddingYTG,
  setIsRequestingMyPredictions,
  setMyPredictions,
  setPredictionAdminApproval,
  setIsFetching100KPredictionWinners,
  set100KPredictionWinners,
  setIsWhitelisted,
  setWhitelistLoading,
  setConfetti,
  //pools
  setActivePool,
  setPoolInfo,
  setIsRequestingDashboardPool,
  setDashboardPool,
  setPoolParticipants,
  setGameUrl,
} = userSlice.actions;

export const userReducer = userSlice.reducer;
