import { useEffect, useState } from "react";
import S from "./coin-animation.styled";
import coin2 from "../../../assets/images/coin2.svg";
import coin3 from "../../../assets/images/coin3.svg";
import coin4 from "../../../assets/images/coin4.svg";
import coin5 from "../../../assets/images/coin5.svg";
import coin6 from "../../../assets/images/coin6.svg";
import coin7 from "../../../assets/images/coin7.svg";
import coin8 from "../../../assets/images/coin8.svg";
import coin9 from "../../../assets/images/coin9.svg";
import coin10 from "../../../assets/images/coin10.svg";
import coin11 from "../../../assets/images/coin11.svg";
import coin12 from "../../../assets/images/coin12.svg";
import coin13 from "../../../assets/images/coin13.svg";
import coin14 from "../../../assets/images/coin14.svg";
import coin15 from "../../../assets/images/coin15.svg";
import coin16 from "../../../assets/images/coin16.svg";
import coin17 from "../../../assets/images/coin17.svg";
import coin1 from "../../../assets/images/coin.svg";

// constants
const coinsArray = [coin1, coin2, coin3, coin4, coin5, coin6, coin7, coin8, coin9, coin10, coin11, coin12, coin13, coin14, coin15, coin16, coin17];

//functions
const ConfettiCont = () => {
  let randomWidth = Math.floor(Math.random() * Math.max(document.documentElement.clientWidth, window.innerWidth || 0));
  let randomHeight = Math.floor(Math.random() * Math.max(document.documentElement.clientHeight, window.innerHeight || 500));

  const confettiStyle = () => {
    return {
      width: "30px",
      height: "30px",
      top: `${randomHeight}px`,
      left: `${randomWidth}px`,
      animationDelay: `${Math.floor(Math.random() * 15)}s`,
      transform: `rotate(${Math.floor(Math.random() * 360)}deg)`,
    };
  };

  const children = <S.confetti src={coinsArray[Math.floor(Math.random() * 16)]} style={confettiStyle()} />;

  return <S.ConfettiShowerContainer>{children}</S.ConfettiShowerContainer>;
};

const CoinAnimations = () => {
  //state values
  const [count, setCount] = useState([]);

  //functions
  let dummyCount: any = [];
  for (let i = 0; i < 100; i++) {
    dummyCount.push(i);
  }

  useEffect(() => {
    setCount(dummyCount);
  }, []);
  return <S.CreatoAddedAnimeContainer>{count.length > 0 && count.map((item) => <ConfettiCont key={item} />)}</S.CreatoAddedAnimeContainer>;
};

export default CoinAnimations;
