import { FC } from "react";
import { useSelector } from "react-redux";
import S from "./header.styled";
import { onrampInstance } from "../../..";
import { toFixed } from "../../../utils/helpers";
import { useUserDetails } from "../../../utils/hooks/selector";

const MyUSDTBalance: FC = () => {
  //state values
  const userDetails = useSelector(useUserDetails);

  return (
    <S.MyEarningsWrapper data-tut="tour-wallet-balance">
      <S.MyBalanceText>My Balance:</S.MyBalanceText>
      <S.EarningsAmount>{toFixed(Number(userDetails.balance), 4)} USDT</S.EarningsAmount>
    </S.MyEarningsWrapper>
  );
};

export default MyUSDTBalance;
