import { FC } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import S from "./earning-card/earning-card.styled";
import RecentWinnerCarouselSkeleton from "../../utils/widgets/skeletons/recent-winner-carousel-skeleton";
import Recent10KWinner from "./Recent10KWinner";
import Recent100KWinner from "./Recent100KWinner";
import RecentOneKWinner from "./RecentOneKWinner";

const RecentWinnerCarousel: FC = () => {
  return (
    <S.CarouselWrapper data-tut="tour-recent-winners">
      <S.CustomCarousel
        showArrows={false}
        showIndicators={true}
        stopOnHover={true}
        showThumbs={false}
        showStatus={false}
        autoPlay
        infiniteLoop
        swipeable
        isMarginActive={true}
        transitionTime={750}
        renderIndicator={(onClickHandler, isSelected, index, label) => {
          return (
            <S.Dot
              selected={isSelected}
              onClick={onClickHandler}
              onKeyDown={onClickHandler}
              value={index}
              key={index}
              role="button"
              tabIndex={0}
              title={`${label} ${index + 1}`}
              aria-label={`${label} ${index + 1}`}
            />
          );
        }}
      >
        <RecentOneKWinner />
        <Recent10KWinner />
        <Recent100KWinner />
      </S.CustomCarousel>
    </S.CarouselWrapper>
  );
};

export default RecentWinnerCarousel;
