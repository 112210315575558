import { FC } from "react";
import S from "./gradient-blur-styled";
import { CustomModalTypes } from "../../types/custom-modal";

interface GradientModalProps extends CustomModalTypes.Container {
  isTimerModal?: boolean;
}

const GradientBlurModal: FC<GradientModalProps> = ({ children, open, customStyle, isTimerModal = false }) => {
  return (
    <S.GradientContainer>
      <S.GradientBlurContainer>
        <S.Gradient1 />
        <S.Gradient2 />
        <S.Gradient3 />
        <S.Gradient4 />
      </S.GradientBlurContainer>
      <S.GradientModalContainer open={open} isTimerModal={isTimerModal}>
        {children}
      </S.GradientModalContainer>
    </S.GradientContainer>
  );
};

export default GradientBlurModal;
