import { CSSProperties } from "react";
import { Box, styled } from "@mui/material";
import oneMatchBg from "../../../assets/images/one-match-bg.png";

namespace S {
  export const ContentContainer = styled(Box, {
    shouldForwardProp: (prop) => prop !== "customStyle" && prop !== "validRoute",
  })(({ customStyle, validRoute }: { customStyle?: CSSProperties; validRoute: Boolean }) => ({ theme }) => ({
    // position:"relative",
    overflowY: "auto",
    padding: theme.spacing(8),
    backgroundImage: validRoute ? `URL(${oneMatchBg})` : "",
    backgroundRepeat: "no-repeat",
    backgroundSize: "100% 100%",
    [theme.breakpoints.down("md")]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
    [theme.breakpoints.down("sm")]: {
      overflowX: "hidden",
    },
    ...customStyle,
  }));
}

export default S;
