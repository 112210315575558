import { FC } from "react";
import { useSelector } from "react-redux";
import { Typography } from "@mui/material";
import S from "./mainboard.styled";
import {
  GRADIENT1,
  GRADIENT2,
  GRADIENT3,
  hundredKDrawWinningAmount,
  HUNDRED_K,
  oneKDrawWinningAmount,
  ONE_K,
  tenKDrawWinningAmount,
  TEN_K,
} from "../../utils/constants";
import { getProgressPercentage, useGetDimensions } from "../../utils/helpers";
import {
  useDraw100kNumber,
  useDraw10kNumber,
  useDraw1kNumber,
  useIsAppLoading,
  useIsReady,
  useTicketsSold,
  useUserDetails,
} from "../../utils/hooks/selector";
import { CustomCardWrapper } from "../../utils/widgets";
import DrawCard from "../../utils/widgets/cards/DrawCard";
import EllipseContainer from "../../utils/widgets/ellipse-container";
import CircularProgressWithLabel from "../../utils/widgets/progress-bar/CircularProgressWithLabel";
import MyProgressSkeleton from "../../utils/widgets/skeletons/my-progress-skeleton";
import ProgressWithCount from "./ProgressWithCount";

const MyProgress: FC = () => {
  //state values
  const ticketsSold = useSelector(useTicketsSold);
  const userDetails = useSelector(useUserDetails);
  const isReady = useSelector(useIsReady);
  const isAppLoading = useSelector(useIsAppLoading);
  const draw1kNumber = useSelector(useDraw1kNumber);
  const draw10kNumber = useSelector(useDraw10kNumber);
  const draw100kNumber = useSelector(useDraw100kNumber);

  //constants
  const isMd = useGetDimensions("md");
  const isSm = useGetDimensions();
  const hasSkeleton = isAppLoading || !isReady || !userDetails.id;

  return (
    <CustomCardWrapper>
      {hasSkeleton ? (
        <MyProgressSkeleton />
      ) : (
        <S.MyProgress data-tut="tour-my-progress">
          <S.MyProgressHeader>
            <Typography variant={isSm ? "body2" : isMd ? "body1" : "h6"}>
              {userDetails.isFreeUser ? "DRAW PROGRESS" : "MY LUCKY ROUND PROGRESS"}
            </Typography>
            <S.GradientText shineText="#participate2yearn">#participate2yearn</S.GradientText>
            {/* <S.ThreeDotsButton>
            <MoreVertIcon />
          </S.ThreeDotsButton> */}
          </S.MyProgressHeader>
          <S.ProgressBarsContainer>
            <ProgressWithCount>
              <EllipseContainer percentage={getProgressPercentage(ticketsSold, draw1kNumber, userDetails.isFreeUser)} gradient={GRADIENT1} />
              <CircularProgressWithLabel
                percentage={getProgressPercentage(ticketsSold, draw1kNumber, userDetails.isFreeUser)}
                gradient={GRADIENT1}
                amount={oneKDrawWinningAmount}
              />
              <DrawCard drawCount={ONE_K} />
            </ProgressWithCount>
            <ProgressWithCount>
              <EllipseContainer percentage={getProgressPercentage(ticketsSold, draw10kNumber, userDetails.isFreeUser)} gradient={GRADIENT2} />
              <CircularProgressWithLabel
                percentage={getProgressPercentage(ticketsSold, draw10kNumber, userDetails.isFreeUser)}
                gradient={GRADIENT2}
                amount={tenKDrawWinningAmount}
              />
              <DrawCard drawCount={TEN_K} />
            </ProgressWithCount>
            <ProgressWithCount>
              <EllipseContainer percentage={getProgressPercentage(ticketsSold, draw100kNumber, userDetails.isFreeUser)} gradient={GRADIENT3} />
              <CircularProgressWithLabel
                percentage={getProgressPercentage(ticketsSold, draw100kNumber, userDetails.isFreeUser)}
                gradient={GRADIENT3}
                amount={hundredKDrawWinningAmount}
              />
              <DrawCard drawCount={HUNDRED_K} />
            </ProgressWithCount>
          </S.ProgressBarsContainer>
        </S.MyProgress>
      )}
    </CustomCardWrapper>
  );
};

export default MyProgress;
