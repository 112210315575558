import { Button, Stack } from "@mui/material";
import { Box, styled } from "@mui/material";

namespace S {
  export const Footer = styled(Box)(({ theme }) => ({
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "20px 50px 20px 50px",
    boxSizing: "border-box",
    borderTop: `1px solid rgba(89, 89, 89, 0.2)`,
    [theme.breakpoints.down("sm")]: {
      padding: "unset",
    },
  }));

  export const FooterButtonContainer = styled(Box)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    gap: theme.spacing(3),
  }));

  export const DoneBtnContainer = styled(Box)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    width: "100%",
  }));

  export const SkipButton = styled(Button)(({ theme }) => ({
    borderColor: theme.palette.custom.primary.main,
    color: theme.palette.custom.primary.main,
    textTransform: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "0.625rem",
    padding: "0.25rem 1.5rem",
    fontSize: "16px",
    fontWeight: "600",
    borderRadius: "0.5rem",
    //MuiButton-root
    "&:hover": {
      borderColor: theme.palette.custom.primary.main,
      color: theme.palette.custom.primary.main,
    },
    [theme.breakpoints.down("sm")]: {
      padding: "5px 10px",
    },
  }));

  export const DotContainer = styled(Stack)(({ theme }) => ({
    width: "50%",
    maxWidth: "50%",
    gap: theme.spacing(1.1),
    flexDirection: "row",
    alignItems: "center",
    p: {
      fontWeight: "bold",
    },
  }));

  export const Dot = styled(Box, {
    shouldForwardProp: (prop) => prop !== "currentPage",
  })(({ currentPage }: { currentPage: boolean }) => ({ theme }) => ({
    width: currentPage ? "1.875rem" : "0.5rem",
    height: "0.5rem",
    borderRadius: currentPage ? "0.375rem" : "50%",
    transitionDuration: "0.2s",
    backgroundColor: currentPage ? theme.palette.custom.primary.main : "#C7DAFF",
    cursor: "pointer",
  }));
}

export default S;
