import { FC } from "react";
import { ModalBody, ModalHeader } from "../../../utils/widgets/custom-modal";
import Jackpot from "../../mainboard/Jackpot";
import JackpotWinnerBody from "./jackpot-winner-body";
import JackpotWinnerHeader from "./jackpot-winner-header";

type JackpotWinningModalPropsType = {
  onClose: () => void;
  winnerId: string;
  userId: string;
  value: string;
  slot: string;
};

const JackpotWinningModal: FC<JackpotWinningModalPropsType> = ({ onClose, value, winnerId, slot, userId }) => {
  return (
    <>
      <ModalHeader handleClose={onClose} isTransparent={true}>
        <JackpotWinnerHeader value={value} userId={userId} />
      </ModalHeader>
      <ModalBody isTransparent={true}>
        <JackpotWinnerBody>
          <Jackpot winnerId={winnerId} value={value} slot={slot} userId={userId} showFooter={false} className="jackpot-winner-modal" />
        </JackpotWinnerBody>
      </ModalBody>
    </>
  );
};

export default JackpotWinningModal;
