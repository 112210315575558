import SensorsIcon from "@mui/icons-material/Sensors";
import { Box, IconButton, Stack, styled, Typography, keyframes } from "@mui/material";
import { HUNDRED_K_DRAW, ONE_K_DRAW, TEN_K_DRAW, TextGradient, TransparentTextGradient } from "../../utils/constants";

namespace S {
  export const MainboardWrapper = styled(Stack)(({ theme }) => ({
    gap: theme.spacing(6),
    maxWidth: "620px",
  }));

  export const BuyNowWithInviter = styled(Stack)(({ theme }) => ({
    minHeight: "10rem",
    maxHeight: "10rem",
    gap: theme.spacing(4),
    justifyContent: "center",
    alignItems: "center",
    padding: theme.spacing(6),
    background: `linear-gradient(${theme.palette.custom.bg.app}, ${theme.palette.custom.bg.app}) padding-box`,
  }));

  export const BuyNow = styled(Stack)(({ theme }) => ({
    gap: theme.spacing(4),
    justifyContent: "center",
    alignItems: "center",
    padding: theme.spacing(6),

    h6: {
      textAlign: "center",
      background: "linear-gradient(268.66deg, #FFE600 5.41%, #24FF00 93.94%)",
      webkitBackgroundClip: "text",
      WebkitTextFillColor: "transparent",
      backgroundClip: "text",
      fontWeight: 600,

      span: {
        fontSize: "2rem !important",
      },
    },
  }));

  export const Inviter = styled(Stack)(({ theme }) => ({
    gap: theme.spacing(4),
    justifyContent: "center",
    alignItems: "center",
    padding: theme.spacing(6),
  }));

  export const ThankYou = styled(Stack)(({ theme }) => ({
    minHeight: "31.625rem",
    maxHeight: "31.625rem",
    gap: theme.spacing(9),
    justifyContent: "center",
    alignItems: "center",
    padding: theme.spacing(6),
    background: `linear-gradient(${theme.palette.custom.secondary.secondary_3}, ${theme.palette.custom.secondary.secondary_3}) padding-box`,

    p: {
      fontWeight: "bold",
    },

    div: {
      gap: theme.spacing(2),

      h4: {
        fontWeight: "bold",
        background: "linear-gradient(268.66deg, #FFE600 5.41%, #24FF00 93.94%)",
        webkitBackgroundClip: "text",
        WebkitTextFillColor: "transparent",
        backgroundClip: "text",
      },
    },
  }));

  export const MyProgress = styled(Stack)(({ theme }) => ({
    minHeight: "12.5rem",
    maxHeight: "12.5rem",
    gap: theme.spacing(3),
    justifyContent: "space-between !important",
  }));

  export const MyProgressHeader = styled(Box)(({ theme }) => ({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    h6: {
      fontWeight: 600,
      color: theme.palette.custom.secondary.secondary_1,
    },
  }));

  export const GradientText = styled(Typography, { shouldForwardProp: (prop) => prop !== "shineText" })(
    ({ shineText }: { shineText: string }) =>
      ({ theme }) => ({
        background: TextGradient,
        webkitBackgroundClip: "text",
        WebkitTextFillColor: "transparent",
        backgroundClip: "text",
        backgroundSize: "200px",
        fontSize: "1.0625rem !important",
        fontWeight: "bold !important",
        color: TextGradient,
        textShadow: `0 0px 0px ${TextGradient}`,
        position: "relative",
        ":after": {
          content: `"${shineText}"`,
          // minWidth: "200px",
          background: TransparentTextGradient,
          webkitBackgroundClip: "text",
          WebkitTextFillColor: "transparent",
          backgroundClip: "text",
          position: "absolute",
          top: 0,
          left: 0,
          transform: "translate(0,0)",
          zIndex: 3,
          animation: "pool-shine 20s linear infinite",
          "@keyframes pool-shine": {
            "0%": {
              backgroundPosition: "-300px",
            },
            "100%": {
              backgroundPosition: "300px",
            },
          },
        },
        [theme.breakpoints.down("sm")]: {
          fontSize: "0.875rem !important",
        },
      }),
  );

  export const ThreeDotsButton = styled(IconButton)(({ theme }) => ({
    svg: {
      color: theme.palette.custom.secondary.secondary_1,
      background: theme.palette.custom.primary.main,
      borderRadius: "50%",
    },
  }));

  export const ProgressWithCountWrapper = styled(Box)(() => ({
    position: "relative",
  }));

  export const ProgressBarsContainer = styled(Box)(({ theme }) => ({
    display: "flex",
    gap: "3.6875rem",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("md")]: {
      gap: "1rem",
      justifyContent: "space-around",
      height: "10rem",
    },
    [theme.breakpoints.down("sm")]: {
      gap: "0.5rem",
    },
  }));

  export const TicketSold = styled(Stack)(({ theme }) => ({
    minHeight: "10.875rem",
    maxHeight: "10.875rem",
    gap: theme.spacing(4),
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      minHeight: "fit-content",
      maxHeight: "fit-content",
    },
  }));

  export const TicketSoldHeader = styled(Box)(({ theme }) => ({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    h6: {
      fontWeight: 600,
      color: theme.palette.custom.secondary.secondary_1,
    },
  }));

  export const LiveContainer = styled(Box)(({ theme }) => ({
    height: "2rem",
    width: "5rem",
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    background: theme.palette.custom.bg.live,
    borderRadius: "0.25rem",
    [theme.breakpoints.down("md")]: {
      height: " 1.25rem",
      width: "3.9375rem",
    },
  }));

  export const LiveText = styled(Typography)(({ theme }) => ({
    color: theme.palette.custom.secondary.secondary_1,
    fontWeight: "600",
    fontSize: "0.75rem",
    lineHeight: "1",
  }));

  export const CustomSensorsIcon = styled(SensorsIcon)(() => ({
    fontSize: "1rem",
    animation: "spin 2s linear infinite",
    "@keyframes spin": {
      "0%": {
        transform: "rotate(0deg)",
      },
      "100%": {
        transform: "rotate(360deg)",
      },
    },
  }));

  const changeFlipValueColor = (length: number | undefined, color: string) => {
    let css: { [key: string]: {} } = { gap: "5px" };
    for (var i = 6; i > 6 - (length as number); i--) {
      css[`span:nth-of-type(${i})`] = {
        span: {
          span: {
            color: `${color} !important`,
          },
        },
      };
    }
    return css;
  };

  export const FlipCounterWrapper = styled(Box, {
    shouldForwardProp: (prop) => prop !== "countLength",
  })(({ countLength }: { countLength?: number }) => ({ theme }) => ({
    display: "flex",
    gap: theme.spacing(2),
    section: changeFlipValueColor(countLength, theme.palette.custom.accent.accent_2),
    [theme.breakpoints.down("md")]: {
      section: {
        width: "100%",
        justifyContent: "space-between !important",
        span: {
          width: "8vw !important",
          textAlign: "center",
          span: {
            span: {
              textAlign: "center",
              fontSize: "2.5rem !important",
            },
          },
        },
      },
    },
    [theme.breakpoints.down("sm")]: {
      section: {
        span: {
          width: "10.5vw !important",
          height: "5rem !important",
          span: {
            span: {
              fontSize: "2rem !important",
            },
          },
        },
      },
    },
  }));

  export const MyTeam = styled(Stack)(() => ({
    minHeight: "21.25rem",
    maxHeight: "21.25rem",
    // gap: theme.spacing(4),
  }));

  export const MyTeamHeader = styled(Box)(({ theme }) => ({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    h6: {
      fontWeight: 600,
      color: theme.palette.custom.secondary.secondary_1,
    },
    paddingBottom: "20px",
  }));

  export const TeamEarning = styled(Typography)(({ theme }) => ({
    color: `${theme.palette.custom.accent.accent_1} !important`,
    fontWeight: 600,
  }));

  export const TeamMembersContainer = styled(Box)(() => ({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingBottom: "0.375rem",
  }));

  export const TeamMembersCountContainer = styled(Box)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    gap: "0.5rem",
    p: {
      color: theme.palette.custom.secondary.secondary_1,
      fontWeight: 600,
    },
  }));

  export const TeamMembersEarning = styled(Typography)(({ theme }) => ({
    color: `${theme.palette.custom.accent.accent_1} !important`,
    fontWeight: 600,
  }));

  export const TeamMembersListWrapper = styled(Box)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    gap: "0.575rem",
    background: "rgba(22, 21, 26, 0.14)",
    border: "1px solid #3E3E3E",
    boxShadow: "0px 4px 4px rgba(89, 89, 89, 0.05)",
    padding: theme.spacing(2),
    overflowY: "auto",
    borderRadius: "0 0 1rem 1rem",
  }));

  export const TeamMemberContent = styled(Box)(({ theme }) => ({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "0.8rem",
    p: {
      color: theme.palette.custom.secondary.secondary_1,
    },
  }));

  export const TeamMemberDetailsWrapper = styled(Box)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(4),
    p: {
      color: theme.palette.custom.secondary.secondary_1,
      fontWeight: 500,
    },
  }));

  export const MyTeamFooter = styled(Box)(() => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingTop: "15px",
  }));

  export const WaitingForTeam = styled(Stack)(({ theme }) => ({
    gap: theme.spacing(4),
    alignItems: "center",
    justifyContent: "center",
    div: {
      textAlign: "center",
    },
    p: {
      fontWeight: "bold",
    },
    padding: theme.spacing(2),
  }));

  export const WinningNumberContainer = styled(Stack)(({ theme }) => ({
    minHeight: "10.25rem",
    maxHeight: "10.25rem",
    gap: theme.spacing(3),
    justifyContent: "space-between",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      minHeight: "fit-content",
      maxHeight: "fit-content",
    },
  }));

  export const WinningNumberHeader = styled(Box)(() => ({
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  }));

  export const WinningNumberHeadDetails = styled(Box)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
    h6: {
      fontWeight: 600,
      color: theme.palette.custom.secondary.secondary_1,
    },
  }));

  export const WinningAmountWrapper = styled(Box)(({ theme }) => ({
    borderRadius: theme.spacing(2),
    border: "1px solid #3E3E3E",
    padding: theme.spacing(1),
    textAlign: "center",
    top: theme.spacing(5),
    h5: {
      fontWeight: 600,
      color: theme.palette.custom.accent.accent_1,
    },
  }));

  export const WinningAmountNumber = styled(Typography)(({ theme }) => ({
    fontWeight: 600,
    color: theme.palette.custom.accent.accent_1,
    fontSize: "24px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "20px",
    },
  }));

  export const TeamMemberAddressWrapper = styled(Box)({
    minWidth: "62%",
    textAlign: "left",
  });

  //Jackpot Container
  export const Jackpot = styled(Box, {
    shouldForwardProp: (prop) => prop !== "customStyle",
  })(({ customStyle }: { customStyle?: string }) => ({ theme }) => ({
    borderRadius: "0.625rem",
    padding: theme.spacing(2),
    minHeight: "10.75rem",
    maxeight: "10.75rem",
    background:
      customStyle === ONE_K_DRAW
        ? "linear-gradient(92.48deg, #1967FC 1.43%, #19FCB8 75%)"
        : customStyle === TEN_K_DRAW
        ? "linear-gradient(268.66deg, #FFE600 5.41%, #24FF00 93.94%)"
        : customStyle === HUNDRED_K_DRAW
        ? " linear-gradient(337.35deg, #EB00FF 7.34%, #E9B80C 91.96%)"
        : "",
  }));

  export const JackpotHeader = styled(Box)(() => ({
    position: "relative",
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "space-between",
    // height: "180px",
    overflow: "hidden !important",
    gap: "0.7rem",
  }));

  export const JackpotAnimationWrapper = styled(Box)(({ theme }) => ({
    position: "relative",
    width: "100%",
    height: "120px",
    overflow: "hidden",
    [theme.breakpoints.down("md")]: {
      height: "80px",
    },
    [theme.breakpoints.down("sm")]: {
      height: "60px",
    },
  }));

  export const JackpotAmountContainer = styled(Box)(() => ({}));

  export const JackpotText = styled(Typography)(({ theme }) => ({
    fontWeight: 700,
    whiteSpace: "nowrap",
    color: theme.palette.custom.secondary.secondary_3,
    [theme.breakpoints.down("md")]: {
      fontSize: "1rem",
    },
  }));

  export const JackpotAvatarContainer = styled(Box)(() => ({
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "center",
    minWidth: "40%",
    height: "100%",
  }));

  export const JackpotBuddyContainer = styled(Stack)(() => ({
    gap: "1rem",
    alignSelf: "center",
    alignItems: "flex-end",
  }));

  export const JackpotBuddyImageContainer = styled(Stack)(({ theme }) => ({
    width: "150px",
    height: "80%",
    justifyContent: "flex-end",
    // [theme.breakpoints.down("md")]: {
    //   width: "100px",
    // },
    img: {
      width: "100%",
      height: "100%",
      [theme.breakpoints.down("sm")]: {
        width: "50px",
      },
    },
  }));

  export const JackpotHeaderImg = styled(Box)(({ theme }) => ({
    textAlign: "center",
    lineHeight: "1",
    zIndex: 6,
    width: "fit-content",
    img: {
      width: "250px",
      [theme.breakpoints.down("md")]: {
        width: "150px",
      },
      [theme.breakpoints.down("sm")]: {
        width: "100px",
      },
    },
  }));

  export const JackpotHeaderText1 = styled(Typography)(({ theme }) => ({
    color: theme.palette.custom.secondary.secondary_3,
    fontSize: "2.75rem",
    fontWeight: "700",
    letterSpacing: "0.375rem",
    textAlign: "center",
    lineHeight: "1",
    zIndex: 6,
  }));

  export const JackpotHeaderText2 = styled(Typography)(() => ({
    fontSize: "4.625rem",
    fontWeight: "800",
    textAlign: "center",
    letterSpacing: "0.5rem",
    lineHeight: "1.3",
    animation: `${breatheAnimation} 0.2s linear infinite`,
    background: "linear-gradient(179.99deg, #FF1DF6 40.09%, #E92626 70.27%)",
    webkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    backgroundClip: "text",
    zIndex: 6,
    WebkitTextStroke: "0.125rem #BD3225 !important",
  }));

  const breatheAnimation = keyframes`
  50%{
    opacity:50%;
  }
  `;

  export const FlipCounterMainContainer = styled(Box)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: theme.palette.custom.strokes.primary,
    padding: `${theme.spacing(2)} ${theme.spacing(2.5)}`,
    borderRadius: "0.5rem",
  }));

  export const FlipCounterContainer = styled(Box)(({ theme }) => ({
    display: "flex",
    justifyContent: "center",
    minHeight: "9rem",
    width: "100%",
    gap: theme.spacing(2),
    "&.its-you-text": {
      section: {
        height: "10rem",
        overflow: "hidden",
      },
    },
    section: {
      gap: "0.3125rem",
      span: {
        minHeight: "12rem",
        padding: "0 1.55rem 0 0.32rem !important",
        span: {
          span: {
            minHeight: "12rem",
            fontSize: "6rem !important",
          },
        },
      },
    },
    [theme.breakpoints.down("md")]: {
      minHeight: "unset !important",
      section: {
        width: "100%",
        justifyContent: "space-between !important",
        minHeight: "unset !important",
        maxHeight: "6rem !important",
        span: {
          width: "7.5vw !important",
          textAlign: "center",
          minHeight: "unset !important",
          maxHeight: "6rem !important",
          span: {
            span: {
              textAlign: "center",
              fontSize: "3rem !important",
              maxHeight: "6rem !important",
              height: "auto !important",
            },
          },
        },
      },
    },
    [theme.breakpoints.down("sm")]: {
      minHeight: "6rem",
      section: {
        maxHeight: "5rem !important",
        span: {
          width: "6vw !important",
          maxHeight: "5rem !important",
          padding: "0 0.7rem 0 0.15rem !important",

          span: {
            span: {
              maxHeight: "5rem !important",
              fontSize: "2rem !important",
            },
          },
        },
      },
    },
  }));

  export const JackpotFooter = styled(Box)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: `0 ${theme.spacing(4)}`,
    // backgroundColor: theme.palette.custom.strokes.primary,
    backgroundColor: "#37373b",
    maxHeight: "3.625rem",
    minHeight: "3.625rem",
  }));

  export const FooterSection1 = styled(Box)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(0.8),
  }));
  export const FooterText1 = styled(Box)(({ theme }) => ({
    fontSize: "0.625rem",
    fontWeight: "600",
    color: theme.palette.custom.secondary.secondary_1,
  }));

  export const FooterTextGradient = styled(Typography, {
    shouldForwardProp: (prop) => prop !== "customStyle",
  })(({ customStyle }: { customStyle?: string }) => ({
    fontSize: "0.875rem",
    fontWeight: "600",
    background:
      customStyle === ONE_K_DRAW
        ? "linear-gradient(92.48deg, #1967FC 1.43%, #19FCB8 75%)"
        : customStyle === TEN_K_DRAW
        ? "linear-gradient(268.66deg, #FFE600 5.41%, #24FF00 93.94%)"
        : customStyle === HUNDRED_K_DRAW
        ? " linear-gradient(337.35deg, #EB00FF 7.34%, #E9B80C 91.96%)"
        : "",
    webkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    backgroundClip: "text",
  }));

  export const FooterSection2 = styled(Box)(() => ({
    display: "flex",
    alignSelf: "flex-start",
    alignItems: "center",
    justifyContent: "space-between",
    minWidth: "15.25rem",
    marginTop: "0.1875rem",
    border: "1px solid red",
  }));

  export const FooterSection3 = styled(Box)(() => ({
    marginLeft: "2rem",
  }));

  export const Wrapper1Img = styled("img")(() => ({
    position: "absolute",
    top: 0,
    left: "30%",
    transform: "translateX(-50%)",
    zIndex: "6",
  }));

  export const Wrapper2Img = styled(Wrapper1Img)(() => ({
    left: "40%",
  }));

  export const Wrapper3Img = styled(Wrapper1Img)(() => ({
    left: "50%",
  }));

  export const Wrapper4Img = styled(Wrapper1Img)(() => ({
    left: "60%",
  }));

  export const Wrapper5Img = styled(Wrapper1Img)(() => ({
    left: "70%",
  }));

  export const JackpotWinningTextContainer = styled(Box)(({ theme }) => ({
    position: "relative",
    padding: `${theme.spacing(2.5)} 0`,
  }));

  export const JackpotWinningText = styled(Typography)(() => ({
    textAlign: "center",
    background: "linear-gradient(169.74deg, #F4831F 30.05%, #F9F6AE 50.28%, #E49A29 69.27%)",
    webkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    backgroundClip: "text",
    WebkitTextStroke: "2px #b6421e",
    fontWeight: 600,
    position: "relative",
    animation: "zoomInWithJackIntheBox 3s forwards",
    "@keyframes zoomInWithJackIntheBox": {
      from: {
        opacity: 0,
        transform: " scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0) rotate(30deg)",
        animationTimingFunction: "cubic-bezier(0.55, 0.055, 0.675, 0.19)",
      },
      "50%": {
        opacity: 0.7,
        transform: "rotate(-10deg)",
      },
      "60%": {
        opacity: 1,
        transform: "scale3d(0.475, 0.475, 0.475) translate3d(0, 0px, 0) rotate(5deg)",
        animationTimingFunction: "cubic-bezier(0.175, 0.885, 0.32, 1)",
      },
      "100%": {
        transform: "translate3d(0, -10px, 0)",
      },
    },
  }));
}

export default S;
