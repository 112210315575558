import { FC } from "react";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import { ThemeProvider } from "@mui/material";
import { GraphQLClient } from "graphql-request";
import { bsc } from "wagmi/chains";
import store from "../../store";
import { ReferrerContextProvider } from "../contexts/ReferrerContext";
import { darkTheme } from "../theme";
import { platformPoolUrl, platformPredictionUrl, platformUrl, predictionPoolUrl } from "./platforms";

type Props = {
  children: JSX.Element | JSX.Element[];
};
const CURRENT_CHAIN_ID: number = Number(process.env.REACT_APP_CURRENT_CHAINID);

export const polygonMumbai = {
  id: 80001,
  name: "Polygon Mumbai",
  network: "maticmum",
  nativeCurrency: {
    name: "MATIC",
    symbol: "MATIC",
    decimals: 18,
  },
  rpcUrls: {
    default: {
      http: [process.env.REACT_APP_MATIC_RPC_URL || ""],
    },
    public: {
      http: [process.env.REACT_APP_MATIC_RPC_URL || ""],
    },
  },
  blockExplorers: {
    etherscan: {
      name: "PolygonScan",
      url: "https://mumbai.polygonscan.com",
    },
    default: {
      name: "PolygonScan",
      url: "https://mumbai.polygonscan.com",
    },
  },
  contracts: {
    multicall3: {
      address: "0xca11bde05977b3631167028862be2a173976ca11",
      blockCreated: 25770160,
    },
  },
  testnet: true,
};

/** Web3modal config**/

export const graphQlClient = new GraphQLClient(platformUrl[CURRENT_CHAIN_ID]);
export const poolClient = new GraphQLClient(platformPoolUrl[CURRENT_CHAIN_ID]);
export const predictionClient = new GraphQLClient(platformPredictionUrl[CURRENT_CHAIN_ID]);
export const predictionPoolClient = new GraphQLClient(predictionPoolUrl[CURRENT_CHAIN_ID]);

const CustomProvider: FC<Props> = ({ children }) => {
  return (
    <Provider store={store}>
      <ReferrerContextProvider>
        <ThemeProvider theme={darkTheme}>
          <Router>{children}</Router>
        </ThemeProvider>
      </ReferrerContextProvider>
    </Provider>
  );
};

export default CustomProvider;
