import { Box, styled, Typography } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { GRADIENT1, GRADIENT2, GRADIENT3, GRAD_COLOR_1, GRAD_COLOR_2, GRAD_COLOR_3 } from "../../constants";
import { gradient } from "../../types/progress-bar";

namespace S {
  export const CircularProgressWrapper = styled(Box, {
    shouldForwardProp: (prop) => prop !== "currentRoll",
  })(({ currentRoll }: { currentRoll?: string }) => () => ({
    height: "45px",
    width: "45px",
    position: "relative",
    borderRadius: "50%",
    background:
      currentRoll === GRADIENT1
        ? "linear-gradient(92.48deg, #1967FC 1.43%, #19FCB8 75%)"
        : currentRoll === GRADIENT2
        ? "linear-gradient(268.66deg, #FFE600 5.41%, #24FF00 93.94%)"
        : currentRoll === GRADIENT3
        ? " linear-gradient(337.35deg, #EB00FF 7.34%, #E9B80C 91.96%)"
        : "black",
  }));

  export const StaticCircularProgressBar = styled(CircularProgress)(({ theme }) => ({
    position: "absolute",
    left: 0,
    top: 0,
    ".MuiCircularProgress-circle": {
      color: theme.palette.custom.secondary.secondary_2,
    },
  }));

  export const CustomCircularProgressBar = styled(CircularProgress)(() => ({
    transform: "rotate(90deg) !important",
    position: "absolute",
    left: 0,
    top: 0,
  }));

  export const LabelContentWrapper = styled(Box)(() => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: "0.25rem",
    top: "45%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    position: "absolute",
  }));

  export const LabelContent = styled(Typography, {
    shouldForwardProp: (prop) => prop !== "gradient",
  })(({ gradient }: { gradient: gradient }) => ({ theme }) => ({
    background: gradient === GRADIENT1 ? GRAD_COLOR_1 : gradient === GRADIENT2 ? GRAD_COLOR_2 : GRAD_COLOR_3,
    webkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    backgroundClip: "text",
    fontSize: theme.spacing(2),
    fontWeight: 600,
  }));

  export const LabelContentRoll = styled(Typography)(({ theme }) => ({
    color: theme.palette.custom.secondary.secondary_4,
    fontSize: theme.spacing(2),
    fontWeight: 600,
  }));
}

export default S;
