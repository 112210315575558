import { Skeleton } from "@mui/material";
import S from "./cricket-prediction-won-skeleton.styled";

const CricketPredicitonWinnerSkeleton = () => {
  return (
    <S.SkeletonContainer>
      <S.TopContainer>
        <Skeleton variant="circular" sx={{ height: "25px", width: "25px", borderRadius: "50%" }} />
        <Skeleton variant="text" sx={{ width: "75%", height: "32px" }} />
      </S.TopContainer>
      <S.BottomContainer>
        <Skeleton variant="rectangular" sx={{ borderRadius: "8px", width: "118px", height: "32px" }} />
      </S.BottomContainer>
    </S.SkeletonContainer>
  );
};

export default CricketPredicitonWinnerSkeleton;
