import { Box, styled, Typography } from "@mui/material";

namespace S {
  export const IdlePageWrapper = styled(Box)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    background: theme.palette.custom.bg.app,
    height: "100vh",
    gap: theme.spacing(10),
  }));

  export const WarningText = styled(Typography)(({ theme }) => ({
    color: theme.palette.custom.secondary.secondary_1,
    fontSize: "3rem",
    textAlign: "center",
  }));
}

export default S;
