import { Promise } from "bluebird";
import store from "../../../store";
import { setIsRedeeming } from "../../../store/slices/user";
import { fetchPredictGameTokens } from "../../../store/slices/wallet.actions";
import Toast from "../../../utils/widgets/toast";
import PredictionABI from "../build/prediction_abi.json";
import { PREDICT_CONTRACT, web3 } from "./web3";

export namespace predictionContract {
  let deployed, predictContract: any;
  const CURRENT_CHAIN_ID: number = Number(process.env.REACT_APP_CURRENT_CHAINID);

  export const setup = async (library?: any) => {
    deployed = false;
    try {
      if (library) web3.setProvider(library);
      let predictionAbi = PredictionABI.abi as any;
      predictContract = new web3.eth.Contract(predictionAbi, PREDICT_CONTRACT[CURRENT_CHAIN_ID]);
      Promise.promisifyAll(predictContract, { suffix: "Promise" });
      deployed = true;
    } catch (e) {
      console.log(e);
    }
    return deployed;
  };

  export const getPredictionOwner = async () => await predictContract.methods.owner().call();

  export const getPredictionDrawFee = async () => {
    let predictionFee: string = await predictContract.methods.predictionDrawFee().call();
    return predictionFee;
  };

  export const getPredictionDraw100KFee = async () => {
    let prediction100KFee: string = await predictContract.methods.predictionDraw100kFee().call();
    return prediction100KFee;
  };

  export const getFreePredictedDraw = async (drawNumber: string, address: string) => {
    let predictedDraw: any = await predictContract.methods.freeDrawPrediction(drawNumber, address).call();
    return predictedDraw.ticketNumber;
  };

  export const getFreePredictedDraw100K = async (address: string) => {
    let predictedDraw100K: any = await predictContract.methods.free100kDrawPrediction(address).call();
    return predictedDraw100K.ticketNumber;
  };

  export const getPremiumPredictedDraw = async (drawNumber: string, address: string) => {
    let predictedDraw: any = await predictContract.methods.premiumDrawPrediction(drawNumber, address).call();
    return predictedDraw.ticketNumber;
  };

  export const getPremiumPredictedDraw100K = async (address: string) => {
    let premiumPredictedDraw100K: any = await predictContract.methods.premium100kDrawPrediction(address).call();
    return premiumPredictedDraw100K.ticketNumber;
  };

  // Redeem
  export const redeem1KPredictionRewards = async (drawNumber: string, address: string, type: string, id: string) => {
    let gasPrice = await web3.eth.getGasPrice();

    predictContract.methods
      .redeem1kDrawRewards(drawNumber)
      .send({ from: address, gaslimit: 1100000, gasPrice })
      .on("transactionHash", (hash: any) => {
        // console.log("redeem 1k hash", hash);
      })
      .on("receipt", (receipt: any) => {
        // console.log("redeem 1k receipt", receipt);
        if (receipt.status) {
          store.dispatch(fetchPredictGameTokens());
          setTimeout(() => {
            store.dispatch(fetchPredictGameTokens());
            Toast({ message: "Redeem 1k prediction was successful!", type: "success" });
            store.dispatch(setIsRedeeming({ id, fetching: { redeeming: false, redeemed: true } }));
          }, 2000);
        } else {
          store.dispatch(setIsRedeeming({ id, fetching: { redeeming: false, redeemed: false } }));
          Toast({ message: "Redeem 1k prediction was not successful!", type: "error" });
        }
      })
      .on("error", (error: any, receipt: any) => {
        store.dispatch(setIsRedeeming({ id, fetching: { redeeming: false, redeemed: false } }));
        console.log("error", error.message, receipt);
        if (error.message.includes(":")) {
          let msg = error.message.split(":")[0];
          Toast({ message: msg, type: "error" });
        } else Toast({ message: error.message, type: "error" });
      });
  };

  export const redeem100KPredictionRewards = async (address: string, type: string, id: string) => {
    let gasPrice = await web3.eth.getGasPrice();

    predictContract.methods
      .redeem100kDrawRewards()
      .send({ from: address, gaslimit: 1100000, gasPrice })
      .on("transactionHash", (hash: any) => {
        // console.log("redeem 100k hash", hash);
      })
      .on("receipt", (receipt: any) => {
        // console.log("redeem 100k receipt", receipt);
        if (receipt.status) {
          store.dispatch(fetchPredictGameTokens());
          setTimeout(() => {
            store.dispatch(fetchPredictGameTokens());
            Toast({ message: "Redeem 100k prediction was successful!", type: "success" });
            store.dispatch(setIsRedeeming({ id, fetching: { redeeming: false, redeemed: true } }));
          }, 2000);
        } else {
          store.dispatch(setIsRedeeming({ id, fetching: { redeeming: false, redeemed: false } }));
          Toast({ message: "Redeem 100k prediction was not successful!", type: "error" });
        }
      })
      .on("error", (error: any, receipt: any) => {
        store.dispatch(setIsRedeeming({ id, fetching: { redeeming: false, redeemed: false } }));
        console.log("error", error.message, receipt);
        if (error.message.includes(":")) {
          let msg = error.message.split(":")[0];
          Toast({ message: msg, type: "error" });
        } else Toast({ message: error.message, type: "error" });
      });
  };

  //To get predict game claimed and unclaimed amount
  export const getPredictGameParticipant = async (address: string) => {
    let participant: any = await predictContract.methods.participants(address).call();
    return participant;
  };

  export const predictDrawWinner = async (
    ticketNumber: string,
    address: string,
    cb: (ticketNumber: string, txnHash: string) => void,
    resetLoader: () => void,
  ) => {
    let gasPrice = await web3.eth.getGasPrice();

    predictContract.methods
      .freePredictDrawWinner(ticketNumber)
      .send({ from: address, gaslimit: 1100000, gasPrice })
      .on("transactionHash", (hash: any) => {
        // console.log("hash", hash);
      })
      .on("receipt", (receipt: any) => {
        // console.log(receipt, "receipt");
        if (receipt.status) {
          let ticket_number = receipt.events.FreePredictedDrawWinner.returnValues.ticketNumber;
          cb(ticket_number, receipt.transactionHash);
          Toast({ message: "Prediction transaction was successful!", type: "success" });
        } else {
          resetLoader();
          Toast({ message: "Prediction transaction was not successful!", type: "error" });
        }
      })
      .on("error", (error: any, receipt: any) => {
        resetLoader();
        console.log("error", error.message, receipt);
        if (error.message.includes(":")) {
          let msg = error.message.split(":")[0];
          Toast({ message: msg, type: "error" });
        } else Toast({ message: error.message, type: "error" });
      });
  };

  export const predictDraw100KWinner = async (
    ticketNumber: string,
    address: string,
    cb: (ticketNumber: string, txnHash: string) => void,
    resetLoader: () => void,
  ) => {
    let gasPrice = await web3.eth.getGasPrice();

    predictContract.methods
      .freePredictDraw100kWinner(ticketNumber)
      .send({ from: address, gaslimit: 1100000, gasPrice })
      .on("transactionHash", (hash: any) => {
        // console.log("hash", hash);
      })
      .on("receipt", (receipt: any) => {
        // console.log(receipt, "receipt");
        if (receipt.status) {
          let ticket_number = receipt.events.FreePredictedDraw100KWinner.returnValues.ticketNumber;
          cb(ticket_number, receipt.transactionHash);
          Toast({ message: "Prediction transaction was successful!", type: "success" });
        } else {
          resetLoader();
          Toast({ message: "Prediction transaction was not successful!", type: "error" });
        }
      })
      .on("error", (error: any, receipt: any) => {
        resetLoader();
        console.log("error", error.message, receipt);
        if (error.message.includes(":")) {
          let msg = error.message.split(":")[0];
          Toast({ message: msg, type: "error" });
        } else Toast({ message: error.message, type: "error" });
      });
  };

  export const premiumPredictDrawWinner = async (
    ticketNumber: string,
    address: string,
    cb: (ticketNumber: string, txnHash: string) => void,
    resetLoader: () => void,
  ) => {
    try {
      let amount = await getPredictionDrawFee();
      let gasPrice = await web3.eth.getGasPrice();

      predictContract.methods
        .premiumPredictDrawWinner(ticketNumber, amount)
        .send({ from: address, gaslimit: 1100000, gasPrice })
        .on("transactionHash", (hash: any) => {
          // console.log("hash", hash);
        })
        .on("receipt", (receipt: any) => {
          // console.log(receipt, "receipt");
          if (receipt.status) {
            let ticket_number = receipt.events.PremiumPredictedDrawWinner.returnValues.ticketNumber;
            cb(ticket_number, receipt.transactionHash);
            Toast({ message: "Prediction transaction was successful!", type: "success" });
          } else {
            resetLoader();
            Toast({ message: "Prediction transaction was not successful!", type: "error" });
          }
        })
        .on("error", (error: any, receipt: any) => {
          resetLoader();
          console.log("error", error.message, receipt);
          if (error.message.includes(":")) {
            let msg = error.message.split(":")[0];
            Toast({ message: msg, type: "error" });
          } else Toast({ message: error.message, type: "error" });
        });
    } catch (error) {
      resetLoader();
    }
  };

  export const premiumPredictDraw100kWinner = async (
    ticketNumber: string,
    address: string,
    cb: (ticketNumber: string, txnHash: string) => void,
    resetLoader: () => void,
  ) => {
    let amount = await getPredictionDraw100KFee();
    let gasPrice = await web3.eth.getGasPrice();

    predictContract.methods
      .premiumPredictDraw100kWinner(ticketNumber, amount)
      .send({ from: address, gaslimit: 1100000, gasPrice })
      .on("transactionHash", (hash: any) => {
        // console.log("hash", hash);
      })
      .on("receipt", (receipt: any) => {
        // console.log(receipt, "receipt");
        if (receipt.status) {
          let ticket_number = receipt.events.PremiumPredictedDraw100KWinner.returnValues.ticketNumber;
          cb(ticket_number, receipt.transactionHash);
          Toast({ message: "Prediction transaction was successful!", type: "success" });
        } else {
          resetLoader();
          Toast({ message: "Prediction transaction was not successful!", type: "error" });
        }
      })
      .on("error", (error: any, receipt: any) => {
        resetLoader();
        console.log("error", error.message, receipt);
        if (error.message.includes(":")) {
          let msg = error.message.split(":")[0];
          Toast({ message: msg, type: "error" });
        } else Toast({ message: error.message, type: "error" });
      });
  };

  export const withdrawTokens = async (address: string, resetLoaders: () => void) => {
    let gasPrice = await web3.eth.getGasPrice();

    predictContract.methods
      .withdrawTokens()
      .send({ from: address, gaslimit: 1100000, gasPrice })
      .on("transactionHash", (hash: any) => {
        // console.log("hash", hash);
      })
      .on("receipt", (receipt: any) => {
        // console.log(receipt, "receipt");
        store.dispatch(fetchPredictGameTokens());
        resetLoaders();
      })
      .on("error", (error: any) => {
        console.log("error", error.message);
        resetLoaders();
      });
  };
}
