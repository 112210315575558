import { Box, Stack, styled, Typography } from "@mui/material";
import { GRAD_COLOR_1, GRAD_COLOR_3, HUNDRED_K_DRAW, TransparentTextGradient, TransparentTextGradientForGradColor3 } from "../../../utils/constants";

namespace S {
  export const PredictionCardContainer = styled(Stack)(({ theme }) => ({
    color: "#000",
    fontWeight: 600,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    gap: "1.25rem",
    [theme.breakpoints.down("sm")]: {
      gap: "0.5rem",
      padding: "1.5rem",
    },
    padding: "2rem",
  }));

  export const PredictionHeader = styled(Box)(({ theme }) => ({
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      padding: "0 3rem",
    },
  }));

  export const PredictionCardTitle = styled(Typography)(({ theme }) => ({
    color: "#000",
    fontWeight: 600,
    fontSize: "1.185rem",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.175rem",
      marginLeft: "0",
    },
    marginLeft: "1.5rem",
  }));

  export const ThanksCardSubTitle = styled("a")(({ theme }) => ({
    display: "flex",
    color: theme.palette.custom.primary.main,
    cursor: "pointer",
    zIndex: "2",
    textDecoration: "none",
    fontWeight: 500,
    fontSize: "1rem",
    gap: "0.5rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.7rem",
    },
  }));

  export const YearnGamesIcon = styled("img")(({ theme }) => ({
    cursor: "pointer",
    width: "0.875rem",
    [theme.breakpoints.down("sm")]: {
      width: "0.75rem",
    },
  }));

  //Need to remove this
  export const PredictedTicket = styled(Typography, {
    shouldForwardProp: (prop) => prop !== "gradient",
  })(({ gradient }: { gradient: string }) => ({ theme }) => ({
    fontWeight: 600,
    fontSize: "1.375rem",
    textAlign: "center",
    background: gradient === HUNDRED_K_DRAW ? GRAD_COLOR_3 : GRAD_COLOR_1,
    webkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    backgroundClip: "text",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.175rem",
    },
  }));

  export const GradientTextContainer = styled(Box)(() => ({
    position: "relative",
    margin: "1.25rem 0",
  }));

  export const CustomText = styled(Typography)(({ theme }) => ({
    fontSize: "1.375rem",
    fontWeight: "600",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    textAlign: "center",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.175rem",
    },
  }));

  export const DefaultText = styled(CustomText, { shouldForwardProp: (prop) => prop !== "gradient" })(({ gradient }: { gradient: string }) => ({
    background: gradient === HUNDRED_K_DRAW ? GRAD_COLOR_3 : GRAD_COLOR_1,
    webkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    backgroundClip: "text",
    color: gradient === HUNDRED_K_DRAW ? GRAD_COLOR_3 : GRAD_COLOR_1,
    textShadow: `0 0px 0px ${gradient === HUNDRED_K_DRAW ? GRAD_COLOR_3 : GRAD_COLOR_1}`,
  }));

  export const ShiningText = styled(CustomText, { shouldForwardProp: (prop) => prop !== "gradient" })(({ gradient }: { gradient: string }) => ({
    color: "transparent",
    background: gradient === HUNDRED_K_DRAW ? TransparentTextGradientForGradColor3 : TransparentTextGradient,
    "-webkit-background-clip": "text",
    "-webkit-text-fill-color": "transparent",
    animation: "pool-shine 20s linear infinite",
    "@keyframes pool-shine": {
      "0%": {
        backgroundPosition: "-300px",
      },
      "100%": {
        backgroundPosition: "300px",
      },
    },
  }));

  export const ThanksCardContainer = styled(Stack)(({ theme }) => ({
    alignItems: "center",
    justifyContent: "center",
    gap: theme.spacing(4),
    ".MuiInputBase-root": {
      background: theme.palette.custom.secondary.secondary_1,
      color: theme.palette.custom.secondary.secondary_2,
      border: `1px solid ${theme.palette.custom.secondary.secondary_2}`,
    },
  }));

  export const ThanksCardTitle = styled(Typography)(() => ({
    color: "#000",
    fontWeight: 600,
    marginBottom: "0.5rem",
  }));

  export const ThanksCardContent = styled(Typography)(({ theme }) => ({
    color: theme.palette.custom.secondary.secondary_2,
    "#special-text": {
      color: `${theme.palette.custom.secondary.secondary_2} !important`,
      fontWeight: 600,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.875rem",
    },
  }));

  export const ThanksCardsPointWrapper = styled(Stack)(({ theme }) => ({
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    gap: theme.spacing(3),
  }));

  export const ThanksCardScrollContainer = styled(Stack)(({ theme }) => ({
    gap: theme.spacing(3),
    height: "100%",
    maxHeight: "20vh",
    overflow: "auto",
  }));

  export const ThanksCardPointsContainer = styled(Box)(({ theme }) => ({
    width: "95%",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    gap: theme.spacing(2),
  }));

  export const UpgradeTextContainer = styled(Box)(({ theme }) => ({
    display: "flex",
    justifyContent: "center",
    backgroundColor: "#CBF6FF",
    padding: "0.5rem 2rem",
  }));

  export const PoolUrl = styled("a")(({ theme }) => ({
    color: theme.palette.custom.primary.main,
    textDecoration: "none",
  }));

  export const CheckBoxImage = styled("img")(() => ({
    width: "1.2rem",
    height: "auto",
  }));

  export const PredictionMainWrapper = styled(Box)(() => ({
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  }));
}

export default S;
