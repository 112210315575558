import { Box } from "@mui/material";
import { styled } from "@mui/material";

namespace S {
  export const CornerImg = styled("img")(({ theme }) => ({
    position: "absolute",
    [theme.breakpoints.down("sm")]: {
      width: "100px ",
      height: "auto",
    },
  }));

  export const ContentWrapper = styled(Box)(({ theme }) => ({
    height: "100%",
    display: "grid",
    gridTemplateRows: "85% 15%",
  }));

  export const GreetingsModalBody = styled(Box)(({ theme }) => ({
    padding: "3rem 2rem",
    [theme.breakpoints.down("sm")]: {
      padding: "0.75rem",
    },
  }));
}

export default S;
