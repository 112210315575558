import { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListIcon from "@mui/icons-material/List";
import { Box } from "@mui/material";
import moment from "moment";
import { useAccount, useWalletClient } from "wagmi";
import S from "./game-card.styled";
import Logo1 from "../../../assets/images/countryLogo1.png";
import Logo2 from "../../../assets/images/countryLogo2.png";
import BottmBarImg from "../../../assets/images/flashBar.png";
import TrophyImage from "../../../assets/images/gameTrophy.png";
import Player1 from "../../../assets/images/player1.png";
import Player2 from "../../../assets/images/player2.png";
import VersesImg from "../../../assets/images/verses.png";
import Trophy from "../../../assets/images/worldcupTrophy.png";
import { PredictionToEarn } from "../../../services/ethereum/contract/predictionPTE";
import { PredictionPoolContract } from "../../../services/ethereum/contract/predictionPoolContract";
import { PRIMARY, cricketTeams, getFlag } from "../../../utils/constants";
import { useIsAppLoading, useIsReady } from "../../../utils/hooks/selector";
import { Button } from "../../../utils/widgets";
import GameCardSkeleton from "../../../utils/widgets/skeletons/game-card-skeleton";

const GameCard = () => {
  //states
  const [active, setActive] = useState("cricket");
  const [timeRemaining, setTimeRemaining] = useState({ hours: 0, minutes: 0, seconds: 0 });
  const [countdownEnded, setCountdownEnded] = useState(false);
  const [poolContract, setPoolContract] = useState<any>(null);
  const [predictionDetail, setPredictionDetail] = useState<{
    endTime: number | null;
  }>({
    endTime: null,
  });

  const isAppLoading = useSelector(useIsAppLoading);
  const isReady = useSelector(useIsReady);
  const { data: signer } = useWalletClient();
  //constants
  const navigate = useNavigate();
  let liveMatchId: string | number | null = null;

  for (const team of cricketTeams) {
    if (team.status === "live") {
      liveMatchId = team.pool;
      break; // Stop searching once a live match is found
    }
  }

  const liveCricketData = cricketTeams.filter((c) => c.pool === liveMatchId)[0];

  //functions
  const navigateCricketScreen = () => {
    if (liveMatchId !== null) {
      navigate(`/cricket/${liveMatchId}`);
    } else {
      navigate(`/cricket/${cricketTeams[0].id}`);
    }
  };

  useEffect(() => {
    if (!isReady && !liveCricketData?.pool) return;
    const getPoolContract = async () => {
      try {
        const poolContract = await PredictionPoolContract.getPoolAddress(liveCricketData?.pool);

        setPoolContract(poolContract);
      } catch (error) {
        setPoolContract(null);
      }
    };
    getPoolContract();
  }, [isReady, liveCricketData]);

  useEffect(() => {
    if (!poolContract) {
      return;
    } else {
      const getPoolData = async () => {
        try {
          let matchData: any = await PredictionToEarn.poolBasicBatch(signer, poolContract);

          setPredictionDetail({
            endTime: matchData?.endTime || null,
          });
        } catch (error) {
          console.log(error);
        }
      };
      getPoolData();
    }
  }, [poolContract]);

  // To calculate end time
  useEffect(() => {
    if (predictionDetail.endTime !== null) {
      const intervalId = setInterval(() => {
        const now = new Date().getTime();
        const difference = predictionDetail.endTime! * 1000 - now;

        if (difference <= 0) {
          // The countdown has ended
          clearInterval(intervalId);
          setTimeRemaining({ hours: 0, minutes: 0, seconds: 0 });
          setCountdownEnded(true);
        } else {
          const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
          const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
          const seconds = Math.floor((difference % (1000 * 60)) / 1000);

          setTimeRemaining({ hours, minutes, seconds });
        }
      }, 1000); // Update every second

      // Clean up the interval when the component unmounts
      return () => clearInterval(intervalId);
    }
  }, [predictionDetail.endTime]);

  const formatTime = (value: number) => (value < 10 ? `0${value}` : value);

  return (
    <>
      {isAppLoading || !isReady ? (
        <GameCardSkeleton />
      ) : (
        <S.GameContainer data-tut="tour-game-predict">
          <S.Header>
            <S.HeadContainer>
              <S.GradientText shineText={"#predict2yearn"}>#predict2yearn</S.GradientText>
              <S.ListIconButton disabled={false}>
                <ListIcon />
              </S.ListIconButton>
            </S.HeadContainer>
            <S.TabContainer>
              <S.ToggleText active={active === "cricket"} onClick={() => setActive("cricket")}>
                CRICKET
              </S.ToggleText>
              <S.ToggleText active={active === "soccer"} onClick={() => setActive("soccer")}>
                SOCCER
              </S.ToggleText>
            </S.TabContainer>
          </S.Header>
          {active === "cricket" ? (
            <>
              {!liveCricketData ? (
                <S.NoTournamentContainer>
                  <S.SorryText>SORRY</S.SorryText>
                  <S.NoTournamentText>NO CURRENT</S.NoTournamentText>
                  <S.NoTournamentText>TOURNAMENTS</S.NoTournamentText>
                  <S.SorryText sx={{ textAlign: "right" }}>ONGOING</S.SorryText>
                </S.NoTournamentContainer>
              ) : (
                <>
                  <S.ImageContainer>
                    <S.InnerImageContainer>
                      <S.GameImage src={getFlag(liveCricketData.teamA.flag)} alt="cricket-flag" />
                      <S.GameImage src={Trophy} alt="trophy-image" />
                      <S.GameImage src={getFlag(liveCricketData.teamB.flag)} alt="cricket-flag" />
                    </S.InnerImageContainer>
                  </S.ImageContainer>

                  <S.Body>
                    <S.TournamentText>Tournament</S.TournamentText>
                    <S.TrophyContainer>
                      <Box>
                        <img src={TrophyImage} alt="Trophy" />
                      </Box>
                      <S.TrophyTextContainer>
                        <S.TrophyText>{liveCricketData.tournament.name}</S.TrophyText>
                        <S.TrophySubText>
                          {liveCricketData?.tournament.matchType} {liveCricketData?.matchNumber} OF {liveCricketData?.tournament.totalMatches} ·{" "}
                          {moment(liveCricketData?.matchTimeStamp).format("Do MMM YYYY")} · {liveCricketData?.matchVenue}
                        </S.TrophySubText>
                      </S.TrophyTextContainer>
                    </S.TrophyContainer>
                    <S.LocksInContainer>
                      <S.LocksInText>
                        {countdownEnded
                          ? "Locks In Soon"
                          : `Locks In: ${formatTime(timeRemaining.hours)}:${formatTime(timeRemaining.minutes)}:${formatTime(timeRemaining.seconds)}`}
                      </S.LocksInText>
                      <S.ListIconButton disabled={false}>
                        <ChevronRightIcon />
                      </S.ListIconButton>
                    </S.LocksInContainer>
                  </S.Body>
                </>
              )}
              <Box data-tut="tour-game-predict-button">
                <Button
                  buttonType={PRIMARY}
                  disabled={false}
                  onClick={navigateCricketScreen}
                  containerCustomStyle={{
                    padding: "0 1.25rem",
                  }}
                  customStyle={{
                    fontSize: "14px",
                    width: "100% !important",
                  }}
                >
                  Play Now
                </Button>
              </Box>
            </>
          ) : (
            <>
              <S.soccerImageContainer>
                <S.TotalImageContainer>
                  <S.PlayerImageContainer>
                    <S.CountryLogoImage src={Logo1} />
                    <S.PlayerImage src={Player1} />
                  </S.PlayerImageContainer>
                  <S.VersesImage src={VersesImg} />
                  <S.PlayerImageContainer>
                    <S.CountryLogoImage src={Logo2} />
                    <S.PlayerImage src={Player2} />
                  </S.PlayerImageContainer>
                </S.TotalImageContainer>
                <S.BarContainer>
                  <S.BarImage src={BottmBarImg} />
                </S.BarContainer>
                <S.TextContainer>
                  <S.CommingSoonText>COMING SOON</S.CommingSoonText>
                </S.TextContainer>
              </S.soccerImageContainer>
              <S.soccerButtonContainer>
                <Button
                  buttonType={PRIMARY}
                  disabled={true}
                  onClick={() => {}}
                  customStyle={{
                    fontSize: "14px",
                    width: "100% !important",
                  }}
                >
                  Play Now
                </Button>
              </S.soccerButtonContainer>
            </>
          )}
        </S.GameContainer>
      )}
    </>
  );
};

export default GameCard;
