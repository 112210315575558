import { FC, useState } from "react";
import S from "./greetings.styled";
import gameConsoleImg from "../../assets/images/greetings-assets/game-console.png";
import guideImg from "../../assets/images/greetings-assets/guide.svg";
import PopperImg from "../../assets/images/greetings-assets/poppers.png";
import { participationGuide } from "../../utils/constants";
import GreetingsModal from "../modals/greetings-modal";
import ThanksCardFooter from "./footer";
import ThanksCard from "./thanks-card";
import UserGuide from "./user-guide";
import YearnGamesCard from "./yearn-games-card";

type GreetingsModalType = {
  open: boolean;
  handleClose: () => void;
};

const GreetingCardModal: FC<GreetingsModalType> = (props) => {
  //props
  const { open, handleClose } = props;

  // states values
  const [page, setPage] = useState(0);

  //functions
  const getModalContent = () => {
    switch (page) {
      case 0:
        return {
          component: <ThanksCard />,
          img: PopperImg,
          customStyles: {
            top: "-26px",
            left: "-13px",
          },
        };
      case 1:
        return {
          component: <YearnGamesCard />,
          img: gameConsoleImg,
          customStyles: {
            top: "-41px",
            left: "-31px",
          },
        };
      case 2:
        return {
          component: <UserGuide guide={participationGuide} />,
          img: guideImg,
          customStyles: {
            top: "-41px",
            left: "-31px",
          },
        };
      default:
        return {
          component: <ThanksCard />,
          img: PopperImg,
          customStyles: {
            top: "-26px",
            left: "-13px",
          },
        };
    }
  };

  const handlePage = (value?: number) => setPage(value ?? page + 1);

  return (
    <GreetingsModal open={open} handleClose={handleClose}>
      <S.CornerImg sx={getModalContent().customStyles} src={getModalContent().img} draggable={false} alt="" />
      <S.ContentWrapper>
        <S.GreetingsModalBody>{getModalContent().component}</S.GreetingsModalBody>
        <ThanksCardFooter totalPages={4} currentPage={page + 1} page={page} onClick={handlePage} handleClose={handleClose} />
      </S.ContentWrapper>
    </GreetingsModal>
  );
};

export default GreetingCardModal;
