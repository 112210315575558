import { styled, Box, Grid } from "@mui/material";

namespace S {
  export const WinningCounterModalContainer = styled(Grid, { shouldForwardProp: (prop) => prop !== "showWinner" })(
    ({ showWinner }: { showWinner: boolean }) =>
      ({ theme }) => ({
        display: "grid",
        alignItems: "center",
        justifyContent: "space-between",
        gridTemplateColumns: showWinner ? "auto auto" : "auto",
        gap: theme.spacing(4),
        [theme.breakpoints.down("md")]: {
          display: "flex",
          justifyContent: "center",
          // gridTemplateColumns: "auto",
        },
      }),
  );

  export const WinningTimerContainer = styled(Box)(({ theme }) => ({
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    backgroundColor: "rgba(0,0,0,0.6)",
    border: `3px solid ${theme.palette.custom.secondary.secondary_5}`,
    borderRadius: "2rem",
    [theme.breakpoints.down("sm")]: {
      minHeight: "300px",
      maxWidth: "95vw",
    },
  }));
}

export default S;
