import { Theme } from "@mui/material";
import { useMediaQuery, Breakpoint } from "@mui/material";
import CryptoJS from "crypto-js";
import { ethers } from "ethers";
import moment from "moment";
import Web3 from "web3";
import { predictionContract } from "../../services/ethereum/contract/predictionContract";
import { web3 } from "../../services/ethereum/contract/web3";
import { lottoContract } from "../../services/ethereum/contract/web3Contract";
import store from "../../store";
import { setWinningTimer } from "../../store/slices/user";
import { verifyReferrer, verifyReferrerByAddress, verifyReferrerById } from "../../store/slices/user.actions";
import { CRICKET_PATH_NAME, REFERRAL_PATH_NAME } from "../constants";
import { CounterTimerArgsType } from "../types/counter";
import { UserSlicetypes } from "../types/state";
import Toast from "../widgets/toast";
import { LOCAL_STORAGE_EXCEPTION } from "./../constants/index";

export const getWholeNum = (count: string) => {
  let len = count?.length;
  switch (len) {
    case 1:
      return "00000" + count;
    case 2:
      return "0000" + count;
    case 3:
      return "000" + count;
    case 4:
      return "00" + count;
    case 5:
      return "0" + count;
    default:
      return count;
  }
};

export function toFixed(num: number, fixed: number) {
  fixed = fixed || 0;
  fixed = Math.pow(10, fixed);
  return Math.floor(num * fixed) / fixed;
}

export const getInvitedBy = (pathName: string): UserSlicetypes.InvitedBy => {
  const pathArray = pathName.split("/").filter(Boolean);
  const isReferredBy = pathArray[0] === REFERRAL_PATH_NAME;
  const isCricket = pathArray[0] === CRICKET_PATH_NAME;

  if (pathArray.length && isReferredBy && pathArray.length === 3) {
    const invitedBy = {
      referrerId: pathArray[1],
      address: pathArray[2],
    };
    return invitedBy;
  } else if (pathArray.length && isCricket && pathArray.length) return { cricket: true, referrerId: "", address: "" };
  return null;
};

export const isMyself = (userId: string) => {
  const { userDetails } = store.getState().user;
  return userDetails.id === userId;
};

export const verifyOwners = async (address: string) => {
  let isOwner = false;
  const owners = await Promise.all([lottoContract.getLottoOwner(), predictionContract.getPredictionOwner()]);
  isOwner = owners.includes(getChecksumAddress(address));
  // console.log("isOwner", isOwner);

  return isOwner;
};

export const getProgressPercentage = (current: number, total: number, isFreeUser: boolean = false): number => {
  const currentDrawCount = current % total;
  const percentage = Math.floor((currentDrawCount / total) * 100);
  if (currentDrawCount <= total) return percentage;
  else if (isFreeUser) return currentDrawCount <= total ? percentage : 0;
  else return 100;
};

export const getWalletBalance = async (account: string) => {
  let walletBalance = await web3.eth.getBalance(account);
  let balEther = parseFloat(web3.utils.fromWei(walletBalance, "ether")).toFixed(4);
  return balEther;
};

export const getRoundAmount = (amount: string) => {
  return parseFloat(web3.utils.fromWei(amount, "ether")).toFixed(2);
};

export const getOneKSlot = (drawNumber: number, draw1kNumber: number) => {
  const from = drawNumber === 1 ? "1" : `${(drawNumber - 1) * draw1kNumber + 1}`;
  const to = `${drawNumber * draw1kNumber}`;

  return `${from} - ${to}`;
};

export const getTenKSlot = (drawNumber: number, draw10kNumber: number) => {
  const from = drawNumber === 1 ? "1" : `${(drawNumber - 1) * draw10kNumber + 1}`;
  const to = `${drawNumber * draw10kNumber}`;

  return `${from} - ${to}`;
};

export const getEllipsisString = (text: string, start: number, end: number) => {
  if (text.trim().length < start + end) return text;
  const startWith = text.slice(0, start);
  const endWith = text.slice(-end);
  return `${startWith}....${endWith}`;
};

export const getChecksumAddress = (address: string) => {
  return ethers.utils.getAddress(address);
};

export const checkReferrer = async (referrerId: string, referrerAddress: string) => {
  if (referrerId && referrerAddress) {
    let res = await verifyReferrer(referrerId, referrerAddress);
    return res;
  } else if (referrerId && !referrerAddress) {
    let res = await verifyReferrerById(referrerId);
    return res;
  } else if (referrerAddress && !referrerId) {
    let res = await verifyReferrerByAddress(referrerAddress);
    return res;
  }
};

export const addMumbaiMaticChain = async (provider: any) => {
  if (provider) {
    const chainId = 80001;
    try {
      await provider.request({
        method: "wallet_addEthereumChain",
        params: [
          {
            chainId: `0x${chainId.toString(16)}`,
            chainName: "Mumbai Matic",
            nativeCurrency: {
              name: "Matic",
              symbol: "matic",
              decimals: 18,
            },
            rpcUrls: [process.env.REACT_APP_MATIC_RPC_URL],
            blockExplorerUrls: [process.env.REACT_APP_MUMBAI_POLYGON_EXPLORER],
          },
        ],
      });
      Toast({ message: "Switched to Mumbai Testnet successfully!", type: "success" });
      return true;
    } catch (e: any) {
      console.error(e);
      Toast({ message: "Kindly switch to Mumbai Testnet!" });
      return false;
    }
  } else {
    Toast({ message: "No valid provider found!", type: "error" });
  }
};

export const handleCounterTimer = ({ clearTimer, onClose = () => {} }: CounterTimerArgsType) => {
  const winningTimer = store.getState().user.winningTimer;
  let hoursValue = Number(winningTimer.hours);
  let minutesValue = Number(winningTimer.minutes);
  let secondsValue = Number(winningTimer.seconds);

  let counterInterval = setInterval(() => {
    hoursValue = hoursValue < 0 ? 0 : hoursValue;
    minutesValue = minutesValue < 0 ? 59 : minutesValue;
    secondsValue = secondsValue < 0 ? 59 : secondsValue;

    if (hoursValue === 0 && minutesValue === 0 && secondsValue === 0) {
      if (clearTimer) {
        clearInterval(counterInterval);
        onClose();
      } else {
        hoursValue = 0;
        minutesValue = 6;
        secondsValue = 0;
      }
    }

    store.dispatch(
      setWinningTimer({
        hours: hoursValue.toString().length === 1 ? `0${hoursValue}` : `${hoursValue}`,
        minutes: minutesValue.toString().length === 1 ? `0${minutesValue}` : `${minutesValue}`,
        seconds: secondsValue.toString().length === 1 ? `0${secondsValue}` : `${secondsValue}`,
      }),
    );

    if (hoursValue > 0 || minutesValue > 0 || secondsValue > -1) {
      secondsValue--;
    }
    if (secondsValue < 0) {
      minutesValue--;
    }
    if (minutesValue < 0) {
      hoursValue--;
    }
  }, 1000);
  return counterInterval;
};

//Draw completion
export const getOneKCompleted = (ticketsSold: number, recentWinner: string, currentDraw: string, draw1KNumber: number) => {
  let completed = false;
  if (!recentWinner?.length && ticketsSold !== 0 && ticketsSold % draw1KNumber === 0 && +currentDraw * draw1KNumber === ticketsSold) completed = true;

  return completed;
};

export const getTenKCompleted = (ticketsSold: number, recentWinner: string, currentTenKDraw: string, draw10kNumber: number) => {
  let completed = false;
  if (!recentWinner?.length && ticketsSold !== 0 && ticketsSold % draw10kNumber === 0 && +currentTenKDraw * draw10kNumber === ticketsSold)
    completed = true;

  return completed;
};

export const getHundredKCompleted = (ticketsSold: number, recentWinner: string, draw100kNumber: number) => {
  let completed = false;
  if (!recentWinner?.length && ticketsSold !== 0 && ticketsSold % draw100kNumber === 0) completed = true;

  return completed;
};

export const isNumber = (text: string) => {
  let isNumber = Number.isInteger(Number(text));
  return isNumber;
};

export const getExplorerLink = (chainId: Number, hash: string) => {
  switch (chainId) {
    case 80001: {
      return `${process.env.REACT_APP_MUMBAI_POLYGON_EXPLORER}/tx/${hash}`;
    }
    case 56: {
      return `${process.env.REACT_APP_BSC_EXPLORER}/tx/${hash}`;
    }
    default: {
      return "/";
    }
  }
};

export const exploreAccount = (chainId: number, address: string) => {
  switch (chainId) {
    case 80001: {
      return `${process.env.REACT_APP_MUMBAI_POLYGON_EXPLORER}/address/${address}`;
    }
    case 56: {
      return `${process.env.REACT_APP_BSC_EXPLORER}/address/${address}`;
    }
    default: {
      return "/";
    }
  }
};

export const formatNumber = (num: number) => {
  let formatter = Intl.NumberFormat("en-US");
  return formatter.format(num);
};

//TODO: Need to verify with the appstatus
export const getCurrentTenKdraw = (ticketsSold: number, appStatus: string, draw10kNumber: number) => {
  let draw = 0;
  draw = Math.floor(+ticketsSold / draw10kNumber);

  return draw;
};

export const useGetDimensions = (dim?: Breakpoint) => useMediaQuery((theme: Theme) => theme.breakpoints.down(dim ?? "sm"));

export const FindPercentage = (totalpercent = 100, currentpercent: number) => {
  const result = ((totalpercent - currentpercent) * 100) / totalpercent;
  return result;
};

export const convertWeiToEth = (weiAmount: string | undefined) => {
  if (weiAmount) return Web3.utils.fromWei(weiAmount, "ether");
};

export const getEarnedAmountVariant = (amount: string) => {
  let variant: "h2" | "h3" | "h4" = "h2";
  const amountLength = amount.length;
  switch (amountLength) {
    case 9:
    case 10:
    case 11:
      variant = "h3";
      break;
    case 12:
    case 13:
    case 14:
    case 15:
      variant = "h4";
      break;
    default:
      break;
  }
  return variant;
};

export const getClaimButtonAvailability = (nextAvailableAt: number) => {
  const now = +new Date();
  let availability = "";
  if (nextAvailableAt > now) availability = moment(nextAvailableAt).fromNow();
  return availability;
};

export const getDurationInWords = (milliSeconds: number) => {
  let humanized = "";
  const duration = moment.duration(milliSeconds);
  humanized = duration.humanize();

  return humanized;
};

export const toFixedOf = (value: number, fixedOf: number) => {
  return !isNaN(value) && !isNaN(fixedOf) ? parseFloat(parseFloat(value.toString()).toFixed(fixedOf)) : value;
};

export const formatNumberToSmallScale = (number: number) => {
  if (number / Math.pow(10, 9) >= 1) return `${toFixedOf(number / Math.pow(10, 9), 2)}B`;
  else if (number / Math.pow(10, 6) >= 1) return `${toFixedOf(number / Math.pow(10, 6), 2)}M`;
  // else if ((number / Math.pow(10, 3)) >= 1) return `${toFixedOf(number / Math.pow(10, 3), 2)}K`

  return number;
};

export const encryptCrypto = (data: any) => {
  const salt = process.env.REACT_APP_SALT ?? "";
  const ciphertext = CryptoJS.AES.encrypt(JSON.stringify(data), salt).toString();

  return ciphertext;
};

export const clearLocalStorageWithException = () => {
  for (let i = 0; i < localStorage.length; i++) {
    const key: any = localStorage.key(i);

    // Check if the current key is not in the exceptions list
    if (!LOCAL_STORAGE_EXCEPTION.includes(key)) {
      // Remove the item from local storage
      localStorage.removeItem(key);
    }
  }
};

export const hasDecimals = (data: number) => data !== Math.floor(data);
