import { FC } from "react";
import S from "./jackpot-winner-body.styled";

type JackpotWinnerBodyPropsType = {
  children: React.ReactNode;
};

const JackpotWinnerBody: FC<JackpotWinnerBodyPropsType> = ({ children }) => {
  return <S.JackpotWinnerBodyContainer>{children}</S.JackpotWinnerBodyContainer>;
};

export default JackpotWinnerBody;
