import { Stack, styled, Typography } from "@mui/material";
import { HUNDRED_K_DRAW, ONE_K_DRAW, TEN_K_DRAW } from "../../../../utils/constants";
import { Box } from "@mui/material";


namespace S {
  export const JackpotWinnerHeaderContainer = styled(Stack)(({ theme }) => ({
    gap: theme.spacing(4),
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(6),
    marginLeft: theme.spacing(6),
    marginRight: theme.spacing(6),
  }));

  export const HeaderText1 = styled(Typography)(({ theme }) => ({
    fontSize: "30px",
    fontWeight: "600",
    [theme.breakpoints.down("md")]: {
      fontSize: "20px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
    },
  }));
  export const HeaderText2 = styled(Typography)(({ theme }) => ({
    fontSize: "42px",
    fontWeight: "600",
    [theme.breakpoints.down("md")]: {
      fontSize: "30px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "20px",
    },
  }));

  export const HighlightText = styled("span", {
    shouldForwardProp: (prop) => prop !== "customStyle",
  })(({ customStyle }: { customStyle?: string }) => () => ({
    background:
      customStyle === ONE_K_DRAW
        ? "linear-gradient(92.48deg, #1967FC 1.43%, #19FCB8 75%)"
        : customStyle === TEN_K_DRAW
        ? "linear-gradient(268.66deg, #FFE600 5.41%, #24FF00 93.94%)"
        : customStyle === HUNDRED_K_DRAW
        ? " linear-gradient(337.35deg, #EB00FF 7.34%, #E9B80C 91.96%)"
        : "",
    webkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    backgroundClip: "text",
  }));

  export const JackpotWinningTextContainer = styled(Box)(({ theme }) => ({
    position: "relative",
  }));

  export const JackpotWinningText = styled(Typography)(() => ({
    textAlign: "center",
    background: "linear-gradient(169.74deg, #F4831F 30.05%, #F9F6AE 50.28%, #E49A29 69.27%)",
    webkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    backgroundClip: "text",
    WebkitTextStroke: "2px #b6421e",
    fontWeight: 600,
    position: "relative",
    animation: "zoomInWithJackIntheBox 3s forwards",
    "@keyframes zoomInWithJackIntheBox": {
      from: {
        opacity: 0,
        transform: " scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0) rotate(30deg)",
        animationTimingFunction: "cubic-bezier(0.55, 0.055, 0.675, 0.19)",
      },
      "50%": {
        opacity: 0.7,
        transform: "rotate(-10deg)",
      },
      "60%": {
        opacity: 1,
        transform: "scale3d(0.475, 0.475, 0.475) translate3d(0, 0px, 0) rotate(5deg)",
        animationTimingFunction: "cubic-bezier(0.175, 0.885, 0.32, 1)",
      },
      "100%": {
        transform: "translate3d(0, -10px, 0)",
      },
    },
  }));
}

export default S;