import { FC, useEffect, useCallback, memo } from "react";
import { useSelector } from "react-redux";
import S from "./winning-body-styled";
import { setHundredKWinner, updateRecentWinners } from "../../../../store/slices/user";
import { getHundredKDrawWinnerDetails, getOneKDrawWinnerDetails, getTenKDrawWinnerDetails } from "../../../../store/slices/user.actions";
import { HUNDRED_K_DRAW, ONE_K_DRAW, TEN_K_DRAW } from "../../../../utils/constants";
import { getCurrentTenKdraw, handleCounterTimer, useGetDimensions } from "../../../../utils/helpers";
import {
  useAppStatus,
  useCurrentDraw,
  useCurrentTenKDraw,
  useDraw10kNumber,
  useEvents,
  useTicketsSold,
  useWinningTimer,
} from "../../../../utils/hooks/selector";
import { useAppDispatch } from "../../../../utils/hooks/state";
import { AppStatus } from "../../../../utils/types";
import FlipCounter from "../../../../utils/widgets/counter/FlipCounter";

type WinningBodyPropsType = {
  handleWinnerOverlayClosing: () => { winnerId: string; value: string };
  isClearTimer: boolean;
};

const WinningCounterBody: FC<WinningBodyPropsType> = ({ handleWinnerOverlayClosing, isClearTimer }) => {
  //constructors
  const dispatch = useAppDispatch();

  //constants
  const isSm = useGetDimensions();

  //state values
  const appStatus = useSelector(useAppStatus);
  const currentDraw = useSelector(useCurrentDraw);
  const ticketsSold = useSelector(useTicketsSold);
  const winningTimer = useSelector(useWinningTimer);
  const recentWinners = useSelector(useEvents).recentWinners;
  const currentTenKDraw = useSelector(useCurrentTenKDraw);
  const draw10kNumber = useSelector(useDraw10kNumber);

  //functions
  //The below 1k,10k and 100k functions are will fetch data in interval untill we receive winner from graph.
  const updateOneK = async () => {
    let fetch1KWinnerInterval: ReturnType<typeof setInterval>;
    if (!recentWinners[ONE_K_DRAW].ticketNumber) {
      fetch1KWinnerInterval = setInterval(async () => {
        let data = await getOneKDrawWinnerDetails(currentDraw);
        if (data) {
          dispatch(
            updateRecentWinners({
              draw: ONE_K_DRAW,
              winner: {
                userId: data?.userId,
                drawNumber: data?.drawNumber,
                ticketNumber: data?.ticketNumber,
              },
            }),
          );
          data.ticketNumber && clearInterval(fetch1KWinnerInterval);
        }
      }, 2000);
    }
  };

  const updateTenK = async () => {
    let fetch10KWinnerInterval: ReturnType<typeof setInterval>;
    if (!recentWinners[TEN_K_DRAW].ticketNumber) {
      fetch10KWinnerInterval = setInterval(async () => {
        const tenKdraw = getCurrentTenKdraw(+ticketsSold, appStatus, draw10kNumber);
        let data = await getTenKDrawWinnerDetails(tenKdraw.toString());
        if (data) {
          dispatch(
            updateRecentWinners({
              draw: TEN_K_DRAW,
              winner: {
                userId: data?.userId,
                drawNumber: data?.drawNumber,
                ticketNumber: data?.ticketNumber,
              },
            }),
          );
          data.ticketNumber && clearInterval(fetch10KWinnerInterval);
        }
      }, 2000);
    }
  };

  const updateHundredK = async () => {
    let fetch100KWinnerInterval: ReturnType<typeof setInterval>;
    if (!recentWinners[TEN_K_DRAW].ticketNumber) {
      fetch100KWinnerInterval = setInterval(async () => {
        let data = await getHundredKDrawWinnerDetails();
        if (data) {
          dispatch(
            updateRecentWinners({
              draw: HUNDRED_K_DRAW,
              winner: {
                userId: data?.userId,
                drawNumber: data?.drawNumber ?? 1,
                ticketNumber: data?.ticketNumber,
              },
            }),
          );
          dispatch(setHundredKWinner(data));
          data.ticketNumber && clearInterval(fetch100KWinnerInterval);
        }
      }, 2000);
    }
  };

  useEffect(() => {
    const handleStatus = async () => {
      switch (appStatus) {
        case AppStatus.drawOneKWinner: {
          updateOneK();
          break;
        }
        case AppStatus.drawTenKWinner: {
          if (!recentWinners[ONE_K_DRAW].drawNumber) updateOneK();
          updateTenK();
          break;
        }
        case AppStatus.drawHundredKWinner: {
          if (!recentWinners[ONE_K_DRAW].drawNumber) updateOneK();
          if (!recentWinners[TEN_K_DRAW].drawNumber) updateTenK();
          setTimeout(() => updateHundredK(), 5000);
          break;
        }
        default:
          break;
      }
    };
    handleStatus();
  }, [appStatus]);

  const MemoizedHandlerCounter = useCallback(() => {
    let counterInterval: any;
    let counterProps = {
      clearTimer: isClearTimer,
      onClose: handleWinnerOverlayClosing,
    };
    counterInterval = handleCounterTimer(counterProps);
    return () => {
      clearInterval(counterInterval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let cleanup = MemoizedHandlerCounter();

    return () => {
      cleanup();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <S.WinningBodyContainer direction={"row"}>
      <FlipCounter width={70} height={100} numbers={winningTimer.hours} isTimer={true} />
      <S.WinningBodyTimeDivider variant={isSm ? "h3" : "h1"}>:</S.WinningBodyTimeDivider>
      <FlipCounter width={70} height={100} numbers={winningTimer.minutes} isTimer={true} />
      <S.WinningBodyTimeDivider variant={isSm ? "h3" : "h1"}>:</S.WinningBodyTimeDivider>
      <FlipCounter width={70} height={100} numbers={winningTimer.seconds} isTimer={true} />
    </S.WinningBodyContainer>
  );
};

export default memo(WinningCounterBody);
