import { FC } from "react";
import { useSelector } from "react-redux";
import { Table, TableBody, TableHead, TableRow, Tooltip, Typography } from "@mui/material";
import moment from "moment";
import S from "./winners-body.styled";
import { CURRENT_CHAIN_ID, EXPLORER_URL, HUNDRED_K_DRAW, ONE_K_DRAW, TEN_K_DRAW } from "../../../../utils/constants";
import { getChecksumAddress, getEllipsisString, getOneKSlot, getTenKSlot, getWholeNum, isMyself } from "../../../../utils/helpers";
import { useDraw100kNumber, useDraw10kNumber, useDraw1kNumber } from "../../../../utils/hooks/selector";
import { UserSlicetypes } from "../../../../utils/types/state";
import RoundImage from "../../../../utils/widgets/RoundImage";

type DrawWinnersPropsType = {
  winnersList: UserSlicetypes.IUsers[] | [];
  draw: string;
  searchValue: string;
};

const DrawWinnersTable: FC<DrawWinnersPropsType> = ({ winnersList, draw, searchValue }) => {
  //constants
  const DRAW_WINNERS_TABLE_HEADINGS = ["WINNERS", "LUCKY PASS", "SLOT", "DATE", "ADDRESS", "TRANSACTION HASH"];
  const draw1kNumber = useSelector(useDraw1kNumber);
  const draw10kNumber = useSelector(useDraw10kNumber);
  const draw100kNumber = useSelector(useDraw100kNumber);

  const explorerUrl = EXPLORER_URL[CURRENT_CHAIN_ID];

  return (
    <S.TableContainer>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            {DRAW_WINNERS_TABLE_HEADINGS.map((heading, i) => (
              <S.TableHeading key={`${heading}_${i}`}>{heading}</S.TableHeading>
            ))}
          </TableRow>
        </TableHead>
        {Boolean(winnersList.length) && (
          <TableBody>
            {winnersList.map((winner) => {
              let isMe = isMyself(winner.userId);
              let slot =
                draw === ONE_K_DRAW
                  ? getOneKSlot(winner.drawNumber, draw1kNumber)
                  : draw === TEN_K_DRAW
                  ? getTenKSlot(winner.drawNumber, draw10kNumber)
                  : `1 - ${draw100kNumber}`;
              let date = moment(winner.blockTimestamp).format("DD MMM YYYY");

              return (
                <TableRow key={winner.id}>
                  <S.TableContent mySelf={isMe}>
                    <S.DetailsWrapper mySelf={isMe}>
                      <RoundImage src={winner.dp} alt="w-mem" height={30} width={30} />
                      <Typography>
                        #{winner.userId}&nbsp;{isMe && <span>(Me)</span>}
                      </Typography>
                    </S.DetailsWrapper>
                  </S.TableContent>
                  <S.TableTicketContent mySelf={isMe} isTenK={draw === TEN_K_DRAW} isHundredK={draw === HUNDRED_K_DRAW}>
                    YT {getWholeNum(winner.ticketId)}
                  </S.TableTicketContent>
                  <S.TableContent mySelf={isMe}>{slot}</S.TableContent>
                  <S.TableContent mySelf={isMe}>{date}</S.TableContent>
                  <S.TableContent mySelf={isMe}>
                    <Tooltip title="Click to explore account" key={winner.id}>
                      <a href={`${explorerUrl}/address/${winner.address}`} target="_blank" key={`${winner.address}_${winner.id}`}>
                        {getEllipsisString(getChecksumAddress(winner.address), 6, 5)}
                      </a>
                    </Tooltip>
                  </S.TableContent>
                  <S.TableContent mySelf={isMe}>
                    <Tooltip title="Click to explore transaction" key={`${winner.transactionHash}_${winner.id}`}>
                      <a href={`${explorerUrl}/tx/${winner.transactionHash}`} target="_blank" key={`${winner.transactionHash}_${winner.id}`}>
                        {getEllipsisString(winner.transactionHash, 6, 5)}
                      </a>
                    </Tooltip>
                  </S.TableContent>
                </TableRow>
              );
            })}
          </TableBody>
        )}
      </Table>
      {!winnersList.length && Boolean(searchValue.trim().length) && (
        <S.NoRecordContainer>
          <Typography variant="body1">There is no winner for this address : {searchValue}</Typography>
        </S.NoRecordContainer>
      )}
    </S.TableContainer>
  );
};

export default DrawWinnersTable;
