import { styled, Box } from "@mui/material";

namespace S {
  export const SkeletonContainer = styled(Box)(({ theme }) => ({
    boxSizing: "border-box",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    zIndex: 2,
    height: "504px",
    borderRadius: "10px",
    backgroundColor: theme.palette.custom.secondary.secondary_3,
    [theme.breakpoints.down("sm")]: {
      height: "auto",
    },
  }));

  export const Header = styled(Box)(({ theme }) => ({
    boxSizing: "border-box",
    width: "100%",
    height: "68px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "1rem",
    gap: "10px",
  }));

  export const HeaderLeft = styled(Box)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    gap: "1rem",
    lineHeight: 1,
    width: "50%",
  }));

  export const MessageContainer = styled(Box)(({ theme }) => ({
    boxSizing: "border-box",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "0.5rem",
    height: "32px",
    width: "100%",
  }));

  export const RoundInfoBox = styled(Box)(({ theme }) => ({
    boxSizing: "border-box",
    width: "100%",
    height: "58px",
    padding: "0 1rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: "#16151A66",
    gap: "10px",
    [theme.breakpoints.down("sm")]: {
      height: "auto",
      // flexDirection: "column",
      alignItems: "start",
    },
  }));

  export const RoundInfo = styled(Box)(({ theme }) => ({
    width: "33.3%",
    display: "flex",
    gap: "10px",
    justifyContent: "center",
    alignItems: "end",
  }));

  export const SaleOuterContainer = styled(Box)(({ theme }) => ({
    boxSizing: "border-box",
    height: "346px",
    maxHeight: "346px",
    width: "100%",
    borderRadius: "0 0 10px 10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      height: "auto",
      maxHeight: "none",
    },
  }));

  export const SaleContainer = styled(Box)(({ theme }) => ({
    boxSizing: "border-box",
    display: "flex",
    height: "calc(100% - 1rem)",
    width: "100%",
    margin: "0.5rem 1rem",
    borderRadius: "10px",
    backgroundColor: theme.palette.custom.secondary.secondary_6,
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column-reverse",
      height: "auto",
    },
  }));

  export const SaleLeftContainer = styled(Box)(({ theme }) => ({
    gap: "20px",
    display: "flex",
    flexDirection: "column",
    boxSizing: "border-box",
    height: "calc(100% - 2rem)",
    width: "50%",
    padding: "0 1rem",
    margin: "1rem 0",
    borderRight: "1px solid #FFFFFF0F",
    overflowY: "auto",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: "auto",
      gap: "15px",
      paddingTop: "0px",
      borderRight: "none",
    },
  }));

  export const SaleRightContainer = styled(Box)(({ theme }) => ({
    boxSizing: "border-box",
    height: "100%",
    width: "50%",
    display: "flex",
    flexDirection: "column",
    padding: "1rem",
    gap: "1rem",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: "auto",
    },
  }));

  export const TextBox = styled(Box)(({ theme }) => ({
    display: "flex",
    gap: "10px",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "end",
  }));
}

export default S;
