import { Stack, styled, Typography } from "@mui/material";

namespace S {
  export const WinningBodyContainer = styled(Stack)(({ theme }) => ({
    padding: `${theme.spacing(2)} ${theme.spacing(4)}`,
    alignItems: "center",
    justifyContent: "space-between",
    "section > span": {
      border: `1px solid ${theme.palette.custom.secondary.secondary_5}`,
    },
    section: {
      span: {
        fontSize: "60px  !important",
        fontWeight: "600 !important",
        [theme.breakpoints.down("md")]: {
          width: "68px !important",
          height: "78px !important",
          fontSize: "30px !important",
        },
        [theme.breakpoints.down("sm")]: {
          width: "38px !important",
          height: "58px !important",
          fontSize: "30px !important",
        },
        span: {
          span: {
            [theme.breakpoints.down("md")]: {
              width: "68px !important",
              height: "78px !important",
            },
            [theme.breakpoints.down("sm")]: {
              width: "38px !important",
              height: "58px !important",
            },
          },
        },
      },
    },
  }));

  export const WinningBodyTimeDivider = styled(Typography)({
    fontWeight: 600,
    fontFamily: "poppins",
  });
}

export default S;
