import { FC } from "react";
import S from "./mantra-header.styled";

const MantraHeader: FC = () => {
  return (
    <S.HeaderContainer>
      <S.HeaderText1>PROFIT</S.HeaderText1>
      <S.HeaderText2>
        MANTRA
        <S.HeaderTextUnderLine />
      </S.HeaderText2>
    </S.HeaderContainer>
  );
};
export default MantraHeader;
