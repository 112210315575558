import { Box, styled } from "@mui/material";

namespace S {
  export const SkeletonContainer = styled(Box)(({ theme }) => ({
    padding: "1.25rem 0rem 1.25rem 0rem",
    boxSizing: "border-box",
    width: "380px",
    // height: "100%",
    height: "512px",
    maxHeight: "512px",
    borderRadius: "0.625rem",
    backgroundColor: theme.palette.custom.secondary.secondary_3,
    display: "flex",
    flexDirection: "column",
    gap: "1.50625rem",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  }));

  export const TotalContainer = styled(Box)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    gap: "1.30625rem",
  }));

  export const headContainer = styled(Box)(({ theme }) => ({
    padding: "0rem 1.25rem 0rem 1.25rem",
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  }));

  export const TextBox = styled(Box)(({ theme }) => ({
    display: "flex",
    gap: "20px",
    justifyContent: "space-between",
    alignItems: "center",
  }));

  export const TabBox = styled(Box)(({ theme }) => ({
    display: "flex",
    justifyContent: "space-around",
  }));

  export const ImageContainer = styled(Box)(({ theme }) => ({
    height: "150px",
    width: "100%",
  }));

  export const BodyContainer = styled(Box)(({ theme }) => ({
    padding: "0rem 1.25rem 0rem 1.25rem",
    display: "flex",
    flexDirection: "column",
    gap: "5px",
  }));

  export const UpperBodyBox = styled(Box)(({ theme }) => ({
    display: "flex",
    gap: "20px",
    width: "100%",
  }));

  export const BottomBodyBox = styled(Box)(({ theme }) => ({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  }));

  export const ButtonBox = styled(Box)(({ theme }) => ({
    padding: "0rem 1.25rem 0rem 1.25rem",
    height: "36.5px",
    marginTop: "10px",
  }));
}

export default S;
