import { styled, Box, Stack, Typography, IconButton } from "@mui/material";
import {
  GRAD_COLOR_1,
  GRAD_COLOR_2,
  GRAD_COLOR_3,
  HUNDRED_K_DRAW,
  TEN_K_DRAW,
  TextGradient,
  TransparentTextGradient,
} from "../../../utils/constants";

namespace S {
  export const ChallengeCard = styled(Stack)(({ theme }) => ({
    gap: theme.spacing(4.5),
    minHeight: "24.75rem",
    maxHeight: "24.75rem",
  }));

  export const CardHeader = styled(Box)(({ theme }) => ({
    boxSizing: "border-box",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  }));

  export const HeaderText = styled(Typography)(({ theme }) => ({
    fontSize: "20px",
    fontWeight: 500,
    lineHeight: 1,
    [theme.breakpoints.down("sm")]: {
      fontSize: "1rem",
    },
  }));

  export const GradientText = styled(Typography, { shouldForwardProp: (prop) => prop !== "shineText" })(({ shineText }: { shineText: string }) => ({
    background: TextGradient,
    webkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    backgroundClip: "text",
    backgroundSize: "200px",
    fontSize: "1.0625rem !important",
    fontWeight: "500 !important",
    color: TextGradient,
    lineHeight: 1,
    textShadow: `0 0px 0px ${TextGradient}`,
    position: "relative",
    ":after": {
      content: `"${shineText}"`,
      background: TransparentTextGradient,
      webkitBackgroundClip: "text",
      WebkitTextFillColor: "transparent",
      backgroundClip: "text",
      position: "absolute",
      top: 0,
      left: 0,
      transform: "translate(0,0)",
      zIndex: 3,
      animation: "pool-shine 20s linear infinite",
      "@keyframes pool-shine": {
        "0%": {
          backgroundPosition: "-300px",
        },
        "100%": {
          backgroundPosition: "300px",
        },
      },
    },
  }));

  export const CardContent = styled(Box)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    gap: "1.7rem",
    width: "100%",
  }));

  export const ContentHeader = styled(Box)(({ theme }) => ({
    display: "flex",
    gap: "2rem",
    height: "64px",
    width: "100%",
    alignItems: "center",
    padding: "15px 0 25px 0",
    borderBottom: "1px solid #FFFFFF33",
  }));

  export const Poster = styled(Box)(({ theme }) => ({
    width: "60px",
    height: "64px",
    overflow: "hidden",
  }));

  export const PosterImage = styled("img")(({ theme }) => ({
    width: "100%",
    height: "100%",
  }));

  export const PosterContent = styled(Box)(({ theme }) => ({
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
  }));

  export const PosterName = styled(Typography)(({ theme }) => ({
    fontSize: "14px",
    fontWeight: 500,
  }));

  export const StarRating = styled(Box)(({ theme }) => ({
    display: "flex",
    marginTop: "-5px",
  }));

  export const PlaysAndWinnings = styled(Box)(({ theme }) => ({
    display: "flex",
    gap: "1.3rem",
  }));

  export const SentenceWrap = styled(Box)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    gap: "0.5rem",
  }));

  export const SubGradientText = styled(Typography, {
    shouldForwardProp: (prop) => prop !== "gradient",
  })(({ gradient }: { gradient: string }) => ({ theme }) => ({
    fontSize: "10px",
    fontWeight: "500",
    background: gradient === HUNDRED_K_DRAW ? GRAD_COLOR_3 : gradient === TEN_K_DRAW ? GRAD_COLOR_2 : GRAD_COLOR_1,
    webkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    backgroundClip: "text",
    lineHeight: 1,
  }));

  export const SubText = styled(Typography)(({ theme }) => ({
    fontSize: "10px",
    fontWeight: "500",
    lineHeight: 1,
  }));

  export const EntryFee = styled(Box)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    gap: "1.2rem",
  }));

  export const EntryHeaderText = styled(Typography)(({ theme }) => ({
    fontSize: "16px",
    fontWeight: 600,
  }));

  export const FeeStructure = styled(Box)(({ theme }) => ({
    display: "flex",
    gap: "0.9rem",
  }));

  export const FeeText = styled(Typography)(({ theme }) => ({
    fontSize: "12px",
    fontWeight: 500,
    // background: theme.palette.custom.accent.accent_4,
    background: "#3E3E3E80",
    padding: "2px 5px",
    borderRadius: "4px",
  }));

  export const PerksSubText = styled(Typography)(({ theme }) => ({
    fontSize: "12px",
    fontWeight: 500,
  }));

  export const GradientPerksText = styled(Typography, {
    shouldForwardProp: (prop) => prop !== "gradient",
  })(({ gradient }: { gradient: string }) => ({ theme }) => ({
    fontSize: "16px",
    fontWeight: "600",
    background: gradient === HUNDRED_K_DRAW ? GRAD_COLOR_3 : gradient === TEN_K_DRAW ? GRAD_COLOR_2 : GRAD_COLOR_1,
    webkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    backgroundClip: "text",
    lineHeight: 1,
  }));
}

export default S;
