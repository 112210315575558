import { Dispatch, FC, SetStateAction } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import S from "./left-side-panel.styled";
import { PRIMARY } from "../../utils/constants";
import { useGetDimensions } from "../../utils/helpers";
import { Button } from "../../utils/widgets";
import RecentWinnerCarousel from "../right-side-panel/RecentWinnerCarousel";
import PoolProgress from "../right-side-panel/earning-card/PoolProgress";
import Affiliater from "./affiliater";
import GradientPoolTicket from "./gradient-pool-ticket";
import MyTeamAffiliateEarnings from "./my-team-affiliate-earnings";
import SkillGaming from "./skill-gaming";

type LeftSidePanelProps = {
  showLeftPanel: boolean;
  setShowLeftPanel: Dispatch<SetStateAction<boolean>>;
};

const LeftSidePanel: FC<LeftSidePanelProps> = ({ showLeftPanel, setShowLeftPanel }) => {
  //constants
  const isMd = useGetDimensions("md");

  return (
    /* LeftSidePanelWrapper is created using mui Stack compopnent, So you can apply Stack components deafult props directly */
    <S.LeftSidePanelWrapper panelView={showLeftPanel}>
      {isMd && (
        <Button
          buttonType={PRIMARY}
          onClick={() => {
            setShowLeftPanel(!showLeftPanel);
          }}
          containerCustomStyle={{
            display: "flex",
            justifyContent: "end",
            position: "sticky",
            top: 0,
            zIndex: 4,
            background: "#16151A",
            paddingBottom: "15px",
          }}
          customStyle={{
            // width: "30px !important",
            // height: "30px !important",
            // padding: "10px !important",
            // minWidth: "1.7rem",
            // maxWidth: "1.7rem",
            // borderRadius: "50%",
            // marginTop: "1.25rem",
            // marginLeft: "auto",
            minHeight: "1.7rem",
            minWidth: "1.7rem",
            padding: "0",
            maxWidth: "1.7rem",
            borderRadius: "50%",
            marginTop: "1rem",
          }}
        >
          <ArrowBackIcon />
        </Button>
      )}
      <Affiliater />
      {/* <ProfitMantra /> */}
      {/* <GradientPoolTicket /> */}
      <RecentWinnerCarousel />
      {/* <PoolProgress /> */}
      {/* <MyTeamAffiliateEarnings /> */}
      <SkillGaming />
      {/* <EveryOneK /> */}
      {/* <Every100K /> */}
    </S.LeftSidePanelWrapper>
  );
};

export default LeftSidePanel;
