import { FC } from "react";
import { useNavigate } from "react-router-dom";
import S from "./idle-page.styled";
import { Button } from "../../utils/widgets";

const IdlePage: FC = () => {
  const navigate = useNavigate();

  return (
    <S.IdlePageWrapper>
      <S.WarningText>You are being inactive for too long</S.WarningText>
      <Button
        buttonType="primary"
        onClick={() => {
          navigate(0);
        }}
        customStyle={{ fontSize: "2rem", padding: "0.1rem 1.5rem" }}
      >
        Ok
      </Button>
    </S.IdlePageWrapper>
  );
};
export default IdlePage;
