import { forwardRef } from "react";
import { CSSProperties } from "@mui/styled-engine";
import Input from "../inputs";

interface CustomProps {
  searchHandler: (e: string) => void;
  value: string;
  placeholder?: string;
  getInputProps?: () => React.InputHTMLAttributes<HTMLInputElement>;
  customStyle?: CSSProperties;
}

type Ref = HTMLInputElement | string;

// let debounceTimer: undefined | NodeJS.Timeout;

// function debounce(cb: () => void) {
//   if (debounceTimer) clearTimeout(debounceTimer);
//   debounceTimer = setTimeout(cb, 500); // .5s is the debounce time.
// }

const SearchBar = forwardRef<Ref, CustomProps>(({ searchHandler, value, placeholder = "Search Address", getInputProps, customStyle }, ref) => {
  // useEffect(
  //   () => () => {
  //     searchHandler && searchHandler("");
  //   },
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  //   [],
  // );

  return (
    <Input
      value={value}
      readOnly={false}
      placeholder={placeholder}
      onChange={(e) => searchHandler(e)}
      customStyle={{
        height: "2.1875rem",
        fontSize: "0.875rem",
        backgroundColor: "#2D2C31",
        ...customStyle,
        "&.Mui-focused": {
          outline: "0",
          boxShadow: "0 0 0 2px #1967fc",
        },
      }}
      getInputProps={getInputProps}
    />
  );
});
export default SearchBar;
