import { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import { getTenKDrawWinners } from "../../store/slices/user.actions";
import { TEN, tenKDrawWinningAmount, TEN_K_DRAW } from "../../utils/constants";
import { getWholeNum } from "../../utils/helpers";
import { useEnteredDraw, useEvents, useIsAppLoading, useTenKDrawWinners, useWinnerOverlay } from "../../utils/hooks/selector";
import { useAppDispatch } from "../../utils/hooks/state";
import AffiliaterCard from "../../utils/widgets/cards/AffiliateCard";
import { TenKDrawWinnersModal } from "../modals/draw-winners";
import { WinnerCard, WinnerCardBody, WinnerCardHeader } from "./recent-winner-card";

const Recent10KWinner: FC = () => {
  //constructors
  const dispatch = useAppDispatch();

  //state values
  const tenKDrawWinners = useSelector(useTenKDrawWinners);
  const enteredDraw = useSelector(useEnteredDraw);
  const recentWinners = useSelector(useEvents).recentWinners;
  const winnerOverlay = useSelector(useWinnerOverlay);
  const isAppLoading = useSelector(useIsAppLoading);

  const [drawWinnersModal, setDrawWinnersModal] = useState(false);

  //constants
  let drawNumber = 0;
  let period = "";
  let winnerId = "";
  let ticketId = "";
  const recentWinner = tenKDrawWinners[tenKDrawWinners.length - 1];

  if (recentWinner) {
    winnerId = recentWinner.userId;
    ticketId = getWholeNum(recentWinner.ticketId);
    drawNumber = recentWinner.drawNumber;
    period = moment(recentWinner.blockTimestamp).format("MMMM DD,YYYY");
  }

  useEffect(() => {
    dispatch(getTenKDrawWinners());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enteredDraw, recentWinners[TEN_K_DRAW].drawNumber]);

  //On WinnerTimerModal or isApploading Open
  useEffect(() => {
    if (winnerOverlay || isAppLoading) setDrawWinnersModal(false);
  }, [winnerOverlay, isAppLoading]);

  return (
    <WinnerCard>
      <WinnerCardHeader
        draw={TEN}
        amount={tenKDrawWinningAmount}
        disabled={!tenKDrawWinners.length}
        onClick={() => setDrawWinnersModal(true)}
        date={period}
      />
      <WinnerCardBody>
        <AffiliaterCard ticketId={ticketId} userId={winnerId} drawNumber={drawNumber} draw={TEN_K_DRAW} />
      </WinnerCardBody>
      {drawWinnersModal && <TenKDrawWinnersModal open={true} handleClose={() => setDrawWinnersModal(false)} />}
    </WinnerCard>
  );
};

export default Recent10KWinner;
