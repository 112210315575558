import { FC } from "react";
import { Skeleton } from "@mui/material";
import S from "./winning-ticket-skeleton.styled";

const WinningTicketSkeleton: FC = () => {
  return (
    <S.SkeletonContainer>
      <S.SkeletonHeader>
        <Skeleton variant="text" sx={{ fontSize: "2rem", width: "60%" }} />
        <Skeleton variant="text" sx={{ fontSize: "2rem", width: "15%" }} />
      </S.SkeletonHeader>

      <S.SkeletonBody>
        {Array.from({ length: 6 }).map((data, i) => {
          return <Skeleton variant="rectangular" width={100} height={100} key={i} />;
        })}
      </S.SkeletonBody>
    </S.SkeletonContainer>
  );
};

export default WinningTicketSkeleton;
