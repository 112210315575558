import { Box, Skeleton } from "@mui/material";
import S from "./game-card-skeleton.styled";

const GameCardSkeleton = () => {
  return (
    <>
      <S.SkeletonContainer>
        <S.TotalContainer>
          <S.headContainer>
            <S.TextBox>
              <Skeleton variant="text" sx={{ fontSize: "1rem", width: "40%" }} />
              <Skeleton variant="circular" sx={{ width: "25px", height: "25px" }} />
            </S.TextBox>
            <S.TabBox>
              <Skeleton variant="text" sx={{ fontSize: "2rem", width: "35%" }} />
              <Skeleton variant="text" sx={{ fontSize: "2rem", width: "35%" }} />
            </S.TabBox>
          </S.headContainer>
          <S.ImageContainer>
            <Skeleton variant="rectangular" sx={{ width: "100%", height: "100%" }} />
          </S.ImageContainer>
          <S.BodyContainer>
            <Skeleton variant="text" sx={{ fontSize: "1.5rem", width: "40%" }} />

            <S.UpperBodyBox>
              <Box>
                <Skeleton variant="rounded" sx={{ height: "40px", width: "32px" }} />
              </Box>
              <Box>
                <Skeleton variant="text" sx={{ fontSize: "0.9rem", width: "170px" }} />
                <Skeleton variant="text" sx={{ fontSize: "0.7rem", width: "200px" }} />
              </Box>
            </S.UpperBodyBox>
            <S.BottomBodyBox>
              <Skeleton variant="text" sx={{ fontSize: "1.5rem", width: "40%" }} />
              <Skeleton variant="circular" sx={{ height: "25px", width: "25px" }} />
            </S.BottomBodyBox>
          </S.BodyContainer>
          <S.ButtonBox>
            <Skeleton variant="rounded" sx={{ width: "100%", height: "100%" }} />
          </S.ButtonBox>
        </S.TotalContainer>
      </S.SkeletonContainer>
    </>
  );
};

export default GameCardSkeleton;
