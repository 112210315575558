export const platformUrl: any = {
  80001: `${process.env.REACT_APP_SUBGRAPH_ENDPOINT}`,
  56: `${process.env.REACT_APP_BSC_SUBGRAPH_LOTTO_ENDPOINT}`,
};

export const platformPoolUrl: any = {
  80001: `${process.env.REACT_APP_POOLS_SUBGRAPH_ENDPOINT}`,
  56: `${process.env.REACT_APP_BSC_SUBGRAPH_LOTTO_POOL_ENDPOINT}`,
};

export const platformPredictionUrl: any = {
  80001: `${process.env.REACT_APP_PREDICTION_SUBGRAPH_ENDPOINT}`,
  56: `${process.env.REACT_APP_BSC_SUBGRAPH_LOTTO_PREDICTION_ENDPOINT}`,
};

export const predictionPoolUrl: any = {
  80001: `${process.env.REACT_APP_MATIC_PREDICTION_POOL_SUBGRAPH_ENDPOINT}`,
  56: `${process.env.REACT_APP_BSC_PREDICTION_POOL_SUBGRAPH_ENDPOINT}`,
};

export const yearnTokenAddress: any = {
  80001: `${process.env.REACT_APP_MUMBAI_YEARN}`,
  56: `${process.env.REACT_APP_BSC_YEARN}`,
};

export const usdtTokenAddress: any = {
  80001: `${process.env.REACT_APP_TOKEN_ADDRESS}`,
  56: `${process.env.REACT_APP_BSC_USDT}`,
};
