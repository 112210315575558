import { IconButton } from "@mui/material";
import { Stack, styled, Box, Typography } from "@mui/material";
import { ONE_K_DRAW, TEN_K_DRAW, HUNDRED_K_DRAW } from "./../../../../utils/constants/index";

namespace S {
  export const MyTicketsModalBody = styled(Stack)(({ theme }) => ({
    boxSizing: "border-box",
    justifyContent: "flex-start",
    alignItems: "center",
    // height: "36.75rem",
    width: "29.4375rem",
    margin: `0 ${theme.spacing(4)}`,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  }));

  export const WinningTicketContainer = styled(Stack)(({ theme }) => ({
    boxSizing: "border-box",
    width: "100%",
    borderBottom: `0.0625rem solid ${theme.palette.custom.strokes.primary}`,
    justifyContent: "flex-start",
    padding: `0 ${theme.spacing(4)}`,
  }));

  export const MyTicketsContainer = styled(Stack)(() => ({
    boxSizing: "border-box",
    // height: "27.625rem",
    width: "100%",
    marginBottom: "1.875rem",
    minHeight: "10rem",
    maxHeight: "27.625rem",
  }));

  export const Row = styled(Box)(() => ({
    boxSizing: "border-box",
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  }));

  export const HeadingRow = styled(Row)(() => ({
    height: "3.4375rem",
  }));

  export const MyTicketsHeadingRow = styled(Row)(({ theme }) => ({
    padding: `0 ${theme.spacing(4)}`,
    height: "3.4375rem",
  }));

  export const WinningTicketsScrollContainer = styled(Stack)(({ theme }) => ({
    gap: theme.spacing(4),
    marginBottom: "0.625rem",
  }));

  export const MyTicketsScrollContainer = styled(Stack)(({ theme }) => ({
    gap: theme.spacing(4),
    overflowY: "auto",
    minHeight: "10rem",
    maxHeight: "21.25rem",
    padding: `0 ${theme.spacing(4)}`,
    "&::-webkit-scrollbar-track": {
      backgroundColor: "#3E3E3E",
    },
  }));

  export const TicketHolder = styled(Stack)(({ theme }) => ({
    alignItems: "center",
    gap: theme.spacing(2),
    flexDirection: "row",
  }));

  export const TicketDrawHolder = styled(TicketHolder)(() => ({
    width: "16.125rem",
  }));

  export const CustomGoldenTicket = styled("img")(() => ({}));

  export const HeadingText = styled(Typography)(() => ({
    fontSize: "0.75rem",
    fontWeight: "500",
    background: "linear-gradient(337.35deg, #EB00FF 7.34%, #E9B80C 91.96%)",
    webkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    backgroundClip: "text",
  }));

  export const DrawCount = styled(Typography)(({ theme }) => ({
    fontSize: "0.75rem",
    fontWeight: "500",
    color: theme.palette.custom.secondary.secondary_1,
    opacity: "0.4",
  }));

  export const TicketText = styled(Typography, {
    shouldForwardProp: (prop) => prop !== "Draw",
  })(({ draw }: { draw?: string }) => ({ theme }) => ({
    fontSize: "1rem",
    fontWeight: "600",
    background:
      draw === ONE_K_DRAW
        ? "linear-gradient(92.48deg, #1967FC 1.43%, #19FCB8 75%)"
        : draw === TEN_K_DRAW
        ? "linear-gradient(268.66deg, #FFE600 5.41%, #24FF00 93.94%)"
        : draw === HUNDRED_K_DRAW
        ? "linear-gradient(337.35deg, #EB00FF 7.34%, #E9B80C 91.96%)"
        : theme.palette.custom.secondary.secondary_1,
    webkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    backgroundClip: "text",
  }));

  export const WinningText = styled(Typography, {
    shouldForwardProp: (prop) => prop !== "Draw",
  })(({ draw }: { draw?: string }) => ({ theme }) => ({
    fontSize: "0.75rem",
    fontWeight: "600",
    background:
      draw === ONE_K_DRAW
        ? "linear-gradient(92.48deg, #1967FC 1.43%, #19FCB8 75%)"
        : draw === TEN_K_DRAW
        ? "linear-gradient(268.66deg, #FFE600 5.41%, #24FF00 93.94%)"
        : draw === HUNDRED_K_DRAW
        ? "linear-gradient(337.35deg, #EB00FF 7.34%, #E9B80C 91.96%)"
        : theme.palette.custom.secondary.secondary_1,
    webkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    backgroundClip: "text",
  }));

  export const IBtnContainer = styled(IconButton)(({ theme }) => ({
    background: theme.palette.custom.primary.main,
    maxHeight: "1rem",
    maxWidth: "1rem",
    height: "1rem",
    pointerEvents: "auto",
    alignSelf: "center",
    "&:hover": {
      backgroundColor: "#1846dd",
    },
    p: {
      lineHeight: "0",
      fontSize: "0.7375rem",
      color: theme.palette.custom.secondary.secondary_1,
    },
  }));
}
export default S;
