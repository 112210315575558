import { FC, ReactNode } from "react";
import S from "./greetings-modal.styled";

type GreetingsModalType = {
  open: boolean;
  handleClose: () => void;
  children: ReactNode;
};

const GreetingsModal: FC<GreetingsModalType> = ({ open, handleClose, children }) => {
  return (
    <S.CustomModal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
      <>
        <S.ModalCrossedContainer />
        <S.ModalContainer>{children}</S.ModalContainer>
      </>
    </S.CustomModal>
  );
};
export default GreetingsModal;
