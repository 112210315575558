import { Stack, styled, Typography, Box, Divider } from "@mui/material";
import { GRAD_COLOR_3, GRAD_COLOR_1, HUNDRED_K_DRAW } from "./../../../../utils/constants/index";

namespace S {
  export const HeaderContainer = styled(Stack)(({ theme }) => ({
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottom: `0.0625rem solid ${theme.palette.custom.strokes.primary}`,
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
    // minWidth: "70rem !important",
    [theme.breakpoints.down("md")]: {
      marginLeft: theme.spacing(2),
      flexWrap: "wrap",
    },
  }));

  export const HeaderContainerLeft = styled(Stack)(({ theme }) => ({
    boxSizing: "border-box",
    gap: theme.spacing(4),
    padding: `${theme.spacing(4)}`,
  }));

  export const HeaderContainerRight = styled(Stack)(({ theme }) => ({
    alignSelf: "end",
    marginBottom: "1rem",
    flex: 0.8,
    alignItems: "center",
    justifyContent: "flex-end",
    gap: "1rem",
    div: {
      // width: "100%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      flex: "unset",
    },
  }));

  export const HeaderText = styled(Typography)(({ theme }) => ({
    fontSize: "1.375rem",
    fontWeight: "500",
    width: "fit-content",
    lineHeight: "0.8",
    [theme.breakpoints.down("md")]: {
      fontSize: "0.875rem",
    },
  }));

  export const HeaderGradientText = styled(Typography, {
    shouldForwardProp: (prop) => prop !== "gradient",
  })(({ gradient }: { gradient: string }) => ({ theme }) => ({
    cursor: "pointer",
    lineHeight: "0.9",
    display: "flex",
    gap: theme.spacing(2.5),
    flexDirection: "column",
    fontSize: "1.2rem",
    fontWeight: "600",
    width: "fit-content",
    [theme.breakpoints.down("md")]: {
      fontSize: "0.8rem",
    },
    background: gradient === HUNDRED_K_DRAW ? GRAD_COLOR_3 : GRAD_COLOR_1,
    webkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    backgroundClip: "text",
    textAlign: "center",
  }));

  export const HeaderGradientTextOneK = styled(Typography)(({ theme }) => ({
    cursor: "pointer",
    lineHeight: "0.9",
    display: "flex",
    gap: theme.spacing(2.5),
    flexDirection: "column",
    fontSize: "2.5rem",
    fontWeight: "600",
    width: "fit-content",
    [theme.breakpoints.down("md")]: {
      fontSize: "1.5rem",
    },
    background: GRAD_COLOR_1,
    webkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    backgroundClip: "text",
    textAlign: "center",
  }));

  export const HeaderToggleContainer = styled(Stack)(({ theme }) => ({
    alignItems: "center",
    gap: theme.spacing(2),
  }));

  export const HeaderToggleText = styled(Typography, { shouldForwardProp: (prop) => prop !== "isActive" && prop !== "disabled" })(
    ({ isActive, disabled }: { isActive: boolean; disabled?: boolean }) =>
      ({ theme }) => ({
        fontWeight: 500,
        fontSize: "14px",
        color: isActive ? theme.palette.custom.primary.main : disabled ? "#fff4" : "unset",
        cursor: disabled ? "not-allowed" : "pointer",
      }),
  );

  export const CustomDivider = styled(Divider)({
    height: "18px",
    color: "#fff6",
  });

  export const CustomPrimaryDivider = styled(CustomDivider)({
    height: "2.1875rem",
  });

  export const HeaderTextUnderLine = styled("span", { shouldForwardProp: (prop) => prop !== "isActive" && prop !== "gradient" })(
    ({ isActive, gradient }: { isActive: boolean; gradient: string }) =>
      () => ({
        height: "0.375rem",
        borderRadius: "1.25rem",
        background: isActive ? (gradient === HUNDRED_K_DRAW ? GRAD_COLOR_3 : GRAD_COLOR_1) : "transparent",
      }),
  );

  export const SearchIcon = styled("img")({
    width: "1.2rem",
    height: "1.2rem",
    paddingLeft: "1rem",
  });

  export const SearchContainer = styled(Stack)(({ theme }) => ({
    boxSizing: "border-box",
    alignItems: "center",
    justifyContent: "space-around",
    gap: "1rem",
    border: `1px solid #fff4`,
    borderRadius: "10px",
    width: "20.4375rem",
    height: "2.25rem",
    ".MuiInputBase-root": {
      border: "none",
      "&.Mui-active": {
        boxShadow: "none !important",
        border: "none !important",
        outline: "none !important",
      },
      "&.Mui-focused": {
        boxShadow: "none !important",
        border: "none !important",
        outline: "none !important",
        ".outer-input": {
          border: "0.0625rem solid red",
        },
      },
    },
    ":focus-within": {
      border: `2px solid ${theme.palette.custom.primary.main}`,
    },
    [theme.breakpoints.down("md")]: {
      width: "18.75rem",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      maxWidth: "21.875rem",
    },
  }));

  export const DrawContainer = styled(Stack)(({ theme }) => ({
    boxSizing: "border-box",
    flexDirection: "row",
    minHeight: "2.75rem",
    maxHeight: "2.75rem",
    borderRadius: "0.5rem",
    alignItems: "center",
    marginBottom: theme.spacing(1.5),
    gap: "1rem",
    p: {
      fontWeight: "500",
      whiteSpace: "nowrap",
    },
    [theme.breakpoints.down("md")]: {
      minHeight: "2.25rem",
      maxHeight: "2.25rem",
      p: {
        fontSize: "14px",
      },
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      maxWidth: "21.875rem",
      justifyContent: "space-between",
      gap: "unset",
    },
  }));

  export const DrawAmountContainer = styled(Stack)(({ theme }) => ({
    boxSizing: "border-box",
    height: "100%",
    minWidth: "13rem",
    flexDirection: "row",
    borderRadius: "8px",
    background: theme.palette.custom.secondary.secondary_3,
    border: `0.0625rem solid #fff4`,
    justifyContent: "space-between",
    alignItems: "center",
    padding: `0 ${theme.spacing(4)}`,
    position: "relative",
    ":focus-within": {
      border: `0.125rem solid ${theme.palette.custom.primary.main}`,
    },
    [theme.breakpoints.down("md")]: {
      minWidth: "12rem",
    },
    [theme.breakpoints.down("sm")]: {
      minWidth: "unset",
      width: "calc(100% - 103px)",
      padding: `0 ${theme.spacing(2)}`,
    },
  }));

  export const DrawInput = styled("input")(({ theme }) => ({
    border: "none",
    outline: "none",
    background: "transparent",
    fontSize: "16px",
    fontWeight: "600",
    color: theme.palette.custom.secondary.secondary_1,
    width: "70%",
    textAlign: "center",
    "&::-webkit-outer-spin-button": {
      WebkitAppearance: "none",
      margin: 0,
    },
    "&::-webkit-inner-spin-button": {
      WebkitAppearance: "none",
      margin: 0,
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "0.875rem",
    },
  }));

  export const MathButton = styled(Box, {
    shouldForwardProp: (prop) => prop !== "select",
  })(({ select }: { select?: Boolean }) => ({ theme }) => ({
    color: select ? "rgba(255, 255, 255, 0.6)" : theme.palette.custom.secondary.secondary_1,
    fontSize: "26px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: select ? "default" : "pointer",
    userSelect: "none",
  }));

  export const ErrorText = styled(Typography)(({ theme }) => ({
    position: "absolute",
    bottom: "-18px",
    left: "-60px",
    fontSize: "14px",
    color: "red",
    fontWeight: "600",
    width: "fit-content",
    lineHeight: "0.8",
    [theme.breakpoints.down("md")]: {
      bottom: "-16px",
      fontSize: "12px !important",
    },
    [theme.breakpoints.down("sm")]: {
      left: "-40px",
    },
  }));
}

export default S;
