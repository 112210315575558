import { Box, styled, Typography } from "@mui/material";

namespace S {
  export const QrModalbody = styled(Box)(({ theme }) => ({
    padding: theme.spacing(6),
    svg: {
      width: "400px",
      height: "400px",
      background: "white",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        height: "100%",
      },
    },
  }));

  export const Heading = styled(Typography)(() => ({
    fontSize: "1.25rem",
    fontWeight: "bold",
    padding: "1.5rem 1rem 0",
    lineHeight: 1.6,
    letterSpacing: "0.0075em",
    textAlign: "center",
  }));
}

export default S;
