import { FC } from "react";
import { Typography } from "@mui/material";
import S from "./footer.styled";
import { Button, CustomDivider } from "../../../utils/widgets";

type ThanksCardFooterType = {
  totalPages: number;
  currentPage: number;
  page: number;
  onClick: (value?: number) => void;
  handleClose: () => void;
};

const ThanksCardFooter: FC<ThanksCardFooterType> = ({ totalPages, currentPage, onClick, page, handleClose }) => {
  //constants
  const maxDots = 7;

  //functions
  const dotCreator = () => {
    let dots = [];

    for (let i = 1; i <= totalPages - 1; i++) {
      if (i <= maxDots || i === totalPages)
        dots.push(<S.Dot currentPage={currentPage === i} key={`dot_${i}_${totalPages + currentPage}`} onClick={() => onClick(i - 1)} />);
      const hasId = dots.filter((d) => d.props.id);
      if (i > maxDots && !hasId.length)
        dots.push(
          <Typography id="elipse-dots" key={`elipse_${i}_${totalPages + currentPage}`}>
            ...
          </Typography>,
        );
    }
    return dots;
  };

  return (
    <>
      {/* <CustomDivider dir="horizontal" /> */}
      <S.Footer>
        {page !== 3 && <S.DotContainer>{dotCreator()}</S.DotContainer>}
        {page === totalPages - 2 ? (
          <S.DoneBtnContainer>
            <Button buttonType="primary" onClick={handleClose} customStyle={{ fontSize: "1rem", padding: "0.32rem 1.875rem" }}>
              Done
            </Button>
          </S.DoneBtnContainer>
        ) : (
          <S.FooterButtonContainer>
            <S.SkipButton variant="outlined" onClick={handleClose}>
              Skip
            </S.SkipButton>
            <Button customStyle={{ fontSize: "1rem", padding: "0.32rem 1.875rem" }} buttonType="primary" onClick={() => onClick()}>
              Next
            </Button>
          </S.FooterButtonContainer>
        )}
      </S.Footer>
    </>
  );
};

export default ThanksCardFooter;
