import { FC, useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import S from "./winning-timer-styled";
import { HUNDRED_K_DRAW, ONE_K_DRAW, TEN_K_DRAW } from "../../../utils/constants";
import { getOneKSlot, getTenKSlot, handleCounterTimer } from "../../../utils/helpers";
import {
  useAnnouncedWinner,
  useAppStatus,
  useCurrentTenKDraw,
  useDraw100kNumber,
  useDraw10kNumber,
  useDraw1kNumber,
  useEvents,
  useIsDrawReady,
  useTicketsSold,
} from "../../../utils/hooks/selector";
import { AppStatus } from "../../../utils/types";
import { ModalBody, ModalFooter, ModalHeader } from "../../../utils/widgets/custom-modal";
import GradientBlurModal from "../../../utils/widgets/gradient-blur-modal";
import JackpotWinningModal from "../jackpot-winning-modal";
import WinnerListModal from "./winner-list-modal";
import WinningTimerBody from "./winning-timer-body";
import WinningTimerFooter from "./winning-timer-footer";
import WinningTimerHeader from "./winning-timer-header";

type WinningModalPropsType = {
  onClose: () => void;
};

const WinningTimerModal: FC<WinningModalPropsType> = ({ onClose }) => {
  //state values
  const ticketsSold = useSelector(useTicketsSold);
  const recentWinners = useSelector(useEvents).recentWinners;
  const winnerId = useSelector(useAnnouncedWinner).winnerId;
  const value = useSelector(useAnnouncedWinner).value;
  const slot = useSelector(useAnnouncedWinner).slot;
  const userId = useSelector(useAnnouncedWinner).userId;
  const currentTenKDraw = useSelector(useCurrentTenKDraw);
  const appStatus = useSelector(useAppStatus);
  const draw1kNumber = useSelector(useDraw1kNumber);
  const draw10kNumber = useSelector(useDraw10kNumber);
  const draw100kNumber = useSelector(useDraw100kNumber);
  const isDrawReady = useSelector(useIsDrawReady);

  const [drawWinner, setDrawWinner] = useState({ winnerId, value, slot, userId });
  const [isClearTimer, setIsClearTimer] = useState(false);
  //constants
  const tenKCompleted = Boolean(ticketsSold !== 0 && ticketsSold % draw10kNumber === 0 && +currentTenKDraw * draw10kNumber === ticketsSold);
  const hundredKCompleted = Boolean(ticketsSold !== 0 && ticketsSold % draw100kNumber === 0);
  const showWinnerTickets = Boolean((tenKCompleted || hundredKCompleted) && recentWinners[ONE_K_DRAW].ticketNumber);

  useEffect(() => {
    if (recentWinners[ONE_K_DRAW].drawNumber && isDrawReady) handleWinnerOverlayClosing();
  }, [
    recentWinners[ONE_K_DRAW].drawNumber,
    recentWinners[TEN_K_DRAW].drawNumber,
    recentWinners[HUNDRED_K_DRAW].drawNumber,
    tenKCompleted,
    hundredKCompleted,
    isDrawReady,
    currentTenKDraw,
  ]);

  //functions
  //This function returns winner based on which draw happening.
  const handleWinnerOverlayClosing = () => {
    let winner = drawWinner;
    if (!recentWinners[ONE_K_DRAW]) return winner;
    if (tenKCompleted) {
      if (!recentWinners[TEN_K_DRAW]) return winner;
      else if (hundredKCompleted) {
        if (!recentWinners[HUNDRED_K_DRAW]) return winner;
        else
          winner = {
            winnerId: recentWinners[HUNDRED_K_DRAW].ticketNumber,
            value: HUNDRED_K_DRAW,
            slot: `1 - ${draw100kNumber}`,
            userId: recentWinners[HUNDRED_K_DRAW].userId,
          };
      } else
        winner = {
          winnerId: recentWinners[TEN_K_DRAW].ticketNumber,
          value: TEN_K_DRAW,
          slot: getTenKSlot(recentWinners[TEN_K_DRAW].drawNumber, draw10kNumber),
          userId: recentWinners[TEN_K_DRAW].userId,
        };
    } else
      winner = {
        winnerId: recentWinners[ONE_K_DRAW].ticketNumber,
        value: ONE_K_DRAW,
        slot: getOneKSlot(recentWinners[ONE_K_DRAW].drawNumber, draw1kNumber),
        userId: recentWinners[ONE_K_DRAW].userId,
      };

    if (winner.winnerId) {
      setDrawWinner(winner);
      setIsClearTimer(true);
    }
    return winner;
  };

  const handleWinnerTimerClose = () => {
    onClose();
    let counterProps = {
      clearTimer: isClearTimer,
      onClose: handleWinnerOverlayClosing,
    };
    let intervalTimer = handleCounterTimer(counterProps);
    clearInterval(intervalTimer);
    setTimeout(() => {
      handleCounterTimer(counterProps);
    }, 1000);
  };

  const showTimerOnRefresh = useCallback(() => {
    let showJackpot = false;
    if (drawWinner.winnerId && appStatus !== AppStatus.onHoldForDraw) {
      if (hundredKCompleted && drawWinner.value === HUNDRED_K_DRAW) showJackpot = true;
      else if (tenKCompleted && drawWinner.value === TEN_K_DRAW && !hundredKCompleted) showJackpot = true;
      else if (!tenKCompleted && !hundredKCompleted) showJackpot = true;
      else showJackpot = false;
    }
    return showJackpot;
  }, [drawWinner.winnerId, appStatus]);

  return (
    <>
      <GradientBlurModal isTimerModal={true} open={true}>
        <S.WinningCounterModalContainer container showWinner={showWinnerTickets}>
          {showWinnerTickets ? <WinnerListModal /> : null}
          <S.WinningTimerContainer>
            {showTimerOnRefresh() ? (
              <JackpotWinningModal
                winnerId={drawWinner.winnerId}
                value={drawWinner.value}
                slot={drawWinner.slot}
                userId={drawWinner.userId}
                onClose={onClose}
              />
            ) : (
              <>
                <ModalHeader handleClose={handleWinnerTimerClose} isTransparent={true} disableCloseIcon={true}>
                  <WinningTimerHeader />
                </ModalHeader>
                <ModalBody isTransparent={true}>
                  <WinningTimerBody handleWinnerOverlayClosing={handleWinnerOverlayClosing} isClearTimer={isClearTimer} />
                </ModalBody>
                <ModalFooter isTransparent={true}>
                  <WinningTimerFooter amount="5000" onClose={handleWinnerTimerClose} />
                </ModalFooter>
              </>
            )}
          </S.WinningTimerContainer>
        </S.WinningCounterModalContainer>
      </GradientBlurModal>
    </>
  );
};

export default WinningTimerModal;
