import { Box, Paper, styled, TableCell } from "@mui/material";

namespace S {
  export const TableContainer = styled(Paper)(({ theme }) => ({
    background: theme.palette.custom.secondary.secondary_3,
    padding: `10px ${theme.spacing(6)}`,
    overflowX: "auto",
    maxHeight: "31.25rem",
    minHeight: "12.5rem",
    td: {
      padding: "10px",
    },
  }));

  export const TableHeading = styled(TableCell)(({ theme }) => ({
    fontSize: "0.75rem",
    fontWeight: 600,
    color: "#fff6",
    borderBottom: "none",
    minWidth: "180px",
    backgroundColor: theme.palette.custom.secondary.secondary_3,
    padding: "0.625rem",
  }));

  export const TableContent = styled(TableCell, {
    shouldForwardProp: (prop) => prop !== "mySelf",
  })(({ mySelf }: { mySelf: boolean }) => ({ theme }) => ({
    fontSize: "0.875rem",
    fontWeight: 500,
    color: mySelf ? theme.palette.custom.accent.accent_2 : theme.palette.custom.secondary.secondary_1,
    borderBottom: "none",
    minWidth: "11.25rem",
    [theme.breakpoints.down("md")]: {
      minWidth: "8rem",
    },
  }));

  export const DetailsWrapper = styled(Box, {
    shouldForwardProp: (prop) => prop !== "mySelf",
  })(({ mySelf }: { mySelf: boolean }) => ({ theme }) => ({
    display: "flex",
    gap: theme.spacing(2),
    alignItems: "center",
    borderBottom: "none",
    p: {
      fontSize: "0.875rem",
      fontWeight: 500,
      color: mySelf ? theme.palette.custom.accent.accent_2 : theme.palette.custom.secondary.secondary_1,
      span: {
        fontSize: theme.spacing(2),
        color: theme.palette.custom.secondary.secondary_2,
      },
    },
  }));

  export const NoRecordContainer = styled(Box)(() => ({
    minHeight: "9.75rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  }));
}

export default S;
