import { Box } from "@mui/material";
import { styled, Stack, Typography } from "@mui/material";

namespace S {
  export const ThanksCardContainer = styled(Stack)(({ theme }) => ({
    alignItems: "center",
    justifyContent: "center",
    gap: theme.spacing(4),
    ".MuiInputBase-root": {
      background: theme.palette.custom.secondary.secondary_1,
      color: theme.palette.custom.secondary.secondary_2,
      border: `1px solid ${theme.palette.custom.secondary.secondary_2}`,
    },
  }));

  export const YearnGamesTitleContainer = styled(Box)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  }));

  export const ThanksCardTitle = styled(Typography)(() => ({
    color: "#000",
    fontWeight: 600,
  }));

  export const ThanksCardSubTitle = styled("a")(({ theme }) => ({
    display: "flex",
    color: theme.palette.custom.primary.main,
    cursor: "pointer",
    zIndex: "2",
    textDecoration: "none",
    fontWeight: 500,
    fontSize: "1rem",
    gap: "0.5rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.875rem",
    },
  }));

  export const YearnGamesIcon = styled("img")(({ theme }) => ({
    cursor: "pointer",
    width: "0.875rem",
    [theme.breakpoints.down("sm")]: {
      width: "0.75rem",
    },
  }));

  export const ThanksCardContent = styled(Typography)(({ theme }) => ({
    color: theme.palette.custom.secondary.secondary_2,
    ".special-text": {
      color: theme.palette.custom.secondary.secondary_2,
      fontWeight: 600,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.875rem",
    },
  }));

  export const ThanksCardsPointWrapper = styled(Stack)(({ theme }) => ({
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
  }));

  export const ThanksCardScrollContainer = styled(Stack)(({ theme }) => ({
    gap: theme.spacing(3),
    height: "100%",
    maxHeight: "20vh",
    overflow: "auto",
    margin: "0.5rem 0",
  }));

  export const ThanksCardPointsContainer = styled(Box)(({ theme }) => ({
    width: "95%",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    gap: theme.spacing(2),
  }));

  export const PoolUrl = styled("a")(({ theme }) => ({
    color: theme.palette.custom.primary.main,
    textDecoration: "none",
  }));

  export const CheckBoxImage = styled("img")(() => ({
    width: "1.2rem",
    height: "auto",
  }));

  export const PopupContainer = styled(Box)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    gap: "0.5rem",
    padding: "0.2rem !important",
  }));

  export const NormalInfoText = styled(Typography)(({ theme }) => ({
    fontSize: "12px",
    fontWeight: "500",
    color: theme.palette.custom.secondary.secondary_1,
  }));

  export const InfoRow = styled(Box)(() => ({
    display: "flex",
    justifyContent: "space-between",
    width: "117px",
  }));

  export const GreenInfoText = styled(NormalInfoText)(({ theme }) => ({
    display: "flex",
    justifyContent: "space-between",
    color: theme.palette.custom.accent.accent_1,
    width: "30px",
  }));

  export const ColonSpan = styled("span")(() => ({
    width: "5px",
  }));
}

export default S;
