import { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Box } from "@mui/material";
import { InputAdornment } from "@mui/material";
import { useAccount } from "wagmi";
import { SInvitedByPopover, SMyEarningsPopover } from "./popovers.styled";
import { lottoContract } from "../../services/ethereum/contract/web3Contract";
import { GRADIENT } from "../../utils/constants";
import { getEllipsisString } from "../../utils/helpers";
import { useClaimableAmount, useIsReady, useMyEarnings, useUserDetails } from "../../utils/hooks/selector";
import { CustomPopoverProps } from "../../utils/types";
import { GradientButton, Triangle } from "../../utils/widgets";
import CustomPopover from "../../utils/widgets/popover";
import Toast from "../../utils/widgets/toast";

const MyEarningsPopover: FC<CustomPopoverProps> = (props) => {
  const { id, isOpen, onClose } = props;

  //constructor
  const { address } = useAccount();

  //state values
  const userDetails = useSelector(useUserDetails);
  const myEarnings = useSelector(useMyEarnings);
  const isReady = useSelector(useIsReady);
  const claimableAmount = useSelector(useClaimableAmount);
  const [isClaimable, setIsClaimable] = useState<boolean>(false);
  const [claimAmount, setClaimAmount] = useState("");
  const [maxSelected, setMaxSelected] = useState(false);
  const [isClaiming, setIsClaiming] = useState(false);

  //constants
  const totalEarnings = myEarnings.winning + myEarnings.winnerReferrerBonuses;

  //functions
  const onAmountChange = (e: any) => {
    if (maxSelected) setMaxSelected(false);
    setClaimAmount(e.target.value);
  };

  const onClickMax = () => {
    setMaxSelected(true);
    setClaimAmount(claimableAmount.unclaimed.toString());
  };

  const onClaimAmount = () => {
    if (!isReady) return Toast({ message: "Contracts are not ready yet, try after some time!", type: "warning" });
    if (!isClaiming) {
      if (claimAmount === "" || claimAmount === "0") {
        Toast({ message: "Enter a valid amount!" });
        return;
      }
      setIsClaiming(true);
      if (maxSelected) {
        lottoContract.claimAmount(claimableAmount.unclaimed.toString(), `${address}`, () => {
          setIsClaiming(false);
          setClaimAmount("");
        });
      } else {
        lottoContract.claimAmount(claimAmount, `${address}`, () => {
          setIsClaiming(false);
          setClaimAmount("");
        });
      }
    }
  };

  useEffect(() => {
    if (userDetails.address && isReady) lottoContract.getIsClaimActive().then((res: boolean) => setIsClaimable(res));
  }, [userDetails.address, isReady]);

  return (
    <>
      {Boolean(isOpen) && <Triangle customStyle={{ top: 20, left: -117, borderBottomColor: "#2D2C31" }} />}
      <CustomPopover id={id} isOpen={isOpen} onClose={onClose} customStyle={{ top: 24, left: -2 }}>
        <SMyEarningsPopover.Wrapper>
          <SMyEarningsPopover.Header>
            <SMyEarningsPopover.HeaderText>My Earnings</SMyEarningsPopover.HeaderText>
          </SMyEarningsPopover.Header>
          <SMyEarningsPopover.Body>
            <Box>
              <SMyEarningsPopover.Text1 variant="subtitle2">My Address</SMyEarningsPopover.Text1>
              <SInvitedByPopover.UserAddress variant="body1">{getEllipsisString(userDetails.address, 14, 13)}</SInvitedByPopover.UserAddress>
            </Box>
            <SMyEarningsPopover.Row>
              <SMyEarningsPopover.Text1 variant="subtitle2">Earned from Winnings</SMyEarningsPopover.Text1>
              <SMyEarningsPopover.Text3 variant="subtitle1">${myEarnings.winning}</SMyEarningsPopover.Text3>
            </SMyEarningsPopover.Row>
            <SMyEarningsPopover.Row>
              <SMyEarningsPopover.Text1 variant="subtitle2">Earned from Referrals</SMyEarningsPopover.Text1>
              <SMyEarningsPopover.Text3 variant="subtitle1">${myEarnings.winnerReferrerBonuses}</SMyEarningsPopover.Text3>
            </SMyEarningsPopover.Row>
            <SMyEarningsPopover.Row>
              <SMyEarningsPopover.Text1 variant="subtitle2">Unclaimed Amount</SMyEarningsPopover.Text1>
              <SMyEarningsPopover.Text3 variant="subtitle1">${claimableAmount.unclaimed}</SMyEarningsPopover.Text3>
            </SMyEarningsPopover.Row>
            <SMyEarningsPopover.Row>
              <SMyEarningsPopover.Text1 variant="subtitle2">Claimed Amount</SMyEarningsPopover.Text1>
              <SMyEarningsPopover.Text3 variant="subtitle1">${claimableAmount.claimed}</SMyEarningsPopover.Text3>
            </SMyEarningsPopover.Row>
            <SMyEarningsPopover.AmountInputField
              placeholder="Enter claim amount"
              onChange={onAmountChange}
              value={claimAmount}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SMyEarningsPopover.MaxChipWrapper onClick={onClickMax} disabled={maxSelected || claimableAmount.unclaimed === 0 || isClaiming}>
                      <SMyEarningsPopover.MaxText disabled={maxSelected || claimableAmount.unclaimed === 0 || isClaiming}>
                        MAX
                      </SMyEarningsPopover.MaxText>
                    </SMyEarningsPopover.MaxChipWrapper>
                  </InputAdornment>
                ),
              }}
              disabled={claimableAmount.unclaimed === 0 || !isClaimable || isClaiming}
            />
            <SMyEarningsPopover.MaxAmountWarningText isVisible={Number(claimAmount) !== 0 && Number(claimAmount) === claimableAmount.unclaimed}>
              You have reached the maximum value
            </SMyEarningsPopover.MaxAmountWarningText>
            <SMyEarningsPopover.AmountExceededWarningText isVisible={Number(claimAmount) > claimableAmount.unclaimed}>
              You have exceeded the maximum value
            </SMyEarningsPopover.AmountExceededWarningText>
            <SMyEarningsPopover.ClaimAmountWrapper>
              <GradientButton
                children={isClaiming ? "Claiming..." : "Claim Amount"}
                buttonType={GRADIENT}
                onClick={onClaimAmount}
                disabled={claimableAmount.unclaimed === 0 || !isClaimable || isClaiming}
                loading={isClaiming}
              />
            </SMyEarningsPopover.ClaimAmountWrapper>
            {/* <SMyEarningsPopover.Row>
              <SMyEarningsPopover.Text1 variant="subtitle2">EARNINGS FROM WINNER REFERRAL PAYOUTS</SMyEarningsPopover.Text1>
              <SMyEarningsPopover.Text3 variant="subtitle1">${myEarnings.winnerReferralPayouts}</SMyEarningsPopover.Text3>
            </SMyEarningsPopover.Row> */}
            {/* <SMyEarningsPopover.Row>
              <SMyEarningsPopover.Text1 variant="subtitle2">EARNINGS FROM REFERRAL PAYOUTS</SMyEarningsPopover.Text1>
              <SMyEarningsPopover.Text3 variant="subtitle1">${myEarnings.referralPayouts}</SMyEarningsPopover.Text3>
            </SMyEarningsPopover.Row> */}
          </SMyEarningsPopover.Body>
          <SMyEarningsPopover.Footer>
            <SMyEarningsPopover.Row>
              <SMyEarningsPopover.Text2>TOTAL EARNED</SMyEarningsPopover.Text2>
              <SMyEarningsPopover.Text4>${totalEarnings}</SMyEarningsPopover.Text4>
            </SMyEarningsPopover.Row>
          </SMyEarningsPopover.Footer>
        </SMyEarningsPopover.Wrapper>
      </CustomPopover>
    </>
  );
};

export default MyEarningsPopover;
