import { ToastContainer } from "react-toastify";
import { Box, Stack, styled } from "@mui/material";
import { Typography } from "@mui/material";
import { darkTheme } from "../../theme";

namespace S {
  export const Toast = styled("div")(() => ({
    fontWeight: "600",
    letterSpacing: "0.5px",
  }));

  export const ToastContainerStyled = styled(ToastContainer)(() => ({
    ".Toastify__toast": {
      color: darkTheme.palette.custom.secondary.secondary_1,

      ".Toastify__close-button": {
        color: darkTheme.palette.custom.secondary.secondary_1,
      },

      ".Toastify__toast-icon > svg": {
        fill: darkTheme.palette.custom.secondary.secondary_1,
      },

      "&--success": {
        backgroundColor: darkTheme.palette.custom.toast.success,
      },
      "&--error": {
        backgroundColor: darkTheme.palette.custom.toast.error,
      },
      "&--warning": {
        backgroundColor: darkTheme.palette.custom.toast.warning,
      },
      "&--info": {
        "&, .Toastify__close-button": {
          backgroundColor: darkTheme.palette.custom.toast.info,
        },

        ".Toastify__toast-icon > svg": {
          fill: darkTheme.palette.custom.secondary.secondary_1,
        },
      },
      "&, .Toastify__close-button": {
        opacity: "1 !important",
      },
    },
  }));

  export const MessageCard = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.custom.accent.accent_2,
    height: "2.5rem",
    display: "flex",
    alignItems: "center",
    padding: "0 2.5rem",
    fontWeight: "bold",
    justifyContent: "space-evenly",
    gap: "1.5rem",
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      height: "auto",
    },
  }));

  export const MessageCardTimerWrapper = styled(Stack)(({ theme }) => ({
    padding: `0.1rem 0.5rem`,
    alignItems: "center",
    justifyContent: "space-between",
    cursor: "pointer",
    gap: "1rem",
    section: {
      span: {
        fontSize: "20px  !important",
        fontWeight: "600 !important",
      },
    },
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column !important",
      gap: "unset",
    },
  }));

  export const FlipCounterContainer = styled(Stack)(({ theme }) => ({
    flexDirection: "row",
    alignItems: "center",
    gap: theme.spacing(2),
  }));

  export const MessageCardTimerDivider = styled(Typography)({
    fontWeight: 600,
    fontFamily: "poppins",
    fontSize: "1rem",
  });
}

export default S;
