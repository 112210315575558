import { FC } from "react";
import FlipNumbers from "react-flip-numbers";
import { getWholeNum } from "../../../utils/helpers";

type FlipCounterPropsType = {
  width?: number;
  height?: number;
  numbers?: string;
  isTimer?: boolean;
  flipNumber?: string;
  isMessageCounter?: boolean;
};

const FlipCounter: FC<FlipCounterPropsType> = (props) => {
  //constants
  const { height = 120, width = 76.5, numbers = "0", isTimer = false, isMessageCounter = false, flipNumber } = props;

  return (
    <FlipNumbers
      height={height}
      width={width}
      color="#FFFFFF"
      background={isMessageCounter ? "transparent" : isTimer ? "rgba(0,0,0,0.5)" : "#000000"}
      play
      numbers={isTimer ? numbers : getWholeNum(flipNumber ?? "0")}
      // numberStyle={{
      //   fontSize: "10px",
      // }}
      // nonNumberStyle={{
      //   padding: "1rem",
      // }}
      perspective={700}
    />
  );
};

export default FlipCounter;
