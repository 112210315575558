import { FC } from "react";

const GradientSVG3: FC = () => {
  //constants
  const gradientTransform = `rotate(90)`;

  return (
    <svg style={{ height: 0, width: 0 }}>
      <defs>
        <linearGradient id="gradient3" gradientTransform={gradientTransform}>
          <stop offset="0%" stopColor="#EB00FF" />
          <stop offset="100%" stopColor="#E9B80C" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default GradientSVG3;
