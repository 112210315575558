import { Stack, styled } from "@mui/material";

namespace S {
  export const LeftSidePanelWrapper = styled(Stack, {
    shouldForwardProp: (prop) => prop !== "panelView",
  })(({ panelView }: { panelView: boolean }) => ({ theme }) => ({
    gap: theme.spacing(6),
    maxWidth: "23.75rem",
    minWidth: "23.75rem",
    boxSizing: "border-box",
    [theme.breakpoints.down("md")]: {
      height: "calc(100% - 5rem)",
      width: "100%",
      maxWidth: "29.0625rem",
      padding: theme.spacing(8),
      paddingTop: "0",
      overflowY: "auto",
      backgroundColor: theme.palette.custom.bg.app,
      position: "absolute",
      top: "5rem",
      left: "0",
      borderTopRightRadius: "0.5rem",
      borderBottomRightRadius: "0.5rem",
      alignContent: "start",
      zIndex: "11",
      transition: "transform 0.5s ease-out",
      transform: panelView ? "translateX(0)" : "translateX(calc(-29.0625rem))",
      "& > div: nth-of-type(3)": {
        overflow: "unset !important",
      },
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  }));

  export const Refer = styled(Stack, {
    shouldForwardProp: (prop) => prop !== "isNoUser",
  })(({ isNoUser }: { isNoUser: boolean }) => ({ theme }) => ({
    gap: theme.spacing(4),
    alignItems: "center",
    justifyContent: "center",
    ...(!isNoUser && { height: "12.5rem" }),
    maxHeight: "18.75rem",

    p: {
      textAlign: "center",
      fontWeight: "bold",
      fontSize: "1.25rem",
    },
  }));
}

export default S;
