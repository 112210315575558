import { useSelector } from "react-redux";
import { Tooltip } from "@mui/material";
import S from "./thanks-card.styled";
import OpenArrow from "../../../assets/images/greetings-assets/openWindow.svg";
import CHECKBOX from "../../../assets/images/square-checkbox.png";
import { REFERRAL_PATH_NAME, creditPercentage, EXPLORER_URL } from "../../../utils/constants";
import { useGetDimensions } from "../../../utils/helpers";
import { useMaxTickets, useParticipantTxnHash, useUserDetails, useUserTickets } from "../../../utils/hooks/selector";
import InputWithCopyAndShare from "../../input-with-copy-and-share";

const ThanksCard = () => {
  //state values
  const userDetails = useSelector(useUserDetails);
  const ticketNumbers = useSelector(useUserTickets).ticketNumbers;
  const participantTxnHash = useSelector(useParticipantTxnHash);
  const maxTickets = useSelector(useMaxTickets);

  //constants
  const isSm = useGetDimensions();
  const CURRENT_CHAIN_ID: number = Number(process.env.REACT_APP_CURRENT_CHAINID);
  const explorerUrl = EXPLORER_URL[CURRENT_CHAIN_ID];

  //functions
  const handleClick = () => {
    window.open(`${explorerUrl}/tx/${participantTxnHash}`);
  };

  return (
    <S.ThanksCardContainer>
      <S.YearnGamesTitleContainer>
        <S.ThanksCardTitle variant={isSm ? "body1" : "h5"}>Thanks for your Participation</S.ThanksCardTitle>
        <S.ThanksCardSubTitle onClick={handleClick}>
          View transaction on block explorer
          <S.YearnGamesIcon src={OpenArrow} alt="redirect-icon" onClick={handleClick} />
        </S.ThanksCardSubTitle>
      </S.YearnGamesTitleContainer>
      <S.ThanksCardContent width={isSm ? "100%" : "95%"} textAlign={"center"}>
        Here’s your referral URL. Every user that joins with this URL will be part of your team
      </S.ThanksCardContent>
      <InputWithCopyAndShare
        Url={`${window.location.origin}/${REFERRAL_PATH_NAME}/${userDetails.id}/${userDetails.address}`}
        InputContainerStyle={{ margin: "0.5rem 0", minWidth: isSm ? "18rem" : "27rem", maxWidth: isSm ? "18rem" : "27rem" }}
      />
      <S.ThanksCardsPointWrapper>
        <S.ThanksCardTitle alignSelf={"flex-start"}>What happens next?</S.ThanksCardTitle>
        <S.ThanksCardScrollContainer>
          <S.ThanksCardPointsContainer>
            <S.CheckBoxImage src={CHECKBOX} alt="square-checkbox" />
            <S.ThanksCardContent>
              You will receive {ticketNumbers.length * 1000} <span className="special-text">YEARN</span> and{" "}
              <Tooltip
                arrow
                PopperProps={{
                  sx: {
                    "& .MuiTooltip-tooltip": {
                      background: "#2D2C31",
                      maxWidth: "15rem",
                      "@media (max-width: 600px)": {
                        maxWidth: "10rem",
                      },
                    },

                    "& .MuiTooltip-arrow": {
                      "&::before": {
                        backgroundColor: "#2D2C31",
                      },
                    },
                  },
                }}
                title={
                  <S.PopupContainer>
                    <S.NormalInfoText>
                      Buying multiple Lucky Passes in a single transaction gives tokens as credit. This credit increases in a linear way, up to the
                      maximum of {maxTickets} Lucky Passes.
                    </S.NormalInfoText>
                    {Object.keys(creditPercentage).map((ticket, i) => {
                      return (
                        <S.InfoRow key={`${ticket}_${i}`}>
                          <S.NormalInfoText>{ticket}</S.NormalInfoText>
                          <S.GreenInfoText>
                            <S.ColonSpan>:</S.ColonSpan>
                            {creditPercentage[ticket]}
                          </S.GreenInfoText>
                        </S.InfoRow>
                      );
                    })}
                  </S.PopupContainer>
                }
              >
                <span className="special-text" style={{ cursor: "pointer" }}>
                  BONUS
                </span>
              </Tooltip>{" "}
              shortly.
            </S.ThanksCardContent>
          </S.ThanksCardPointsContainer>
          <S.ThanksCardPointsContainer>
            <S.CheckBoxImage src={CHECKBOX} alt="square-checkbox" />
            <S.ThanksCardContent>
              You have the opportunity to take part in{" "}
              <S.PoolUrl href={process.env.REACT_APP_POOL_MANAGEMENT_LINK} target="_blank">
                pool
              </S.PoolUrl>
              {"."}
            </S.ThanksCardContent>
          </S.ThanksCardPointsContainer>
          <S.ThanksCardPointsContainer>
            <S.CheckBoxImage src={CHECKBOX} alt="square-checkbox" />
            <S.ThanksCardContent>
              You can participate on <span className="special-text">Free</span> and <span className="special-text">Premium</span> Predictions.
            </S.ThanksCardContent>
          </S.ThanksCardPointsContainer>
        </S.ThanksCardScrollContainer>
      </S.ThanksCardsPointWrapper>
    </S.ThanksCardContainer>
  );
};

export default ThanksCard;
