import { FC } from "react";

const GradientSVG2: FC = () => {
  //constants
  const gradientTransform = `rotate(90)`;

  return (
    <svg style={{ height: 0, width: 0 }}>
      <defs>
        <linearGradient id="gradient2" gradientTransform={gradientTransform}>
          <stop offset="0%" stopColor="#FFE600" />
          <stop offset="100%" stopColor="#24FF00" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default GradientSVG2;
