import { Dispatch, FC, SetStateAction, useEffect } from "react";
import { useSelector } from "react-redux";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Box } from "@mui/material";
import S from "./right-side-panel.styled";
import { getTopReferrersIn100K, getTopReferrersInOneK } from "../../store/slices/user.actions";
import { PRIMARY } from "../../utils/constants";
import { useGetDimensions } from "../../utils/helpers";
import { useCurrentDraw, useDraw1kNumber, useEnteredDraw, useTicketsSold } from "../../utils/hooks/selector";
import { useAppDispatch } from "../../utils/hooks/state";
import { Button, CustomCardWrapper } from "../../utils/widgets";
import RecentWinnerCarousel from "./RecentWinnerCarousel";
import Challenge from "./challenge";
import Distributor from "./earning-card/Distributor";
import GameCard from "./game-card";
import TopReferralsCard from "./top-referrals-card";

type RightSidePanelProps = {
  showRightPanel: boolean;
  setShowRightPanel: Dispatch<SetStateAction<boolean>>;
};

const RightSidePanel: FC<RightSidePanelProps> = ({ showRightPanel, setShowRightPanel }) => {
  //constructors
  const isMd = useGetDimensions("md");
  const dispatch = useAppDispatch();

  //state values
  const enteredDraw = useSelector(useEnteredDraw);
  const currentDraw = useSelector(useCurrentDraw);
  const ticketsSold = useSelector(useTicketsSold);
  const draw1kNumber = useSelector(useDraw1kNumber);

  //constants
  const isOneKReached = Boolean(ticketsSold !== 0 && ticketsSold % draw1kNumber === 0 && +currentDraw * draw1kNumber === ticketsSold);

  //Collecting top referrers
  //TODO:Need to cache this record for a period.
  useEffect(() => {
    dispatch(getTopReferrersIn100K());
    dispatch(getTopReferrersInOneK(+currentDraw));
    if (currentDraw !== "1") dispatch(getTopReferrersInOneK(1));
  }, [enteredDraw, currentDraw, isOneKReached, ticketsSold]);

  return (
    /* RightSidePanelWrapper is created using mui Stack compopnent, So you can apply Stack components deafult props directly */
    <S.RightSidePanelWrapper panelView={showRightPanel}>
      {isMd && (
        <Button
          buttonType={PRIMARY}
          onClick={() => {
            setShowRightPanel(!showRightPanel);
          }}
          containerCustomStyle={{
            position: "sticky",
            top: 0,
            zIndex: 4,
            background: "#16151A",
            paddingBottom: "15px",
          }}
          customStyle={{
            // width: "34px !important",
            // height: "30px !important",
            // padding: "10px !important",
            // minWidth: "1.7rem",
            // maxWidth: "1.7rem",
            // borderRadius: "50%",
            // marginTop: "1.25rem",
            // marginLeft: "auto",
            minHeight: "1.7rem",
            minWidth: "1.7rem",
            padding: "0",
            maxWidth: "1.7rem",
            borderRadius: "50%",
            marginTop: "1rem",
          }}
        >
          <ArrowForwardIcon />
        </Button>
      )}
      {/* <RecentWinnerCarousel /> */}
      {/* <Distributor /> */}
      <GameCard />
      {/* <TopReferralsCard /> */}
      <Challenge />

      {/* <CustomCardWrapper>
        <Box
          sx={{
            minHeight: "24.75rem",
            maxHeight: "24.75rem",
          }}
        ></Box>
      </CustomCardWrapper> */}
    </S.RightSidePanelWrapper>
  );
};

export default RightSidePanel;
