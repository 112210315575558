import { FC } from "react";
import { circularProgressClasses, Box } from "@mui/material";
import S from "./progress-bar.styled";
import GradientSVG from "../gradients/gradient1";
import { gradient, progressBarProps } from "../../types/progress-bar";
import { GRADIENT1, GRADIENT2, GRADIENT3 } from "../../constants";
import GradientSVG3 from "../gradients/gradient3";
import GradientSVG2 from "../gradients/gradient2";

const CircularProgressWithLabelForJackpot: FC<progressBarProps> = ({ gradient, percentage, amount, value }) => {
  //functions
  const getGradient = (gradient: gradient) => {
    switch (gradient) {
      case GRADIENT1: {
        return <GradientSVG />;
      }
      case GRADIENT2: {
        return <GradientSVG2 />;
      }
      case GRADIENT3: {
        return <GradientSVG3 />;
      }
      default:
        return <GradientSVG />;
    }
  };

  return (
    <S.CircularProgressWrapper currentRoll={value}>
      <Box sx={{ position: "relative" }}>
        <S.StaticCircularProgressBar variant="determinate" thickness={4} value={100} size={45} />
        {getGradient(gradient)}
        <S.CustomCircularProgressBar
          variant="determinate"
          value={percentage}
          sx={{
            [`& .${circularProgressClasses.circle}`]: {
              stroke: `url(#${gradient})`,
              strokeLinecap: "round",
            },
          }}
          thickness={4}
          size={45}
        />
      </Box>
      <S.LabelContentWrapper>
        {value ? <S.LabelContentRoll>ROLL</S.LabelContentRoll> : <S.LabelContent gradient={gradient}>{percentage}%</S.LabelContent>}
      </S.LabelContentWrapper>
    </S.CircularProgressWrapper>
  );
};

export default CircularProgressWithLabelForJackpot;
