import { FC } from "react";
import S from "./user-guide.styled";
import playIcon from "../../../assets/images/greetings-assets/play-icon.png";
import { useGetDimensions } from "../../../utils/helpers";
import { UserGuideType } from "../../../utils/types";

type UserGuideProps = {
  guide: UserGuideType[];
};

const UserGuide: FC<UserGuideProps> = ({ guide }) => {
  //constants
  const isSm = useGetDimensions();

  return (
    <S.UserGuideCardContainer>
      <S.UserGuideTitle variant={isSm ? "body1" : "h5"}>Your Ultimate Guide</S.UserGuideTitle>
      <S.YearnGamePointsWrapper>
        <S.YearnGamePointsContainer>
          <S.YearnGamesContent textAlign={"center"}>Offering guidance through video content</S.YearnGamesContent>
        </S.YearnGamePointsContainer>
      </S.YearnGamePointsWrapper>
      <S.UserGuidePointsWrapper>
        {guide.map((g) => {
          return (
            <S.UserGuidePointsContainer key={g.id}>
              <S.UserGuideContent>
                {g.id}. {g.label}
              </S.UserGuideContent>
              <S.UserGuideUrl href={g.link} target="_blank">
                <S.PlayIconImage src={playIcon} alt="play-icon" />
              </S.UserGuideUrl>
            </S.UserGuidePointsContainer>
          );
        })}
      </S.UserGuidePointsWrapper>
    </S.UserGuideCardContainer>
  );
};

export default UserGuide;
