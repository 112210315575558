import { Box, IconButton, Stack, styled, Typography } from "@mui/material";

namespace S {
  export const WinnerCard = styled(Stack)(({ theme }) => ({
    gap: theme.spacing(2),
    minHeight: "15rem",
    maxHeight: "15rem",
    backgroundColor: theme.palette.custom.secondary.secondary_3,
    borderRadius: "0.625rem",
    justifyContent: "center",
  }));

  export const WinnerCardHeader = styled(Box)(({ theme }) => ({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0 1rem 0 1rem",
    maxHeight: "3rem",
    [theme.breakpoints.down("sm")]: {
      padding: "0 0.625rem 0 0.625rem",
    },
  }));

  export const WinnerHeaderTextContainer = styled(Box)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    // justifyContent: "space-between",
    h6: {
      color: theme.palette.custom.secondary.secondary_1,
      fontWeight: 600,
    },
  }));

  export const WinnerHeaderText = styled(Typography)(({ theme }) => ({
    fontWeight: 600,
    width: "fit-content",
    lineHeight: 1.2,
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
    },
  }));

  export const WinningAmountWrapper = styled(Box)(({ theme }) => ({
    borderRadius: theme.spacing(2),
    border: "1px solid #3E3E3E",
    padding: theme.spacing(1),
    textAlign: "center",
    alignSelf: "end",
    h5: {
      fontWeight: 600,
      color: theme.palette.custom.secondary.secondary_1,
    },
  }));

  export const WinningAmount = styled(Typography)(({ theme }) => ({
    fontWeight: 600,
    color: theme.palette.custom.secondary.secondary_1,
  }));

  export const WinnerCardBody = styled(Box)(() => ({
    display: "flex",
    justifyContent: "center",
    padding: "0 1rem",
    borderRadius: "0.5rem",
  }));

  export const WinnerCardFooter = styled(Box)(() => ({
    display: "flex",
    justifyContent: "space-between",
    boxSizing: "border-box",
    background: "rgba(255, 255, 255, 0.05)",
    borderTop: "1px solid #3E3E3E",
    boxShadow: "0px 4px 4px rgba(89, 89, 89, 0.05)",
    borderRadius: "0px 0px 10px 10px",
    height: "3.375rem",
    width: "inherit",
    padding: "1rem",
    alignItems: "center",
    img: {
      cursor: "pointer",
    },
  }));

  export const ListIconButton = styled(IconButton, {
    shouldForwardProp: (prop) => prop !== "disabled",
  })(({ disabled }: { disabled: boolean }) => ({ theme }) => ({
    background: disabled ? theme.palette.custom.secondary.secondary_2 : theme.palette.custom.primary.main,
    height: "1.5rem",
    width: "1.5rem",
    pointerEvents: disabled ? "none" : "auto",
    svg: {
      fontSize: "1rem",
    },
    "&:hover": {
      backgroundColor: disabled ? "" : "#1846dd",
    },
  }));

  export const SlotContentWrapper = styled(Box)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    p: {
      fontWeight: 500,
      color: theme.palette.custom.secondary.secondary_1,
    },
  }));

  export const SlotContent = styled(Typography)(() => ({
    fontWeight: 600,
  }));

  export const WinnerHeaderSpan = styled("span")({
    fontSize: "10px",
    fontWeight: 400,
  });
}

export default S;
