import { FC } from "react";
import { useSelector } from "react-redux";
import S from "./winner-list-styled";
import { useGetDimensions } from "../../../../utils/helpers";
import { useDraw100kNumber, useEvents, useTicketsSold } from "../../../../utils/hooks/selector";
import AffiliaterCard from "../../../../utils/widgets/cards/AffiliateCard";

// import { ModalBody } from "../../../../utils/widgets/custom-modal";

const WinnerListModal: FC = () => {
  //state values
  const recentWinners = useSelector(useEvents).recentWinners;
  const ticketsSold = useSelector(useTicketsSold);
  const draw100kNumber = useSelector(useDraw100kNumber);

  //constants
  const winnersList = Object.keys(recentWinners)
    .filter((w) => recentWinners[w].drawNumber)
    .map((draw) => {
      if (recentWinners[draw].drawNumber) {
        return {
          ...recentWinners[draw],
          draw,
        };
      }
    });
  const hundredKCompleted = Boolean(ticketsSold !== 0 && ticketsSold % draw100kNumber === 0);
  const isMd = useGetDimensions("md");
  const isSm = useGetDimensions();

  return (
    <S.WinnerListModalContainer>
      {/* <ModalBody isTransparent={true}> */}
      <S.WinnerListTitle variant={isSm ? "subtitle1" : isMd ? "h4" : "h2"}>Recent</S.WinnerListTitle>
      <S.WinnerListGradientTitle variant={isSm ? "h6" : isMd ? "h3" : "h2"}>Winner!</S.WinnerListGradientTitle>
      <S.WinnerListTicketCardContainer>
        {winnersList.map((winner, i) => {
          if ((winnersList.length > (hundredKCompleted ? 2 : 1) && i === winnersList.length - 1) || !winner) return null;
          return (
            <AffiliaterCard
              key={`${winner}_${i}`}
              isTimerModal={true}
              ticketId={winner.ticketNumber}
              userId={winner.userId}
              drawNumber={winner.drawNumber}
              draw={winner.draw}
            />
          );
        })}
      </S.WinnerListTicketCardContainer>
      {/* </ModalBody> */}
    </S.WinnerListModalContainer>
  );
};

export default WinnerListModal;
