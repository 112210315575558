import { FC } from "react";
import { Typography } from "@mui/material";
import S from "./hundredk-draw.styled";
import { useGetDimensions } from "../../../../../utils/helpers";

const HundredKDraw: FC = () => {
  //constants
  const isSm = useGetDimensions();

  return (
    <S.Container>
      <S.ContentHeaderText>What is 100K Draw ?</S.ContentHeaderText>
      <Typography variant={isSm ? "caption" : "body2"}>
        For 1,00,000 participants joining a draw will happen once. This doesn’t mean 1,00,000 user from the moment you join, rather the next 1,00,000
        mark nearing from the moment the user join.
      </Typography>
      <S.ExampleText variant={isSm ? "caption" : "body2"}>Example:</S.ExampleText>
      {/* <Typography variant={isSm ? "caption" : "body2"}>
        Example: 10K draw happens in every 10,000 users despite of their position, a draw will happen from 0 - 10,000, 10,000-20,000, 20,000-3,000
        ...... 90,000-1,00,000.
      </Typography> */}
      <Typography variant={isSm ? "caption" : "body2"}>
        Lets assume you position number is 15,930 then your 100K draw will happen at 100k users. If your position is 100k then you will be instantly
        participating in the 10K draw as well as 1K draw.
      </Typography>
    </S.Container>
  );
};
export default HundredKDraw;
