import { useEffect, useState } from "react";
import S from "./grafetti.styled";
import grafetti from "../../../assets/images/grafetti1.png";

//functions
const ConfettiCont = () => {
  let randomWidth = Math.floor(Math.random() * Math.max(document.documentElement.clientWidth, window.innerWidth || 0));
  let randomHeight = Math.floor(Math.random() * Math.max(document.documentElement.clientHeight, window.innerHeight || 500));

  let grafettiDimension = () => {
    return Math.floor(Math.random() * 10) + 5;
  };

  const confettiStyle = () => {
    let dimension = grafettiDimension();
    return {
      width: dimension,
      height: dimension,
      top: `${randomHeight}px`,
      left: `${randomWidth}px`,
      animationDelay: `${Math.floor(Math.random() * 15)}s`,
      transform: `rotate(${Math.floor(Math.random() * 360)}deg)`,
    };
  };

  const children = <S.confetti src={grafetti} style={confettiStyle()} />;

  return <S.ConfettiShowerContainer>{children}</S.ConfettiShowerContainer>;
};

const ConfettiAnimations = () => {
  //state values
  const [count, setCount] = useState([]);

  //functions
  let dummyCount: any = [];
  for (let i = 0; i < 250; i++) {
    dummyCount.push(i);
  }

  useEffect(() => {
    setCount(dummyCount);
  }, []);
  return <S.CreatoAddedAnimeContainer>{count.length > 0 && count.map((item) => <ConfettiCont key={item} />)}</S.CreatoAddedAnimeContainer>;
};

export default ConfettiAnimations;
