import { Promise } from "bluebird";
import store from "../../../store";
import { setIsPredictingPool, setIsRedeemRewardLoading, setIsWithdrawingFund } from "../../../store/slices/match";
import { setConfetti } from "../../../store/slices/user";
import Toast from "../../../utils/widgets/toast";
import ABI from "../build/predictToEarn_abi.json";
import { web3 } from "./web3";

export namespace PredictionToEarn {
  export const getContract = async (library: any, contractAddress: any) => {
    if (library) {
      web3.setProvider(library);
    }

    const PoolAbi = ABI.abi as any; // ABI definitions
    const Contract = new web3.eth.Contract(PoolAbi, contractAddress);
    Promise.promisifyAll(Contract, { suffix: "Promise" });
    return Contract;
  };

  // Get pool A amount in wei
  export const poolATotal = async (library: any, contractAddress: any) => {
    const Contract = await getContract(library, contractAddress);
    let total = await Contract.methods.poolATotal().call();
    return web3.utils.fromWei(total, "ether");
  };

  // Get pool B amount in wei
  export const poolBTotal = async (library: any, contractAddress: any) => {
    const Contract = await getContract(library, contractAddress);
    let total = await Contract.methods.poolBTotal().call();
    return web3.utils.fromWei(total, "ether");
  };

  export const isWinnerPoolDecided = async (library: any, contractAddress: any) => {
    const Contract = await getContract(library, contractAddress);
    return await Contract.methods.isWinnerPoolDecided().call();
  };

  export const isPredictionEnded = async (library: any, contractAddress: any) => {
    const Contract = await getContract(library, contractAddress);
    return await Contract.methods.isPredictionEnded().call();
  };

  export const endTime = async (library: any, contractAddress: any) => {
    const Contract = await getContract(library, contractAddress);
    return await Contract.methods.END_TIME().call();
  };

  export const minAmount = async (library: any, contractAddress: any) => {
    const Contract = await getContract(library, contractAddress);
    let amount = await Contract.methods.MIM_AMOUNT().call();
    return web3.utils.fromWei(amount, "ether");
  };

  export const maxAmount = async (library: any, contractAddress: any) => {
    const Contract = await getContract(library, contractAddress);
    let amount = await Contract.methods.MAX_AMOUNT().call();
    return web3.utils.fromWei(amount, "ether");
  };

  export const poolBasicBatch = async (library: any, contractAddress: any) => {
    const data: any = {};
    let result = await Promise.all([
      PredictionToEarn.poolATotal(library, contractAddress),
      PredictionToEarn.poolBTotal(library, contractAddress),
      PredictionToEarn.isWinnerPoolDecided(library, contractAddress),
      PredictionToEarn.isPredictionEnded(library, contractAddress),
      PredictionToEarn.endTime(library, contractAddress),
      PredictionToEarn.minAmount(library, contractAddress),
      PredictionToEarn.maxAmount(library, contractAddress),
      PredictionToEarn.winnerAnnouncedOn(library, contractAddress),
    ]);

    data.poolATotal = result[0];
    data.poolBTotal = result[1];
    data.isWinnerPoolDecided = result[2];
    data.isPredictionEnded = result[3];
    data.endTime = result[4];
    data.minAmount = result[5];
    data.maxAmount = result[6];
    data.winnerAnnouncedOn = result[7];

    return data;
  };

  export const winnerAnnouncedOn = async (library: any, contractAddress: any) => {
    const Contract = await getContract(library, contractAddress);
    return await Contract.methods.winnerAnnouncedOn().call();
  };

  export const isPoolAHolder = async (library: any, contractAddress: any, account: any) => {
    const Contract = await getContract(library, contractAddress);
    return await Contract.methods.isPoolAHolder(account).call();
  };

  export const isPoolBHolder = async (library: any, contractAddress: any, account: any) => {
    const Contract = await getContract(library, contractAddress);
    return await Contract.methods.isPoolBHolder(account).call();
  };

  export const userPrediction = async (library: any, contractAddress: any, account: any) => {
    try {
      let result = await Promise.all([
        PredictionToEarn.isPoolAHolder(library, contractAddress, account),
        PredictionToEarn.isPoolBHolder(library, contractAddress, account),
      ]);
      // console.log(result, "result");
      if (result[0]) return "poolA";
      if (result[1]) return "poolB";
      return null;
    } catch (error) {
      console.log(error);
      return null;
    }
  };

  export const expectedRewards = async (library: any, contractAddress: any, account: any) => {
    const Contract = await getContract(library, contractAddress);
    let reward = await Contract.methods.expectedRewards(account).call();
    return web3.utils.fromWei(reward, "ether");
  };

  // Get pool A details of user
  /**
    0: address: owner 0x488a930027768548259cfAb3611dbF6EeecD5323
    1: uint256: amount 1000000000000000000
    2: uint256: percentage 10000000
    3: uint256: claimed_amount 0
    4: bool: isRedeemed false
    5: uint8: status 0 (enum STATUS { NONE, NO_RESULT, WON, LOST })
   */
  export const poolA = async (library: any, contractAddress: any, address: any) => {
    const Contract = await getContract(library, contractAddress);
    return await Contract.methods.poolA(address).call();
  };

  export const poolB = async (library: any, contractAddress: any, address: any) => {
    const Contract = await getContract(library, contractAddress);
    return await Contract.methods.poolB(address).call();
  };

  export const userPoolDetails = async (library: any, contractAddress: any, account: any) => {
    try {
      let result = await Promise.all([
        PredictionToEarn.poolA(library, contractAddress, account),
        PredictionToEarn.poolB(library, contractAddress, account),
      ]);

      let status: any = { 0: "NONE", 1: "NO_RESULT", 2: "WON", 3: "LOST" };
      let data: any = {};
      if (result[0].owner === account) {
        data["myPrediction"] = "poolA";
        data["amount"] = result[0].amount !== "0" ? web3.utils.fromWei(result[0].amount, "ether") : "0";
        data["claimed_amount"] = result[0].claimed_amount !== "0" ? web3.utils.fromWei(result[0].claimed_amount, "ether") : "0";
        data["isRedeemed"] = result[0].isRedeemed;
        data["status"] = status[result[0].status];
      }
      if (result[1].owner === account) {
        data["myPrediction"] = "poolB";
        data["amount"] = result[1].amount !== "0" ? web3.utils.fromWei(result[1].amount, "ether") : "0";
        data["claimed_amount"] = result[1].claimed_amount !== "0" ? web3.utils.fromWei(result[1].claimed_amount, "ether") : "0";
        data["isRedeemed"] = result[1].isRedeemed;
        data["status"] = status[result[1].status];
      }
      return data;
    } catch (error) {
      console.log(error);
      return null;
    }
  };

  export const predictPoolA = async (
    library: any,
    contractAddress: any,
    address: string,
    amount: any,
    cb?: (res: any) => void,
    handleConnecting?: () => void,
  ) => {
    let amountWei = web3.utils.toWei(amount, "ether");
    let gasPrice = await web3.eth.getGasPrice();

    const Contract = await getContract(library, contractAddress);
    Contract.methods
      .predictPoolA(amountWei)
      .send({ from: address, gaslimit: 1100000, gasPrice })
      .on("transactionHash", (hash: any) => {
        // console.log("hash", hash);
      })
      .on("receipt", (receipt: any) => {
        // console.log(receipt, "receipt");
        if (receipt.status) {
          Toast({ message: "Predict Submitted!", type: "success" });
          store.dispatch(setConfetti(true));
          if (cb) cb(receipt);
        } else Toast({ message: "Predict was not done!", type: "error" });
        store.dispatch(setIsPredictingPool());
      })
      .on("error", (error: any) => {
        console.log("error", error.message);

        if (error.message.includes(":")) {
          let msg = error.message.split(":")[0];
          Toast({ message: msg, type: "error" });
        } else Toast({ message: error.message, type: "error" });
        store.dispatch(setIsPredictingPool());

        if (handleConnecting) handleConnecting();
      });
  };

  export const predictPoolB = async (
    library: any,
    contractAddress: any,
    address: string,
    amount: any,
    cb?: (res: any) => void,
    handleConnecting?: () => void,
  ) => {
    let amountWei = web3.utils.toWei(amount, "ether");
    let gasPrice = await web3.eth.getGasPrice();

    const Contract = await getContract(library, contractAddress);
    Contract.methods
      .predictPoolB(amountWei)
      .send({ from: address, gaslimit: 1100000, gasPrice })
      .on("transactionHash", (hash: any) => {
        // console.log("hash", hash);
      })
      .on("receipt", (receipt: any) => {
        // console.log(receipt, "receipt");
        if (receipt.status) {
          Toast({ message: "Predict Submitted!", type: "success" });
          store.dispatch(setConfetti(true));
          if (cb) cb(receipt);
        } else Toast({ message: "Predict was not done!", type: "error" });
        store.dispatch(setIsPredictingPool(false));
      })
      .on("error", (error: any) => {
        console.log("error", error.message);

        if (error.message.includes(":")) {
          let msg = error.message.split(":")[0];
          Toast({ message: msg, type: "error" });
        } else Toast({ message: error.message, type: "error" });
        store.dispatch(setIsPredictingPool(false));

        if (handleConnecting) handleConnecting();
      });
  };

  export const withdrawFund = async (
    library: any,
    contractAddress: any,
    address: string,
    amount: any,
    cb?: (res: any) => void,
    handleConnecting?: () => void,
  ) => {
    let amountWei = web3.utils.toWei(amount, "ether");
    let gasPrice = await web3.eth.getGasPrice();

    const Contract = await getContract(library, contractAddress);
    Contract.methods
      ._withdrawFund(amountWei)
      .send({ from: address, gaslimit: 1100000, gasPrice })
      .on("transactionHash", (hash: any) => {
        // console.log("hash", hash);
      })
      .on("receipt", (receipt: any) => {
        // console.log(receipt, "receipt");
        if (receipt.status) {
          Toast({ message: "Withdraw Successful!", type: "success" });
          if (cb) cb(receipt);
        } else Toast({ message: "Withdraw was not done!", type: "error" });
        store.dispatch(setIsWithdrawingFund());
      })
      .on("error", (error: any) => {
        console.log("error", error.message);

        if (error.message.includes(":")) {
          let msg = error.message.split(":")[0];
          Toast({ message: msg, type: "error" });
        } else Toast({ message: error.message, type: "error" });
        store.dispatch(setIsWithdrawingFund());

        if (handleConnecting) handleConnecting();
      });
  };
}

export const redeemRewards = async (library: any, contractAddress: any, address: string, cb: () => void) => {
  store.dispatch(setIsRedeemRewardLoading(true));
  const Contract = await PredictionToEarn.getContract(library, contractAddress);
  let gasPrice = await web3.eth.getGasPrice();

  Contract.methods
    .redeemRewards()
    .send({ from: address, gaslimit: 1100000, gasPrice })
    .on("transactionHash", (hash: any) => {
      // console.log("hash", hash);
    })
    .on("receipt", (receipt: any) => {
      // console.log(receipt, "receipt");
      if (receipt.status) {
        Toast({ message: "Redeemed Successfully!", type: "success" });
        store.dispatch(setConfetti(true));
      } else Toast({ message: "Redeem was not done!", type: "error" });
      store.dispatch(setIsRedeemRewardLoading(false));
      cb();
    })
    .on("error", (error: any) => {
      console.log("error", error.message);

      if (error.message.includes(":")) {
        let msg = error.message.split(":")[0];
        Toast({ message: msg, type: "error" });
      } else Toast({ message: error.message, type: "error" });
      store.dispatch(setIsRedeemRewardLoading(false));
    });
};
