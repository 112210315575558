import { useEffect } from "react";
import { useNetwork, useSwitchNetwork } from "wagmi";

export const usePolygon = () => {
  const { error, isLoading, switchNetwork } = useSwitchNetwork();

  const { chain, chains } = useNetwork();

  useEffect(() => {
    if (!isLoading && !error && chains.length && chain && chain.id !== chains[0].id) {
      switchNetwork?.(chains[0].id);
    }
  }, [chains, isLoading, error, switchNetwork, chain]);
};
