import { FC } from "react";
import { useSelector } from "react-redux";
import { Skeleton } from "@mui/material";
import S from "./affiliater-skeleton.styled";
import { useEnteredDraw } from "../../../hooks/selector";

const AffiliaterSkeleton: FC = () => {
  //state values
  const enteredDraw = useSelector(useEnteredDraw);

  return (
    <S.SkeletonContainer>
      {!enteredDraw ? (
        <Skeleton variant="text" sx={{ fontSize: "3.5rem", width: "100%", alignSelf: "center", margin: "auto 0" }} />
      ) : (
        <>
          <S.SkeletonTopContainer>
            <Skeleton variant="circular" width={60} height={60} />
            <S.SkeletonTopSubContainer>
              <Skeleton variant="text" sx={{ fontSize: "0.8rem", width: "50%" }} />
              <Skeleton variant="text" sx={{ fontSize: "2rem", width: "80%" }} />
            </S.SkeletonTopSubContainer>
          </S.SkeletonTopContainer>

          <S.SkeletonBottomContainer>
            <S.SkeletonSubContainer>
              <Skeleton variant="text" sx={{ fontSize: "2rem", width: "30%" }} />
              <Skeleton variant="text" sx={{ fontSize: "0.5rem", width: "40%" }} />
            </S.SkeletonSubContainer>
            <S.SkeletonSubContainerRight>
              <S.SkeletonContentContainer>
                <Skeleton variant="text" sx={{ fontSize: "1rem", width: "40%" }} />
                <Skeleton variant="text" sx={{ fontSize: "2rem", width: "50%" }} />
              </S.SkeletonContentContainer>
              <Skeleton variant="rectangular" width={60} height={60} />
            </S.SkeletonSubContainerRight>
          </S.SkeletonBottomContainer>
        </>
      )}
    </S.SkeletonContainer>
  );
};

export default AffiliaterSkeleton;
