import { Dispatch, FC, SetStateAction } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import S from "./right-side-arrow-panel.styled";
import { PRIMARY } from "../../utils/constants";
import { Button } from "../../utils/widgets";

type RightSideArrowPanelProps = {
  showRightPanel: boolean;
  setShowRightPanel: Dispatch<SetStateAction<boolean>>;
  setShowLeftPanel: (val: boolean) => void;
};

const RightSideArrowPanel: FC<RightSideArrowPanelProps> = ({ showRightPanel, setShowRightPanel, setShowLeftPanel }) => {
  return (
    <S.RightSideArrowPanelWrapper>
      <Button
        buttonType={PRIMARY}
        onClick={() => {
          setShowRightPanel(!showRightPanel);
          setShowLeftPanel(false);
        }}
        containerCustomStyle={{
          position: "sticky",
          top: "-39px",
        }}
        customStyle={{
          minHeight: "1.7rem",
          minWidth: "1.7rem",
          padding: "0",
          maxWidth: "1.7rem",
          borderRadius: "50%",
          marginTop: "1rem",
        }}
      >
        <ArrowBackIcon />
      </Button>
    </S.RightSideArrowPanelWrapper>
  );
};

export default RightSideArrowPanel;
