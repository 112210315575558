import { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { IconButton, Typography } from "@mui/material";
import S from "./header.styled";
import UserDp from "../../../assets/images/ProfileImage.png";
import InviterDp from "../../../assets/images/User1.png";
import { isMyself, useGetDimensions } from "../../../utils/helpers";
import { useEnteredDraw, useInvitedBy, useIsAppLoading, useIsWhitelisted, useUserDetails, useWinnerOverlay } from "../../../utils/hooks/selector";
import InvitedByPopover from "../../popovers/InvitedByPopover";

const UserDetailsCard: FC = () => {
  //state values
  const invitedBy = useSelector(useInvitedBy);
  const enteredDraw = useSelector(useEnteredDraw);
  const userDetails = useSelector(useUserDetails);
  const winnerOverlay = useSelector(useWinnerOverlay);
  const isAppLoading = useSelector(useIsAppLoading);
  const isWhitelisted = useSelector(useIsWhitelisted);

  const [inviteByPopover, setInviteByPopover] = useState<HTMLButtonElement | HTMLImageElement | null>(null);

  //constants
  const isMd = useGetDimensions("md");

  //On WinnerTimerModal or isAppLoading Open
  useEffect(() => {
    if (winnerOverlay || isAppLoading) setInviteByPopover(null);
  }, [winnerOverlay, isAppLoading]);

  //functions
  const handleClick = (event: React.MouseEvent<HTMLButtonElement | HTMLImageElement>) => {
    setInviteByPopover(event.currentTarget);
  };

  const handleClose = () => {
    setInviteByPopover(null);
  };

  //TODO:Profile image should be generated randomly.
  return (
    <S.UserDetailsWrapper customStyle={{ display: !invitedBy && !enteredDraw ? "none" : "flex" }} data-tut="tour-user-info">
      {!isMd && invitedBy && !enteredDraw && <S.InvitedByText>invited by:</S.InvitedByText>}
      <S.ProfileImage src={invitedBy && !enteredDraw ? InviterDp : UserDp} onClick={isMd ? handleClick : () => {}} />
      {/* {!isMd && ( */}
      <>
        <Typography>#{invitedBy && !enteredDraw ? invitedBy.referrerId : userDetails.id}</Typography>
        {isWhitelisted && <S.WhiteListedUser>{"(Whitelisted User)"}</S.WhiteListedUser>}
        {isMyself(invitedBy && !enteredDraw ? invitedBy.referrerId : userDetails.id) && <S.MyProfile>(Me)</S.MyProfile>}
        <IconButton onClick={handleClick}>
          <KeyboardArrowDownIcon />
        </IconButton>
      </>
      {/* )} */}
      {inviteByPopover && <InvitedByPopover id={Boolean(inviteByPopover) ? "invite-by" : undefined} isOpen={inviteByPopover} onClose={handleClose} />}
    </S.UserDetailsWrapper>
  );
};

export default UserDetailsCard;
