import { FC } from "react";
import { useSelector } from "react-redux";
import { Typography } from "@mui/material";
import S from "./levels.styled";
import arrow from "../../../../../assets/images/Arrow.png";
import level0 from "../../../../../assets/images/Level0.png";
import level1 from "../../../../../assets/images/Level1.png";
import level2 from "../../../../../assets/images/Level2.png";
import level3 from "../../../../../assets/images/Level3.png";
import level4n5 from "../../../../../assets/images/Level4n5.png";
import { useGetDimensions } from "../../../../../utils/helpers";
import { usePaidUserReferralAmountByLevel } from "../../../../../utils/hooks/selector";

const Levels: FC = () => {
  //state values
  const levels = useSelector(usePaidUserReferralAmountByLevel);

  //constants
  const isSm = useGetDimensions();

  return (
    <S.Container>
      <S.ContentHeaderText>Team Levels</S.ContentHeaderText>
      <S.LevelsContainer>
        <S.SeperateLevel>
          <S.ImageText2>&nbsp;</S.ImageText2>
          <img src={level0} alt="level0" />
          <S.ImageText1>YOU</S.ImageText1>
        </S.SeperateLevel>
        <S.ArrowImage src={arrow} alt="arrow" />
        <S.SeperateLevel>
          <S.ImageText2>x ${levels[1].toFixed(0)}</S.ImageText2>
          <img src={level1} alt="level1" />
          <S.ImageText1>LEVEL 1</S.ImageText1>
        </S.SeperateLevel>
        <S.ArrowImage src={arrow} alt="arrow" />
        <S.SeperateLevel>
          <S.ImageText2>x ${levels[2].toFixed(1)}</S.ImageText2>
          <img src={level2} alt="level2" />
          <S.ImageText1>LEVEL 2</S.ImageText1>
        </S.SeperateLevel>
        <S.ArrowImage src={arrow} alt="arrow" />
        <S.SeperateLevel>
          <S.ImageText2>x ${levels[3]}</S.ImageText2>
          <img src={level3} alt="level3" />
          <S.ImageText1>LEVEL 3</S.ImageText1>
        </S.SeperateLevel>
        <S.ArrowImage src={arrow} alt="arrow" />
        <S.SeperateLevel>
          <S.ImageText2>x ${levels[4]}</S.ImageText2>
          <img src={level4n5} alt="level4" />
          <S.ImageText1>LEVEL 4</S.ImageText1>
        </S.SeperateLevel>
        <S.ArrowImage src={arrow} alt="arrow" />
        <S.SeperateLevel>
          <S.ImageText2>x ${levels[5]}</S.ImageText2>
          <img src={level4n5} alt="level5" />
          <S.ImageText1>LEVEL 5</S.ImageText1>
        </S.SeperateLevel>
      </S.LevelsContainer>
      <Typography variant={isSm ? "caption" : "body2"}>Every level can have up to n number of supporters.</Typography>
      <Typography variant={isSm ? "caption" : "body2"}>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus id fermentum eros, sit amet venenatis lectus. Integer commodo ut quam quis
        cursus. Donec quis nulla quis neque ornare porttitor. Suspendisse a mi luctus purus auctor scelerisque et non nisl. Curabitur eget pretium
        ligula.
      </Typography>
    </S.Container>
  );
};
export default Levels;
