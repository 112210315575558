import { FC } from "react";
import { Typography } from "@mui/material";
import S from "./onek-Draw.styled";
import { useGetDimensions } from "../../../../../utils/helpers";

const OneKDraw: FC = () => {
  //constants
  const isSm = useGetDimensions();

  return (
    <S.Container>
      <S.ContentHeaderText>What is 1K Draw ?</S.ContentHeaderText>
      <Typography variant={isSm ? "caption" : "body2"}>
        For every 1000 participants joining a draw will happen. This doesn’t mean 1000 user from the moment you join, rather the next 1000 mark
        nearing from the moment the user join.
      </Typography>
      <S.ExampleText variant={isSm ? "caption" : "body2"}>Example:</S.ExampleText>
      <Typography variant={isSm ? "caption" : "body2"}>
        1K draw happens in every 1000 users despite of their position, a draw will happen from 0 - 1000, 1000-2000, 2000-3000 ...... 99,000-1,00,000.
      </Typography>
      <Typography variant={isSm ? "caption" : "body2"}>
        Lets assume you position number is 5930 then your 1K draw will happen at 6000 users. If your position is 6000 then you will be instantly
        participating in the 1K draw.
      </Typography>
    </S.Container>
  );
};
export default OneKDraw;
