import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import LogoutIcon from "@mui/icons-material/Logout";
import { Tooltip } from "@mui/material";
import { useAccount, useDisconnect } from "wagmi";
import S from "./header.styled";
import USDTLogo from "../../../assets/images/usdtLogo.svg";
import { onrampInstance } from "../../..";
import { disconnectWallet } from "../../../store/slices/wallet.actions";
import { useGetDimensions } from "../../../utils/helpers";
import { useEnteredDraw, useHasParticipant, useIsAppLoading, useIsReady, useIsWhitelisted, useWinnerOverlay } from "../../../utils/hooks/selector";
import { useAppDispatch } from "../../../utils/hooks/state";
import HeaderSkeleton from "../../../utils/widgets/skeletons/header-skeleton";
import ConnectButton from "../../connect/ConnectButton";
import ReferPopover from "../../popovers/ReferPopover";
import MyUSDTBalance from "./MyUSDTBalance";
import UserDetailsCard from "./UserDetailsCard";

const HeaderMobileRightContainer = () => {
  //constructors
  const dispatch = useAppDispatch();

  //state values
  const [referPopover, setReferPopover] = useState<HTMLButtonElement | null>(null);
  const enteredDraw = useSelector(useEnteredDraw);
  const winnerOverlay = useSelector(useWinnerOverlay);
  const isAppLoading = useSelector(useIsAppLoading);
  const isReady = useSelector(useIsReady);
  const isWhitelisted = useSelector(useIsWhitelisted);
  const hasParticipant = useSelector(useHasParticipant);
  const { isConnected: isWalletConnected } = useAccount();
  const { disconnect } = useDisconnect();

  //constants
  const isSm = useGetDimensions();
  const hasSkeleton = isWalletConnected && (isAppLoading || !isReady || !hasParticipant);

  //On WinnerTimerModal or isAppLoading Open
  useEffect(() => {
    if (winnerOverlay || isAppLoading) setReferPopover(null);
  }, [winnerOverlay, isAppLoading]);

  return (
    <S.HeaderMobileRightContainer>
      {hasSkeleton ? (
        <HeaderSkeleton />
      ) : (
        <>
          {!enteredDraw && isWalletConnected && isWhitelisted && <S.WhiteListedUser>{"(Whitelisted User)"}</S.WhiteListedUser>}
          {/* {isWalletConnected && !isSm && <MyUSDTBalance />} */}
          {!enteredDraw && isWalletConnected && <MyUSDTBalance />}
          <S.BuyButton
            onClick={() => {
              onrampInstance.show();
            }}
          >
            Buy
            <img className="img" src={USDTLogo} alt="usdt" />
            USDT
          </S.BuyButton>
          {enteredDraw ? (
            <>
              <UserDetailsCard />
              <S.ReferContainer>
                {referPopover && (
                  <ReferPopover id={Boolean(referPopover) ? "refer-by" : undefined} isOpen={referPopover} onClose={() => setReferPopover(null)} />
                )}
              </S.ReferContainer>
            </>
          ) : // <>{!isSm && <ConnectButton />}</>
          null}
          {isWalletConnected && (
            <Tooltip title="Disconnect" arrow>
              <S.LogoutButton
                onClick={() => {
                  dispatch(disconnectWallet());
                  disconnect();
                }}
              >
                <LogoutIcon />
              </S.LogoutButton>
            </Tooltip>
          )}
        </>
      )}
    </S.HeaderMobileRightContainer>
  );
};

export default HeaderMobileRightContainer;
