import { useEffect } from "react";
import store from "../../store";
import {
  setAppStatus,
  setIsListeningEventstream,
  setIsSomethingWrong,
  setPoolInfo,
  updateCurrent10KDraw,
  updateTicketsSold,
} from "../../store/slices/user";
import { BACKEND_URL } from "../constants";
import { getCurrentTenKdraw } from "./../helpers/index";
import { useAppDispatch } from "./state";

const CURRENT_CHAIN_ID: number = Number(process.env.REACT_APP_CURRENT_CHAINID);

const useEventListener = () => {
  //constructor
  const dispatch = useAppDispatch();

  //constants
  let retryCount = 1;
  let source: EventSource;

  useEffect(() => {
    let eventStreamTimeout = setTimeout(() => callEventstream(), 1500);
    return () => {
      clearTimeout(eventStreamTimeout);
      if (source) source.close();
    };
  }, []);

  const callEventstream = () => {
    source = new EventSource(`${BACKEND_URL[CURRENT_CHAIN_ID]}/events`);

    source.addEventListener("open", () => {
      // console.log("SSE opened!");
    });

    source.addEventListener("message", (e) => {
      const data = JSON.parse(e.data);
      if (data.data) {
        const { appStatus, ticketNumber, poolInfo } = data.data;
        const draw10kNumber = store.getState().draw.draw10kNumber;
        retryCount = 1;
        dispatch(setIsSomethingWrong(false));
        dispatch(setIsListeningEventstream(false));
        dispatch(updateTicketsSold(+ticketNumber));
        dispatch(setAppStatus(appStatus));
        dispatch(updateCurrent10KDraw(`${getCurrentTenKdraw(+ticketNumber, appStatus, draw10kNumber)}`));
        const parsedPoolInfo = JSON.parse(poolInfo);
        dispatch(setPoolInfo(parsedPoolInfo));
        const cachedTicketNumber = localStorage.getItem("ticketNumber");
        cachedTicketNumber !== ticketNumber && localStorage.setItem("ticketNumber", ticketNumber);
      }
    });

    source.addEventListener("error", (e) => {
      console.error("Error: ", e);
      dispatch(setIsListeningEventstream(true));
      if (retryCount < 6) {
        retryCount += 1;
        setTimeout(() => callEventstream(), 1500);
      } else {
        dispatch(setIsListeningEventstream(false));
        retryCount = 1;
        dispatch(setIsSomethingWrong(true));
      }
    });
  };
};

export default useEventListener;
