import { FC } from "react";
import { useSelector } from "react-redux";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Box } from "@mui/material";
import S from "./header.styled";
import YEARN_LOGO from "../../../assets/images/yearn-coin.svg";
import { getRoundAmount } from "../../../utils/helpers";
import { useClaimableToken, usePredictGameToken } from "../../../utils/hooks/selector";

type MyEarnedTokensProps = {
  onClick: (event: React.MouseEvent<HTMLDivElement>) => void;
};

const MyEarnedToken: FC<MyEarnedTokensProps> = ({ onClick }) => {
  //state values
  const claimableToken = useSelector(useClaimableToken);
  const predictGameToken = useSelector(usePredictGameToken);

  //constants
  const totalTokens = getRoundAmount(
    `${BigInt(claimableToken.claimed) + BigInt(claimableToken.unclaimed) + BigInt(predictGameToken.claimed) + BigInt(predictGameToken.unclaimed)}`,
  );

  return (
    <S.MyEarnedTokensWrapper data-tut="tour-my-tokens">
      <S.MyEarnedTokensLogo src={YEARN_LOGO} alt="yearn-logo" />
      <S.MyEarnedTokenAmount variant="subtitle1">{totalTokens}</S.MyEarnedTokenAmount>
      <Box onClick={(e) => onClick(e)} display={"flex"} alignItems={"center"} justifyContent={"center"}>
        <KeyboardArrowDownIcon />
      </Box>
    </S.MyEarnedTokensWrapper>
  );
};

export default MyEarnedToken;
