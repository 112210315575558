import { Dispatch, FC, SetStateAction, ChangeEvent } from "react";
import { useSelector } from "react-redux";
import { useAccount } from "wagmi";
import S from "./predict-draw-winner-header.styled";
import SEARCH_ICON from "../../../../assets/images/search.svg";
import { HUNDRED_K_DRAW, ONE_K_DRAW } from "../../../../utils/constants";
import { useMaxDraws } from "../../../../utils/hooks/selector";
import { PredictionHeaderType } from "../../../../utils/types/custom-modal";
import SearchBar from "../../../../utils/widgets/search-bar";

type activePredictionDrawType = typeof HUNDRED_K_DRAW | typeof ONE_K_DRAW;

type DrawWinnersHeaderType = {
  searchValue: string;
  handleSearch: (e: string) => void;
  activePredictionType: PredictionHeaderType;
  handleOnActivePredictionType: (value: PredictionHeaderType) => void;
  activePredictionDraw: activePredictionDrawType;
  handleOnActivePredictionDraw: (value: activePredictionDrawType) => void;
  draw: number | null;
  setDraw: Dispatch<SetStateAction<number | null>>;
  showSearch: boolean;
  isHundredKCompleted: boolean;
};

const PredictDrawWinnersHeader: FC<DrawWinnersHeaderType> = (props) => {
  //props
  const {
    handleSearch,
    searchValue,
    activePredictionType,
    handleOnActivePredictionType,
    activePredictionDraw,
    handleOnActivePredictionDraw,
    draw,
    setDraw,
    showSearch,
    isHundredKCompleted,
  } = props;

  //state values
  const maxDraws = useSelector(useMaxDraws);

  //constants
  const { isConnected } = useAccount();
  const canShowSearch = activePredictionType === PredictionHeaderType.allPredictions && showSearch;

  let debounceTimer: undefined | NodeJS.Timeout;

  //functions
  const debounce = (cb: () => void) => {
    if (debounceTimer) clearTimeout(debounceTimer);
    debounceTimer = setTimeout(cb, 500); // .5s is the debounce time.
  };

  const handleDrawCount = (e: ChangeEvent<HTMLInputElement>) => {
    let value = parseInt(e.target.value);
    setDraw(isNaN(value) ? null : value);
  };

  return (
    <S.HeaderContainer>
      <S.HeaderContainerLeft>
        <S.HeaderText>WINNERS OF</S.HeaderText>
        {isHundredKCompleted ? (
          <S.HeaderToggleContainer direction={"row"}>
            <S.HeaderGradientText gradient={HUNDRED_K_DRAW} onClick={() => handleOnActivePredictionDraw(HUNDRED_K_DRAW)}>
              {/* 100K DRAW PREDICTION */}
              LUCKY ROUND 3 PREDICTION
              <S.HeaderTextUnderLine isActive={activePredictionDraw === HUNDRED_K_DRAW} gradient={HUNDRED_K_DRAW} />
            </S.HeaderGradientText>
            <S.CustomPrimaryDivider orientation="vertical" flexItem />
            <S.HeaderGradientText gradient={ONE_K_DRAW} onClick={() => handleOnActivePredictionDraw(ONE_K_DRAW)}>
              {/* 1K DRAW PREDICTION */}
              LUCKY ROUND 1 PREDICTION
              <S.HeaderTextUnderLine isActive={activePredictionDraw === ONE_K_DRAW} gradient={ONE_K_DRAW} />
            </S.HeaderGradientText>
          </S.HeaderToggleContainer>
        ) : (
          <S.HeaderGradientTextOneK>
            {/* 1K DRAW PREDICTION */}
            LUCKY ROUND 1 PREDICTION
            <S.HeaderTextUnderLine isActive={true} gradient={ONE_K_DRAW} />
          </S.HeaderGradientTextOneK>
        )}
        <S.HeaderToggleContainer direction={"row"}>
          <S.HeaderToggleText
            isActive={activePredictionType === PredictionHeaderType.allPredictions}
            onClick={() => handleOnActivePredictionType(PredictionHeaderType.allPredictions)}
          >
            {PredictionHeaderType.allPredictions}
          </S.HeaderToggleText>
          <S.CustomDivider orientation="vertical" flexItem />
          <S.HeaderToggleText
            isActive={activePredictionType === PredictionHeaderType.myPredictions}
            disabled={!isConnected}
            onClick={() => (isConnected ? handleOnActivePredictionType(PredictionHeaderType.myPredictions) : null)}
          >
            {PredictionHeaderType.myPredictions}
          </S.HeaderToggleText>
        </S.HeaderToggleContainer>
      </S.HeaderContainerLeft>
      <S.HeaderContainerRight direction={"column"}>
        {canShowSearch && (
          <S.SearchContainer direction={"row"}>
            <S.SearchIcon src={SEARCH_ICON} alt="search-icon" />
            <SearchBar searchHandler={handleSearch} value={searchValue} customStyle={{ border: "none" }} />
          </S.SearchContainer>
        )}
        {activePredictionDraw === ONE_K_DRAW && activePredictionType === PredictionHeaderType.allPredictions && (
          <S.DrawContainer>
            <p>Search Draw</p>
            <S.DrawAmountContainer>
              <S.MathButton select={!draw || draw <= 1} onClick={() => setDraw(draw && draw > 1 ? draw - 1 : draw)}>
                -
              </S.MathButton>
              <S.DrawInput type="number" value={draw ?? ""} placeholder="Search Draw" min={1} max={maxDraws} onChange={(e) => handleDrawCount(e)} />
              <S.MathButton select={draw === null || draw >= maxDraws} onClick={() => setDraw(draw !== null && draw < maxDraws ? draw + 1 : draw)}>
                +
              </S.MathButton>
              {draw && draw > maxDraws ? <S.ErrorText>Draw number exceeded</S.ErrorText> : null}
              {draw !== null && draw < 1 ? <S.ErrorText>Draw number should be above 1</S.ErrorText> : null}
            </S.DrawAmountContainer>
          </S.DrawContainer>
        )}
      </S.HeaderContainerRight>
    </S.HeaderContainer>
  );
};

export default PredictDrawWinnersHeader;
