import { Promise } from "bluebird";
import { getCurrentChainId } from "../../../store/slices/wallet.actions";
import ABI from "../build/pools_abi.json";
import { PREDICTION_POOL_CONTRACT, web3 } from "./web3";

export namespace PredictionPoolContract {
  let deployed, Contract: any;

  export const setup = async (library: any) => {
    deployed = false;
    let currentChainId = await getCurrentChainId();
    if (library) {
      web3.setProvider(library);
    }
    const PoolAbi = ABI.abi as any; // ABI definitions
    Contract = new web3.eth.Contract(PoolAbi, PREDICTION_POOL_CONTRACT[currentChainId]);
    Promise.promisifyAll(Contract, { suffix: "Promise" });
    deployed = true;
    return deployed;
  };
  export const getPoolAddress = async (index: number) => await Contract.methods.pools(index).call();
}
