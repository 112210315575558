import { Box, Stack, styled, Typography } from "@mui/material";
import { GRAD_COLOR_1 } from "../../../../utils/constants";

namespace S {
  export const WinningFooterContainer = styled(Stack, { shouldForwardProp: (prop) => prop !== "isMobileView" })(
    ({ isMobileView }: { isMobileView: boolean }) =>
      ({ theme }) => ({
        padding: `${theme.spacing(6)}`,
        alignItems: "center",
        justifyContent: "space-between",
        gap: isMobileView ? theme.spacing(4) : "auto",
      }),
  );

  export const WinningAmountBtnContainer = styled(Box)(() => ({
    width: "40%",
    height: "2.5rem",
    borderRadius: "8px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "0.2rem",
    position: "relative",
    overflow: "hidden",
    cursor: "pointer",
  }));

  export const GradientBG = styled(Box)({
    width: "300px",
    height: "300px",
    background: "linear-gradient(92.85deg, #1967FC 62.87%, #19FCB8 95.76%)",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: "-1",
    animation: "rotateBg 3s ease-in-out infinite",
    "@keyframes rotateBg": {
      to: {
        transform: "translate(-50%, -50%) rotate(360deg)",
      },
    },
  });

  export const WinningProgressPercentageContainer = styled(Stack)(({ theme }) => ({
    gap: theme.spacing(4),
    alignItems: "center",
    justifyContent: "center",
  }));

  export const WinningAmountBtnElement = styled(Box)(({ theme }) => ({
    width: "100%",
    height: "100%",
    backgroundColor: theme.palette.custom.secondary.secondary_3,
    borderRadius: "8px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: theme.spacing(2),
  }));

  export const WinningAmount = styled(Typography)(({ theme }) => ({
    background: GRAD_COLOR_1,
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    fontSize: theme.spacing(4),
    fontWeight: 600,
  }));

  export const WinningMoneyBagImage = styled("img")(() => ({
    width: "25px",
    height: "auto",
  }));
}

export default S;
