import { FC } from "react";
import S from "./cards.styled";

type Props = {
  className?: string;
  children: React.ReactNode;
};

export const CustomCardWrapper: FC<Props> = ({ className, children }) => {
  return <S.CustomCardWrapper className={className}>{children}</S.CustomCardWrapper>;
};
