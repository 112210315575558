import { Promise } from "bluebird";
import { getCurrentChainId } from "../../../store/slices/wallet.actions";
import poolContractABI from "../build/pools_abi.json";
import { POOL_CONTRACT, web3 } from "./web3";

export namespace poolContract {
  let deployed, web3Contract: any;

  export const setup = async (library?: any) => {
    deployed = false;
    let currentChainId = await getCurrentChainId();
    // let provider = new Web3.providers.HttpProvider(process.env.REACT_APP_MATIC_RPC_URL || "");
    // web3 = new Web3(provider);
    if (library) {
      web3.setProvider(library);
    }
    const myContractAbi = poolContractABI.abi as any;
    web3Contract = new web3.eth.Contract(myContractAbi, POOL_CONTRACT[currentChainId]);
    Promise.promisifyAll(web3Contract, { suffix: "Promise" });
    deployed = true;
    return deployed;
  };

  export const getActivePool = async () => {
    let activePool = await web3Contract.methods.activePool().call();
    return activePool;
  };

  export const getFeeAccount = async () => {
    let feeAcc = await web3Contract.methods.feeAccount().call();
    return feeAcc;
  };

  export const getPastLogs = async () => {
    let latestBlockNumber = await web3.eth.getBlockNumber().then((res: any) => {
      return Number(res);
    });
    let pastLogs: any = await web3Contract.getPastEventsPromise("ActivePool", {
      fromBlock: latestBlockNumber - 2000000,
      toBlock: "latest",
    });
    return pastLogs;
  };

  export const subscribePoolCreated = async () => {
    web3Contract.events
      .PoolCreated()
      .on("connected", async (subscriptionId: string) => {
        // console.log(subscriptionId, "subscription");
      })
      .on("data", async (event: any) => {
        // console.log("event", event);
      })
      .on("error", (error: any) => {
        console.log("error", error);
      });
  };

  export const subscribeActivePool = async () => {
    web3Contract.events
      .ActivePool()
      .on("connected", async (subscriptionId: string) => {
        // console.log(subscriptionId, "subscription");
      })
      .on("data", async (event: any) => {
        // console.log("event", event);
      })
      .on("error", (error: any) => {
        console.log("error", error);
      });
  };
}
