import { Dispatch, FC, SetStateAction } from "react";
import S from "./mantra-footer.styled";
import Navigators from "../../../../utils/widgets/navigators";

type MantraFooterprops = {
  mantraPage: number;
  setMantraPage: Dispatch<SetStateAction<number>>;
};

const MantraFooter: FC<MantraFooterprops> = ({ mantraPage, setMantraPage }) => {
  return (
    <S.FooterContainer>
      <Navigators totalPages={6} currentPage={mantraPage} setCurrentPage={setMantraPage} />
    </S.FooterContainer>
  );
};

export default MantraFooter;
