import { CSSProperties, Dispatch, FC, SetStateAction } from "react";
import { Typography } from "@mui/material";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { S } from "./navigators.styled";
import { Button } from "../../../utils/widgets";
import { PRIMARY } from "../../../utils/constants";

type NavigatorsProps = {
  totalPages: number;
  currentPage: number;
  setCurrentPage: Dispatch<SetStateAction<number>>;
  customStyle?: CSSProperties;
};

const Navigators: FC<NavigatorsProps> = ({ totalPages, currentPage, setCurrentPage, customStyle }) => {
  //constants
  const maxDots = 7;

  //functions
  const dotCreator = () => {
    let dots = [];

    for (let i = 1; i <= totalPages; i++) {
      if (i <= maxDots || i === totalPages) dots.push(<S.Dot currentPage={currentPage === i} key={`dot_${i}_${totalPages + currentPage}`} />);
      const hasId = dots.filter((d) => d.props.id);
      if (i > maxDots && !hasId.length)
        dots.push(
          <Typography id="elipse-dots" key={`elipse_${i}_${totalPages + currentPage}`}>
            ...
          </Typography>,
        );
    }
    return dots;
  };

  return (
    <S.Container customStyle={customStyle}>
      <S.DotContainer>{dotCreator()}</S.DotContainer>
      <S.NavigatorsContainer>
        <Button
          buttonType={PRIMARY}
          onClick={() => setCurrentPage(currentPage === 1 ? currentPage : currentPage - 1)}
          disabled={currentPage === 1}
          customStyle={{
            minWidth: "1.5rem",
            maxWidth: "1.5rem",
            padding: "0",
            borderRadius: "50%",
          }}
        >
          <KeyboardArrowLeftIcon />
        </Button>
        <Button
          buttonType={PRIMARY}
          onClick={() => setCurrentPage(currentPage === totalPages ? currentPage : currentPage + 1)}
          disabled={currentPage === totalPages}
          customStyle={{
            minWidth: "1.5rem",
            maxWidth: "1.5rem",
            padding: "0",
            borderRadius: "50%",
          }}
        >
          <KeyboardArrowRightIcon />
        </Button>
      </S.NavigatorsContainer>
    </S.Container>
  );
};

export default Navigators;
