import { FC } from "react";
import { Skeleton, Stack } from "@mui/material";
import S from "./predict-winners-skeleton.styled";

const PredictWinnersSkeleton: FC = () => {
  return (
    <S.SkeletonContainer>
      <S.SkeletonHeader>
        <Skeleton variant="text" sx={{ fontSize: "2rem", width: "60%" }} />
        <Skeleton variant="text" sx={{ fontSize: "2rem", width: "15%" }} />
      </S.SkeletonHeader>

      <S.SkeletonBody>
        <S.SkeletonBodyLeft>
          <Stack flexDirection={"row"} alignItems={"center"} gap={1} width={"100%"}>
            <Skeleton variant="circular" width={50} height={50} />
            <Skeleton variant="text" sx={{ fontSize: "2rem", width: "40%" }} />
          </Stack>
          <Skeleton variant="text" sx={{ fontSize: "1rem", width: "100%" }} />
        </S.SkeletonBodyLeft>
        <S.SkeletonBodyRight>
          <Skeleton variant="text" sx={{ fontSize: "2rem", width: "55%" }} />
        </S.SkeletonBodyRight>
      </S.SkeletonBody>
    </S.SkeletonContainer>
  );
};

export default PredictWinnersSkeleton;
