import React from "react";
import ReactDOM from "react-dom/client";
import { OnrampWebSDK } from "@onramp.money/onramp-web-sdk";
import "./assets/css/index.css";
import "./assets/css/react-tour.css";
import App from "./components/App";
import reportWebVitals from "./reportWebVitals";

const CURRENT_CHAIN_ID = process.env.REACT_APP_CURRENT_CHAINID || 80001;
export const onrampInstance = new OnrampWebSDK({
  appId: CURRENT_CHAIN_ID == 80001 ? 2 : 659719, // replace this with the appID you got during onboarding process

  // coinCode: "usdt",
  // network: CURRENT_CHAIN_ID == 80001 ? "matic20-test" : "bep20", // replace this with the network you want to use
  flowType: 1, // 1 -> onramp || 2 -> offramp || 3 -> Merchant checkout
  // ... pass other configs here
});

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
