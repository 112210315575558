import { Carousel } from "react-responsive-carousel";
import { Stack, Box, Typography, styled } from "@mui/material";
import { TextGradient, TransparentTextGradient } from "../../../utils/constants";

namespace S {
  export const EarningCard = styled(Stack)(({ theme }) => ({
    minHeight: "10.8763rem",
    maxHeight: "10.8763rem",
  }));

  export const EarningCardHeader = styled(Box)(({ theme }) => ({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  }));

  export const EarningCardFooter = styled(Box)(({ theme }) => ({
    display: "flex",
    justifyContent: "end",
    alignItems: "center",
  }));

  export const EarningCardHeaderContent = styled(Box)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    textAlign: "start",
    h6: {
      color: theme.palette.custom.secondary.secondary_1,
      fontWeight: 600,
    },
  }));

  export const EarningDistributedText = styled(Typography)(() => ({
    fontWeight: 600,
  }));

  export const EarnedAmount = styled(Typography)(({ theme }) => ({
    background: "radial-gradient(72.13% 132.54% at 27.87% 38.89%, #E9B80C 0%, #EB00FF 100%)",
    webkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    backgroundClip: "text",
    textAlign: "center",
    fontWeight: 600,
    margin: "1rem 0",
    [theme.breakpoints.down("sm")]: {
      fontSize: "45px !important",
    },
  }));

  export const ProfitMantraHeaderText = styled(Typography)(({ theme }) => ({
    color: theme.palette.custom.secondary.secondary_1,
    fontWeight: 600,
  }));

  export const HeadingGradientText = styled(Typography, { shouldForwardProp: (prop) => prop !== "shineText" })(
    ({ shineText }: { shineText: string }) => ({
      fontSize: "1.0625rem",
      fontWeight: "500",
      background: TextGradient,
      webkitBackgroundClip: "text",
      WebkitTextFillColor: "transparent",
      backgroundClip: "text",
      backgroundSize: "200px",
      color: TextGradient,
      textShadow: `0 0px 0px ${TextGradient}`,
      position: "relative",
      ":after": {
        content: `"${shineText}"`,
        // minWidth: "200px",
        background: TransparentTextGradient,
        webkitBackgroundClip: "text",
        WebkitTextFillColor: "transparent",
        backgroundClip: "text",
        position: "absolute",
        top: 0,
        left: 0,
        transform: "translate(0,0)",
        zIndex: 3,
        animation: "pool-shine 20s linear infinite",
        "@keyframes pool-shine": {
          "0%": {
            backgroundPosition: "-300px",
          },
          "100%": {
            backgroundPosition: "300px",
          },
        },
      },
    }),
  );

  export const ProfitMantraContentWrapper = styled(Stack)(({ theme }) => ({
    paddingTop: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      paddingTop: theme.spacing(0),
      margin: "auto 0",
    },
  }));

  export const ProfitMantraContent = styled(Typography)(({ theme }) => ({
    color: theme.palette.custom.secondary.secondary_1,
    margin: `${theme.spacing(2)} 0`,
    [theme.breakpoints.down("sm")]: {
      margin: "0",
    },
  }));

  export const ReferalContentWrapper = styled(Box)(({ theme }) => ({
    alignItems: "center",
    gap: theme.spacing(3),
    display: "flex  ",
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      marginBottom: 0,
    },
  }));

  export const ProfileReferalEarn = styled(Typography)(() => ({
    background: "linear-gradient(268.66deg, #FFE600 5.41%, #24FF00 93.94%)",
    webkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    backgroundClip: "text",
    textAlign: "center",
    fontWeight: 600,
    cursor: "pointer",
  }));

  export const ProfileReferalEarnAmount = styled(ProfileReferalEarn)(({ theme }) => ({
    fontSize: "2.125rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.875rem",
    },
  }));

  export const CarouselWrapper = styled(Box)(({ theme }) => ({
    width: "100%",
    height: "auto",
    borderRadius: "0.5rem",
    background: theme.palette.custom.secondary.secondary_3,
    overflow: "hidden",
  }));

  export const CustomCarousel = styled(Carousel, { shouldForwardProp: (prop) => prop !== "isMarginActive" })(
    ({ isMarginActive = false }: { isMarginActive?: boolean }) => ({
      ".control-dots": {
        margin: isMarginActive ? "0.2rem 0" : "0 !important",
        display: "flex !important",
        justifyContent: "center !important",
        gap: "0.5rem !important",
        borderRadius: "0.5rem",
      },
    }),
  );

  export const Dot = styled("li", {
    shouldForwardProp: (prop) => prop !== "selected",
  })(({ selected }: { selected: boolean }) => ({ theme }) => ({
    width: selected ? "1.875rem" : "0.5rem",
    height: "0.5rem",
    borderRadius: selected ? "0.375rem" : "50%",
    transitionDuration: "0.2s",
    listStyle: "none",
    backgroundColor: selected ? theme.palette.custom.primary.main : theme.palette.custom.secondary.secondary_1,
    "&:hover": {
      cursor: "pointer",
    },
  }));

  export const DistributionNoContentContainer = styled(Box)(() => ({
    minHeight: "10.8763rem",
    maxHeight: "10.8763rem",
  }));

  export const DistributionBlurContainer = styled(Stack)({
    position: "absolute",
    top: 0,
    left: 0,
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    width: "100%",
    minHeight: "13.2rem",
    maxHeight: "13.2rem",
    zIndex: "1",
    borderRadius: "0.625rem",
    backgroundColor: "rgba(217, 217, 217, 0.01)",
    backdropFilter: "blur(14px)",
  });

  export const BlurTextContainer = styled(Stack)(({ theme }) => ({
    alignItems: "center",
    justifyContent: "center",
    width: "21.125rem",
    height: "3.1875rem",
    borderRadius: "0.5rem",
    border: `0.0625rem solid ${theme.palette.custom.secondary.secondary_1}`,
    background: "rgba(22,21,26,0.7)",
    [theme.breakpoints.down("sm")]: {
      height: "fit-content",
      maxWidth: "90%",
      padding: "0.5rem 0.5rem",
    },
  }));

  export const BlurText = styled(Typography)(({ theme }) => ({
    textAlign: "center",
    fontSize: "1.25rem",
    fontWeight: "600",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1rem",
    },
  }));
}

export default S;
