import { Dispatch, FC, SetStateAction } from "react";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import S from "./left-side-arrow-panel.styled";
import { PRIMARY } from "../../utils/constants";
import { Button } from "../../utils/widgets";

type LeftSideArrowPanelProps = {
  showLeftPanel: boolean;
  setShowLeftPanel: Dispatch<SetStateAction<boolean>>;
  setShowRightPanel: (val: boolean) => void;
};

const LeftSideArrowPanel: FC<LeftSideArrowPanelProps> = ({ showLeftPanel, setShowLeftPanel, setShowRightPanel }) => {
  return (
    <S.LeftSideArrowPanelWrapper>
      <Button
        buttonType={PRIMARY}
        onClick={() => {
          setShowLeftPanel(!showLeftPanel);
          setShowRightPanel(false);
        }}
        containerCustomStyle={{
          position: "sticky",
          top: "-39px",
        }}
        customStyle={{
          minHeight: "1.7rem",
          minWidth: "1.7rem",
          padding: "0",
          maxWidth: "1.7rem",
          borderRadius: "50%",
          marginTop: "1rem",
        }}
      >
        <ArrowForwardIcon />
      </Button>
    </S.LeftSideArrowPanelWrapper>
  );
};

export default LeftSideArrowPanel;
