import { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import { HUNDRED, hundredKDrawWinningAmount, HUNDRED_K_DRAW } from "../../utils/constants";
import { getWholeNum } from "../../utils/helpers";
import { useHundredKWinner, useIsAppLoading, useWinnerOverlay } from "../../utils/hooks/selector";
import AffiliaterCard from "../../utils/widgets/cards/AffiliateCard";
import { HundredKDrawWinnersModal } from "../modals/draw-winners";
import { WinnerCard, WinnerCardBody, WinnerCardHeader } from "./recent-winner-card";

const Recent100KWinner: FC = () => {
  //state values
  const hundredKWinner = useSelector(useHundredKWinner);
  const winnerOverlay = useSelector(useWinnerOverlay);
  const isAppLoading = useSelector(useIsAppLoading);

  const [drawWinnersModal, setDrawWinnersModal] = useState(false);

  //constants
  let drawNumber = 0;
  let period = "";
  let winnerId = "";
  let ticketId = "";

  if (hundredKWinner.ticketNumber) {
    winnerId = hundredKWinner.userId;
    ticketId = getWholeNum(hundredKWinner.ticketNumber);
    drawNumber = hundredKWinner.drawNumber;
    period = moment(+hundredKWinner.blockTimestamp).format("MMMM DD,YYYY");
  }

  //On WinnerTimerModal or isAppLoading Open
  useEffect(() => {
    if (winnerOverlay || isAppLoading) setDrawWinnersModal(false);
  }, [winnerOverlay, isAppLoading]);

  return (
    <WinnerCard>
      <WinnerCardHeader
        draw={HUNDRED}
        amount={hundredKDrawWinningAmount}
        disabled={!hundredKWinner.ticketNumber}
        onClick={() => setDrawWinnersModal(true)}
        date={period}
      />
      <WinnerCardBody>
        <AffiliaterCard ticketId={ticketId} userId={winnerId} drawNumber={drawNumber} draw={HUNDRED_K_DRAW} />
      </WinnerCardBody>
      {drawWinnersModal && <HundredKDrawWinnersModal open={true} handleClose={() => setDrawWinnersModal(false)} />}
    </WinnerCard>
  );
};

export default Recent100KWinner;
