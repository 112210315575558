import { CSSProperties, FC } from "react";
import { CSSObject } from "@emotion/react";
import S from "./inputs.styled";

type InputProps = {
  onChange?: (text: string) => void;
  readOnly?: boolean;
  value: string;
  placeholder?: string;
  customStyle?: CSSProperties | CSSObject;
  type?: string;
  onBlur?: () => void;
  getInputProps?: () => object;
};

const Input: FC<InputProps> = ({ onChange, readOnly, value, placeholder, customStyle, type, onBlur, getInputProps }) => {
  if (getInputProps) {
    return (
      <S.AutoCompleteInput
        readOnly={Boolean(readOnly)}
        value={value}
        onChange={(e) => {
          if (onChange) onChange(e.target.value);
        }}
        placeholder={placeholder ?? ""}
        type={type ?? "text"}
        sx={customStyle ?? {}}
        onBlur={onBlur}
        {...getInputProps()}
      />
    );
  }
  return (
    <S.CustomInput
      readOnly={Boolean(readOnly)}
      value={value}
      disableUnderline
      onChange={(e) => {
        if (onChange) onChange(e.target.value);
      }}
      placeholder={placeholder ?? ""}
      type={type ?? "text"}
      sx={customStyle ?? {}}
      onBlur={onBlur}
    />
  );
};

export default Input;
