import { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import CheckIcon from "@mui/icons-material/Check";
import { Box, InputAdornment, Tooltip } from "@mui/material";
import { useAccount, useWalletClient } from "wagmi";
import S from "./stake-yearn.styled";
import GiftBox from "../../../assets/images/gift-box.svg";
import { stakeContract } from "../../../services/ethereum/contract/stakeContract";
import { RootState } from "../../../store";
import {
  claimStakeAmount,
  fetchLastClaimedTime,
  fetchMyRewards,
  fetchMyStakes,
  fetchStakeClaimableAmount,
  stakeDeposit,
} from "../../../store/slices/stake.actions";
import { GRADIENT, ONE_K_DRAW, PRIMARY } from "../../../utils/constants";
import { toFixed, useGetDimensions } from "../../../utils/helpers";
import {
  useIsAppLoading,
  useIsClaiming,
  useIsReady,
  useIsStaking,
  useIsWithdrawing,
  useLastClaimedTime,
  useMyRewards,
  useMyStakes,
  useStakeClaimableAmount,
  useStakeTransactionHash,
  useUserDetails,
  useWithdrawTransactionHash,
} from "../../../utils/hooks/selector";
import { useAppDispatch } from "../../../utils/hooks/state";
import { Button, CustomCardWrapper, GradientButton } from "../../../utils/widgets";
import CircularLoader from "../../../utils/widgets/loader/CircularLoader";
import DotLoader from "../../../utils/widgets/loader/DotLoader";
import StakeYearnSkeleton from "../../../utils/widgets/skeletons/stake-yearn-skeleton";
import Toast from "../../../utils/widgets/toast";

const StakeYearn: FC = () => {
  // constructors
  const dispatch = useAppDispatch();

  // state values
  const userDetails = useSelector(useUserDetails);
  const isAppLoading = useSelector(useIsAppLoading);
  const isReady = useSelector(useIsReady);
  const isStakeFetching = useSelector(useMyStakes).fetching;
  const myStakes = useSelector(useMyStakes).payload;
  const isRewardFetching = useSelector(useMyRewards).fetching;
  const myRewards = useSelector(useMyRewards).payload;
  const isStaking = useSelector(useIsStaking);
  const isWithdrawing = useSelector(useIsWithdrawing);
  const stakeClaimableAmount = useSelector(useStakeClaimableAmount);
  const canClaim = useSelector(useLastClaimedTime).canClaim;
  const claimAfter = useSelector(useLastClaimedTime).claimAfter;
  const isStakeClaiming = useSelector(useIsClaiming);
  const yearnBalance = useSelector((state: RootState) => state.user.yearnBalance);
  const address = useSelector(useUserDetails).address;
  const balance = useSelector(useUserDetails).balance;
  const [deposit, setDeposit] = useState("");
  const [withdraw, setWithdraw] = useState("");
  const { isConnected: isWalletConnected } = useAccount();
  const { data: signer } = useWalletClient();

  //constants
  const isSm = useGetDimensions();
  const hasSkeleton = isAppLoading || !isReady || !userDetails.id || isRewardFetching || isStakeFetching;

  useEffect(() => {
    if (!isReady) return;
    let interval = setInterval(() => {
      dispatch(fetchMyStakes(true));
      dispatch(fetchMyRewards(true));
    }, 5000);
    return () => clearInterval(interval);
  }, [isReady]);

  useEffect(() => {
    if (!isReady || !address) return;
    let fetchInterval: ReturnType<typeof setInterval>;
    fetchInterval = setInterval(async () => {
      dispatch(fetchStakeClaimableAmount());
    }, 1000);
    if (!address || !isWalletConnected) clearInterval(fetchInterval);

    return () => clearInterval(fetchInterval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isReady, isWalletConnected, address]);

  // fetching last claimed status
  useEffect(() => {
    if (!isReady || !address || !isWalletConnected) return;
    dispatch(fetchLastClaimedTime());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isReady, address, isWalletConnected]);

  const stakeWithdraw = async () => {
    const cliffTime = await stakeContract.cliffTime();
    const stakingTime = await stakeContract.stakingTime(address);
    const limit = +cliffTime + +stakingTime;
    const currentTimeStamp = +new Date();
    const daysLeft = Math.floor((limit - Math.floor(currentTimeStamp / 1000)) / (60 * 60 * 24));
    if (currentTimeStamp < limit * 1000) return Toast({ message: `Try after ${daysLeft} ${daysLeft > 1 ? "days" : "day"}`, type: "error" });

    await stakeContract.withdraw(withdraw, address, () => {
      dispatch(fetchMyRewards(true));
      setWithdraw("");
    });
  };

  return (
    <>
      {hasSkeleton ? (
        <StakeYearnSkeleton />
      ) : (
        <CustomCardWrapper className="stake-card">
          <S.StakeYearnContainer>
            <S.StakeHeader>
              <S.HeaderLeft>
                <S.HeaderText>STAKE $YEARN</S.HeaderText>
                <S.HeaderSubText>APR 100%</S.HeaderSubText>
              </S.HeaderLeft>
              <S.HeaderRight>
                <Tooltip title={myRewards}>
                  <S.AccentRewardText data-tut="tour-my-rewards">
                    MY REWARDS :{" "}
                    <span> {isRewardFetching ? <DotLoader isNormal={true} /> : +myRewards ? Number(myRewards).toFixed(4) : myRewards}</span>
                  </S.AccentRewardText>
                </Tooltip>
                <Tooltip title={myStakes}>
                  <S.AccentStakeText data-tut="tour-my-stakes">
                    MY STAKES : <span>{isStakeFetching ? <DotLoader isNormal={true} /> : +myStakes ? Number(myStakes).toFixed(4) : myStakes}</span>
                  </S.AccentStakeText>
                </Tooltip>
              </S.HeaderRight>
            </S.StakeHeader>
            <S.Content>
              <S.ContentLeft>
                <S.InputContainer>
                  <S.StakeLabel>
                    Stake $YEARN (90 days)
                    <S.IBtnContainer>
                      <p>i</p>
                    </S.IBtnContainer>
                  </S.StakeLabel>
                  <S.CustomInput
                    data-tut="tour-yearn-stake"
                    type="number"
                    placeholder="Enter Value"
                    disableUnderline
                    value={deposit}
                    onChange={(e) => setDeposit(e.target.value)}
                    disabled={isStaking}
                    endAdornment={
                      <Tooltip title={Number(deposit) > Number(yearnBalance) ? "Insufficient balance to stake" : ""}>
                        <InputAdornment position="end" sx={{ gap: "0.5rem" }}>
                          <Button
                            buttonType={PRIMARY}
                            customStyle={{
                              fontSize: "0.95rem",
                              padding: "0",
                              background: "transparent",
                              color: "#1846dd",
                              fontWeight: "bold",
                              "&:hover": {
                                background: "transparent",
                              },
                            }}
                            disabled={isStaking || !isReady || !Number(yearnBalance)}
                            onClick={() => setDeposit(yearnBalance)}
                          >
                            Max
                          </Button>
                          <Button
                            buttonType={PRIMARY}
                            onClick={() => {
                              dispatch(stakeDeposit(signer, deposit, () => setDeposit("")));
                            }}
                            customStyle={{
                              height: "2.75rem",
                              minWidth: "2.9375rem",
                              borderRadius: "0 0.5rem 0.5rem 0",
                            }}
                            disabled={
                              !deposit.trim().length || isStaking || !isReady || Number(deposit) > Number(yearnBalance) || !Number(yearnBalance)
                            }
                            // loading={isStaking}
                          >
                            {isStaking ? <CircularLoader size={25} /> : <CheckIcon />}
                          </Button>
                        </InputAdornment>
                      </Tooltip>
                    }
                  />
                  <S.TicketErrorText isVisible={false}>
                    {Number(deposit) <= Number(yearnBalance) ? (
                      <>
                        {`Available balance : `}
                        <span className="balance-text">{toFixed(Number(yearnBalance), 4)} $ YEARN</span>
                      </>
                    ) : (
                      <>
                        <span className="insufficient-balance-text">Insufficient Balance</span>
                      </>
                    )}
                  </S.TicketErrorText>
                </S.InputContainer>
                <S.InputContainer>
                  <S.UnStakeLabel>
                    Unstake $YEARN (after 30 days)
                    <S.IBtnContainer>
                      <p>i</p>
                    </S.IBtnContainer>
                  </S.UnStakeLabel>
                  <Tooltip title={!+myStakes ? "You don't have any stakes to unstake!" : ""}>
                    <S.CustomInput
                      data-tut="tour-yearn-unstake"
                      type="number"
                      placeholder="Enter Value"
                      disableUnderline
                      value={withdraw}
                      onChange={(e) => setWithdraw(e.target.value)}
                      disabled={isWithdrawing || !+myStakes || isStakeFetching}
                      endAdornment={
                        <Tooltip title={+withdraw > +myStakes ? "You don't have enough stakes to unstake!" : ""}>
                          <InputAdornment position="end">
                            <Button
                              buttonType={PRIMARY}
                              onClick={stakeWithdraw}
                              customStyle={{
                                height: "2.75rem",
                                minWidth: "2.9375rem",
                                borderRadius: "0 0.5rem 0.5rem 0",
                              }}
                              disabled={
                                !withdraw.trim().length || isWithdrawing || !isReady || !+myStakes || isStakeFetching || +withdraw > +myStakes
                              }
                            >
                              {isWithdrawing ? <CircularLoader size={25} /> : <CheckIcon />}
                            </Button>
                          </InputAdornment>
                        </Tooltip>
                      }
                    />
                  </Tooltip>
                </S.InputContainer>
              </S.ContentLeft>
              <S.ContentRight>
                <S.GiftBoxImage src={GiftBox} alt="gift box" />
                <S.GiftText>You have earned</S.GiftText>
                <Tooltip title={stakeClaimableAmount} placement="top">
                  <S.GradientGiftText gradient={ONE_K_DRAW} data-tut="tour-earned-yearn">
                    {isRewardFetching ? (
                      <DotLoader isNormal={true} />
                    ) : +stakeClaimableAmount ? (
                      Number(stakeClaimableAmount).toFixed(4)
                    ) : (
                      stakeClaimableAmount
                    )}{" "}
                    $YEARN{" "}
                  </S.GradientGiftText>
                </Tooltip>
                <Box data-tut="tour-yearn-reward-claim">
                  <GradientButton
                    buttonType={GRADIENT}
                    onClick={() => dispatch(claimStakeAmount(signer))}
                    customStyle={{
                      borderRadius: "8px",
                      height: "32px",
                      padding: "4px 22px",
                      fontSize: "14px",
                      fontWeight: 500,
                      minWidth: "155px",
                    }}
                    containerCustomStyle={{
                      borderRadius: "8px",
                      height: "32px",
                    }}
                    title={
                      !canClaim
                        ? `Please wait for ${claimAfter} ${claimAfter > 1 ? "days" : "day"} before claiming your rewards.`
                        : !+stakeClaimableAmount && !isStakeClaiming
                        ? "You don't have rewards to claim!"
                        : ""
                    }
                    disabled={!stakeClaimableAmount.trim().length || !+stakeClaimableAmount || isStakeClaiming || !canClaim}
                    loading={isStakeClaiming}
                  >
                    {/* {isStakeClaiming ? <CircularLoader size={20} /> : "Claim Rewards"} */}
                    Claim Rewards
                  </GradientButton>
                </Box>
              </S.ContentRight>
            </S.Content>
          </S.StakeYearnContainer>
        </CustomCardWrapper>
      )}
    </>
  );
};

export default StakeYearn;
