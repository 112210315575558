import { Box, Stack, styled, Typography } from "@mui/material";
import { GRAD_COLOR_3 } from "../../../../utils/constants";

namespace S {
  export const WinnerListModalContainer = styled(Stack)(({ theme }) => ({
    boxSizing: "border-box",
    maxWidth: "28.688rem",
    minWidth: "28.688rem",
    minHeight: "100%",
    maxHeight: "100%",
    padding: theme.spacing(4),
    backgroundColor: "rgba(0,0,0,0.6)",
    border: `3px solid ${theme.palette.custom.secondary.secondary_5}`,
    borderRadius: "2rem",
    overflow: "hidden",
    [theme.breakpoints.down("md")]: {
      minHeight: "500px",
      padding: theme.spacing(2),
      maxWidth: "100%",
      minWidth: "95vw",
    },
  }));

  export const WinnerListTitle = styled(Typography)(({ theme }) => ({
    fontWeight: 600,
    [theme.breakpoints.down("md")]: {
      textAlign: "center",
      marginRight: "2.5rem",
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: "2.5rem",
    },
  }));

  export const WinnerListGradientTitle = styled(Typography)(({ theme }) => ({
    background: GRAD_COLOR_3,
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    fontWeight: 600,
    [theme.breakpoints.down("md")]: {
      textAlign: "center",
    },
  }));

  export const WinnerListTicketCardContainer = styled(Box)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "start",
    gap: theme.spacing(3.5),
    padding: `${theme.spacing(2)} 3.2rem`,
    minHeight: "24rem",
    overflowY: "auto",
    overflowX: "hidden",
    [theme.breakpoints.down("md")]: {
      minHeight: "22rem",
    },
    [theme.breakpoints.down("sm")]: {
      minHeight: "25rem",
      padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
    },
    ".ticket": {
      minHeight: "150px",
      "& > .MuiStack-root:nth-of-type(1)": {
        "& > .MuiStack-root:nth-of-type(2)": {
          img: {
            top: -15,
            [theme.breakpoints.down("md")]: {
              top: -23,
            },
            [theme.breakpoints.down("sm")]: {
              top: -15,
            },
          },
        },
      },
    },
  }));
}

export default S;
