import { FC } from "react";
import { useSelector } from "react-redux";
import { Typography } from "@mui/material";
import S from "./refer-and-earn.styled";
import { useGetDimensions } from "../../../../../utils/helpers";
import { usePaidUserReferralAmountByLevel } from "../../../../../utils/hooks/selector";

const ReferAndEarn: FC = () => {
  //state values
  const paidUserReferralAmountByLevel = useSelector(usePaidUserReferralAmountByLevel);

  //constants
  const isSm = useGetDimensions();

  return (
    <S.Container>
      <S.ContentHeaderText>Refer and Earn</S.ContentHeaderText>
      <Typography variant={isSm ? "caption" : "body2"}>
        Earn money for every referral you make, earn further from every referral they make and the cycle goes on. The more you refer, the more they
        refer, making you <S.HighlightedText> gain profit</S.HighlightedText>.
      </Typography>
      <S.ExampleText variant={isSm ? "caption" : "body2"}>Earning has never been this easy before!</S.ExampleText>
      <S.ContentHeaderText>
        FOR EVERY REFERRAL YOU EARN &nbsp;&nbsp;&nbsp;
        <S.HighlightedText2>${paidUserReferralAmountByLevel[1].toFixed(0)}</S.HighlightedText2>
      </S.ContentHeaderText>
      <Typography variant={isSm ? "caption" : "body2"}>
        You gain profit till 5 levels. Each level can have as many supporters as possible, there is no limit to how many supporters each level can
        have. Hence even if you share to two people, there is a chance that those two can share among ten peoples helping you in gain profit. If you
        share more then they will share more increasing your total number of supporters. With each level your referral rewards decreases little, but
        this happens only with levels and not supporters. No reward is given if you go beyond 5 levels.
      </Typography>
    </S.Container>
  );
};
export default ReferAndEarn;
