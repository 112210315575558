import { FC } from "react";
import { useSelector } from "react-redux";
import S from "./prediction-success-card.styled";
import OpenArrow from "../../../assets/images/greetings-assets/openWindow.svg";
import CHECKBOX from "../../../assets/images/square-checkbox.svg";
import { EXPLORER_URL } from "../../../utils/constants";
import { getWholeNum } from "../../../utils/helpers";
import { useIsPredictionSucceed } from "../../../utils/hooks/selector";

//TODO: Need to fill the amounts from the contract.
const switchContentBasedOnUser: any = {
  free: {
    ONE_K_DRAW: {
      title: "Thanks For Free Predicting!",
      defaultToken: "1",
      winningToken: "100",
      nearestWinningToken: "",
      winningAmount: "25000",
      getPoints: function () {
        const points = [
          `You'll receive <span id="special-text">${this.defaultToken} YEARN</span> token soon.`,
          `If your prediction wins, you'll get <span id="special-text">${this.winningToken} YEARN</span> prize pool.`,
          `Keep making predictions for each draw and earn rewards.`,
        ];
        return points;
      },
      getConsider: function () {
        return `*Upgrade to <span id="special-text">Premium</span> and earn more tokens and up to <span id="special-text">${this.winningAmount} USDT </span>prize pool`;
      },
    },
    HUNDRED_K_DRAW: {
      title: "Thanks For Free Predicting!",
      defaultToken: "1",
      winningToken: "5000",
      nearestWinningToken: "",
      winningAmount: "25000",
      getPoints: function () {
        const points = [
          `You'll receive <span id="special-text">${this.defaultToken} YEARN</span> token soon.`,
          `If your prediction wins, you'll get <span id="special-text">${this.winningToken} YEARN</span> prize pool.`,
          `Keep making predictions for each draw and earn rewards.`,
        ];
        return points;
      },
      getConsider: function () {
        return `*Upgrade to <span id="special-text">Premium</span> and earn more tokens and up to <span id="special-text">${this.winningAmount} USDT </span> prize pool`;
      },
    },
  },
  premium: {
    ONE_K_DRAW: {
      title: "Thanks For Premium Predicting!",
      defaultToken: "5",
      winningToken: "1000",
      nearestWinningToken: "500",
      winningAmount: "25000",
      getPoints: function () {
        const points = [
          `You'll receive <span id="special-text">${this.defaultToken} YEARN</span> token soon.`,
          `If your prediction wins, you'll get <span id="special-text">${this.winningToken} YEARN</span> tokens.`,
          `If your prediction wins in the nearest match, you'll get <span id="special-text">${this.nearestWinningToken} YEARN</span> prize pool.`,
          `Keep making predictions for each draw and earn rewards.`,
        ];
        return points;
      },
      getConsider: function () {
        return `*Upgrade to <span id="special-text">Jackpot Prediction</span> to win up to <span id="special-text">${this.winningAmount} USDT prize pool.</span>.`;
      },
    },
    HUNDRED_K_DRAW: {
      title: "Thanks For Premium Predicting!",
      defaultToken: "10",
      winningToken: "15000",
      nearestWinningToken: "7500",
      winningAmount: "25000",
      getPoints: function () {
        const points = [
          `You'll receive <span id="special-text">${this.defaultToken} YEARN</span> token soon.`,
          `If your prediction wins, you will receive <span id="special-text">${this.winningAmount} USDT</span> and ${this.winningToken} <span id="special-text">YEARN</span> tokens.`,
          `If your prediction wins in the nearest match, you'll get <span id="special-text">${this.nearestWinningToken} YEARN</span> tokens.`,
          `Keep making predictions for each draw and earn rewards.`,
        ];
        return points;
      },
      getConsider: function () {
        return `*Consider referring your friends to earn predictions.`;
      },
    },
  },
};

const PredictionSuccessCard: FC = () => {
  //state values
  const type = useSelector(useIsPredictionSucceed).type;
  const draw = useSelector(useIsPredictionSucceed).draw;
  const prediction = useSelector(useIsPredictionSucceed).prediction;
  const txnHash = useSelector(useIsPredictionSucceed).txnHash;

  //constants
  const CURRENT_CHAIN_ID: number = Number(process.env.REACT_APP_CURRENT_CHAINID);
  const explorerUrl = EXPLORER_URL[CURRENT_CHAIN_ID];

  //functions
  const handleClick = () => {
    window.open(`${explorerUrl}/tx/${txnHash}`);
  };

  return (
    <S.PredictionMainWrapper>
      <S.PredictionCardContainer>
        <S.PredictionHeader>
          <S.PredictionCardTitle>{switchContentBasedOnUser[type][draw].title}</S.PredictionCardTitle>
          <S.ThanksCardSubTitle onClick={handleClick}>
            View transaction on block explorer
            <S.YearnGamesIcon src={OpenArrow} alt="redirect-icon" onClick={handleClick} />
          </S.ThanksCardSubTitle>
        </S.PredictionHeader>
        <S.GradientTextContainer>
          <S.DefaultText gradient={draw}>Predicted Lucky Pass - YT{getWholeNum(prediction)}</S.DefaultText>
          <S.ShiningText gradient={draw}>Predicted Lucky Pass - YT{getWholeNum(prediction)}</S.ShiningText>
        </S.GradientTextContainer>
        <S.ThanksCardsPointWrapper>
          <S.ThanksCardTitle alignSelf={"flex-start"}>What happens next?</S.ThanksCardTitle>
          <S.ThanksCardScrollContainer>
            {switchContentBasedOnUser[type][draw].getPoints().map((point: string, i: string) => {
              return (
                <S.ThanksCardPointsContainer key={i}>
                  <S.CheckBoxImage src={CHECKBOX} alt="square-checkbox" />
                  <S.ThanksCardContent dangerouslySetInnerHTML={{ __html: point }}></S.ThanksCardContent>
                </S.ThanksCardPointsContainer>
              );
            })}
          </S.ThanksCardScrollContainer>
        </S.ThanksCardsPointWrapper>
      </S.PredictionCardContainer>
      <S.UpgradeTextContainer>
        <S.ThanksCardContent
          sx={{ fontSize: "0.9rem", textAlign: "center" }}
          dangerouslySetInnerHTML={{ __html: switchContentBasedOnUser[type][draw].getConsider() }}
        ></S.ThanksCardContent>
      </S.UpgradeTextContainer>
    </S.PredictionMainWrapper>
  );
};

export default PredictionSuccessCard;
