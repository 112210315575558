import { FC } from "react";
import { useNavigate } from "react-router-dom";
import S from "./something-wrong.styled";

const SomethingWrong: FC = () => {
  //constructor
  const navigate = useNavigate();

  return (
    <S.SomethingWrongWrapper>
      <S.WarningText>
        Something went wrong!
        <br />
        It's not on you, It's on us ..&nbsp;
        <span onClick={() => navigate(0)}>Click here&nbsp;</span>
        to retry.
      </S.WarningText>
    </S.SomethingWrongWrapper>
  );
};

export default SomethingWrong;
