import { Box } from "@mui/material";
import { Stack, styled, Typography } from "@mui/material";

namespace S {
  export const ThankyouForParticipatingContainer = styled(Stack)(({ theme }) => ({
    minHeight: "25.25rem",
    maxHeight: "25.25rem",
    justifyContent: "space-evenly",
    alignItems: "center",
    padding: ` ${theme.spacing(6)}`,
    background: `linear-gradient(${theme.palette.custom.bg.app}, ${theme.palette.custom.bg.app}) padding-box`,
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(4),
    },
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
    },
  }));

  export const HeaderContainer = styled(Stack)(({ theme }) => ({
    gap: theme.spacing(2),
    textAlign: "center",
  }));

  export const HeaderText = styled(Typography)(({ theme }) => ({
    fontSize: "1.25rem",
    fontWeight: "600",
    [theme.breakpoints.down("md")]: {
      fontSize: "0.875rem",
    },
  }));

  export const ThankYouContainer = styled(Stack)(({ theme }) => ({
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    [theme.breakpoints.down("md")]: {
      gap: theme.spacing(4),
    },
  }));

  export const GradientHeaderText = styled(Typography)(({ theme }) => ({
    background: "linear-gradient(337.35deg, #EB00FF 7.34%, #E9B80C 91.96%)",
    webkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    backgroundClip: "text",
    fontSize: "1.625rem",
    fontWeight: "600",
    lineHeight: "3.125rem",
    [theme.breakpoints.down("md")]: {
      fontSize: "1.4rem",
      lineHeight: "2.25rem",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "1rem",
    },
  }));

  export const BonusBox = styled(Box)(({ theme }) => ({
    width: "33%",
    height: "auto",
    [theme.breakpoints.down("md")]: {
      width: 150,
    },
    [theme.breakpoints.down("sm")]: {
      width: 100,
    },
  }));

  export const BonusImg = styled("img")(() => ({
    width: "100%",
    height: "100%",
  }));

  export const BodyContainer = styled(Stack)(({ theme }) => ({
    alignItems: "center",
    justifyContent: "center",
    gap: theme.spacing(5),
    button: {
      padding: `0.3rem ${theme.spacing(4)}`,
      [theme.breakpoints.down("sm")]: {
        fontSize: "0.75rem",
        padding: `0.3rem ${theme.spacing(2)}`,
      },
    },
  }));

  export const BodyTitle = styled(Typography)(({ theme }) => ({
    fontWeight: 500,
    fontSize: "1rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.875rem",
      whiteSpace: "nowrap",
    },
    span: {
      color: theme.palette.custom.accent.accent_2,
    },
  }));

  export const ApproveTitle = styled(Typography, { shouldForwardProp: (prop) => prop !== "isApproved" })(
    ({ isApproved }: { isApproved: boolean }) =>
      ({ theme }) => ({
        fontWeight: 500,
        fontSize: "1.375rem",
        color: isApproved ? theme.palette.custom.accent.accent_1 : theme.palette.custom.accent.accent_2,
        lineHeight: 1,
        [theme.breakpoints.down("sm")]: {
          fontSize: "1rem",
        },
      }),
  );

  export const ClaimDetailsContainer = styled(Stack)(({ theme }) => ({
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    gap: theme.spacing(3),
  }));
}

export default S;
