import { styled, Box, CircularProgress, Typography } from "@mui/material";

namespace S {
  export const Layout = styled(Box)(({ theme }) => ({
    display: "grid",
    gridAutoRows: "auto 1fr",
    backgroundColor: theme.palette.custom.bg.app,
    height: "100vh",
    width: "100%",
    position: "relative",
    overflow: "auto",
  }));

  export const AppLoader = styled(CircularProgress)(({ theme }) => ({
    position: "absolute",
    top: "50%",
    left: "50%",
    svg: {
      color: theme.palette.custom.primary.main,
    },
  }));

  export const TourTextContainer = styled(Box)(({ theme }) => ({
    minWidth: "250px",
    paddingRight: "1rem",
  }));

  export const TourHeader = styled(Typography)(({ theme }) => ({
    lineHeight: 1,
    fontSize: "22px",
    fontWeight: 600,
    marginBottom: "20px",
  }));

  export const TourSubText = styled(Typography)(({ theme }) => ({
    lineHeight: 1.45,
    fontSize: "16px",
    fontWeight: 500,
  }));
}

export default S;
