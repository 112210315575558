import { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useAccount } from "wagmi";
import { getMyPredictions, get1KPredictionWinners, getIsAdminApproved } from "../../../store/slices/user.actions";
import { HUNDRED_K_DRAW, ONE_K_DRAW } from "../../../utils/constants";
import { getChecksumAddress } from "../../../utils/helpers";
import {
  useAppStatus,
  useCurrentDraw,
  useDraw100kNumber,
  useDraw1kNumber,
  useIsRequestingMyPredictions,
  useMaxDraws,
  useMyPredictions,
  usePrediction100KWinners,
  usePredictionWinners,
  useTicketsSold,
} from "../../../utils/hooks/selector";
import { useAppDispatch } from "../../../utils/hooks/state";
import { AppStatus } from "../../../utils/types";
import { PredictionHeaderType } from "../../../utils/types/custom-modal";
import { UserSlicetypes } from "../../../utils/types/state";
import { CustomModal, ModalBody, ModalFooter, ModalHeader } from "../../../utils/widgets/custom-modal";
import PredictDrawWinnerTable from "./predict-draw-winner-body";
import PredictDrawWinnersHeader from "./predict-draw-winner-header";

type PredictDrawWinnerModalType = {
  open: boolean;
  onClose: () => void;
};

type activePredictionDrawType = typeof HUNDRED_K_DRAW | typeof ONE_K_DRAW;

const PredictDrawWinnerModal: FC<PredictDrawWinnerModalType> = ({ open, onClose }) => {
  //constructors
  const dispatch = useAppDispatch();

  //state values
  const myPredictions = useSelector(useMyPredictions);
  const isRequestingMyPredictions = useSelector(useIsRequestingMyPredictions);
  const predictionWinners = useSelector(usePredictionWinners);
  const prediction100KWinners = useSelector(usePrediction100KWinners);
  const ticketsSold = useSelector(useTicketsSold);
  const currentDraw = useSelector(useCurrentDraw);
  const appStatus = useSelector(useAppStatus);
  const draw1kNumber = useSelector(useDraw1kNumber);
  const draw100kNumber = useSelector(useDraw100kNumber);
  const maxDraws = useSelector(useMaxDraws);

  const { isConnected: isWalletConnected, address } = useAccount();

  //pre-constants
  const hundredKCompleted = Boolean(ticketsSold !== 0 && ticketsSold % draw100kNumber === 0);
  const defaultDraw = +currentDraw > 1 ? (appStatus === AppStatus.drawHundredKWinner ? +currentDraw : +currentDraw - 1) : null;
  const [activePredictionType, setActivePredictionType] = useState(PredictionHeaderType.allPredictions);
  const [activePredictionDraw, setActivePredictionDraw] = useState<activePredictionDrawType>(hundredKCompleted ? HUNDRED_K_DRAW : ONE_K_DRAW);
  const [draw, setDraw] = useState<number | null>(defaultDraw);
  const [searchValue, setSearchValue] = useState("");

  //constants
  const filteredAllPredictionWinners: UserSlicetypes.PredictionWinner[] =
    activePredictionDraw === ONE_K_DRAW
      ? draw && predictionWinners[`${draw}`]
        ? predictionWinners[`${draw}`].filter((w) => getChecksumAddress(w.address).includes(searchValue))
        : []
      : prediction100KWinners.filter((w) => getChecksumAddress(w.address).includes(searchValue));

  const allPredictionWinners = searchValue.trim().length
    ? filteredAllPredictionWinners
    : activePredictionDraw === ONE_K_DRAW
    ? draw && predictionWinners[`${draw}`]
      ? predictionWinners[`${draw}`]
      : []
    : prediction100KWinners;
  const filteredMyPredictionWinners: UserSlicetypes.MyPredictionWinner[] = myPredictions[activePredictionDraw].filter((w) =>
    getChecksumAddress(w.address).includes(searchValue),
  );
  const myPredictionWinners = searchValue.trim().length ? filteredMyPredictionWinners : myPredictions[activePredictionDraw];

  // Prediction winners
  // Fetching 1K prediction winners
  useEffect(() => {
    if (!draw || draw > maxDraws) return;
    dispatch(get1KPredictionWinners(`${draw}`));
  }, [draw, draw1kNumber, draw100kNumber]);

  // Fetching my prediction winners
  useEffect(() => {
    if (
      !isWalletConnected ||
      !address ||
      isRequestingMyPredictions ||
      (myPredictions.ONE_K_DRAW.length && !hundredKCompleted) ||
      myPredictions.HUNDRED_K_DRAW.length
    )
      return;
    dispatch(getIsAdminApproved());
    dispatch(getMyPredictions(address));
  }, [isWalletConnected, address]);

  //functions
  const handleSearch = (e: string) => {
    setSearchValue(e);
  };

  const handleOnActivePredictionType = (item: PredictionHeaderType) => {
    setActivePredictionType(item);
    setSearchValue("");
  };

  const handleOnActivePredictionDraw = (item: activePredictionDrawType) => {
    setActivePredictionDraw(item);
    setSearchValue("");
  };

  return (
    <CustomModal open={open} onClose={onClose}>
      <ModalHeader handleClose={() => onClose()}>
        <PredictDrawWinnersHeader
          handleSearch={handleSearch}
          handleOnActivePredictionType={handleOnActivePredictionType}
          activePredictionType={activePredictionType}
          searchValue={searchValue}
          showSearch={Boolean(allPredictionWinners.length || searchValue.trim().length)}
          activePredictionDraw={activePredictionDraw}
          handleOnActivePredictionDraw={handleOnActivePredictionDraw}
          draw={draw}
          setDraw={setDraw}
          isHundredKCompleted={hundredKCompleted}
        />
      </ModalHeader>
      <ModalBody customStyle={{ borderRadius: "0.625rem" }}>
        <PredictDrawWinnerTable
          draw={draw}
          allPredictionWinners={allPredictionWinners}
          myPredictionWinners={myPredictionWinners}
          activePredictionType={activePredictionType}
          activePredictionDraw={activePredictionDraw}
        />
      </ModalBody>
      <ModalFooter>
        {/* <S.FooterWrapper>
          <Navigators totalPages={5} currentPage={2} setCurrentPage={() => {}} />
        </S.FooterWrapper> */}
      </ModalFooter>
    </CustomModal>
  );
};
export default PredictDrawWinnerModal;
