import { Stack, styled, Typography } from "@mui/material";

namespace S {
  export const Container = styled(Stack)(({ theme }) => ({
    boxSizing: "border-box",
    flexDirection: "row",
    height: "25.6rem",
    maxWidth: "38.75rem",
    overflowY: "auto",
    [theme.breakpoints.down("sm")]: {
      minWidth: "calc(100vw - 1.875rem)",
    },
  }));

  export const ProtocolContainer = styled(Stack)(({ theme }) => ({
    boxSizing: "border-box",
    maxWidth: "50%",
    height: "100%",
    gap: theme.spacing(4),
    padding: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
      minWidth: "100%",
      padding: theme.spacing(2),
    },
  }));

  export const ContentHeaderText = styled(Typography)(() => ({
    fontSize: "1rem",
    fontWeight: "600",
  }));

  export const UnorderList = styled("ul")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(4),
    marginTop: "2.5rem",
    padding: "0 1.25rem",
    [theme.breakpoints.down("sm")]: {
      marginTop: "0rem",
    },
  }));

  export const NormalText = styled("li")(({ theme }) => ({
    listStyleType: "square",
    fontSize: "1.125rem",
    fontWeight: "600",
    listStyleImage: "linear-gradient(268.66deg, #FFFFFF 5.41%, #FFFFFF 93.94%)",
    "&::marker": {
      fontSize: "1.25rem",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.625rem",
      "&::marker": {
        fontSize: "0.875rem",
      },
    },
  }));

  export const RainbowText1 = styled(NormalText)(() => ({
    background: "radial-gradient(72.13% 132.54% at 27.87% 38.89%, #24FF00 0%, #FEE600 100%)",
    webkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    backgroundClip: "text",
    listStyleImage: "linear-gradient(268.66deg, #FFE600 5.41%, #24FF00 93.94%)",
  }));

  export const RainbowText2 = styled(NormalText)(() => ({
    fontSize: "0.875rem",
    background: "linear-gradient(92.48deg, #1967FC 1.43%, #19FCB8 75%)",
    webkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    backgroundClip: "text",
    listStyleImage: "linear-gradient(92.48deg, #1967FC 1.43%, #19FCB8 75%)",
  }));

  export const RainbowText3 = styled(NormalText)(() => ({
    fontSize: "0.875rem",
    background: "linear-gradient(337.35deg, #EB00FF 7.34%, #E9B80C 91.96%)",
    webkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    backgroundClip: "text",
    listStyleImage: "linear-gradient(337.35deg, #EB00FF 7.34%, #E9B80C 91.96%)",
  }));

  export const PieChartContainer = styled(Stack)(({ theme }) => ({
    minWidth: "50%",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  }));

  export const CustomPieChartImage = styled("img")(({ theme }) => ({
    maxHeight: "16.875rem",
    maxWidth: "18.125rem",
    width: "90%",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  }));

  export const CustomPieChartImageDownSm = styled("img")(({ theme }) => ({
    display: "none",
    [theme.breakpoints.down("sm")]: {
      margin: "0 auto",
      display: "block",
      maxHeight: "16.875rem",
      maxWidth: "18.125rem",
      width: "90%",
    },
  }));
}

export default S;
