import { Promise } from "bluebird";
import Web3 from "web3";
import store from "../../../store";
import { toggleIsBuying } from "../../../store/slices/user";
import Toast from "../../../utils/widgets/toast";
import erc20Abi from "../build/IERC20.json";
import { web3 } from "./web3";

export const TOKEN: { [id: number]: string | undefined } = {
  80001: process.env.REACT_APP_TOKEN_ADDRESS,
  56: process.env.REACT_APP_BSC_USDT,
};

export const approveTokenMaximumValue = "115792089237316195423570985008687907853269984665640564039457584007913129639935";
const CURRENT_CHAIN_ID: number = Number(process.env.REACT_APP_CURRENT_CHAINID);

export namespace iercContract {
  let deployed, iercContract: any;
  export const setup = async (library?: any) => {
    if (library) web3.setProvider(library);
    const IERCAbi = erc20Abi.abi as any;
    iercContract = new web3.eth.Contract(IERCAbi, TOKEN[CURRENT_CHAIN_ID]);
    Promise.promisifyAll(iercContract, { suffix: "Promise" });
    deployed = true;
    return deployed;
  };
  export const getBalance = async (address: string) => {
    let balance = await iercContract.methods.balanceOf(address).call();
    let readable = Web3.utils.fromWei(balance, "ether");
    return readable;
  };
  export const getDecimals = async () => {
    let decimals = await iercContract.methods.decimals().call();
    // console.log(decimals);
    return decimals;
  };
  export const getAllowance = async (address: string, contractAddress: string | undefined) => {
    let allowance = await iercContract.methods.allowance(address, contractAddress).call();
    let readable = Web3.utils.fromWei(allowance, "ether");
    return readable;
  };
  export const approve = async (
    address: string,
    maxAllowance: string,
    contractAddress: string | undefined,
    handleConnecting: () => void,
    cb: () => void,
  ) => {
    let gasPrice = await web3.eth.getGasPrice();

    iercContract.methods
      .approve(contractAddress, maxAllowance)
      .send({ from: address, gaslimit: 1100000, gasPrice })
      .on("transactionHash", (hash: any) => {
        // console.log("hash", hash);
        store.dispatch(toggleIsBuying({ loading: true, close: true }));
      })
      .on("receipt", (receipt: any) => {
        // console.log("receipt", receipt);
        if (receipt.status) {
          Toast({ message: "Allowance approved!", type: "success" });
          if (cb) cb();
        } else {
          handleConnecting();
          Toast({ message: "Approval was not successful!", type: "error" });
        }
      })
      .on("error", (error: any, receipt: any) => {
        console.log("error", error, receipt);
        Toast({ message: error.message, type: "error" });
        handleConnecting();
      });
  };
}
