import { FC } from "react";
import { useSelector } from "react-redux";
import { Table, TableBody, TableHead, TableRow, Typography } from "@mui/material";
import S from "./predict-draw-winner-table.styled";
import { predictionContract } from "../../../../services/ethereum/contract/predictionContract";
import { setIsRedeeming } from "../../../../store/slices/user";
import { GRADIENT, HUNDRED_K_DRAW, ONE_K_DRAW, PRIMARY } from "../../../../utils/constants";
import { getChecksumAddress, getOneKSlot, isMyself } from "../../../../utils/helpers";
import {
  useDraw100kNumber,
  useDraw1kNumber,
  useIsFetching100KPredictionWinners,
  useIsFetching1KPredictionWinners,
  useIsRedeeming,
  useIsRequestingMyPredictions,
  usePredictionAdminApproval,
} from "../../../../utils/hooks/selector";
import { useAppDispatch } from "../../../../utils/hooks/state";
import { Predict100KType, PredictType } from "../../../../utils/types";
import { PredictionHeaderType } from "../../../../utils/types/custom-modal";
import { UserSlicetypes } from "../../../../utils/types/state";
import { Button, GradientButton } from "../../../../utils/widgets";
import RoundImage from "../../../../utils/widgets/RoundImage";
import DotLoader from "../../../../utils/widgets/loader/DotLoader";

type PredictedDrawProps = {
  draw: number | null;
  allPredictionWinners: UserSlicetypes.PredictionWinner[];
  myPredictionWinners: UserSlicetypes.MyPredictionWinner[];
  activePredictionType: PredictionHeaderType;
  activePredictionDraw: typeof HUNDRED_K_DRAW | typeof ONE_K_DRAW;
};

const PredictDrawWinnerTable: FC<PredictedDrawProps> = (props) => {
  //props
  const { draw, allPredictionWinners, myPredictionWinners, activePredictionType, activePredictionDraw } = props;

  //constructors
  const dispatch = useAppDispatch();

  //state values
  const isRequestingMyPredictions = useSelector(useIsRequestingMyPredictions);
  const isFetching100KPredictionWinners = useSelector(useIsFetching100KPredictionWinners);
  const isFetching1KPredictionWinners = useSelector(useIsFetching1KPredictionWinners);
  const isRedeeming = useSelector(useIsRedeeming);
  const predictionAdminApproval = useSelector(usePredictionAdminApproval);
  const draw1kNumber = useSelector(useDraw1kNumber);
  const draw100kNumber = useSelector(useDraw100kNumber);

  //constants
  const ALL_PREDICTION_WINNERS_TABLE_HEADINGS = ["ADDRESS", "SLOT", "TYPE", "AMOUNT"];
  const MY_PREDICTION_WINNERS_TABLE_HEADINGS = ["DRAWS", "SLOT", "TYPE", "AMOUNT", "REDEEM"];
  const selectedHeadings =
    activePredictionType === PredictionHeaderType.allPredictions ? ALL_PREDICTION_WINNERS_TABLE_HEADINGS : MY_PREDICTION_WINNERS_TABLE_HEADINGS;

  //functions
  const handleOnRedeemPrediction = (drawNumber: number, address: string, type: string, id: string) => {
    dispatch(setIsRedeeming({ id, fetching: { redeeming: true, redeemed: false } }));
    if (type === PredictType.free || type === PredictType.premium) predictionContract.redeem1KPredictionRewards(`${drawNumber}`, address, type, id);
    else predictionContract.redeem100KPredictionRewards(address, type, id);
  };

  return (
    <S.TableContainer>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            {selectedHeadings.map((heading, i) => (
              <S.TableHeading key={`${heading}_${i}`}>{heading}</S.TableHeading>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {activePredictionType === PredictionHeaderType.allPredictions ? (
            <>
              {allPredictionWinners.map((pw, i) => {
                const isMe = isMyself(pw.address);
                const slot = activePredictionDraw === ONE_K_DRAW ? getOneKSlot(pw.drawNumber, draw1kNumber) : `1 - ${draw100kNumber}`;
                return (
                  <TableRow key={`${pw.id}_${pw.winnerToken}_${i}`}>
                    <S.TableContent mySelf={isMe}>
                      <S.DetailsWrapper mySelf={isMe}>
                        <RoundImage src={pw.dp} alt="dp-img" height={30} width={30} />
                        <Typography>
                          {getChecksumAddress(pw.address)}&nbsp;{isMe && <span>(Me)</span>}
                        </Typography>
                      </S.DetailsWrapper>
                    </S.TableContent>
                    <S.TableContent mySelf={isMe}>{slot}</S.TableContent>
                    <S.TableContent mySelf={isMe}>{pw.type}</S.TableContent>
                    {/* <S.TableContent mySelf={isMe}>{getChecksumAddress(pw.address)}</S.TableContent> */}
                    <S.TableContent mySelf={isMe}>{pw.amount}</S.TableContent>
                  </TableRow>
                );
              })}
            </>
          ) : (
            <>
              {myPredictionWinners.map((pw, i) => {
                const isMe = false;
                const slot = activePredictionDraw === ONE_K_DRAW ? getOneKSlot(pw.drawNumber, draw1kNumber) : `1 - ${draw100kNumber}`;
                return (
                  <TableRow key={`${pw.id}_${pw.winnerToken}_${i}`}>
                    <S.TableContent mySelf={isMe}>{pw.drawNumber}</S.TableContent>
                    <S.TableContent mySelf={isMe}>{slot}</S.TableContent>
                    <S.TableContent mySelf={isMe}>{pw.type}</S.TableContent>
                    <S.TableContent mySelf={isMe}>{pw.amount}</S.TableContent>
                    <S.TableContent mySelf={isMe}>
                      {[Predict100KType.free, PredictType.free].includes(pw.type as Predict100KType.free | PredictType.free) ? (
                        <Button
                          buttonType={PRIMARY}
                          onClick={() => handleOnRedeemPrediction(pw.drawNumber, pw.address, pw.type, pw.id)}
                          customStyle={{ fontSize: "12px", height: "28px", borderRadius: "0.25rem !important" }}
                          containerCustomStyle={{ borderRadius: "0.25rem !important" }}
                          disabled={
                            Boolean(pw.transactionHash.length) ||
                            isRedeeming[pw.id].redeeming ||
                            isRedeeming[pw.id].redeemed ||
                            !(
                              (activePredictionDraw === ONE_K_DRAW && predictionAdminApproval.oneK.includes(pw.drawNumber)) ||
                              (activePredictionDraw === HUNDRED_K_DRAW && Boolean(predictionAdminApproval.hundredK.length))
                            )
                          }
                        >
                          {(activePredictionDraw === ONE_K_DRAW && predictionAdminApproval.oneK.includes(pw.drawNumber)) ||
                          (activePredictionDraw === HUNDRED_K_DRAW && Boolean(predictionAdminApproval.hundredK.length))
                            ? isRedeeming[pw.id].redeeming
                              ? "Redeeming"
                              : pw.transactionHash.length || isRedeeming[pw.id].redeemed
                              ? "Redeemed"
                              : "Redeem"
                            : "Coming soon"}
                          loading={isRedeeming[pw.id].redeeming}
                        </Button>
                      ) : (
                        <GradientButton
                          buttonType={GRADIENT}
                          onClick={() => handleOnRedeemPrediction(pw.drawNumber, pw.address, pw.type, pw.id)}
                          customStyle={{ fontSize: "12px", height: "28px" }}
                          containerCustomStyle={{ borderRadius: "0.25rem !important" }}
                          disabled={
                            Boolean(pw.transactionHash.length) ||
                            isRedeeming[pw.id].redeeming ||
                            isRedeeming[pw.id].redeemed ||
                            !(
                              (activePredictionDraw === ONE_K_DRAW && predictionAdminApproval.oneK.includes(pw.drawNumber)) ||
                              (activePredictionDraw === HUNDRED_K_DRAW && Boolean(predictionAdminApproval.hundredK.length))
                            )
                          }
                          loading={isRedeeming[pw.id].redeeming}
                        >
                          {(activePredictionDraw === ONE_K_DRAW && predictionAdminApproval.oneK.includes(pw.drawNumber)) ||
                          (activePredictionDraw === HUNDRED_K_DRAW && Boolean(predictionAdminApproval.hundredK.length))
                            ? isRedeeming[pw.id].redeeming
                              ? "Redeeming"
                              : pw.transactionHash.length || isRedeeming[pw.id].redeemed
                              ? "Redeemed"
                              : "Redeem"
                            : "Coming soon"}
                        </GradientButton>
                      )}
                    </S.TableContent>
                  </TableRow>
                );
              })}
            </>
          )}
        </TableBody>
      </Table>
      {activePredictionType === PredictionHeaderType.allPredictions ? (
        <S.NoRecordContainer>
          {activePredictionDraw === ONE_K_DRAW ? (
            draw && isFetching1KPredictionWinners[`${draw}`] ? (
              <DotLoader />
            ) : !allPredictionWinners.length ? (
              draw ? (
                "No winners found!"
              ) : (
                "Search draw for predict winners."
              )
            ) : (
              ""
            )
          ) : (
            <>{isFetching100KPredictionWinners ? <DotLoader /> : !allPredictionWinners.length ? "No winners found!" : ""}</>
          )}
        </S.NoRecordContainer>
      ) : (
        <S.NoRecordContainer>
          {isRequestingMyPredictions ? <DotLoader /> : !myPredictionWinners.length ? "No winners found!" : ""}
        </S.NoRecordContainer>
      )}
    </S.TableContainer>
  );
};

export default PredictDrawWinnerTable;
