import { FC } from "react";
import S from "./ellipse-container.styled";
import { progressBarProps } from "../../types/progress-bar";

const EllipseContainer: FC<progressBarProps> = ({ gradient, percentage }) => {
  return (
    <S.EllipseWrapper gradient={gradient}>
      <S.EllipseContainerContent gradient={gradient}>{percentage}%</S.EllipseContainerContent>
    </S.EllipseWrapper>
  );
};

export default EllipseContainer;
