import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { StakeSliceType } from "../../utils/types/state";

const initialState: StakeSliceType.InitialState = {
  isStaking: false,
  stakeTransactionHash: "",
  isWithdrawing: false,
  withdrawTransactionHash: "",
  myStakes: {
    fetching: false,
    payload: "0",
  },
  myRewards: {
    fetching: false,
    payload: "0",
  },
  isClaiming: false,
  stakeClaimAmount: "0",
  lastClaimedTime: {
    canClaim: false,
    claimAfter: 0,
  },
};

const stakeSlice = createSlice({
  name: "stake",
  initialState,
  reducers: {
    toggleIsStaking: (state, action: PayloadAction<{ loading: boolean }>) => {
      state.isStaking = action.payload.loading;
    },
    setStakeTransactionHash: (state, action: PayloadAction<{ hash: string }>) => {
      state.stakeTransactionHash = action.payload.hash;
      state.isStaking = false;
    },
    toggleIsWithdrawing: (state, action: PayloadAction<{ loading: boolean }>) => {
      state.isWithdrawing = action.payload.loading;
    },
    setWithdrawTransactionHash: (state, action: PayloadAction<{ hash: string }>) => {
      state.withdrawTransactionHash = action.payload.hash;
    },
    setIsFetchingMyStakes: (state, action: PayloadAction<boolean | undefined>) => {
      state.myStakes.fetching = action.payload ?? initialState.myStakes.fetching;
    },
    setMyStakes: (state, action: PayloadAction<string>) => {
      state.myStakes.payload = action.payload;
      state.myStakes.fetching = false;
    },
    setIsFetchingMyRewards: (state, action: PayloadAction<boolean | undefined>) => {
      state.myRewards.fetching = action.payload ?? initialState.myRewards.fetching;
    },
    setMyRewards: (state, action: PayloadAction<string>) => {
      state.myRewards.payload = action.payload;
      state.myRewards.fetching = false;
    },
    toggleIsClaiming: (state, action: PayloadAction<{ loading: boolean }>) => {
      state.isClaiming = action.payload.loading;
    },
    setStakeClaimAmount: (state, action: PayloadAction<string>) => {
      state.stakeClaimAmount = action.payload;
    },
    setLastClaimedTime: (state, action: PayloadAction<{ canClaim: boolean; claimAfter: number }>) => {
      const { canClaim, claimAfter } = action.payload;

      state.lastClaimedTime.claimAfter = claimAfter;
      state.lastClaimedTime.canClaim = canClaim;
    },
  },
});

export const {
  toggleIsStaking,
  setStakeTransactionHash,
  toggleIsWithdrawing,
  setWithdrawTransactionHash,
  setIsFetchingMyStakes,
  setMyStakes,
  setIsFetchingMyRewards,
  setMyRewards,
  toggleIsClaiming,
  setStakeClaimAmount,
  setLastClaimedTime,
} = stakeSlice.actions;

export const stakeReducer = stakeSlice.reducer;
